import { useMemo, useCallback } from "react";
import { InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../../../../core";
import { OpSchema, operationaliseV2ApiService } from "..";

export const useFetchOpSchema = (opId: string, incidentId: string, generateDemoData = false) => {
  const initialState = useMemo<InitialState<OpSchema, string>>(
    () => ({
      data: null,
      error: null,
      isError: false,
      isFetching: true,
      isSuccess: false
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<OpSchema, string>>(async () => {
    const result: FetchCallBackResult<OpSchema, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    if (opId) {
      try {
        const promise = operationaliseV2ApiService.fetchOpConfigSchema(opId, incidentId, generateDemoData);

        const { data, error, message } = await promise;

        if (error) {
          result.isError = true;
          result.error = message.toString();
        } else {
          result.data = data;
          result.isSuccess = true;
        }
      } catch (err) {
        result.isError = true;
        result.error = err.message?.toString() || err.toString();
      }
    } else {
      result.error = "Invalid opConfigId";
      result.isError = true;
    }

    return result;
  }, [generateDemoData, incidentId, opId]);

  return useDataFetch(fetchFn, initialState, true);
};
