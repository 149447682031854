import { CancelToken } from "axios";
import { isEmpty } from "lodash";
import { asyncGetItem, getParamFromUrl } from "../../../core";
import { IncidentDataResponseItem } from "../CommonAlerts";
import { MTSIncidentSummaryLine } from "../operationalise";
import { DEMO_SUMMARY_PAYLOAD_KEYS } from "../Triage";
import kbn from "../../datasources/core/kbn";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { isErrorResponse } from "../utils";
import { EntityWidgetData } from "../../../biz-entity";
import {
  IncidentTimelineResponse,
  IncidentTimelineRequest,
  KeyContributorsRequest,
  KeyContributorsResponse
} from "./types/IncidentTimelineTypes";
import { ImpactWidgetDataRequest, ImpactWidgetViewRequest, ImpactedWidget } from "./types/triageTypes";
import { BizService, BizEntityApiResult } from "./BizServiceCommon";
import {
  IncidentRequest,
  IncidentResponse,
  SliceSet,
  TriageAdhocRequest,
  TriageRequestCommonConfig,
  WidgetAlertSummaryRequest,
  WidgetAlertSummaryResponse,
  TagFilter,
  TriageResponse,
  WidgetConfigDTO,
  BizEntityInfo,
  CohortIncidentResponse,
  CohortEntityFilter,
  EventFieldFilter,
  EventFieldList,
  TriageVersion,
  BizDataQuery,
  FunnelSchema,
  FunnelSchemaResponse,
  AlertCorrelationResponse
} from "./types";
import { AlertSummaryType } from "./types/common";
import { getSelectorSpecFromTagFilters } from "./widget/widgetConfigUtils";
import {
  WidgetAlertSummaryRequest2,
  UserServiceFieldList,
  WidgetAlertsSummaryResponseV3,
  TriageClustersResponse,
  CorrelatedEventsRequest,
  CorrelatedEventsResponse,
  WidgetCorrelatedEventsRequest
} from "./types/alertTypes";
import {
  WidgetDataDTO,
  WidgetData,
  UserServiceFieldSlice,
  ExploreEntityFilter,
  UserServiceFilterList,
  UserServiceFilterExpression
} from "./types/exploreTypes";

type Processor = keyof TriageRequestCommonConfig;
type SummaryResponse = WidgetAlertsSummaryResponseV3 | TriageClustersResponse;
type SummaryRequest = WidgetAlertSummaryRequest | WidgetAlertSummaryRequest2;

class AlertApiService extends BizService {
  async getIncidentTimeline(
    incidentId: string,
    numBins: number,
    entityFilters: ExploreEntityFilter[],
    eventFieldFilters: UserServiceFilterList,
    startTimeMillis: number,
    endTimeMillis: number,
    includeActionThreads = false,
    generateDemoData = false
  ) {
    await this.init();
    let subUrl = `alert/${incidentId}/timeline?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`;

    if (includeActionThreads) {
      subUrl += `&includeActionThreads=${includeActionThreads}`;
    }

    if (generateDemoData) {
      subUrl += `&generateDemoData=${generateDemoData}`;
    }

    const url = this.getBizEntityUrl(subUrl);
    const makeRequest = () => {
      const payload: IncidentTimelineRequest = {
        entityFilters: entityFilters || [],
        eventFieldFilters: eventFieldFilters || {
          userServiceFilters: []
        },
        numBins
      };
      return this.datasource.post<IncidentTimelineResponse, IncidentTimelineRequest>(url, payload);
    };

    // Add MTSLines by extracting data from alert
    const response = await this.getResult(makeRequest);
    const { bins, alert } = response?.data || {};
    if (bins?.length && alert) {
      const mtsById: Record<string, MTSIncidentSummaryLine> = {};

      alert.incidentPayload?.incident?.timeSeriesSummary?.linesByTimeSeries?.forEach(line => {
        const { summaryLine } = line;
        const { mtsId } = summaryLine || {};

        if (mtsId) {
          mtsById[mtsId] = summaryLine;
        }
      });

      alert.incidentPayload?.incident?.seriesChangeSummary?.seriesChangeSummarycomponents?.forEach(summaryLine => {
        const { mtsId } = summaryLine || {};
        if (mtsId) {
          mtsById[mtsId] = summaryLine;
        }
      });

      alert.incidentPayload?.incident?.timeOutlierSummary?.outliers?.forEach(summaryLine => {
        const { mtsId } = summaryLine || {};
        if (mtsId) {
          mtsById[mtsId] = {
            lastSeenSec: summaryLine.lastSeenSec,
            mtsId: summaryLine.mtsId,
            ranges: summaryLine.ranges,
            ts: summaryLine.ts
          };
        }
      });

      alert.incidentPayload?.incident?.seriesOutlierSummary?.outliers?.forEach(summaryLine => {
        const { mtsId } = summaryLine;

        if (mtsId) {
          mtsById[mtsId] = {
            lastSeenSec: null,
            mtsId: summaryLine.mtsId,
            ranges: summaryLine.ranges,
            ts: summaryLine.ts
          };
        }
      });

      bins.forEach(b => {
        b.activeMTSLines = b.activeMTS.map(id => mtsById[id]).filter(Boolean);
        b.stoppedNowMTSLines = b.stoppedNowMTS.map(id => mtsById[id]).filter(Boolean);
      });
    }

    return response;
  }

  async getKeyContributors(
    incidentId: string,
    opConfigId: string,
    entityFilters: ExploreEntityFilter[],
    eventFieldFilters: UserServiceFilterList,
    startTimeMillis: number,
    endTimeMillis: number,
    diagnosticFields?: UserServiceFieldSlice[],
    alertingFields?: UserServiceFieldSlice[]
  ) {
    await this.init();
    const subUrl = `alert/${incidentId}/keyContributors?startTimeMillis=${startTimeMillis}&endTimeMillis=${endTimeMillis}`;
    const url = this.getBizEntityUrl(subUrl);
    const makeRequest = () => {
      const payload: KeyContributorsRequest = {
        opConfigId,
        entityFilters: entityFilters || [],
        eventFieldFilters: eventFieldFilters || {
          userServiceFilters: []
        }
      };

      if (diagnosticFields?.length) {
        payload.diagnosticFields = {
          slices: diagnosticFields
        };
      }

      if (alertingFields?.length) {
        payload.alertingFields = {
          slices: alertingFields
        };
      }

      return this.datasource.post<KeyContributorsResponse, KeyContributorsRequest>(url, payload);
    };
    return this.getResult(makeRequest);
  }

  async getIncidentsForCohort(entityTypeId: string, cohortId: string, startTimeMillis: number, endTimeMillis: number) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = `${entityTypeId}/cohort/${cohortId}/incidents${timeParams}`;
    const url = this.getBizEntityUrl(subUrl);
    const makeRequest = () => {
      const payload = {};
      return this.datasource.post<CohortIncidentResponse, any>(url, payload);
    };
    return this.getResult(makeRequest);
  }

  async getIncidents(
    entityId: string,
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number,
    widgetId: string,
    metricIds: string[],
    mode: AlertSummaryType,
    incidentIds?: string[]
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = `/${widgetId}/incidents${timeParams}`;
    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const result: BizEntityApiResult<IncidentResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const body: IncidentRequest = {
      metricIds,
      mode,
      incidentIds
    };

    try {
      const demoResponse = await asyncGetItem(DEMO_SUMMARY_PAYLOAD_KEYS.incidentPayload);
      const response = getParamFromUrl("demo")
        ? demoResponse
        : await this.datasource.post<IncidentResponse, IncidentRequest>(url, body);
      const { errors } = response.data;
      if (!errors?.length) {
        result.data = response.data;
      } else {
        result.error = true;
        result.message = errors.join("\n");
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getIncident(
    entityId: string,
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number,
    widgetId: string,
    metricId: string,
    incidentId: string,
    compareId: string
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = `/${widgetId}/metric/${metricId}/compare/${compareId}/incident/${incidentId}${timeParams}`;
    const url = this.getWidgetUrl(entityId, entityType, subUrl);

    const result: BizEntityApiResult<IncidentResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const demoResponse = await asyncGetItem(DEMO_SUMMARY_PAYLOAD_KEYS.incidentPayload);
      const response = getParamFromUrl("demo") ? demoResponse : await this.datasource.get<IncidentResponse, any>(url);
      const { errors } = response.data;
      if (!errors?.length) {
        result.data = response.data;
      } else {
        result.error = true;
        result.message = errors.join("\n");
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getIncidentsCount(
    entityId: string,
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number,
    widgetId: string,
    metricIds: string[]
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = `/${widgetId}/incidents/count${timeParams}`;
    const url = this.getWidgetUrl(entityId, entityType, subUrl);
    const result: BizEntityApiResult<IncidentResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const body: Partial<IncidentRequest> = {
      metricIds
    };

    try {
      const response = await this.datasource.post<IncidentResponse, Partial<IncidentRequest>>(url, body);
      const { errors } = response.data;
      if (!errors?.length) {
        result.data = response.data;
      } else {
        result.error = true;
        result.message = errors.join("\n");
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getCorrelatedEvents(
    incidentId: string = null,
    opId: string = null,
    startTimeMillis: number,
    endTimeMillis: number,
    userServiceFilterExpressions?: UserServiceFilterExpression[],
    bins?: number,
    eventFieldSearchList?: CorrelatedEventsRequest["eventFieldSearchList"]
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = `triage/getCorrelatedEvents/${opId}/${incidentId}${timeParams}`;
    const url = this.getBizEntityUrl(subUrl);
    const result: BizEntityApiResult<CorrelatedEventsResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const body: CorrelatedEventsRequest = {
      bins: bins || 1
    };

    if (userServiceFilterExpressions?.length) {
      body.eventFieldSearchList = {
        requests: [
          {
            userServiceFilterExpression: userServiceFilterExpressions
          }
        ]
      };
    }

    if (eventFieldSearchList) {
      body.eventFieldSearchList = eventFieldSearchList;
    }

    try {
      const response = await this.datasource.post<CorrelatedEventsResponse, CorrelatedEventsRequest>(url, body);
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getCorrelatedEventsForWidgets(
    startTimeMillis: number,
    endTimeMillis: number,
    userServiceFilterExpressions?: UserServiceFilterExpression[],
    bins?: number,
    widgetId?: string,
    widgetConfig?: WidgetConfigDTO
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const subUrl = `triage/getCorrelatedEvents${timeParams}`;
    const url = this.getBizEntityUrl(subUrl);
    const result: BizEntityApiResult<CorrelatedEventsResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const correlatedEventSearchRequest: CorrelatedEventsRequest = {
      bins: bins || 1
    };

    if (userServiceFilterExpressions?.length) {
      correlatedEventSearchRequest.eventFieldSearchList = {
        requests: [
          {
            userServiceFilterExpression: userServiceFilterExpressions
          }
        ]
      };
    }

    const body: WidgetCorrelatedEventsRequest = {
      correlatedEventSearchRequest,
      widgetConfigSource: {
        widgetId,
        widgetConfig
      }
    };

    try {
      const response = await this.datasource.post<CorrelatedEventsResponse, WidgetCorrelatedEventsRequest>(url, body);
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getAlertSummary(
    entityId: string,
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number,
    widgetId: string,
    metricId: string,
    sliceSet: SliceSet,
    bizEntityIds: BizEntityInfo[],
    tagFilters: TagFilter[][],
    cohortEntityFilters: CohortEntityFilter[],
    mode: AlertSummaryType,
    processors: Processor[] = [
      "bizFieldAnalysis",
      "correlatedIncidents",
      "releaseEvents",
      "deviationAnalysis",
      "fieldValues"
    ],
    widgetConfig?: WidgetConfigDTO,
    compareId: string = null,
    incidentId: string = null,
    mqId: string = null,
    eventFieldFilters: EventFieldFilter[] = null,
    analysisFields: EventFieldList[] = null,
    triageVersion: TriageVersion = "TRIAGE_V1",
    baselineTimeRangeMillis = 86400000,
    childMetricId?: string,
    triageApiVersion?: "old" | "new",
    bizDataQuery?: BizDataQuery,
    impactedWidget?: ImpactedWidget
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const isNewTriageApi = triageApiVersion === "new";
    const url = !isNewTriageApi
      ? this.getWidgetUrl(entityId, entityType, `/alerts/summary${timeParams}`)
      : this.getBizEntityUrl(`triage${timeParams}`);

    const result: BizEntityApiResult<WidgetAlertSummaryResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const config: TriageRequestCommonConfig = this.getTriageRequestCommonConfig(processors);

    const baseValid = Boolean(bizEntityIds && mode);
    const paramsValid = isNewTriageApi ? baseValid : baseValid && Boolean(metricId && sliceSet);

    if (!paramsValid) {
      result.error = true;
      result.message = "Invalid request parameters";
    } else {
      const entityFilters = this.getExploreEntityFilters(cohortEntityFilters);
      const oldBody: WidgetAlertSummaryRequest = {
        widgetId,
        metricId,
        compareId,
        incidentId,
        mqId,
        sliceSet,
        bizEntityIds,
        mode,
        config,
        filters: getSelectorSpecFromTagFilters(tagFilters),
        entityFilters,
        eventFieldFilters,
        analysisFields,
        triageVersion,
        baselineTimeRangeMillis,
        bizDataQuery,
        impactedWidget
      };

      if (widgetConfig) {
        oldBody.widgetConfig = {
          ...widgetConfig,
          visualizations: widgetConfig?.visualizations.map(x => JSON.stringify(x)) as any[]
        };
      }

      const body: SummaryRequest = isNewTriageApi ? convertToNewTriageRequestPayload(oldBody, childMetricId) : oldBody;

      try {
        const response: any = getParamFromUrl("demo")
          ? await asyncGetItem(DEMO_SUMMARY_PAYLOAD_KEYS.triagePayload)
          : await this.datasource.post<WidgetAlertSummaryResponse, SummaryRequest>(url, body);

        const { errors } = response.data;
        if (!errors?.length) {
          result.data = response.data;
        } else {
          result.error = true;
          result.message = errors.join("\n");
        }
      } catch (err) {
        this.handleError(err, result);
      }
    }

    return result;
  }

  async getAlertSummaryV3(
    startTimeMillis: number,
    endTimeMillis: number,
    widgetId: string,
    metricId: string,
    sliceSet: SliceSet,
    bizEntityIds: BizEntityInfo[],
    tagFilters: TagFilter[][],
    cohortEntityFilters: CohortEntityFilter[],
    mode: AlertSummaryType,
    processors: Processor[] = [
      "bizFieldAnalysis",
      "correlatedIncidents",
      "releaseEvents",
      "deviationAnalysis",
      "fieldValues"
    ],
    widgetConfig?: WidgetConfigDTO,
    compareId: string = null,
    incidentId: string = null,
    mqId: string = null,
    eventFieldFilters: EventFieldFilter[] = null,
    analysisFields: EventFieldList[] = null,
    baselineTimeRangeMillis = 86400000,
    childMetricId?: string,
    bizDataQuery?: BizDataQuery,
    impactedWidget?: ImpactedWidget,
    fetchClusters?: boolean,
    generateDemoData?: boolean
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = fetchClusters
      ? this.getBizEntityUrl(`triage/clusters${timeParams}`)
      : this.getBizEntityUrl(`triage/v3${timeParams}`);

    const result: BizEntityApiResult<SummaryResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const config: TriageRequestCommonConfig = this.getTriageRequestCommonConfig(processors);

    // const paramsValid = Boolean(metricId && sliceSet);

    // if (!paramsValid) {
    //   result.error = true;
    //   result.message = "Invalid request parameters";
    // } else {
    const entityFilters = this.getExploreEntityFilters(cohortEntityFilters);
    const oldBody: WidgetAlertSummaryRequest = {
      widgetId,
      metricId,
      compareId,
      incidentId,
      mqId,
      sliceSet,
      bizEntityIds,
      mode,
      config,
      filters: getSelectorSpecFromTagFilters(tagFilters),
      entityFilters,
      eventFieldFilters,
      analysisFields,
      triageVersion: "TRIAGE_V1",
      baselineTimeRangeMillis,
      bizDataQuery,
      impactedWidget
    };

    if (widgetConfig) {
      oldBody.widgetConfig = {
        ...widgetConfig,
        visualizations: widgetConfig?.visualizations.map(x => JSON.stringify(x)) as any[]
      };
    }

    const body: SummaryRequest = convertToNewTriageRequestPayload(oldBody, childMetricId);

    try {
      const response: any = await this.datasource.post<SummaryResponse, SummaryRequest>(url, body, {
        params: { generateDemoData }
      });

      const { errors } = response.data;
      if (!errors?.length) {
        result.data = response.data;
      } else {
        result.error = true;
        result.message = errors.join("\n");
      }
    } catch (err) {
      this.handleError(err, result);
    }
    // }

    return result;
  }

  async getAlertSummaryV3ByRequest(
    request: WidgetAlertSummaryRequest2,
    startTimeMillis: number,
    endTimeMillis: number,
    fetchClusters?: boolean,
    generateDemoData?: boolean
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const url = fetchClusters
      ? this.getBizEntityUrl(`triage/clusters${timeParams}`)
      : this.getBizEntityUrl(`triage/v3${timeParams}`);

    const result: BizEntityApiResult<WidgetAlertsSummaryResponseV3 | TriageClustersResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response: any = await this.datasource.post<
        WidgetAlertsSummaryResponseV3 | TriageClustersResponse,
        WidgetAlertSummaryRequest2
      >(url, request, {
        params: {
          generateDemoData
        }
      });

      const { errors } = response.data;
      if (!errors?.length) {
        result.data = response.data;
      } else {
        result.error = true;
        result.message = errors.join("\n");
      }
    } catch (err) {
      this.handleError(err, result);
    }
    // }

    return result;
  }

  async getTriageData(
    entityId: string,
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number,
    widgetId: string,
    metricId: string,
    sliceSet: SliceSet = null,
    bizEntityIds: BizEntityInfo[] = [],
    tagFilters: TagFilter[][] = [],
    cohortEntityFilters: CohortEntityFilter[] = [],
    processors: Processor[] = [
      "bizFieldAnalysis",
      "correlatedIncidents",
      "releaseEvents",
      "deviationAnalysis",
      "fieldValues"
    ],
    widgetConfigDTO: WidgetConfigDTO = null // pass this to activate adhoc triage
  ) {
    await this.init();
    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const triageUrl = `/triage${timeParams}`;
    const url = this.getWidgetUrl(entityId, entityType, triageUrl);

    const result: BizEntityApiResult<TriageResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    if (!metricId || !sliceSet || !bizEntityIds) {
      result.error = true;
      result.message = "Invalid request parameters";
    } else {
      const widgetConfig = widgetConfigDTO ? this.getWidgetConfigFromDto(widgetConfigDTO) : null;
      const entityFilters = this.getExploreEntityFilters(cohortEntityFilters);
      const triageRequest: TriageAdhocRequest = {
        widgetConfig,
        widgetId,
        metricId,
        sliceSet,
        bizEntityIds,
        filters: getSelectorSpecFromTagFilters(tagFilters),
        config: this.getTriageRequestCommonConfig(processors),
        entityFilters
      };
      try {
        const response = await this.datasource.post<TriageResponse, TriageAdhocRequest>(url, triageRequest);
        const { errors } = response.data;
        if (!errors?.length) {
          result.data = response.data;
        } else {
          result.error = true;
          result.message = errors.join("\n");
        }
      } catch (err) {
        this.handleError(err, result);
      }
    }

    return result;
  }

  async getImpactedWidgetData(
    reqPayload: ImpactWidgetDataRequest,
    startTimeMillis: number,
    endTimeMillis: number,
    intervalSecs?: number,
    cancelToken?: CancelToken
  ) {
    const { generateDemoData, ...payload } = reqPayload;

    const intervalMillis =
      startTimeMillis && endTimeMillis
        ? kbn.calculateInterval(
            {
              from: startTimeMillis,
              to: endTimeMillis
            },
            null,
            "1m"
          ).intervalMs
        : 60 * 1000;

    intervalSecs = intervalSecs || timeRangeUtils.getSecondsFromMillis(intervalMillis);

    if (!payload.bins) {
      delete payload.bins;
    }

    if (!payload.timeDuration) {
      delete payload.timeDuration;
    }

    await this.init();

    const timeParams =
      this.getTimeParams(startTimeMillis, endTimeMillis) + (generateDemoData ? `&generateDemoData=true` : "");
    const triageUrl = `triage/impactWidget/data${timeParams}`;
    const url = this.getBizEntityUrl(triageUrl);

    const result: BizEntityApiResult<WidgetDataDTO> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await this.datasource.post<WidgetData, ImpactWidgetDataRequest>(url, payload, { cancelToken });
      const metricData = this.processWidgetDataResponse(response, startTimeMillis, endTimeMillis, intervalSecs);

      this.enrichResults(metricData);

      result.data = metricData;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getImpactedWidgetDrawerData(
    payload: ImpactWidgetViewRequest,
    startTimeMillis: number,
    endTimeMillis: number,
    intervalSecs?: number,
    cancelToken?: CancelToken
  ) {
    const intervalMillis =
      startTimeMillis && endTimeMillis
        ? kbn.calculateInterval(
            {
              from: startTimeMillis,
              to: endTimeMillis
            },
            null,
            "1m"
          ).intervalMs
        : 60 * 1000;

    intervalSecs = intervalSecs || timeRangeUtils.getSecondsFromMillis(intervalMillis);

    if (!payload.bins) {
      delete payload.bins;
    }

    if (!payload.timeDuration) {
      delete payload.timeDuration;
    }

    await this.init();

    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const triageUrl = `triage/impactWidget/events${timeParams}`;
    const url = this.getBizEntityUrl(triageUrl);

    const result: BizEntityApiResult<EntityWidgetData> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await this.datasource.post<EntityWidgetData, ImpactWidgetViewRequest>(url, payload, {
        cancelToken
      });

      result.data = response.data;
      result.error = response.status !== 200;
      result.message = response.statusText;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async downloadWidgetDataByConfig(
    payload: ImpactWidgetViewRequest,
    startTimeMillis: number,
    endTimeMillis: number,
    intervalSecs?: number,
    cancelToken?: CancelToken
  ) {
    const intervalMillis =
      startTimeMillis && endTimeMillis
        ? kbn.calculateInterval(
            {
              from: startTimeMillis,
              to: endTimeMillis
            },
            null,
            "1m"
          ).intervalMs
        : 60 * 1000;

    intervalSecs = intervalSecs || timeRangeUtils.getSecondsFromMillis(intervalMillis);

    if (!payload.bins) {
      delete payload.bins;
    }

    if (!payload.timeDuration) {
      delete payload.timeDuration;
    }

    await this.init();

    const timeParams = this.getTimeParams(startTimeMillis, endTimeMillis);
    const triageUrl = `triage/impactWidget/events/download${timeParams}`;
    const url = this.getBizEntityUrl(triageUrl);

    const result: BizEntityApiResult<Blob> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await this.datasource.post<Blob, ImpactWidgetViewRequest>(url, payload, {
        cancelToken,
        responseType: "blob"
      });

      result.data = response.data;
      result.error = response.status !== 200;
      result.message = response.statusText;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getJourneyFunnelSchema(opId: string, funnelId: string, startTimeMillis: number, endTimeMillis: number) {
    await this.init();

    const result: BizEntityApiResult<FunnelSchema> = {
      data: null,
      error: false,
      message: null
    };

    const subUrl = `triage/${opId}/funnel/${funnelId}/schema`;
    const url = this.getBizEntityUrl(subUrl);

    const params = {
      startTimeMillis,
      endTimeMillis
    };

    try {
      const { data, status, statusText } = await this.datasource.get<FunnelSchemaResponse, null>(url, null, { params });

      if (isErrorResponse(status)) {
        result.error = true;
        result.message = statusText;
      } else {
        const { schema, responseInfo, statusCode } = data;

        if (isErrorResponse(statusCode)) {
          result.error = true;
          result.message =
            responseInfo.errors?.join(", ") || responseInfo.status || "Error fetching journey funnel data";
        } else {
          result.data = schema;
        }
      }
    } catch (err) {
      this.handleError(err, result);
    }

    return result;
  }

  async getIncidentDetails(incidentId: string, generateDemoData = false) {
    const url = generateDemoData
      ? this.getBizEntityUrl(`alert/${incidentId}?includeActionThreads=true&generateDemoData=true`)
      : `/api/alert/incident/${incidentId}?includeActionThreads=true`;

    const result: BizEntityApiResult<IncidentDataResponseItem> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };
    try {
      await this.init();
      const response = await this.datasource.get<IncidentDataResponseItem, null>(url);
      result.data = response.data;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async getGroupedIncident(groupedIncidentId: string, generateDemoData = false) {
    const url = this.getBizEntityUrl(`incident/groupedIncident/${groupedIncidentId}`);
    await this.init();

    const result: BizEntityApiResult<AlertCorrelationResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await this.datasource.get<AlertCorrelationResponse, null>(url, null, {
        params: {
          generateDemoData
        }
      });

      result.data = response.data;
      result.error = response.status !== 200;
      result.message = response.statusText;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  summaryAnalysis(
    entityId: string,
    entityType: string,
    startTimeMillis: number,
    endTimeMillis: number,
    widgetId: string,
    metricId: string,
    sliceSet: SliceSet,
    bizEntityIds: BizEntityInfo[],
    tagFilters: TagFilter[][] = [],
    mode: AlertSummaryType,
    entityFilters: CohortEntityFilter[] = [],
    widgetConfig?: WidgetConfigDTO,
    compareId?: string,
    incidentId?: string,
    mqId?: string
  ) {
    return {
      getDeviationAnalysis: () =>
        this.getAlertSummary(
          entityId,
          entityType,
          startTimeMillis,
          endTimeMillis,
          widgetId,
          metricId,
          sliceSet,
          bizEntityIds,
          tagFilters,
          entityFilters,
          mode,
          ["deviationAnalysis"],
          widgetConfig
        ),

      getCorrelatedIncidents: () =>
        this.getAlertSummary(
          entityId,
          entityType,
          startTimeMillis,
          endTimeMillis,
          widgetId,
          metricId,
          sliceSet,
          bizEntityIds,
          tagFilters,
          entityFilters,
          mode,
          ["correlatedIncidents"],
          widgetConfig,
          compareId,
          incidentId,
          mqId
        ),

      getBusinessFieldAnalysis: () =>
        this.getAlertSummary(
          entityId,
          entityType,
          startTimeMillis,
          endTimeMillis,
          widgetId,
          metricId,
          sliceSet,
          bizEntityIds,
          tagFilters,
          entityFilters,
          mode,
          ["bizFieldAnalysis"],
          widgetConfig,
          compareId,
          incidentId,
          mqId
        ),

      getReleaseEvents: () =>
        this.getAlertSummary(
          entityId,
          entityType,
          startTimeMillis,
          endTimeMillis,
          widgetId,
          metricId,
          sliceSet,
          bizEntityIds,
          tagFilters,
          entityFilters,
          mode,
          ["releaseEvents"],
          widgetConfig,
          compareId,
          incidentId,
          mqId
        ),
      getFieldValues: () =>
        this.getAlertSummary(
          entityId,
          entityType,
          startTimeMillis,
          endTimeMillis,
          widgetId,
          metricId,
          sliceSet,
          bizEntityIds,
          tagFilters,
          entityFilters,
          mode,
          ["fieldValues"],
          widgetConfig,
          compareId,
          incidentId,
          mqId
        )
    };
  }

  private getTriageRequestCommonConfig(processors: Processor[]): TriageRequestCommonConfig {
    const config: TriageRequestCommonConfig = {
      bizFieldAnalysis: processors.indexOf("bizFieldAnalysis") !== -1,
      correlatedIncidents: processors.indexOf("correlatedIncidents") !== -1,
      correlatedEntities: processors.indexOf("correlatedIncidents") !== -1,
      releaseEvents: processors.indexOf("releaseEvents") !== -1,
      deviationAnalysis: processors.indexOf("deviationAnalysis") !== -1,
      fieldValues: processors.indexOf("fieldValues") !== -1
    };
    return config;
  }
}

const alertApiService = new AlertApiService();

export default alertApiService;

const convertToUSFFilters = (evfs: EventFieldFilter[]): UserServiceFilterList => ({
  userServiceFilters: evfs.map(e => ({
    userServiceFilterExpressions: e.filters.map(efp => ({
      field: {
        fieldName: efp.eventField.fieldName,
        dataType: efp.eventField.dataType,
        bizEntityFieldName: null,
        displayBizEntityFieldName: null,
        userServices: efp.eventField?.userServices || [],
        allUserServices: efp.eventField?.userServices?.length ? false : true,
        entityField: efp.eventField.entityField
      },
      operator: efp.op,
      value: efp.value,
      values: efp.values
    }))
  }))
});

const convertToUSFFilters2 = (evfList: EventFieldList[]): UserServiceFieldList[] =>
  evfList.map(evf => ({
    fields: evf.eventFields.map(eventField => ({
      fieldName: eventField.fieldName,
      dataType: eventField.dataType,
      bizEntityFieldName: null,
      displayBizEntityFieldName: null,
      userServices: eventField?.userServices || [],
      allUserServices: eventField?.userServices?.length ? false : true,
      entityField: eventField.entityField
    }))
  }));

function convertToNewTriageRequestPayload(
  reqPayload: WidgetAlertSummaryRequest,
  childMetricId: string
): WidgetAlertSummaryRequest2 {
  const {
    incidentId,
    config,
    baselineTimeRangeMillis,
    entityFilters,
    eventFieldFilters,
    analysisFields,
    compareId,
    widgetConfig,
    sliceSet,
    metricId,
    filters,
    bizDataQuery,
    impactedWidget
  } = reqPayload;

  const payload: WidgetAlertSummaryRequest2 = {
    incidentId,
    config,
    baselineTimeRangeMillis,
    entityFilters,
    eventFieldFilters: convertToUSFFilters(eventFieldFilters),
    userServiceFields: {
      fields: convertToUSFFilters2(analysisFields)
    }
  };

  if (!isEmpty(childMetricId)) {
    payload.childMetricId = childMetricId;
  }

  if (!isEmpty(compareId)) {
    payload.opConfigId = compareId;
  }

  if (!isEmpty(bizDataQuery)) {
    payload.bizDataQuery = bizDataQuery;
  }

  if (!isEmpty(impactedWidget)) {
    payload.impactedWidget = impactedWidget;
  }

  if (isEmpty(compareId) && widgetConfig) {
    payload.bizDataQuery = {
      widgetConfig: widgetConfig,
      sliceSpec: {
        metricId: metricId,
        sliceSet: sliceSet,
        selectorSpec: filters
      }
    };
  }

  return payload;
}
