import { Avatar, Progress, Result, Spin, Skeleton, Divider, Tag, Steps, Timeline } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const IncAvatar = Avatar;
export const IncAvatarGroup = Avatar.Group;
export const IncProgress = Progress;
export const IncResult = Result;
export const IncSpin = Spin;
export const IncLoadingOutlined = LoadingOutlined;
export const IncDivider = Divider;
export const IncChip = Tag;

export const IncTimeline = Timeline;
export const IncTimelineItem = Timeline.Item;

/**
 * @deprecated Use IncSteps component instead
 */
export const IncAntdSteps = Steps;

/**
 * @deprecated Use IncSteps component instead
 */
export const IncAntdStep = Steps.Step;

/**
 * @deprecated Use a div with data-show-loader="true" attribute
 */
export const IncSkeleton = Skeleton;
