import { getFormattedDateTime, IncDateTimeFormat, IncHighchartsDateTimeFormat } from "@inception/ui";
import { DateTime, dateTime, DurationUnit } from "../core/moment_wrapper";
import { logger } from "../core";
import timeRangeUtils from "./TimeRangeUtils";

export interface TimeRangeOption {
  from: string;
  to: string;
  label: string;
  id: number;
}

const PRIMARY_REGEX = new RegExp(/now-([0-9]+)([m,h,d,w,M])/);
const timeRangeOptions: TimeRangeOption[] = [
  {
    from: "now-5m",
    to: "now",
    label: "Last 5 minutes",
    id: 1
  },
  {
    from: "now-15m",
    to: "now",
    label: "Last 15 minutes",
    id: 1
  },
  {
    from: "now-30m",
    to: "now",
    label: "Last 30 minutes",
    id: 1
  },
  {
    from: "now-1h",
    to: "now",
    label: "Last 1 hour",
    id: 1
  },
  {
    from: "now-3h",
    to: "now",
    label: "Last 3 hours",
    id: 1
  },
  {
    from: "now-6h",
    to: "now",
    label: "Last 6 hours",
    id: 1
  },
  {
    from: "now-12h",
    to: "now",
    label: "Last 12 hours",
    id: 1
  },
  {
    from: "now-24h",
    to: "now",
    label: "Last 24 hours",
    id: 0
  },
  {
    from: "now-2d",
    to: "now",
    label: "Last 2 days",
    id: 0
  },
  {
    from: "now-7d",
    to: "now",
    label: "Last 7 days",
    id: 0
  },
  {
    from: "now-30d",
    to: "now",
    label: "Last 30 days",
    id: 0
  },
  {
    from: "now-90d",
    to: "now",
    label: "Last 90 days",
    id: 0
  },
  {
    from: "now-6M",
    to: "now",
    label: "Last 6 months",
    id: 0
  },
  {
    from: "now-1y",
    to: "now",
    label: "Last 1 year",
    id: 0
  }
];
const timeRangeOptionsById: Record<string | number, TimeRangeOption[]> = {};
timeRangeOptions.forEach((rangeOption: TimeRangeOption) => {
  const { id } = rangeOption;

  if (!timeRangeOptionsById[id]) {
    timeRangeOptionsById[id] = [];
  }
  timeRangeOptionsById[id].push(rangeOption);
});

const getMatches = (exp: string) => exp.match(PRIMARY_REGEX);
const getTimeRangeEpoch = (from: DateTime, to: DateTime): Record<string, number> => {
  try {
    const fromMs = from.valueOf();
    const toMs = to.valueOf();

    logger.debug(
      "General TimeRange: ",
      `from: ${getFormattedDateTime(fromMs, IncDateTimeFormat.full, { withSeconds: true })} - to: ` +
        `${getFormattedDateTime(toMs, IncDateTimeFormat.full, { withSeconds: true })}`
    );
    return {
      from: from.valueOf(),
      to: to.valueOf()
    };
  } catch (err) {
    logger.error("TimeRange Error - from, to: ", `${err} - ${from}, ${to}`);
    return {};
  }
};
const getPreviousActiveTimeUnits = (time: string) => {
  const matches = getMatches(time);
  const units = matches[2] as DurationUnit;
  return {
    units,
    value: parseInt(matches[1], 10)
  };
};
const getTimeRangeForComparison = (comparisonTime: string, timeRange: Record<string, DateTime>) => {
  const { from, to } = timeRange;

  if (from && to) {
    const { value, units } = getPreviousActiveTimeUnits(comparisonTime);
    const toComparison = dateTime(to).subtract(value, units);
    const fromComparisonTime = dateTime(from).subtract(value, units);

    logger.debug(
      "Comparison TimeRange: ",
      `from: ${getFormattedDateTime(fromComparisonTime.valueOf(), IncDateTimeFormat.full, { withSeconds: true })} - to: ` +
        `${getFormattedDateTime(toComparison.valueOf(), IncDateTimeFormat.full, { withSeconds: true })}`
    );
    return {
      from: fromComparisonTime,
      to: toComparison
    };
  }
  const { millis: fromMs } = timeRangeUtils.getFrom(comparisonTime);
  const { millis: toMs } = timeRangeUtils.getTo("now");
  return {
    from: dateTime(fromMs),
    to: dateTime(toMs)
  };
};

const createLabelFromTimeRange = (fromMillis: string, toMillis: string): string => {
  const dateFormat = IncHighchartsDateTimeFormat.monthDayDescriptive;
  const options = {
    dateSeparator: "-",
    skipTime: true
  };

  const fromDate = getFormattedDateTime(Number(fromMillis), dateFormat, options);

  const toDate = getFormattedDateTime(Number(toMillis), dateFormat, options);

  return `${fromDate}-${toDate}`;
};

export {
  timeRangeOptions,
  timeRangeOptionsById,
  getMatches,
  getTimeRangeEpoch,
  createLabelFromTimeRange,
  getTimeRangeForComparison
};
