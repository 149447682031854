import {
  getFormattedDateTime,
  getDateTimeByFormat,
  IncDateTimeFormat,
  BROWSER_TIME_ZONE,
  TimeUnit as IncTimeUnit
} from "@inception/ui";
import { cloneDeep } from "lodash";
import qs from "query-string";
import moment, { duration, argThresholdOpts } from "moment-timezone";
import { RawTimeRange, TimeRange, TimeRangeMillis } from "../core/hooks";
import { DateTime, dateTime, DurationUnit, TimeZone } from "../core/moment_wrapper";
import {
  BEGINNING_OF_TIME_LABEL,
  END_OF_TIME_LABEL,
  BEGINNING_OF_TIME_VALUE,
  END_OF_TIME_VALUE
} from "../components/time-range/TimeRangeConstants";

type FromToType = "abs_rel" | "rel_abs" | "abs_abs" | "rel_rel";

const START_OF_MILLIS = Number.NEGATIVE_INFINITY;
const START_OF_PREFIX = "So";
const REL_TIME_REGEX = /^now(-+)(\d+|So)(y|M|d|h|m|w|Q)$/;

export interface TimeRangeOption {
  from: string;
  to: string;
  label: string;
  type?: FromToType;
}

export type TimeUnit = IncTimeUnit;

export type TimeAlignOptions = {
  alignToDay: boolean;
  alignToHr: boolean;
  alignToMin: boolean;
  alignToNext: boolean;
};

class TimeRangeUtils {
  private DEFAULT_COMPARE_FROM_STR = "1d";
  private DEFAULT_FROM_STR = "now-15m";
  private DEFAULT_TO_STR = "now";

  private LAST_MIN_HR_QUICK_OPTS = [
    {
      from: "now-5m",
      to: "now",
      label: "Last 5 minutes"
    },
    {
      from: "now-15m",
      to: "now",
      label: "Last 15 minutes"
    },
    {
      from: "now-30m",
      to: "now",
      label: "Last 30 minutes"
    },
    {
      from: "now-1h",
      to: "now",
      label: "Last 1 hour"
    },
    {
      from: "now-3h",
      to: "now",
      label: "Last 3 hours"
    },
    {
      from: "now-6h",
      to: "now",
      label: "Last 6 hours"
    },
    {
      from: "now-12h",
      to: "now",
      label: "Last 12 hours"
    }
  ];

  private LAST_DAY_WEEK_QUICK_OPTS = [
    {
      from: "now-1d",
      to: "now",
      label: "Last 1 day"
    },
    {
      from: "now-3d",
      to: "now",
      label: "Last 3 days"
    },
    {
      from: "now-7d",
      to: "now",
      label: "Last 7 days"
    },
    {
      from: "now-30d",
      to: "now",
      label: "Last 30 days"
    },
    {
      from: "now-90d",
      to: "now",
      label: "Last 90 days"
    },
    {
      from: `now-${START_OF_PREFIX}d`,
      to: "now",
      label: "Today so far"
    },
    {
      from: `now-${START_OF_PREFIX}w`,
      to: "now",
      label: "Week to date"
    },
    {
      from: `now-${START_OF_PREFIX}M`,
      to: "now",
      label: "Month to date"
    },
    {
      from: `now-${START_OF_PREFIX}Q`,
      to: "now",
      label: "Quarter to date"
    },
    {
      from: `now-${START_OF_PREFIX}y`,
      to: "now",
      label: "Year to date"
    }
  ];

  private NEXT_MIN_HR_QUICK_OPTS = [
    {
      from: "now",
      to: "now+5m",
      label: "Next 5 minutes"
    },
    {
      from: "now",
      to: "now+15m",
      label: "Next 15 minutes"
    },
    {
      from: "now",
      to: "now+30m",
      label: "Next 30 minutes"
    },
    {
      from: "now",
      to: "now+1h",
      label: "Next 1 hour"
    },
    {
      from: "now",
      to: "now+3h",
      label: "Next 3 hours"
    },
    {
      from: "now",
      to: "now+6h",
      label: "Next 6 hours"
    },
    {
      from: "now",
      to: "now+12h",
      label: "Next 12 hours"
    }
  ];

  private NEXT_DAY_WEEK_QUICK_OPTS = [
    {
      from: "now",
      to: "now+1d",
      label: "Next 1 day"
    },
    {
      from: "now",
      to: "now+2d",
      label: "Next 2 days"
    },
    {
      from: "now",
      to: "now+7d",
      label: "Next 7 days"
    },
    {
      from: "now",
      to: "now+30d",
      label: "Next 30 days"
    },
    {
      from: "now",
      to: "now+90d",
      label: "Next 90 days"
    },
    {
      from: "now",
      to: "now+6M",
      label: "Next 6 months"
    },
    {
      from: "now",
      to: "now+1y",
      label: "Next 1 year"
    }
  ];

  getFrom(from?: string, options?: Partial<TimeAlignOptions>) {
    let FR = from || this.getUrlParams().from || this.DEFAULT_FROM_STR;
    FR = typeof FR === "string" ? FR : FR[0];
    return this.getMillis(FR, options);
  }

  getTo(to?: string, options?: Partial<TimeAlignOptions>) {
    let TO = to || this.getUrlParams().to || this.DEFAULT_TO_STR;
    TO = typeof TO === "string" ? TO : TO[0];
    return this.getMillis(TO, options);
  }

  getMillis(timeStr: string, options?: Partial<TimeAlignOptions>): { millis: number; label: string } {
    const millis = parseInt(timeStr, 10);
    if (isNaN(millis)) {
      return this.parseRelativeTime(timeStr, options);
    }
    return {
      millis,
      label: timeStr
    };
  }

  getUrl(from: string, to: string, path: string, compareFrom: string, timeZone?: TimeZone): string {
    path = path || "";
    const parsedUrl = qs.parseUrl(path);
    const { query = {} } = parsedUrl;
    const { url } = parsedUrl;

    const params = {
      ...query,
      from,
      to,
      compareFrom,
      timeZone
    };

    const queryString = qs.stringify(params, { skipEmptyString: true });
    return `${url}?${queryString}`;
  }

  getUrlFromTimeZone(timeZone: string, path: string): string {
    path = path || "";
    const parsedUrl = qs.parseUrl(path);
    const { query = {} } = parsedUrl;
    const { url } = parsedUrl;

    const params = {
      ...query,
      timeZone
    };

    const queryString = qs.stringify(params, { skipEmptyString: true });
    return `${url}?${queryString}`;
  }

  getTimeRangeOptionsForLast(daysOnly?: boolean) {
    if (daysOnly) {
      return {
        0: cloneDeep(this.LAST_DAY_WEEK_QUICK_OPTS)
      };
    }

    return {
      0: cloneDeep(this.LAST_MIN_HR_QUICK_OPTS),
      1: cloneDeep(this.LAST_DAY_WEEK_QUICK_OPTS)
    };
  }

  getTimeRangeOptionsForNext(daysOnly?: boolean) {
    if (daysOnly) {
      return {
        0: cloneDeep(this.NEXT_DAY_WEEK_QUICK_OPTS)
      };
    }

    return {
      0: cloneDeep(this.NEXT_MIN_HR_QUICK_OPTS),
      1: cloneDeep(this.NEXT_DAY_WEEK_QUICK_OPTS)
    };
  }

  getTimeZoneFromTimeRange = (timeRange: TimeRange) => timeRange.timeZone || timeRange.raw.timeZone;

  getConvertedMillisFromTimeZone = (millis: number, timeZone: TimeZone, previousTimeZone: TimeZone) => {
    const selectedZone = timeZone === BROWSER_TIME_ZONE ? moment.tz.guess() : timeZone;
    const currentZone = previousTimeZone === BROWSER_TIME_ZONE ? moment.tz.guess() : previousTimeZone;
    return (
      millis +
      moment.tz.zone(selectedZone).utcOffset(millis) * 60 * 1000 -
      moment.tz.zone(currentZone).utcOffset(millis) * 60 * 1000
    );
  };

  getBrowserTimeZone = () => moment.tz.guess();

  getMillisFromTimeZone = (newDate: Date, timeZone?: TimeZone) => {
    if (timeZone && timeZone !== BROWSER_TIME_ZONE) {
      const momentDate = moment(newDate).format("yyyy-MM-DD HH:mm:ss");
      const convertedTime = moment.tz(momentDate, "yyyy-MM-DD HH:mm:ss", false, timeZone);
      const millis = convertedTime.valueOf();
      return millis;
    } else {
      return newDate.getTime();
    }
  };

  getGroupLabels = (fromMillis: number, toMillis: number): string => {
    const fromDate = new Date(fromMillis);
    const toDate = new Date(toMillis);
    const fromYear = moment(fromDate).format("YY");
    const toYear = moment(toDate).format("YY");
    const fromMonth = moment(fromDate).format("MMM");
    const toMonth = moment(toDate).format("MMM");
    const fromDay = fromDate.getDate();
    const toDay = toDate.getDate();

    if (fromYear === toYear) {
      if (fromMonth === toMonth) {
        if (fromDay === toDay) {
          // Oct 11, 23
          return `${fromMonth} ${fromDay}, ${fromYear}`;
        }
        // Oct 11 - 12, 23
        return `${fromMonth} ${fromDay} - ${toDay}, ${fromYear}`;
      }
      // Oct 11 - Nov 12, 23
      const fromMonthFormat = `${fromMonth} ${fromDay}`;
      const toMonthFormat = `${toMonth} ${toDay}`;
      return `${fromMonthFormat} - ${toMonthFormat}, ${fromYear}`;
    }
    // Oct 11, 23 - Nov 12, 24
    const fromYearFormat = `${fromMonth} ${fromDay}, ${fromYear}`;
    const toYearFormat = `${toMonth} ${toDay}, ${toYear}`;
    return `${fromYearFormat} - ${toYearFormat}`;
  };

  getLabelByTimeRange = (
    from: string,
    to: string,
    timeType?: boolean,
    i18nDisabled?: boolean,
    dateFormat?: IncDateTimeFormat,
    timeZone?: TimeZone
  ): string => {
    let fromLabel = "";
    let toLabel = "";
    let isAbsolute = false;

    if (this.isFutureRelativeTime(from, to)) {
      return this.getFullLabelByTimeMap(to, "Next", "+");
    }
    if (this.isPastRelativeTime(from, to)) {
      return this.getFullLabelByTimeMap(from, "Last", "-");
    }

    const { isAbsolute: isAbsoluteFrom, timeLabel: fromLabelFrom } = this.getLabelForTimeStr(
      from,
      timeType,
      i18nDisabled,
      dateFormat,
      timeZone
    );
    fromLabel = fromLabelFrom;
    isAbsolute = isAbsoluteFrom;

    const { isAbsolute: isAbsoluteTo, timeLabel: toLabelTo } = this.getLabelForTimeStr(
      to,
      timeType,
      i18nDisabled,
      dateFormat,
      timeZone
    );
    toLabel = toLabelTo;
    isAbsolute = isAbsolute || isAbsoluteTo;

    if (fromLabel === toLabel) {
      return fromLabel;
    }
    let countryString = "";
    if (timeZone && isAbsolute && timeZone !== BROWSER_TIME_ZONE) {
      const offset = dateTime(Number(from), timeZone).format("Z");
      const zone = dateTime(Number(from), timeZone).format("z");
      const country = timeZone?.split("/")?.[1]?.replace("_", " ");
      countryString = `(GMT ${offset}) ${zone} (${country})`;
    }
    return `${fromLabel} to ${toLabel} ${countryString}`;
  };

  getLabelForTimeStr(
    timeStr: string,
    timeType?: boolean,
    i18nDisabled?: boolean,
    dateFormat?: IncDateTimeFormat,
    timeZone?: TimeZone
  ) {
    let timeLabel = "";
    let isAbsolute = false;

    if (this.isRelativeTime(timeStr)) {
      timeLabel = this.getRelativeTimeLabel(timeStr, timeType);
    } else {
      timeLabel = this.getRelativeTimeLabelForAbsoluteDates(
        timeStr,
        true,
        timeType,
        i18nDisabled,
        dateFormat,
        timeZone
      );
      isAbsolute = true;
    }

    return {
      timeLabel,
      isAbsolute
    };
  }

  getDefaults() {
    return {
      from: this.DEFAULT_FROM_STR,
      to: this.DEFAULT_TO_STR
    };
  }

  getCompareDefaults() {
    return {
      from: this.DEFAULT_COMPARE_FROM_STR,
      to: ""
    };
  }

  /**
   * @param timestampMs Timestamp in millis
   * @param baseTimestampMs Timestamp in millis relative to which the difference needs to be calculated (Default: Now)
   *
   * @returns Prettified string of time difference
   */
  getDiff(timestampMs: number, baseTimestampMs?: number, withoutSuffix = false): string {
    const tsDateTime = dateTime(timestampMs);
    const baseDateTime = baseTimestampMs ? dateTime(baseTimestampMs) : dateTime();
    return tsDateTime.from(baseDateTime, withoutSuffix);
  }

  getTimeRangeFromRaw(rawTimeRange: RawTimeRange, options?: Partial<TimeAlignOptions>): TimeRange {
    const { from, to, timeZone } = rawTimeRange;
    const { millis: fromMillis, label: fromLabel } = this.getFrom(from, options);

    const { millis: toMillis, label: toLabel } = this.getTo(to, options);

    return {
      from: dateTime(fromMillis),
      to: dateTime(toMillis),
      timeZone,
      raw: {
        from: fromLabel,
        to: toLabel,
        timeZone
      }
    };
  }

  getCompareTimeRangeFromRaw(timeRange: TimeRange, compareFromStr: string) {
    const offsetMillis = this.getMillisFromOffset(compareFromStr);
    const isAbsolute = this.isAbsolute(compareFromStr);

    const diffMillis = timeRange.to.valueOf() - timeRange.from.valueOf();
    const from = isAbsolute ? dateTime(offsetMillis) : timeRange.from.clone().subtract(offsetMillis, "ms");
    const to = from.clone().add(diffMillis, "ms");

    const rawFrom = isAbsolute ? from.valueOf().toString() : compareFromStr;
    const rawTo = isAbsolute ? to.valueOf().toString() : "";

    const cTr: TimeRange = {
      from,
      to,
      raw: {
        from: rawFrom,
        to: rawTo
      }
    };

    return cTr;
  }

  getTimeRangeMillisFromRaw(rawTimeRange: RawTimeRange): TimeRangeMillis {
    const { from, to } = this.getTimeRangeFromRaw(rawTimeRange);

    return {
      from: from.valueOf(),
      to: to.valueOf()
    };
  }

  isFutureRelativeTime(fromLabel: string, toLabel: string): boolean {
    return fromLabel === "now" && toLabel.startsWith("now+");
  }

  isPastRelativeTime(fromLabel: string, toLabel: string): boolean {
    return fromLabel.startsWith("now-") && toLabel === "now";
  }

  isRelativeTime(label: string): boolean {
    const labelTrimmed = label.trim();
    if (
      labelTrimmed === BEGINNING_OF_TIME_VALUE ||
      labelTrimmed === END_OF_TIME_VALUE ||
      labelTrimmed === BEGINNING_OF_TIME_LABEL ||
      labelTrimmed === END_OF_TIME_LABEL
    ) {
      return true;
    } else {
      const regPastFuture = new RegExp(/^(today|now)(\s*(\+|-)\s*([0-9]*)(m|M|h|d|w|y))$/g);
      const regPresent = new RegExp(/^(today|now)\s*$/g);
      return isNaN(parseInt(labelTrimmed, 10)) && (regPastFuture.test(labelTrimmed) || regPresent.test(label));
    }
  }

  getRelativeTimeLabel(label: string, timeType: boolean): string {
    if (label === BEGINNING_OF_TIME_VALUE) {
      return BEGINNING_OF_TIME_LABEL;
    }
    if (label === END_OF_TIME_VALUE) {
      return END_OF_TIME_LABEL;
    }
    return !timeType ? label.replace("now", "today") : label;
  }

  getRelativeTimeLabelForAbsoluteDates(
    label: string,
    isFromDate: boolean,
    timeType: boolean,
    i18nDisabled?: boolean,
    pFormat?: IncDateTimeFormat,
    timeZone?: TimeZone
  ): string {
    const millis = isFromDate ? this.getFrom(label).millis : this.getTo(label).millis;
    let str = "";
    if (i18nDisabled) {
      str = getDateTimeByFormat(millis, pFormat);
    } else {
      str = getFormattedDateTime(millis, pFormat || IncDateTimeFormat.numeric, { withSeconds: true }, timeZone);
    }
    return this.getRelativeTimeLabel(str, timeType);
  }

  isValidTime(input: string | Date | DateTime, dateFormat?: string, timeZone?: TimeZone): boolean {
    try {
      if (typeof input === "string") {
        const isRelativeTime = timeRangeUtils.isRelativeTime(input);
        if (isRelativeTime) {
          return true;
        } else {
          const date = dateTime(input, timeZone, dateFormat);
          if (date.isValid()) {
            return true;
          }
          return false;
        }
      } else if (typeof input === "object") {
        if (input instanceof Date) {
          return !isNaN(new Date(input as Date).getTime());
        } else {
          return (input as DateTime).isValid();
        }
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  getSecondsFromMillis(millis: number): number {
    return Math.floor(millis / 1000);
  }

  getMillisFromOffset(offset: string): number {
    if (offset.startsWith("S")) {
      return START_OF_MILLIS;
    }

    const units = this.getTimeUnitFromLabel(offset);
    const relTimeNum = parseInt(offset, 10);
    const dur = duration(relTimeNum, units);
    const millis = dur.isValid() ? dur.asMilliseconds() : -1;
    return millis;
  }

  isAbsolute(millisStr: string): boolean {
    const millis = parseInt(millisStr, 10);
    return millis.toString() === millisStr;
  }

  getCompareTimeStr(currentMillis: number, compareMillis: number, prefixStr = "previous") {
    const timeShiftMillis = currentMillis - compareMillis;

    let compareStr = this.humanizeDuration(timeShiftMillis, true);
    compareStr = `${prefixStr} ${compareStr}`;
    return compareStr;
  }

  getCompareStringFromTimeShiftMillis(millis: number, prefix = "Previous", suffix = ""): string {
    let compareStr = this.humanizeDuration(millis, true);
    compareStr = `${prefix} ${compareStr} ${suffix}`;
    return compareStr.trim();
  }

  humanizeDuration(millis: number, skipSingularPrefix = false, unit: DurationUnit = "millisecond"): string {
    const dur = duration(millis, unit);
    const thresholds: argThresholdOpts = {
      d: 7,
      w: 4,
      M: 30
    };

    let durationStr = dur.humanize(thresholds);
    if (skipSingularPrefix) {
      durationStr = durationStr.startsWith("an") ? durationStr.substr(3) : durationStr;
      durationStr = durationStr.startsWith("a") ? durationStr.substr(2) : durationStr;
    }
    return durationStr;
  }

  /**
   * Gets latest hour from time range if time range is greater than one hour
   * Useful for making events query which does not allow more than one hour
   */
  getLastHour(fromMillis: number, toMillis: number) {
    const oneHour = 60 * 60 * 1000;
    const timeDifference = toMillis - fromMillis;

    if (timeDifference > oneHour) {
      return {
        from: toMillis - oneHour,
        to: toMillis
      };
    }
    return {
      from: fromMillis,
      to: toMillis
    };
  }

  getMillisFromTimeRange(timeRange: TimeRange) {
    const { from, to } = timeRange;
    return {
      fromMillis: from.valueOf(),
      toMillis: to.valueOf()
    };
  }

  getDiffMillisFromTimeRange(timeRange: TimeRange) {
    const { fromMillis, toMillis } = this.getMillisFromTimeRange(timeRange);
    return toMillis - fromMillis;
  }

  getTimeRangeWithoutSecs = (timeRange: TimeRange) => {
    const { from, to } = timeRange;
    return {
      from: from.clone().seconds(0).milliseconds(0),
      to: to.clone().seconds(0).milliseconds(0)
    };
  };

  getNowMillis() {
    return dateTime().valueOf();
  }

  getNowSecs() {
    const millis = this.getNowMillis();
    return this.getSecondsFromMillis(millis);
  }

  getTimeUnitFromLabel(label: string): TimeUnit {
    const possibleUnit = label.slice(-1);
    const validUnits = ["y", "M", "d", "h", "m", "w", "Q"];
    if (validUnits.includes(possibleUnit)) {
      return possibleUnit as TimeUnit;
    }
    return "ms" as TimeUnit;
  }

  setDefaultTimeRange(from: string, to: string) {
    this.DEFAULT_FROM_STR = from;
    this.DEFAULT_TO_STR = to;
  }

  getTriageCompareStringFromMillis(compareTimeShiftMillis: number): string {
    let compareStr = this.getCompareStringFromTimeShiftMillis(compareTimeShiftMillis);
    const compareDuration = /\d+/g.exec(compareStr)?.[0];
    const numCompareDuration = parseInt(compareDuration, 10);

    if (numCompareDuration > 1) {
      compareStr = this.getCompareStringFromTimeShiftMillis(compareTimeShiftMillis, " ", " ago");
    }

    return compareStr;
  }

  isValidTimeRange(timeRange: TimeRange): boolean {
    const { from, to } = timeRange;
    return from.isValid() && to.isValid() && from < to;
  }

  isMillisToday(millis: number) {
    const todayStart = dateTime().hours(0).minutes(0).seconds(0).milliseconds(0).valueOf();
    return todayStart <= millis;
  }

  isMillisYesterday(millis: number) {
    const yesterdayStart = dateTime().hours(0).minutes(0).seconds(0).milliseconds(0).subtract(1, "day").valueOf();

    return yesterdayStart <= millis;
  }

  private getFullLabelByTimeMap = (dateStr: string, labelPrefix: string, splitStr: string): string => {
    const lookupArray: TimeRangeOption[] = [];

    if (labelPrefix === "Last") {
      lookupArray.push(...this.LAST_MIN_HR_QUICK_OPTS);
      lookupArray.push(...this.LAST_DAY_WEEK_QUICK_OPTS);
    } else if (labelPrefix === "Next") {
      lookupArray.push(...this.NEXT_MIN_HR_QUICK_OPTS);
      lookupArray.push(...this.NEXT_DAY_WEEK_QUICK_OPTS);
    }

    const matchOpt = lookupArray.find(opt => opt.from === dateStr);

    if (matchOpt) {
      return matchOpt.label;
    }

    const labelText = labelPrefix || "Last";
    const splitVal = splitStr || "-";
    const timeMap: Record<TimeUnit, string> = {
      h: "Hours",
      m: "Minutes",
      s: "Seconds",
      w: "Weeks",
      d: "Days",
      M: "Months",
      y: "Years",
      Q: "Quarters"
    };
    const timePart = dateStr.split(splitVal)[1];
    const timeNum = timePart.match(/(\d+)/)[0];
    const unit = timePart.slice(-1) as TimeUnit;
    const timeValue = parseInt(timeNum, 10) > 1 ? timeMap[unit] : timeMap[unit].slice(0, -1);
    return `${labelText} ${timeNum} ${timeValue}`;
  };

  private parseRelativeTime(timeStr: string, options?: Partial<TimeAlignOptions>): { millis: number; label: string } {
    let millis = -1;
    const label = timeStr;

    if (timeStr === "now") {
      millis = dateTime().milliseconds(0).valueOf();
      millis = this.getAlignedTime(millis, options);
    } else if (REL_TIME_REGEX.test(timeStr)) {
      let relTimeStr = timeStr.replace("now", "");
      const operator = relTimeStr.slice(0, 1);
      relTimeStr = relTimeStr.replace(operator, "");

      const offsetMillis = this.getMillisFromOffset(relTimeStr);
      const unit = this.getTimeUnitFromLabel(relTimeStr);

      let mom: DateTime;

      if (offsetMillis === START_OF_MILLIS) {
        mom = dateTime().startOf(unit);
      } else {
        mom = operator === "-" ? dateTime().subtract(offsetMillis, "ms") : dateTime().add(offsetMillis, "ms");
      }

      millis = mom.milliseconds(0).valueOf();
      millis = this.getAlignedTime(millis, options);
    }

    return {
      millis,
      label
    };
  }

  private getAlignedTime(millis: number, options: Partial<TimeAlignOptions>) {
    const { alignToDay = false, alignToHr = false, alignToMin = false, alignToNext = false } = options || {};

    let mom = moment(millis);

    if (alignToDay) {
      mom = alignToNext ? mom.endOf("day") : mom.startOf("day");
    }

    if (alignToHr) {
      mom = alignToNext ? mom.endOf("hour") : mom.startOf("hour");
    }

    if (alignToMin) {
      mom = alignToNext ? mom.endOf("minute") : mom.startOf("minute");
    }

    return mom.valueOf();
  }

  private getUrlParams() {
    const search = window.location.search.slice(1);
    const urlParams = qs.parse(search);
    return urlParams;
  }
}

const timeRangeUtils = new TimeRangeUtils();
export default timeRangeUtils;
