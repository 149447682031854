import {
  Feature,
  BusinessEntityFeatureActions,
  OperationalizeFeatureActions,
  ConfigurationListFeatureActions,
  DashboardListFeatureActions,
  IntegrationsFeatureActions,
  UserFeatureActions,
  AutoDiscoveryFeatureActions,
  Action,
  BUSINESS_ENTITY_ACTIONS,
  OPERATIONALIZE_ACTIONS,
  CONFIGURATION_ACTIONS,
  DASHBOARD_ACTIONS,
  INTEGRATIONS_ACTIONS,
  USERS_ACTIONS,
  AUTO_DISCOVERY_ACTIONS,
  ALERTS_ACTIONS,
  AlertFeatureActions
} from "./features";

//Below Map is internal to UI
export const FeatureActionsMap: Record<keyof typeof Feature, Record<keyof typeof Action, string[]>> = {
  alerts: AlertFeatureActions,
  businessEntity: BusinessEntityFeatureActions,
  operationalize: OperationalizeFeatureActions,
  configurations: ConfigurationListFeatureActions,
  dashboards: DashboardListFeatureActions,
  integrations: IntegrationsFeatureActions,
  users: UserFeatureActions,
  autoDiscovery: AutoDiscoveryFeatureActions
};

//Below Map is internal to UI
export const ActionsMap = {
  ...BUSINESS_ENTITY_ACTIONS,
  ...OPERATIONALIZE_ACTIONS,
  ...CONFIGURATION_ACTIONS,
  ...DASHBOARD_ACTIONS,
  ...INTEGRATIONS_ACTIONS,
  ...USERS_ACTIONS,
  ...AUTO_DISCOVERY_ACTIONS,
  ...ALERTS_ACTIONS
};

export type ActionsMapType = keyof typeof ActionsMap;

export type RequiredPermissionType = {
  feature: Feature;
  action: ActionsMapType;
};

// export type ActionsType<T extends Feature> = {
//   [key in Feature]: FeatureActionConfig<T>;
// };

// export type FeatureActionConfig<T extends Feature> =
//   T extends Feature.businessEntity ? keyof BUSINESS_ENTITY_ACTIONS :
//     T extends Feature.operationalize ? keyof OPERATIONALIZE_ACTIONS : unknown;
