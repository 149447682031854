import { map, random, uniq } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { IncToolTip } from "../../antd-components";

type Tags = Record<string, { tag: string; value: string }>;

interface Props {
  tags: string[];
}

const TagsRenderer: FC<Props> = props => {
  const { tags: pTags } = props;

  const [tags, setTags] = useState<Tags>({});

  useEffect(() => {
    const maxTags = 3;

    const uTags = uniq(pTags);
    const filTags = uTags.slice(0, maxTags);
    const remTags = uTags.slice(maxTags);

    const displayTags: Tags = {};

    filTags.forEach(filTag => {
      const key = `${filTag}-${random(0, 10000, true)}`;
      displayTags[key] = {
        tag: filTag,
        value: filTag
      };
    });

    if (remTags.length) {
      const key = `${remTags[0]}-${random(0, 10000, true)}`;
      const value = remTags.join(", ");
      const tag = `+${remTags.length} more`;
      displayTags[key] = {
        tag,
        value
      };
    }
    setTags(displayTags);
  }, [pTags]);

  return (
    <div className="inc-table-tags-renderer">
      {map(tags, (tagData, key) => (
        <div
          className="tag"
          key={key}
        >
          <IncToolTip
            placement="top"
            titleText={tagData.value}
          >
            <span>{tagData.tag}</span>
          </IncToolTip>
        </div>
      ))}
    </div>
  );
};

export default TagsRenderer;
