import { useCallback } from "react";
import { DemoDataParams } from "..";
import { Entity, useFetchDataStatus } from "../../../../core";
import fieldPickerApiService from "../FieldPickerApiService";
import { ExploreUserService } from "../types/fieldpickerTypes";

export const convertUserservicesToEntity = (list: ExploreUserService[]) =>
  (list || []).map(
    item =>
      ({
        id: item.entityId,
        name: item.name,
        type: item.entityType
      }) as Entity
  );

const useFetchExploreUserservices = (demoDataParams?: DemoDataParams) => {
  const {
    data: exploreUserservices,
    error,
    isError,
    isFetching,
    isSuccess,
    setStatus
  } = useFetchDataStatus<ExploreUserService[], string>({ data: [] });

  const fetchUserserviceList = useCallback(
    async (startTimeMillis: number, endTimeMillis: number) => {
      setStatus([], true, false, false, "");
      let exploreUserservices: ExploreUserService[] = [];
      const { data, error, message } = await fieldPickerApiService.getUserservicesList(
        startTimeMillis,
        endTimeMillis,
        demoDataParams
      );

      if (!error) {
        exploreUserservices = data;
      }

      const errMsg = error ? message : "";

      setStatus(exploreUserservices, false, !error, error, errMsg);
    },
    [demoDataParams, setStatus]
  );

  return {
    exploreUserservices,
    isError,
    isFetching,
    isSuccess,
    error,
    fetchUserserviceList
  };
};

export default useFetchExploreUserservices;
