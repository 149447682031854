import { gql } from "@apollo/client";
import { Entity, EntityLookUpResponse, Property } from "../../core";
import entityApiService from "./EntityApiService";

export type ErrorDetails = {
  id: string;
  name: string;
  className: string;
  discoveryTime: string;
  lastSeenTime: string;
};

class ErrorsApiService {
  /**
   * Fetches the error call stack. This code doesn't accept the time range because it also
   * fetches the meta darta like when was the error last seen and first seen.
   * @param entityId The entityIds of the error
   * @returns map of error entity id to error details like name, classname and stacktrace.
   */
  async fetchErrorMetadata(entityId: string[]): Promise<Map<string, ErrorDetails>> {
    // Since this is a simple lookup use start time as 0
    const from: string = new Date(0).toISOString();
    const to: string = new Date(Date.now()).toISOString();

    const errorDetailsQuery = gql`
      query entityLookup($entityIds: [String], $startTime: DateTime!, $endTime: DateTime!) {
        entityLookup(ids: $entityIds, timeRange: { st: $startTime, et: $endTime }) {
          id
          name
          discoveryTime
          lastSeenTime
          properties(names: ["className"]) {
            name
            value
          }
        }
      }
    `;

    const response: EntityLookUpResponse = await entityApiService.fetchData({
      query: errorDetailsQuery,
      variables: {
        entityIds: entityId,
        startTime: from,
        endTime: to
      }
    });

    const errorIdToErrorDetails: Map<string, ErrorDetails> = new Map();
    if (response && response.data) {
      const errorEntities: Entity[] = response.data.entityLookup;
      if (errorEntities && errorEntities.length > 0) {
        errorEntities.forEach(entity => {
          const { properties, id, name, discoveryTime, lastSeenTime } = entity;
          const className = this.getPropertyValue(properties, CLASS_NAME);

          const details: ErrorDetails = {
            id,
            name,
            className,
            discoveryTime,
            lastSeenTime
          };

          errorIdToErrorDetails.set(id, details);
        });
      }
    }

    return errorIdToErrorDetails;
  }

  private getPropertyValue(properties: Property[], propertyName: string) {
    const property = properties.find(property => property.name === propertyName);
    return property?.value;
  }
}

const errorsApiService = new ErrorsApiService();
export default errorsApiService;

const CLASS_NAME = "className";
