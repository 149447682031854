import { ApolloClient, ApolloQueryResult, DocumentNode, InMemoryCache, gql } from "@apollo/client";
import { uniq } from "lodash";
import { Entity, EntityFilter, getParamFromUrl, logger, Traffic, asyncGetItem } from "../../core";
import { dateTime, DateTime } from "../../core/moment_wrapper";
import appConfig from "../../../appConfig";

//method/types specific to json to convert to graphql queries.
import { jsonToGraphQLQuery, RelationArgsJson, RelationshipJson, RootEntityJson } from "../../lib/JsonToGraphqlQuery";
import { request } from "./base-api";
import { DEMO_SUMMARY_PAYLOAD_KEYS } from "./Triage";
import { EntityPropertiesType } from "./traces/types";
import datasourceApiManager from "./DatasourceApiService";

const RElATIONSHIP = "relationship";

interface GQLPayload {
  query: DocumentNode;
  variables?: Record<string, any>;
  errorPolicy?: "none" | "all" | "ignore";
}

type EntityLookupResponse = {
  entityLookup: Entity[];
};

export class EntityApiService {
  readonly ENTITY_GQL_URL: string = "/entity-query/graphql";
  client: ApolloClient<any>;
  clientInited: boolean;
  cache: InMemoryCache;
  cachedTime: number;

  constructor() {
    this.clientInited = false;
    this.cachedTime = Date.now();
    this.cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            entityLookup: {
              read(_, { args, toReference, readField }) {
                const cacheEntities: any = [];
                let isCached = true;
                args.ids.forEach((id: any) => {
                  const cacheRef = toReference({
                    __typename: "Entity",
                    id: id
                  });
                  cacheEntities.push(cacheRef);
                  const idInCache = readField("id", cacheRef);
                  if (idInCache === undefined) {
                    isCached = false;
                  }
                });
                if (isCached) {
                  return cacheEntities;
                } else {
                  return undefined;
                }
              }
            }
          }
        }
      }
    });
  }

  private init() {
    const currentTime = Date.now();
    if (currentTime - this.cachedTime >= 1000 * 60 * appConfig.cacheRefreshTime) {
      if (this.client) {
        this.client.clearStore();
      }
      this.clientInited = false;
    }
    if (!this.clientInited) {
      try {
        const url = datasourceApiManager.getDefault().getUrl(this.ENTITY_GQL_URL);
        this.client = new ApolloClient({
          uri: url,
          connectToDevTools: true,
          headers: request.getCommonHeaders(),
          cache: this.cache
        });
        this.clientInited = true;
        this.cachedTime = Date.now();
      } catch (error) {
        console.log(error);
        throw error;
      }
    } else {
      return Promise.resolve(this.client);
    }
  }

  async fetchData<T = any>(payload: GQLPayload): Promise<ApolloQueryResult<T>> {
    if (getParamFromUrl("demo")) {
      return asyncGetItem(DEMO_SUMMARY_PAYLOAD_KEYS.entityLookupPayload).then((result: any) => result.data);
    } else {
      await this.init();
      return this.client
        .query(payload)
        .then((result: any) => result)
        .catch(error => logger.error("Entity API", `${error.message}`));
    }
  }

  fetchAllRootApi(): Promise<Entity[]> {
    const epoch = dateTime(0);
    const now = dateTime();
    return this.fetchRootApis(epoch, now);
  }

  async fetchUserServices(from: DateTime, to: DateTime, properties?: string[]): Promise<Entity[]> {
    const query = gql`
      query entities($properties: [String]!, $startTime: DateTime!, $endTime: DateTime!) {
        entities(type: "i_userService", timeRange: { st: $startTime, et: $endTime }) {
          name
          id
          properties(names: $properties) {
            name
            value
          }
        }
      }
    `;

    const response: UserServiceResponse = await this.fetchData({
      query,
      variables: {
        properties: properties || [],
        startTime: from,
        endTime: to
      }
    });

    if (response && response.data) {
      return response.data.entities || [];
    }

    return [];
  }

  async fetchRootApis(from: DateTime, to: DateTime): Promise<Entity[]> {
    const query = gql`{
          rootApis (timeRange:{st:"${from.toISOString()}", et:"${to.toISOString()}"}) {
              id,
              name
          }
      }`;
    const payload: GQLPayload = {
      query: query
    };
    const result = await this.fetchData(payload);
    return result?.data?.rootApis;
  }

  /**
   *
   * @param entityIds Entity ids to lookup
   * @param startTime For simple entity lookups pass start time as 0 so that the name will returned
   *                  no matter if the entity was touched in recent past or not.
   * @param endTime
   * @returns
   */
  async fetchEntityNamesForIds(
    entityIds: Set<string>,
    startTime?: number,
    endTime?: number
  ): Promise<Map<string, string>> {
    const result: Entity[] = await entityApiService.fetchEntityForIds(Array.from(entityIds), startTime, endTime);
    const idToName: Map<string, string> = new Map();

    if (!result) {
      throw new Error("Error fetching entity names by id's");
    }

    if (result) {
      const entityRes: Entity[] = result;
      entityRes.forEach(res => {
        idToName.set(res.id, res.name);
      });
    }

    return idToName;
  }

  async fetchTrafficForIds(trafficIds: string[], startTimeMillis: number, endTimeMillis: number): Promise<Traffic[]> {
    const st = startTimeMillis || 0;
    const et = endTimeMillis || dateTime().valueOf();
    const from: any = new Date(st).toISOString();
    const to: any = new Date(et).toISOString();

    const trafficLookup = gql`
      query trafficLookup($id: [String]!, $startTime: DateTime!, $endTime: DateTime!) {
        trafficLookup(ids: $id, timeRange: { st: $startTime, et: $endTime }) {
          id
          __typename
          type
          src {
            id
            name
          }
          target {
            id
            name
          }
          context {
            id
            name
          }
        }
      }
    `;

    const result: any = await entityApiService.fetchData({
      query: trafficLookup,
      variables: {
        id: uniq(trafficIds),
        startTime: from,
        endTime: to
      }
    });

    return result && result.data && result.data.trafficLookup ? result.data.trafficLookup : [];
  }

  async fetchEntityForIds(
    entityIds: string[],
    startTimeMillis?: number,
    endTimeMillis?: number,
    properties: string[] = ["*"]
  ): Promise<Entity[]> {
    const st = startTimeMillis || 0;
    const et = endTimeMillis || dateTime().valueOf();
    const from: any = new Date(st).toISOString();
    const to: any = new Date(et).toISOString();

    const foo = gql`
      query entityLookup($id: [String]!, $startTime: DateTime!, $endTime: DateTime!, $properties: [String]!) {
        entityLookup(ids: $id, timeRange: { st: $startTime, et: $endTime }) {
          id
          name
          properties(names: $properties) {
            name
            value
            __typename
            type
            setValue
            mapValue {
              key
              value
            }
          }
          type
          __typename
        }
      }
    `;

    const result: any = await entityApiService.fetchData({
      query: foo,
      variables: {
        id: uniq(entityIds),
        startTime: from,
        endTime: to,
        properties
      }
    });

    return result && result.data && result.data.entityLookup ? result.data.entityLookup : [];
  }

  async fetchEntityForIdsWithRelationships(
    entityIds: string[],
    startTimeMillis: number,
    endTimeMillis: number
  ): Promise<Entity[]> {
    const st = startTimeMillis || 0;
    const et = endTimeMillis || dateTime().valueOf();
    const from: any = new Date(st).toISOString();
    const to: any = new Date(et).toISOString();

    const foo = gql`
      query entityLookup($id: [String]!, $startTime: DateTime!, $endTime: DateTime!) {
        entityLookup(ids: $id, timeRange: { st: $startTime, et: $endTime }) {
          id
          name
          type
          relationship(type: "i_component2api") {
            id
            name
            type
            relationship(type: "i_service2component") {
              id
              name
              type
            }
          }
          __typename
        }
      }
    `;

    const result: any = await entityApiService.fetchData({
      query: foo,
      variables: {
        id: uniq(entityIds),
        startTime: from,
        endTime: to
      },
      errorPolicy: "ignore"
    });

    return result && result.data && result.data.entityLookup ? result.data.entityLookup : [];
  }

  async fetchValuesForEntityRelationship(
    entityProperies: EntityPropertiesType[],
    startTime: DateTime,
    endTime: DateTime
  ): Promise<ApolloQueryResult<any>> {
    try {
      const query = this.constructEntityRelationshipQuery(
        entityProperies,
        startTime.toISOString(),
        endTime.toISOString()
      );
      const payload: GQLPayload = {
        query: gql`{
          ${query}
        }`
      };
      return await this.fetchData(payload);
    } catch (error) {
      logger.error("Entity Relations", `${(error as Error).message}`);
    }
  }

  async fetchEntitiesByTypeId(
    entityType: string,
    startTime: DateTime,
    endTime: DateTime,
    entityFilters?: EntityFilter[],
    properties: string[] = ["*"]
  ): Promise<ApolloQueryResult<any>> {
    const query = gql`
      query entities(
        $entityType: String!
        $properties: [String]!
        $startTime: DateTime!
        $endTime: DateTime!
        $filter: [EntityPredicate]!
      ) {
        entities(type: $entityType, filter: $filter, timeRange: { st: $startTime, et: $endTime }) {
          name
          id
          properties(names: $properties) {
            name
            value
          }
        }
      }
    `;

    const payload: GQLPayload = {
      query: query,
      variables: {
        entityType,
        properties,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        filter: entityFilters || []
      }
    };
    return await this.fetchData(payload);
  }

  async fetchSampleData() {
    const SAMPLE_GQL = gql`
      {
        entities(type: "e_pod", timeRange: { st: "1985-04-12T23:20:50.52Z", et: "2021-04-12T23:20:50.52Z" }) {
          id
          properties(names: ["pod", "service", "cluster"]) {
            name
            value
            type
          }
        }
      }
    `;

    const payload: GQLPayload = {
      query: SAMPLE_GQL,
      variables: {}
    };
    return await this.fetchData(payload);
  }

  // no of levels of relationship in the constructed json
  private getLeafKeyLevel(json: RelationshipJson | RelationArgsJson, key: keyof RelationshipJson, level = 0): number {
    if (json[key] && json[key].constructor.name === "Object") {
      level += 1;
      return this.getLeafKeyLevel(json[key], key, level);
    }
    return level;
  }

  // entity-store accepts only entity type (i_api) and not api$i_api
  // which trace-store accepts
  private getEntityTypeIdAndProperties(entityName: string): { entityType: string; properties?: string[] } {
    entityName = entityName.substr(entityName.indexOf("$") + 1).trim();
    const entity = entityName.split(".")[0];
    const property = entityName.split(".")[1];

    if (property) {
      return {
        entityType: entity,
        properties: [property]
      };
    }
    return {
      entityType: entity
    };
  }

  // attaching the resultant relationship json to the child relationship
  private appendToChildRelation(
    parentJson: RelationshipJson,
    childJson: RelationshipJson,
    key: keyof RelationshipJson
  ): RelationshipJson {
    if (!parentJson || !Object.keys(parentJson).length) {
      return childJson;
    }

    const level = this.getLeafKeyLevel(parentJson, key);
    const target = new Array(level).fill(key) as Array<keyof RelationshipJson>;

    const updateChildRelation = (
      obj: RelationshipJson | RelationArgsJson,
      value: RelationshipJson,
      target: Array<keyof RelationshipJson>
    ) => {
      const [top, ...rest] = [...target];

      if (!rest.length) {
        obj[top] = {
          ...obj[top],
          ...value
        };
      } else {
        updateChildRelation(obj[top], value, [...rest]);
      }
    };

    updateChildRelation(parentJson, childJson, target);

    return parentJson;
  }

  /**
   *
   * @param entityRelation string, eg: i_api.rel:i_component2api.rel:i_service2component
   * @param startTime
   * @param endtTime
   * return
   * entities (type: "i_api", timeRange: {st: startTime, et: endtTime}) {
   * id
   * name
   * relationship(type: "i_component2api") {
   *   id,
   *   name,
   *   relationship(type: "i_service2component") {
   *     id,
   *     name
   *   }
   * },
   */
  private constructEntityRelationshipQuery(
    entityProperties: EntityPropertiesType[],
    startTime: string,
    endTime: string
  ): string {
    // const entities = entityRelation.split(':');

    // cnstructing the json for root entity
    if (entityProperties.length === 1) {
      // const { entityType, properties = [] } = this.getEntityTypeIdAndProperties(entities[0]);
      const { entityType, properties = [] } = entityProperties[0];
      const rootEntityQueryJson: RootEntityJson = {
        entities: {
          __args: {
            type: entityType,
            timeRange: {
              st: startTime, // "2021-02-25T02:50:12.841Z",
              et: endTime // "2021-02-25T03:00:12.841Z",
            }
          },
          id: true,
          name: true
        }
      };
      if (properties) {
        rootEntityQueryJson.entities.properties = {
          __args: {
            names: properties
          },
          value: true,
          name: true
        };
      }
      return jsonToGraphQLQuery(rootEntityQueryJson, { pretty: true });
    }

    let relationQueryJson: RelationshipJson;
    const key = RElATIONSHIP;
    const rootEntity = entityProperties.splice(0, 1)[0];
    const { entityType } = rootEntity;
    // cnstructing the json for relationship entities
    entityProperties.forEach((entityProperty: EntityPropertiesType, index: number) => {
      const { entityType, properties } = entityProperty;
      const queryJson: RelationshipJson = {
        relationship: {
          __args: {
            type: entityType
          },
          id: true,
          name: true
        }
      };

      if (properties && index === entityProperties.length - 1) {
        queryJson.relationship.properties = {
          __args: {
            names: properties
          },
          value: true,
          name: true
        };
      }

      relationQueryJson = this.appendToChildRelation(relationQueryJson, queryJson, key);
    });

    // attaching relation entities to the root entity
    const entityRelationQueryJson = {
      entities: {
        __args: {
          type: entityType,
          timeRange: {
            st: startTime, // "2021-02-25T02:50:12.841Z",
            et: endTime // "2021-02-25T03:00:12.841Z",
          }
        },
        id: true,
        name: true,
        ...relationQueryJson
      }
    };
    const query = jsonToGraphQLQuery(entityRelationQueryJson, { pretty: true });
    console.log("query: ", query);
    return query;
  }

  /**
   *
   * @param startTime number
   * @param endTime number
   * @param calledApiIds string[] // api entityIds[]
   * @returns entities: Map<apiId, Record<string, {id: string, name: string}>
   */

  async fetchServiceComponentForCalledApis(
    startTime: number,
    endTime: number,
    calledApiIds: string[]
  ): Promise<Map<string, Record<"api" | "service" | "component", Entity>>> {
    const from = new Date(startTime).toISOString();
    const to = new Date(endTime).toISOString();

    const query = gql`
      query entityLookup($entityIds: [String], $startTime: DateTime!, $endTime: DateTime!) {
        entityLookup(ids: $entityIds, timeRange: { st: $startTime, et: $endTime }) {
          id
          name
          relationship(type: "i_component2api") {
            id
            name
            relationship(type: "i_service2component") {
              id
              name
            }
          }
        }
      }
    `;

    const enitites = await this.fetchData<EntityLookupResponse>({
      query,
      variables: {
        entityIds: uniq(calledApiIds),
        startTime: from,
        endTime: to
      }
    });

    const calledApiToServiceComponentMap: Map<string, Record<"api" | "service" | "component", Entity>> = new Map();
    const data = enitites.data?.entityLookup || [];

    data.forEach((res: Entity) => {
      const api = res;
      const component = api?.relationship?.[0];
      const service = component?.relationship?.[0];

      calledApiToServiceComponentMap.set(api.id, {
        service,
        component,
        api
      });
    });

    return calledApiToServiceComponentMap;
  }

  /**
   *
   * @param startTime startTime of query
   * @param endTime end time of query
   * @param apiIds array of apiId's for which to fetch traceFields relationship
   * @param relationshipProps array of properties you want in traceField entity to be returned
   * @param filter entity filter criteria
   */
  async fetchTraceFieldsForRootApi(
    startTime: number,
    endTime: number,
    apiIds: string[],
    relationshipProps: string[],
    filter: EntityFilter[]
  ): Promise<Entity[]> {
    const from = new Date(startTime).toISOString();
    const to = new Date(endTime).toISOString();

    const query = gql`
      query entityLookup(
        $entityIds: [String]
        $startTime: DateTime!
        $endTime: DateTime!
        $props: [String]!
        $filter: [EntityPredicate]!
      ) {
        entityLookup(ids: $entityIds, timeRange: { st: $startTime, et: $endTime }) {
          id
          name
          type
          relationship(type: "i_api2tracefield", filter: $filter) {
            id
            name
            properties(names: $props) {
              name
              value
            }
          }
        }
      }
    `;

    const entities = await this.fetchData<EntityLookupResponse>({
      query,
      variables: {
        entityIds: apiIds,
        startTime: from,
        endTime: to,
        filter,
        props: relationshipProps
      }
    });
    const data = entities.data?.entityLookup || [];
    return data;
  }

  async fetchEntityRelationshipData(
    startTime: number,
    endTime: number,
    entityIds: string[],
    relationshipType: string,
    filter: EntityFilter[]
  ): Promise<Entity[]> {
    const from = new Date(startTime).toISOString();
    const to = new Date(endTime).toISOString();

    const query = gql`
      query entityLookup(
        $entityIds: [String]
        $startTime: DateTime!
        $endTime: DateTime!
        $relationshipType: String!
        $filter: [EntityPredicate]!
      ) {
        entityLookup(ids: $entityIds, timeRange: { st: $startTime, et: $endTime }) {
          id
          name
          type
          relationship(type: $relationshipType, filter: $filter) {
            id
            name
          }
        }
      }
    `;

    const entities = await this.fetchData<EntityLookupResponse>({
      query,
      variables: {
        entityIds: entityIds,
        startTime: from,
        endTime: to,
        relationshipType: relationshipType,
        filter
      }
    });
    const data = entities.data?.entityLookup || [];
    return data;
  }
}

const entityApiService = new EntityApiService();

export default entityApiService;

type UserServiceResponse = {
  data: {
    entities: Entity[];
  };
};
