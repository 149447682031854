import React from "react";
import { ReportProblemOutlined } from "./MaterialIcons";

export const IncErrorIcon = React.forwardRef<any, any>((props, ref) => (
  <ReportProblemOutlined
    {...props}
    className={`inc-error-icon ${props.className || ""}`}
    ref={ref}
  />
));
