import React, { FC, useMemo } from "react";
import { IncDateTimeFormat, generateId, getFormattedDateTime } from "../../utils";
import Cron from "./react-js-cron";
import { IncCronProps } from "./types";
import { computeFurtherOccurrencesForCron } from "./utils";

export const IncCronEditor: FC<IncCronProps> = props => {
  const {
    onError,
    setCronExpr,
    cronExpr,
    className: pClassName = "",
    clockFormat = "12-hour-clock",
    clearable = false,
    showFurtherOccurrances
  } = props;

  const uniqId = useMemo(() => generateId(), []);

  const furtherOccurrances = useMemo(() => {
    if (!showFurtherOccurrances) {
      return <></>;
    }

    const furtherOccurrances = computeFurtherOccurrencesForCron(cronExpr);
    const nextOccurrances = furtherOccurrances.map((date, idx) => {
      const formattedDate = getFormattedDateTime(date, IncDateTimeFormat.full, {
        withSeconds: true
      });

      const key = `occurrence-${uniqId}-${date.toISOString()}`;
      const displayValue = `${idx === 0 ? "next" : "then"} at ${formattedDate}`;

      return (
        <div
          className="inc-text-subtext-medium inc-label-common marginTp8"
          key={key}
        >
          {displayValue}
        </div>
      );
    });

    return <div className="marginTp12 inc-cron-editor--occurrences">{nextOccurrances}</div>;
  }, [cronExpr, showFurtherOccurrances, uniqId]);

  const className = useMemo(() => ["inc-cron-editor", pClassName].join(" "), [pClassName]);

  return (
    <div className={className}>
      <Cron
        allowedPeriods={["year", "month", "week", "day", "hour", "minute"]}
        clearButton={clearable}
        clockFormat={clockFormat}
        humanizeLabels
        mode="multiple"
        onError={onError}
        setValue={setCronExpr}
        value={cronExpr}
      />

      {showFurtherOccurrances && furtherOccurrances}
    </div>
  );
};
