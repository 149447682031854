import { useCallback } from "react";
import { useFetchDataStatus } from "../../../../core";
import fieldPickerApiService from "../FieldPickerApiService";
import { BizJourneyPathsResponse } from "../types/fieldpickerTypes";
import { BizFieldPredicate } from "../types";

const useFetchJourneyPath = () => {
  const {
    data: journeyPathRes,
    error,
    isError,
    isFetching,
    isSuccess,
    setStatus
  } = useFetchDataStatus<BizJourneyPathsResponse, string>({ data: null });

  const fetchJourneyPaths = useCallback(
    async (
      id: string,
      entityFilters: BizFieldPredicate[],
      startTimeMills: number,
      endTimeMillis: number,
      compareSeconds: number
    ) => {
      setStatus(null, true, false, false, "");
      let journeyPathRes: BizJourneyPathsResponse = null;
      const { data, error, message } = await fieldPickerApiService.getJourneyPaths(
        id,
        entityFilters,
        startTimeMills,
        endTimeMillis,
        compareSeconds
      );

      if (!error) {
        journeyPathRes = data;
      }

      const errMsg = error ? message : "";

      setStatus(journeyPathRes, false, !error, error, errMsg);
    },
    [setStatus]
  );

  return {
    journeyPathRes,
    isError,
    isFetching,
    isSuccess,
    error,
    fetchJourneyPaths
  };
};

export default useFetchJourneyPath;
