import { getBizEntityUrl } from "../utils";

export const CreateCopilot = {
  attributes: {
    createCopilotButton: "create-copilot-button",
    createCopilotSuggestionsDiv: "create-copilot-suggestions-div",
    createCopilotSuggestionCardDiv: "create-copilot-suggestion-card-div",
    createCopilotSuggestionCardButton: "create-copilot-suggestion-card-button",
    startCopilotOnBoardingButton: "start-copilot-onboarding-button",
    kpiStep: "kpi-step",
    cohortCard: "cohort-card",
    cohortCardDescription: "cohort-card-description",
    kpiStepNextButton: "kpi-step-next-button",
    opCardPreview: "op-card-preview",
    saveCopilotButton: "save-copilot-button",
    goToCopilotHomeButton: "go-to-co-pilot-home-button",
    subscribeWizardStep: "subscribe-wizard-step",
    slackTemplate: "slack-template",
    insightPreview: "insight-preview",
    subscribeNextAction: "subscribe-next-action",
    customCreateCopilotButton: "custom-create-copilot-button",
    copilotNameInput: "copilot-name-input",
    addKbButton: "add-kb-button",
    kbNameInput: "kb-name-input",
    kbDescriptionInput: "kb-description-input",
    kbContentInput: "kb-content-input",
    saveKbButton: "save-kb-button",
    createCopilotButtonWithCustomKb: "create-copilot-button-with-custom-kb",
    enterCopilotName: "enter-copilot-name",
    clickCreateCopilotButton: "click-create-copilot-button"
  },
  requestUrls: {
    fetchSubVerticals: getBizEntityUrl("usecaseCatalog/*/suggest*"),
    suggestCopilots: getBizEntityUrl("usecase/v2/suggest"),
    listUserService: "/api-proxy/api/**/userService/list*",
    onBoardCopilotStart: getBizEntityUrl("usecase/v2/onboardCopilotStart"),
    onBoardCopilotStatus: getBizEntityUrl("usecase/v2/onboardCopilotStatus?pollId=*"),
    saveCopilot: getBizEntityUrl("usecase/v2/save"),
    suggestKpis: getBizEntityUrl("usecase/v2/suggest/kpis"),
    cohortStart: getBizEntityUrl("usecase/v2/cohortStart"),
    cohortPoll: getBizEntityUrl("usecase/v2/cohortStatus*"),
    generateKpiQuery: getBizEntityUrl("usecase/v2/generateKPIQueryDef"),
    generateInsights: getBizEntityUrl("usecase/v2/generateInsights"),
    generateDataQueryDef: getBizEntityUrl("usecase/v2/generateDataQueryDef"),
    fetchIncidentSchema: getBizEntityUrl("op10ze/redis*/schema*"),
    chat: getBizEntityUrl("chat/continue?*"),

    searchOp: getBizEntityUrl("op10ze/search"),
    fetchIncidents: getBizEntityUrl("op10ze/fetch/incidents/data*"),
    getIncidentData: getBizEntityUrl("op10ze/redis*/incident/*"),
    createCopilot: getBizEntityUrl("usecase/v2/*"),
    addDimensions: getBizEntityUrl("usecase/*/discover/*/addDimensions?*"),

    feedCommands: getBizEntityUrl("usecase/*/user/feedCommands"),
    feedList: getBizEntityUrl("usecase/*/user/feedList"),
    fetchSuggestions: getBizEntityUrl("suggestions?*"),
    fetchOpSchema: getBizEntityUrl("op10ze/*/schema?*")
  },
  requestKeysToIgnoreIntercept: ["onBoardCopilotStatus", "cohortPoll"]
};
