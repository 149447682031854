import { Action } from "./features";

export enum OPERATIONALIZE_ACTIONS {
  addOperationalize = "addOperationalize",
  editOperationalize = "editOperationalize",
  deleteOperationalize = "deleteOperationalize"
}

export const OperationalizeFeatureActions: Record<keyof typeof Action, Array<keyof typeof OPERATIONALIZE_ACTIONS>> = {
  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  CREATE: ["addOperationalize"],

  //All READ actions goes here
  //Enabled for All
  READ: [],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  UPDATE: ["editOperationalize"],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  DELETE: ["deleteOperationalize"]
};
