import { cloneDeep } from "lodash";
import {
  OpAnalysisCreationConfig,
  OpBizDataQuery,
  OpSchedule,
  DiagnosticKPIs,
  StoryTemplateStr,
  SuppressionConfigDef,
  OpAnalysisType
} from "../../../services/api/operationalise";
import { BizDataQuery, TimeObj, UserServiceFieldSlice } from "../../../services/api/explore";
import { BaseTransformerImpl } from "../../../models";
import { getDefaultBizDataQuery, getUuidForAnalysisConfig } from "./utils";

class AnalysisOperationalizeImpl extends BaseTransformerImpl {
  private analysisCreationConfig: OpAnalysisCreationConfig;

  constructor(analysisCreationConfig: OpAnalysisCreationConfig) {
    super();
    this.analysisCreationConfig = analysisCreationConfig;
  }

  getConfig() {
    return this.analysisCreationConfig;
  }

  // schedule
  getSchedule() {
    return this.analysisCreationConfig.schedule;
  }
  setSchedule(nSchedule: OpSchedule) {
    this.analysisCreationConfig.schedule = nSchedule;
  }

  // rollingFreq
  getRollingFreq() {
    return this.analysisCreationConfig.rollingFreq;
  }
  setRollingFreq(rollingFreq: TimeObj) {
    this.analysisCreationConfig.rollingFreq = rollingFreq;
  }

  // get kpis
  getKpis() {
    return this.analysisCreationConfig.kpis;
  }
  // update kpi record
  setKpi(kpis: Record<string, OpBizDataQuery>) {
    this.analysisCreationConfig.kpis = kpis as Record<string, OpBizDataQuery>;
  }
  // update KpiEntry bizDataQuery
  setKpiEntryBizDataQuery(key: string, value: BizDataQuery) {
    this.analysisCreationConfig.kpis[key] = {
      ...this.analysisCreationConfig.kpis[key],
      bizDataQuery: value
    };
  }
  // update KpiEntry name
  setKpiEntryName(key: string, value: string) {
    this.analysisCreationConfig.kpis[key] = {
      ...this.analysisCreationConfig.kpis[key],
      name: value
    };
  }
  // delete kpiEntry
  deleteKpiEntry(key: string) {
    delete this.analysisCreationConfig.kpis[key];
    delete this.analysisCreationConfig.diagnosticKpis[key];
    delete this.analysisCreationConfig.kpiLevelSuppression?.[key];
  }
  // add kpi entry
  addKpiEntry(eventTypeId: string) {
    const uuid = getUuidForAnalysisConfig();
    this.analysisCreationConfig.kpis[uuid] = {
      bizDataQuery: getDefaultBizDataQuery(eventTypeId),
      name: "Key Performance Indicator",
      rollingFreq: null,
      rollingFunction: null
    };
    this.analysisCreationConfig.diagnosticKpis[uuid] = {
      kpi: []
    };
  }
  cloneKpiEntry(key: string) {
    const uuid = getUuidForAnalysisConfig();
    const entryToClone = this.analysisCreationConfig.kpis[key];
    const diagnosticEntries = this.analysisCreationConfig.diagnosticKpis[key]?.kpi || [];
    if (entryToClone) {
      const clonedEntry = cloneDeep(entryToClone);
      clonedEntry.name = `[CLONE]-${clonedEntry.name}`;
      this.analysisCreationConfig.kpis[uuid] = clonedEntry;
      this.analysisCreationConfig.diagnosticKpis[uuid] = {
        kpi: diagnosticEntries.map(entry => ({
          ...entry,
          name: `[CLONE]-${entry.name}`
        }))
      };
    }
  }

  // get diagnostic kpis
  getDiagnosticKpis(key: string) {
    return this.analysisCreationConfig.diagnosticKpis[key];
  }
  // update kpi record
  setDiagnosticKpi(kpis: Record<string, DiagnosticKPIs>) {
    this.analysisCreationConfig.diagnosticKpis = kpis;
  }
  // override kpiEntry
  overrideDiagnosticKpi(key: string, kpis: DiagnosticKPIs) {
    this.analysisCreationConfig.diagnosticKpis[key] = kpis;
  }
  // update KpiEntry bizDataQuery
  setDiagnosticKpiEntryBizDataQuery(key: string, index: number, value: BizDataQuery) {
    this.analysisCreationConfig.diagnosticKpis[key].kpi[index].bizDataQuery = value;
  }
  // update KpiEntry name
  setDiagnosticKpiEntryName(key: string, index: number, value: string) {
    this.analysisCreationConfig.diagnosticKpis[key].kpi[index].name = value;
  }
  // delete kpiEntry
  deleteDiagnosticKpiEntry(key: string, index: number) {
    this.analysisCreationConfig.diagnosticKpis[key].kpi = this.analysisCreationConfig.diagnosticKpis[key].kpi.filter(
      (_, i) => i !== index
    );
  }
  // add kpi entry
  addDiagnosticKpiEntry(key: string, eventTypeId: string) {
    if (this.analysisCreationConfig.diagnosticKpis?.[key]?.kpi) {
      this.analysisCreationConfig.diagnosticKpis[key].kpi.push(
        this.analysisCreationConfig.kpis[key] || {
          bizDataQuery: getDefaultBizDataQuery(eventTypeId),
          name: "Key Performance Indicator",
          rollingFreq: null,
          rollingFunction: null
        }
      );
    } else {
      this.analysisCreationConfig.diagnosticKpis = {
        [key]: {
          kpi: [
            this.analysisCreationConfig.kpis[key] || {
              bizDataQuery: getDefaultBizDataQuery(eventTypeId),
              name: "Key Performance Indicator",
              rollingFreq: null,
              rollingFunction: null
            }
          ]
        }
      };
    }
  }
  cloneDiagnosticKpiEntry(key: string, index: number) {
    const entryToClone = this.analysisCreationConfig.diagnosticKpis[key].kpi[index];
    if (entryToClone) {
      const clonedEntry = cloneDeep(entryToClone);
      clonedEntry.name = `[CLONE]-${clonedEntry.name}`;
      this.analysisCreationConfig.diagnosticKpis[key].kpi.push(clonedEntry);
    }
  }

  // dimensions to analyze
  getDimensionsToAnalyze() {
    return this.analysisCreationConfig.dimensionsToAnalyze;
  }
  setDimensionsToAnalyze(dimensionsToAnalyze: UserServiceFieldSlice[]) {
    this.analysisCreationConfig.dimensionsToAnalyze = dimensionsToAnalyze;
  }
  // diagnostic fields
  getDiagnosticFields() {
    return this.analysisCreationConfig.diagnosticFields || [];
  }
  setDiagnosticFields(diagnosticFields: UserServiceFieldSlice[]) {
    this.analysisCreationConfig.diagnosticFields = diagnosticFields;
  }

  // get dimension sets to analyze
  getDimensionSetsToAnalyze() {
    return this.analysisCreationConfig.dimensionSetsToAnalyze;
  }
  // add dimension sets to analyze
  addDimensionSetToAnalyze() {
    this.analysisCreationConfig.dimensionSetsToAnalyze.push({
      slices: []
    });
  }
  // remove dimension sets to analyze
  deleteDimensionSetToAnalyze(index: number) {
    this.analysisCreationConfig.dimensionSetsToAnalyze = this.analysisCreationConfig.dimensionSetsToAnalyze.filter(
      (_, key) => key !== index
    );
  }
  // update dimension sets to analyze
  setDimensionSetToAnalyze(index: number, dimensionsToAnalyze: UserServiceFieldSlice[]) {
    this.analysisCreationConfig.dimensionSetsToAnalyze[index].slices = dimensionsToAnalyze;
  }

  // template format
  getTemplateFormat() {
    return this.analysisCreationConfig.templateFormat;
  }
  setTemplateFormat(templateFormat: string) {
    this.analysisCreationConfig.templateFormat = templateFormat;
  }

  // template format json
  getStoryTemplates() {
    return this.analysisCreationConfig.storyTemplates;
  }
  setStoryTemplates(templates: StoryTemplateStr[]) {
    this.analysisCreationConfig.storyTemplates = templates;
  }
  // delete templateJson
  deleteStoryTemplateEntry(index: number) {
    this.analysisCreationConfig.storyTemplates =
      this.analysisCreationConfig.storyTemplates?.filter((_, i) => index !== i) || [];
  }
  // add templateJson entry
  addStoryTemplateEntry() {
    if (this.analysisCreationConfig.storyTemplates) {
      this.analysisCreationConfig.storyTemplates.push({
        name: "",
        templateJsonStr: ""
      });
    } else {
      this.analysisCreationConfig.storyTemplates = [
        {
          name: "",
          templateJsonStr: ""
        }
      ];
    }
  }
  cloneStoryTemplateEntry(index: number) {
    const entryToClone = this.analysisCreationConfig.storyTemplates?.[index];
    if (entryToClone) {
      const clonedEntry = cloneDeep(entryToClone);
      clonedEntry.name = `[CLONE]-${clonedEntry.name}`;
      this.analysisCreationConfig.storyTemplates.push(clonedEntry);
    }
  }

  setStoryTemplateName(index: number, value: string) {
    this.analysisCreationConfig.storyTemplates[index].name = value;
  }
  setStoryTemplateJson(index: number, value: string) {
    this.analysisCreationConfig.storyTemplates[index].templateJsonStr = value;
  }

  // compareToOffset
  getCompareToOffset() {
    return this.analysisCreationConfig.compareToOffset;
  }
  setCompareToOffset(compareToOffset: TimeObj) {
    this.analysisCreationConfig.compareToOffset = compareToOffset;
  }

  // suppression
  getSuppression() {
    return this.analysisCreationConfig.suppression;
  }
  setSuppression(suppression: SuppressionConfigDef[]) {
    this.analysisCreationConfig.suppression = suppression;
  }

  getAnalysisTypes() {
    return this.analysisCreationConfig.analysisType || [];
  }

  setAnalysisTypes(analysisTypes: OpAnalysisType[]) {
    this.analysisCreationConfig.analysisType = analysisTypes;
  }
}

export default AnalysisOperationalizeImpl;
