import { TimeSeries } from "../../../core";
import { DatasourceApi } from "../../api/DatasourceService";
import {
  BaseApi,
  DataQuery,
  DataQueryRequest,
  DataQueryResponse,
  DatasourceSettings,
  InceptionRequestConfig,
  InceptionResponse
} from "../../api/types";
import widgetMockDataHandlerRegistry from "./MockDataRegistry";

export interface MockQuery extends DataQuery {
  metric: any;
  output: boolean;
  alias: any;
  id: any;
  agg: any;
  rate: string;
  dsInterval: any;
  dsFn: any;
  mockData: any;
  tags?: any;
  eTags?: any;
}

export interface MockSettings extends DatasourceSettings {}

export class MockDatasource
  extends DatasourceApi<MockQuery, MockSettings>
  implements DatasourceApi<MockQuery, MockSettings>
{
  private tagKeys: Record<string, string[]> = {};

  constructor(name: string, settings: MockSettings, baseService: BaseApi) {
    super(name, "mock", settings, baseService);
  }

  query(request: DataQueryRequest<MockQuery>): Promise<DataQueryResponse> {
    const { targets } = request;
    let timeseries: TimeSeries[] = [];
    if (targets.length > 0) {
      const { requestId } = request;
      const mockHandler = widgetMockDataHandlerRegistry.getHandler(requestId);
      if (mockHandler) {
        timeseries = mockHandler.getData(request);
      } else {
        timeseries = request.targets.map(
          t =>
            ({
              target: t.metric || "mock",
              datapoints: t.mockData || [[12875, Date.now()]],
              tags: t.tags || {},
              eTags: t.eTags || {},
              metricName: t.metric || "mock"
            }) as TimeSeries
        );
      }

      return Promise.resolve({
        data: timeseries
      } as DataQueryResponse);
    }
  }

  get<T, B, R = InceptionResponse<T>>(url: string, payload?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.get(dsUrl, payload, config);
  }

  post<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.post(dsUrl, data, config);
  }

  delete<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.delete(dsUrl, config);
  }

  put<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const dsUrl = this.getUrl(url);
    return this.baseApiService.put(dsUrl, data, config);
  }
}
