import React from "react";

interface Props {
  width: string | number;
}

const CellLoading: React.FC<Props> = (props: Props) => (
  <div
    className="inc-table-cell-loading"
    style={{ width: props.width }}
  />
);

export default CellLoading;
