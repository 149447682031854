import { PredicateOp, EntityOperation } from "../services/api/explore";

const PredOpMap: Record<string, PredicateOp> = {
  in: "in",
  "=": "eq",
  "!=": "ne",
  ">": "gt",
  ">=": "ge",
  "<": "lt",
  "<=": "le"
};
const EntityNumericOpMap: Record<string, EntityOperation> = {
  in: "in",
  "=": "eq",
  "!=": "neq",
  "<": "lt",
  "<=": "le",
  ">": "gt",
  ">=": "ge"
};
const EntityNonNumericOpMap: Record<string, EntityOperation> = {
  in: "in",
  "=": "eq",
  "!=": "neq",
  "=~": "regex",
  startsWith: "startsWith"
};
const EntityDateTimeOpMap: Record<string, EntityOperation> = {
  "=": "eq",
  "<": "lt",
  ">": "gt",
  range: "range"
};
const EntityOpMap: Record<string, EntityOperation> = {
  ...EntityNumericOpMap,
  ...EntityNonNumericOpMap,
  ...EntityDateTimeOpMap
};

export function getOpFromPredicateOp(pOp: PredicateOp) {
  return Object.keys(PredOpMap).find(k => PredOpMap[k] === pOp);
}

export function getOpFromEntityOperation(pOp: EntityOperation) {
  return Object.keys(EntityOpMap).find(k => EntityOpMap[k] === pOp);
}

export const getNumericPredicateFromValues = (minValue: string, maxValue: string) => {
  let op: EntityOperation = "eq";
  let value: string | string[];

  const minExists = minValue !== null && minValue !== "" && minValue !== "NaN";
  const maxExists = maxValue !== null && maxValue !== "" && maxValue !== "NaN";

  if (!minExists && !maxExists) {
    op = "gt";
    value = "0";
  } else if (!minExists && maxExists) {
    op = "le";
    value = maxValue;
  } else if (minExists && !maxExists) {
    op = "ge";
    value = minValue;
  } else {
    const areValuesEqual = minValue === maxValue;
    op = areValuesEqual ? "eq" : "range";
    value = areValuesEqual ? minValue : [minValue, maxValue];
  }

  return {
    op,
    value
  };
};
