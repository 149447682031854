import { ChartPoint } from "../../../dashboard/widgets/TimeSeries/models/model";
import { DataFrame, DataFrameFieldType, TimeSeries } from "../types";

const dataFrameToTimeseries = (dataFrame: DataFrame): TimeSeries => {
  if (isTimeseriesDataFrame(dataFrame)) {
    const { fields, id, name, labels = {}, eLabels = {}, metricName = "", meta } = dataFrame;

    const timestamps: number[] = fields[0].data;
    const { data } = fields[1];

    const datapoints: ChartPoint[] = timestamps.map((timestamp, i) => [data[i], timestamp]);
    return {
      refId: id,
      target: name,
      tags: labels,
      eTags: eLabels,
      datapoints,
      metricName,
      meta
    };
  }
  throw new Error("Dataframe is not timeseries data frame");
};

export default dataFrameToTimeseries;

const isTimeseriesDataFrame = (dataFrame: DataFrame) => {
  const hasTimeField = dataFrame.fields[0]?.type === DataFrameFieldType.time;
  return hasTimeField && dataFrame.fields.length === 2;
};
