import { parse } from "query-string";
import { map } from "lodash";
import { useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { DataTypeWithDefault } from "../utils";

export type QueryValue = string | string[];

/**
 * @deprecated replace with useTypedQueryParams or useQueryParamsWithDefault.
 */
export const useQueryParams = (): Record<string, QueryValue> => {
  const location = useLocation();
  const { search } = location;
  return useMemo(() => {
    const paramsStr = search.slice(1); // remove ? from the string
    const paramsObj = parse(paramsStr); // Object with no prototype
    const params: Record<string, QueryValue> = {};
    map(paramsObj, (v, k) => {
      params[k] = v;
    });
    return params;
  }, [search]);
};

export const useTypedQueryParams = <T extends Record<string, any>>(): T => {
  const location = useLocation();
  const { search } = location;
  return useMemo(() => {
    const paramsStr = search.slice(1); // remove ? from the string
    const paramsObj = parse(paramsStr); // Object with no prototype
    const params: T = {} as T;
    map(paramsObj, (v: T[keyof T], k: keyof T) => {
      params[k] = v;
    });
    return params;
  }, [search]);
};

interface QueryParamsWithRead<T> {
  params: T;
  getStr: (key: keyof T, defaultVal?: string) => string;
  getStrArr: (key: keyof T, defaultVal?: string[]) => string[];
}

export const useQueryParamsWithDefault = <T extends Record<string, any>>(): QueryParamsWithRead<T> => {
  const params = useTypedQueryParams<T>();

  const getStr = useCallback(
    (key: keyof T, defaultVal: string = null): string => {
      const value = params[key] as any;
      return DataTypeWithDefault.getStr(value, defaultVal);
    },
    [params]
  );

  const getStrArr = useCallback(
    (key: keyof T, defaultVal: string[] = []) => {
      const value = params[key] as any;
      return DataTypeWithDefault.getStrArr(value, defaultVal);
    },
    [params]
  );

  return {
    params,
    getStr,
    getStrArr
  };
};
