import { useCallback, useMemo } from "react";

const useLocalStorage = () => {
  const localStorage = useMemo(() => window.localStorage, []);
  const getItem = useCallback((key: string) => localStorage.getItem(key), [localStorage]);
  const setItem = useCallback((key: string, value: string) => localStorage.setItem(key, value), [localStorage]);
  const removeItem = useCallback((key: string) => localStorage.removeItem(key), [localStorage]);

  return {
    getItem,
    setItem,
    removeItem
  };
};

export default useLocalStorage;
