/**
 * This file contains all the date and time formats required for the app.
 * If you need to add a new format, add it to IncDateTimeFormats interface in types.ts before adding it here.
 */

import { IncDateTimeFormat, IncDateTimeFormatOptions, IncHighchartsDateTimeFormat } from "./formatter-types";

const common: Partial<IncDateTimeFormatOptions> = {
  dateStyle: "full",
  timeStyle: "full",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit"
};

const dateTimeFormats: Record<IncDateTimeFormat | IncHighchartsDateTimeFormat, IncDateTimeFormatOptions> = {
  full: {
    ...common,
    weekday: "short",
    month: "short",
    year: "numeric"
  },
  minimal: {
    ...common,
    month: "short",
    year: "numeric"
  },
  numeric: {
    ...common,
    month: "2-digit",
    year: "numeric"
  },
  timeOnly: {
    timeStyle: "full",
    hour: "2-digit",
    minute: "2-digit"
  },
  monthDay: {
    dateStyle: "full",
    day: "2-digit",
    month: "2-digit"
  },
  monthDayTime: {
    timeStyle: "full",
    hour: "2-digit",
    minute: "2-digit",
    dateStyle: "full",
    day: "2-digit",
    month: "2-digit"
  },
  monthDayYearTime: {
    dateStyle: "full",
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    timeStyle: "full",
    hour: "2-digit",
    minute: "2-digit"
  },
  cohortNumericDate: {
    dateStyle: "full",
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  },
  cohortNumericDateTime: {
    ...common,
    month: "2-digit",
    year: "numeric"
  },
  monthDayDescriptive: {
    dateStyle: "full",
    day: "2-digit",
    month: "short"
  },
  shortDayOnly: {
    dateStyle: "full",
    weekday: "short"
  }
};

const withSecondsOptions: Pick<IncDateTimeFormatOptions, "second"> = {
  second: "2-digit"
};

const hour12Options: Pick<IncDateTimeFormatOptions, "hour12" | "hourCycle"> = {
  hourCycle: "h11",
  hour12: true
};

const hour24Options: Pick<IncDateTimeFormatOptions, "hour12" | "hourCycle"> = {
  hourCycle: "h23",
  hour12: false
};

export { dateTimeFormats, withSecondsOptions, hour12Options, hour24Options };
