import { AuthType, Role } from "../../../core";
import { BootConfigPayload } from "./types";

class MockUiApiService {
  fetchBootConfig() {
    return bootConfigResponse;
  }
}

const mockUiApiService = new MockUiApiService();
export default mockUiApiService;

export const bootConfigResponse: BootConfigPayload = {
  user: {
    id: 1000,
    email: "anomuser@bicycle.io",
    name: "anomuser",
    login: "anomuser@bicycle.io",
    theme: "",
    orgId: 1000,
    isGrafanaAdmin: false,
    isDisabled: false,
    isExternal: false,
    authLabels: [] as any[],
    updatedAt: "2022-10-03T11:54:13Z",
    createdAt: "2021-08-13T05:20:40Z",
    avatarUrl: "/apptuit/public/img/user_profile.png",
    isMockUser: true,
    role: Role.Viewer
  },
  org: {
    id: 1000,
    name: "AnomUserOrg"
  },
  userOrgs: [
    {
      orgId: 1000,
      name: "AnomUserOrg",
      role: Role.Viewer
    }
  ],
  preferences: {
    theme: "",
    homeDashboardId: "0",
    timezone: ""
  },
  authType: {
    authType: AuthType.password,
    name: "Password"
  },
  isSupportLoginEnabled: false,
  isSupportSession: false,
  supportContext: null as any
};
