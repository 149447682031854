import React, { useMemo } from "react";

import CustomSelect from "../components/CustomSelect";
import { UNITS } from "../constants";
import { DEFAULT_LOCALE_EN } from "../locale";
import { HoursProps } from "../types";
import { classNames } from "../utils";

export default function Hours(props: HoursProps) {
  const {
    value,
    setValue,
    locale,
    className,
    disabled,
    readOnly,
    leadingZero,
    clockFormat,
    period,
    periodicityOnDoubleClick,
    mode
  } = props;
  const internalClassName = useMemo(
    () =>
      classNames({
        "react-js-cron-field": true,
        "react-js-cron-hours": true,
        [`${className}-field`]: !!className,
        [`${className}-hours`]: !!className
      }),
    [className]
  );

  return (
    <div className={internalClassName}>
      {locale.prefixHours !== "" && <span>{locale.prefixHours || DEFAULT_LOCALE_EN.prefixHours}</span>}

      <CustomSelect
        className={className}
        clockFormat={clockFormat}
        disabled={disabled}
        leadingZero={leadingZero}
        locale={locale}
        mode={mode}
        period={period}
        periodicityOnDoubleClick={periodicityOnDoubleClick}
        placeholder={locale.emptyHours || DEFAULT_LOCALE_EN.emptyHours}
        readOnly={readOnly}
        setValue={setValue}
        unit={UNITS[1]}
        value={value}
      />
    </div>
  );
}
