import { createAction } from "@reduxjs/toolkit";
import {
  OpAnalysisCreationConfig,
  OpBizDataQuery,
  OpSchedule,
  DiagnosticKPIs,
  StoryTemplateStr,
  SuppressionConfigDef,
  OpAnalysisType
} from "../../../services/api/operationalise";
import { BizDataQuery, TimeObj, UserServiceFieldSlice } from "../../../services/api/explore";
import {
  ADD_ANALYSIS_DIAGNOSTIC_KPI,
  ADD_ANALYSIS_DIMENSION_SETS_TO_ANALYZE,
  ADD_ANALYSIS_KPI,
  ADD_STORY_TEMPLATE_FORMAT,
  CLONE_ANALYSIS_DIAGNOSTIC_KPI,
  CLONE_ANALYSIS_KPI,
  CLONE_STORY_TEMPLATE_FORMAT,
  DELETE_ANALYSIS_DIAGNOSTIC_KPI,
  DELETE_ANALYSIS_DIMENSION_SETS_TO_ANALYZE,
  DELETE_ANALYSIS_KPI,
  DELETE_STORY_TEMPLATE_FORMAT,
  INITIALIZE_ANALYSIS_CONFIG,
  OVERRIDE_ANALYSIS_DIAGNOSTIC_KPI,
  SET_ANALYSIS_COMPARE_TO_OFFSET,
  SET_ANALYSIS_DIAGNOSTIC_FIELDS,
  SET_ANALYSIS_DIAGNOSTIC_KPI,
  SET_ANALYSIS_DIAGNOSTIC_KPI_NAME,
  SET_ANALYSIS_DIAGNOSTIC_KPI_QUERY,
  SET_ANALYSIS_DIMENSIONS_TO_ANALYZE,
  SET_ANALYSIS_DIMENSION_SETS_TO_ANALYZE,
  SET_ANALYSIS_KPI,
  SET_ANALYSIS_KPI_NAME,
  SET_ANALYSIS_KPI_QUERY,
  SET_ANALYSIS_ROLLING_FREQ,
  SET_ANALYSIS_SCHEDULE,
  SET_ANALYSIS_TYPES,
  SET_DRY_RUN,
  SET_STORY_TEMPLATE_FORMAT,
  SET_STORY_TEMPLATE_FORMAT_JSON,
  SET_STORY_TEMPLATE_FORMAT_NAME,
  SET_SUPPRESSION,
  SET_TEMPLATE_FORMAT
} from "./action_names";
import { AnalysisListChildPayload, SetAnalysisRecordEntry } from "./types";

// initialize
export const setAnalysisConfig = createAction<OpAnalysisCreationConfig>(INITIALIZE_ANALYSIS_CONFIG);
export const setDryRun = createAction<boolean>(SET_DRY_RUN);
// schedule
export const setAnalysisSchedule = createAction<OpSchedule>(SET_ANALYSIS_SCHEDULE);
// kpis
export const setAnalysisKpi = createAction<Record<string, OpBizDataQuery>>(SET_ANALYSIS_KPI);
export const cloneAnalysisKpi = createAction<string>(CLONE_ANALYSIS_KPI);
export const addAnalysisKpi = createAction<string>(ADD_ANALYSIS_KPI);
export const deleteAnalysisKpi = createAction<string>(DELETE_ANALYSIS_KPI);
export const setAnalysisKpiName = createAction<SetAnalysisRecordEntry<string>>(SET_ANALYSIS_KPI_NAME);
export const setAnalysisKpiQuery = createAction<SetAnalysisRecordEntry<BizDataQuery>>(SET_ANALYSIS_KPI_QUERY);
// diagnostic kpis
export const setAnalysisDiagnosticKpi = createAction<Record<string, DiagnosticKPIs>>(SET_ANALYSIS_DIAGNOSTIC_KPI);
export const overrideAnalysisDiagnosticKpi = createAction<SetAnalysisRecordEntry<DiagnosticKPIs>>(
  OVERRIDE_ANALYSIS_DIAGNOSTIC_KPI
);
export const cloneAnalysisDiagnosticKpi = createAction<SetAnalysisRecordEntry<number>>(CLONE_ANALYSIS_DIAGNOSTIC_KPI);
export const addAnalysisDiagnosticKpi = createAction<SetAnalysisRecordEntry<string>>(ADD_ANALYSIS_DIAGNOSTIC_KPI);
export const deleteAnalysisDiagnosticKpi = createAction<SetAnalysisRecordEntry<number>>(DELETE_ANALYSIS_DIAGNOSTIC_KPI);
export const setAnalysisDiagnosticKpiName = createAction<SetAnalysisRecordEntry<AnalysisListChildPayload<string>>>(
  SET_ANALYSIS_DIAGNOSTIC_KPI_NAME
);
export const setAnalysisDiagnosticKpiQuery = createAction<
  SetAnalysisRecordEntry<AnalysisListChildPayload<BizDataQuery>>
>(SET_ANALYSIS_DIAGNOSTIC_KPI_QUERY);
// rolling freq and compare offset
export const setAnalysisRollingFreq = createAction<TimeObj>(SET_ANALYSIS_ROLLING_FREQ);
export const setAnalysisCompareToOffset = createAction<TimeObj>(SET_ANALYSIS_COMPARE_TO_OFFSET);
// dimensions
export const setAnalysisDimensionsToAnalyze = createAction<UserServiceFieldSlice[]>(SET_ANALYSIS_DIMENSIONS_TO_ANALYZE);
// diagnostic fields
export const setAnalysisDiagnosticFields = createAction<UserServiceFieldSlice[]>(SET_ANALYSIS_DIAGNOSTIC_FIELDS);
// dimension sets
export const setAnalysisDimensionSetsToAnalyze = createAction<AnalysisListChildPayload<UserServiceFieldSlice[]>>(
  SET_ANALYSIS_DIMENSION_SETS_TO_ANALYZE
);
export const addAnalysisDimensionSetsToAnalyze = createAction(ADD_ANALYSIS_DIMENSION_SETS_TO_ANALYZE);
export const deleteAnalysisDimensionSetsToAnalyze = createAction<AnalysisListChildPayload<number>>(
  DELETE_ANALYSIS_DIMENSION_SETS_TO_ANALYZE
);
// template format
export const setAnalysisTemplateFormat = createAction<string>(SET_TEMPLATE_FORMAT);
// story templates
export const setAnalysisStoryTemplates = createAction<StoryTemplateStr[]>(SET_STORY_TEMPLATE_FORMAT);
export const addAnalysisStoryTemplate = createAction(ADD_STORY_TEMPLATE_FORMAT);
export const cloneAnalysisStoryTemplate = createAction<number>(CLONE_STORY_TEMPLATE_FORMAT);
export const setAnalysisStoryTemplateJson =
  createAction<AnalysisListChildPayload<string>>(SET_STORY_TEMPLATE_FORMAT_JSON);
export const setAnalysisStoryTemplateName =
  createAction<AnalysisListChildPayload<string>>(SET_STORY_TEMPLATE_FORMAT_NAME);
export const deleteAnalysisStoryTemplate = createAction<number>(DELETE_STORY_TEMPLATE_FORMAT);
// suppressions
export const setAnalysisSuppression = createAction<SuppressionConfigDef[]>(SET_SUPPRESSION);
// analysis types
export const setAnalysisTypes = createAction<OpAnalysisType[]>(SET_ANALYSIS_TYPES);
