import { EntityAggregationResponse } from "../../types";

export const aggregationsResponse: EntityAggregationResponse = {
  entityType: "Merchant",
  aggregations: {
    Annual_Revenue: {
      ranges: {
        buckets: [
          {
            key: "mid",
            count: "420",
            from: 1000000,
            to: 1.5e7
          },
          {
            key: "mid",
            count: "330",
            from: 1.5e7,
            to: 3.0e7
          },
          {
            key: "mid",
            count: "260",
            from: 3.0e7,
            to: 4.5e7
          },
          {
            key: "mid",
            count: "450",
            from: 4.5e7,
            to: 6.0e7
          },
          {
            key: "mid",
            count: "540",
            from: 6.0e7,
            to: "Infinity" as any
          }
        ]
      }
    },
    Country: {
      topn: {
        buckets: [
          {
            key: "USA",
            count: "575"
          },
          {
            key: "Canada",
            count: "475"
          },
          {
            key: "Brazil",
            count: "275"
          },
          {
            key: "India",
            count: "375"
          },
          {
            key: "United Kingdom",
            count: "300"
          }
        ]
      }
    },
    Id: {
      topn: {
        buckets: [
          {
            key: "123654",
            count: "10"
          },
          {
            key: "123656",
            count: "10"
          },
          {
            key: "123660",
            count: "10"
          },
          {
            key: "123668",
            count: "10"
          },
          {
            key: "123672",
            count: "10"
          },
          {
            key: "123675",
            count: "10"
          },
          {
            key: "123677",
            count: "10"
          },
          {
            key: "123698",
            count: "10"
          },
          {
            key: "123711",
            count: "10"
          },
          {
            key: "123712",
            count: "10"
          },
          {
            key: "123734",
            count: "10"
          },
          {
            key: "123736",
            count: "10"
          },
          {
            key: "123763",
            count: "10"
          },
          {
            key: "123777",
            count: "10"
          },
          {
            key: "123783",
            count: "10"
          },
          {
            key: "123786",
            count: "10"
          },
          {
            key: "123787",
            count: "10"
          },
          {
            key: "123802",
            count: "10"
          },
          {
            key: "123811",
            count: "10"
          },
          {
            key: "123822",
            count: "10"
          },
          {
            key: "123823",
            count: "10"
          },
          {
            key: "123835",
            count: "10"
          },
          {
            key: "123851",
            count: "10"
          },
          {
            key: "123854",
            count: "10"
          },
          {
            key: "123855",
            count: "10"
          },
          {
            key: "123857",
            count: "10"
          },
          {
            key: "123859",
            count: "10"
          },
          {
            key: "123862",
            count: "10"
          },
          {
            key: "123863",
            count: "10"
          },
          {
            key: "123868",
            count: "10"
          },
          {
            key: "123873",
            count: "10"
          },
          {
            key: "123882",
            count: "10"
          },
          {
            key: "123883",
            count: "10"
          },
          {
            key: "123892",
            count: "10"
          },
          {
            key: "123907",
            count: "10"
          },
          {
            key: "123912",
            count: "10"
          },
          {
            key: "123914",
            count: "10"
          },
          {
            key: "123926",
            count: "10"
          },
          {
            key: "123928",
            count: "10"
          },
          {
            key: "123934",
            count: "10"
          },
          {
            key: "123938",
            count: "10"
          },
          {
            key: "123939",
            count: "10"
          },
          {
            key: "123940",
            count: "10"
          },
          {
            key: "123942",
            count: "10"
          },
          {
            key: "123950",
            count: "10"
          },
          {
            key: "123955",
            count: "10"
          },
          {
            key: "123963",
            count: "10"
          },
          {
            key: "123968",
            count: "10"
          },
          {
            key: "123970",
            count: "10"
          },
          {
            key: "123982",
            count: "10"
          },
          {
            key: "123991",
            count: "10"
          },
          {
            key: "124000",
            count: "10"
          },
          {
            key: "124002",
            count: "10"
          },
          {
            key: "124011",
            count: "10"
          },
          {
            key: "124017",
            count: "10"
          },
          {
            key: "124019",
            count: "10"
          },
          {
            key: "124024",
            count: "10"
          },
          {
            key: "124025",
            count: "10"
          },
          {
            key: "124031",
            count: "10"
          },
          {
            key: "124033",
            count: "10"
          },
          {
            key: "124035",
            count: "10"
          },
          {
            key: "124041",
            count: "10"
          },
          {
            key: "124050",
            count: "10"
          },
          {
            key: "124052",
            count: "10"
          },
          {
            key: "124062",
            count: "10"
          },
          {
            key: "124064",
            count: "10"
          },
          {
            key: "124065",
            count: "10"
          },
          {
            key: "124074",
            count: "10"
          },
          {
            key: "124089",
            count: "10"
          },
          {
            key: "124091",
            count: "10"
          },
          {
            key: "124092",
            count: "10"
          },
          {
            key: "124099",
            count: "10"
          },
          {
            key: "124113",
            count: "10"
          },
          {
            key: "124114",
            count: "10"
          },
          {
            key: "124136",
            count: "10"
          },
          {
            key: "124138",
            count: "10"
          },
          {
            key: "124139",
            count: "10"
          },
          {
            key: "124140",
            count: "10"
          },
          {
            key: "124145",
            count: "10"
          },
          {
            key: "124147",
            count: "10"
          },
          {
            key: "124151",
            count: "10"
          },
          {
            key: "124152",
            count: "10"
          },
          {
            key: "124162",
            count: "10"
          },
          {
            key: "124168",
            count: "10"
          },
          {
            key: "124183",
            count: "10"
          },
          {
            key: "124186",
            count: "10"
          },
          {
            key: "124195",
            count: "10"
          },
          {
            key: "124209",
            count: "10"
          },
          {
            key: "124213",
            count: "10"
          },
          {
            key: "124214",
            count: "10"
          },
          {
            key: "124224",
            count: "10"
          },
          {
            key: "124225",
            count: "10"
          },
          {
            key: "124227",
            count: "10"
          },
          {
            key: "124228",
            count: "10"
          },
          {
            key: "124232",
            count: "10"
          },
          {
            key: "124241",
            count: "10"
          },
          {
            key: "124243",
            count: "10"
          },
          {
            key: "124251",
            count: "10"
          },
          {
            key: "124255",
            count: "10"
          },
          {
            key: "124268",
            count: "10"
          },
          {
            key: "124279",
            count: "10"
          },
          {
            key: "124281",
            count: "10"
          },
          {
            key: "124327",
            count: "10"
          },
          {
            key: "124380",
            count: "10"
          },
          {
            key: "124386",
            count: "10"
          },
          {
            key: "124396",
            count: "10"
          },
          {
            key: "124402",
            count: "10"
          },
          {
            key: "124419",
            count: "10"
          },
          {
            key: "124473",
            count: "10"
          },
          {
            key: "124487",
            count: "10"
          },
          {
            key: "124488",
            count: "10"
          },
          {
            key: "124533",
            count: "10"
          },
          {
            key: "124546",
            count: "10"
          },
          {
            key: "124562",
            count: "10"
          },
          {
            key: "124581",
            count: "10"
          },
          {
            key: "124582",
            count: "10"
          },
          {
            key: "124601",
            count: "10"
          },
          {
            key: "124611",
            count: "10"
          },
          {
            key: "124619",
            count: "10"
          },
          {
            key: "124622",
            count: "10"
          },
          {
            key: "124630",
            count: "10"
          },
          {
            key: "124639",
            count: "10"
          },
          {
            key: "124644",
            count: "10"
          },
          {
            key: "124655",
            count: "10"
          },
          {
            key: "124703",
            count: "10"
          },
          {
            key: "124756",
            count: "10"
          },
          {
            key: "124762",
            count: "10"
          },
          {
            key: "124772",
            count: "10"
          },
          {
            key: "124778",
            count: "10"
          },
          {
            key: "124795",
            count: "10"
          },
          {
            key: "124849",
            count: "10"
          },
          {
            key: "124863",
            count: "10"
          },
          {
            key: "124864",
            count: "10"
          },
          {
            key: "124909",
            count: "10"
          },
          {
            key: "124922",
            count: "10"
          },
          {
            key: "124938",
            count: "10"
          },
          {
            key: "124957",
            count: "10"
          },
          {
            key: "124958",
            count: "10"
          },
          {
            key: "125072",
            count: "10"
          },
          {
            key: "125085",
            count: "10"
          },
          {
            key: "125101",
            count: "10"
          },
          {
            key: "125120",
            count: "10"
          },
          {
            key: "125121",
            count: "10"
          },
          {
            key: "125149",
            count: "10"
          },
          {
            key: "125242",
            count: "10"
          },
          {
            key: "125258",
            count: "10"
          },
          {
            key: "125377",
            count: "10"
          },
          {
            key: "125519",
            count: "10"
          },
          {
            key: "125520",
            count: "10"
          },
          {
            key: "125521",
            count: "10"
          },
          {
            key: "125522",
            count: "10"
          },
          {
            key: "125523",
            count: "10"
          },
          {
            key: "125524",
            count: "10"
          },
          {
            key: "125525",
            count: "10"
          },
          {
            key: "125526",
            count: "10"
          },
          {
            key: "125527",
            count: "10"
          },
          {
            key: "125528",
            count: "10"
          },
          {
            key: "125529",
            count: "10"
          },
          {
            key: "125530",
            count: "10"
          },
          {
            key: "125531",
            count: "10"
          }
        ]
      }
    },
    Industry: {
      topn: {
        buckets: [
          {
            key: "Travel",
            count: "500"
          },
          {
            key: "Retail",
            count: "410"
          },
          {
            key: "Sports",
            count: "330"
          },
          {
            key: "Services",
            count: "280"
          },
          {
            key: "Food",
            count: "170"
          },
          {
            key: "Insurance",
            count: "200"
          },
          {
            key: "E-Commerce",
            count: "110"
          }
        ]
      }
    },
    Name: {
      topn: {
        buckets: [
          {
            key: "2chainz",
            count: "10"
          },
          {
            key: "AA Metals",
            count: "10"
          },
          {
            key: "AOK RVs",
            count: "10"
          },
          {
            key: "Agile Velocity",
            count: "10"
          },
          {
            key: "Air Filter Factory",
            count: "10"
          },
          {
            key: "AlerStallings",
            count: "10"
          },
          {
            key: "All Things Barbecue",
            count: "10"
          },
          {
            key: "Allbirds",
            count: "10"
          },
          {
            key: "Alpha Connect",
            count: "10"
          },
          {
            key: "American Paper Optics",
            count: "10"
          },
          {
            key: "Amijag",
            count: "10"
          },
          {
            key: "Argotrak",
            count: "10"
          },
          {
            key: "Artifact Uprising",
            count: "10"
          },
          {
            key: "Automotive Avenues",
            count: "10"
          },
          {
            key: "Beck Partners",
            count: "10"
          },
          {
            key: "Beef Jerky Outlet",
            count: "10"
          },
          {
            key: "BizIQ",
            count: "10"
          },
          {
            key: "Blink Fitness",
            count: "10"
          },
          {
            key: "Blount Fine Foods",
            count: "10"
          },
          {
            key: "Bombas",
            count: "10"
          },
          {
            key: "CFO Alliance",
            count: "10"
          },
          {
            key: "Cable Ties Unlimited",
            count: "10"
          },
          {
            key: "Cogent Analytics",
            count: "10"
          },
          {
            key: "College Hunks Hauling Junk and Moving (West Chester, PA)",
            count: "10"
          },
          {
            key: "Computer Overhauls",
            count: "10"
          },
          {
            key: "Cooper\u0027s Hawk Winery \u0026 Restaurants",
            count: "10"
          },
          {
            key: "Corkcicle",
            count: "10"
          },
          {
            key: "DEX Imaging",
            count: "10"
          },
          {
            key: "DriveTime",
            count: "10"
          },
          {
            key: "Dynamic Pricing Partners",
            count: "10"
          },
          {
            key: "EAU DE LUXE",
            count: "10"
          },
          {
            key: "EmazingLights / iHeartRaves / IntoTheAM",
            count: "10"
          },
          {
            key: "EventSphere",
            count: "10"
          },
          {
            key: "Eventellect",
            count: "10"
          },
          {
            key: "Evolution Event Solutions",
            count: "10"
          },
          {
            key: "Famous Toastery",
            count: "10"
          },
          {
            key: "Fifteen Five",
            count: "10"
          },
          {
            key: "FlexPrint Managed Print Solutions",
            count: "10"
          },
          {
            key: "Food52",
            count: "10"
          },
          {
            key: "Four Foods Group",
            count: "10"
          },
          {
            key: "FranchiseHelp",
            count: "10"
          },
          {
            key: "Franklin Media",
            count: "10"
          },
          {
            key: "Freddy\u0027s Frozen Custard \u0026 Steakburgers",
            count: "10"
          },
          {
            key: "Goettl Air Conditioning",
            count: "10"
          },
          {
            key: "Graffiti",
            count: "10"
          },
          {
            key: "GraffitiTech",
            count: "10"
          },
          {
            key: "Grain Management",
            count: "10"
          },
          {
            key: "Gramovox",
            count: "10"
          },
          {
            key: "Grand Prix Holdings USA",
            count: "10"
          },
          {
            key: "Granify",
            count: "10"
          },
          {
            key: "Gravy",
            count: "10"
          },
          {
            key: "Green Is Good",
            count: "10"
          },
          {
            key: "Green Plug",
            count: "10"
          },
          {
            key: "GreenBiz Group",
            count: "10"
          },
          {
            key: "GreenCage Security",
            count: "10"
          },
          {
            key: "GreenGoose!",
            count: "10"
          },
          {
            key: "GreenWave Reality",
            count: "10"
          },
          {
            key: "Greysox",
            count: "10"
          },
          {
            key: "Greystone",
            count: "10"
          },
          {
            key: "Gro",
            count: "10"
          },
          {
            key: "Groove",
            count: "10"
          },
          {
            key: "GroundedPower",
            count: "10"
          },
          {
            key: "GroupFlier",
            count: "10"
          },
          {
            key: "Grouper",
            count: "10"
          },
          {
            key: "Gucash",
            count: "10"
          },
          {
            key: "Guestmob",
            count: "10"
          },
          {
            key: "Guidekick",
            count: "10"
          },
          {
            key: "Gun.io",
            count: "10"
          },
          {
            key: "Gungroo",
            count: "10"
          },
          {
            key: "Gymshark",
            count: "10"
          },
          {
            key: "HMS Global Maritime",
            count: "10"
          },
          {
            key: "Halton",
            count: "10"
          },
          {
            key: "Hand Therapy Solutions",
            count: "10"
          },
          {
            key: "Hawkers Asian Street Fare",
            count: "10"
          },
          {
            key: "Hawks",
            count: "10"
          },
          {
            key: "Healthy Harvest",
            count: "10"
          },
          {
            key: "Heartfelt Creations",
            count: "10"
          },
          {
            key: "Heinz",
            count: "10"
          },
          {
            key: "Impact Networking",
            count: "10"
          },
          {
            key: "Insight Global",
            count: "10"
          },
          {
            key: "Ivy Lane",
            count: "10"
          },
          {
            key: "James Martin Furniture",
            count: "10"
          },
          {
            key: "Jersey Mike\u0027s Subs",
            count: "10"
          },
          {
            key: "KeenAlignment",
            count: "10"
          },
          {
            key: "Kelapo",
            count: "10"
          },
          {
            key: "Las Vegas Expo",
            count: "10"
          },
          {
            key: "Level 2 Legal Solutions",
            count: "10"
          },
          {
            key: "Level Up Development",
            count: "10"
          },
          {
            key: "Lightfoot Mechanical",
            count: "10"
          },
          {
            key: "Los Angeles Distributing Company",
            count: "10"
          },
          {
            key: "MGX Copy",
            count: "10"
          },
          {
            key: "MJ Seats",
            count: "10"
          },
          {
            key: "MPACT Strategic Consulting",
            count: "10"
          },
          {
            key: "Magnolia Innovation",
            count: "10"
          },
          {
            key: "Marine Layer",
            count: "10"
          },
          {
            key: "Meriwether \u0026 Tharp",
            count: "10"
          },
          {
            key: "Monogram Foods",
            count: "10"
          },
          {
            key: "Mosquito Squad",
            count: "10"
          },
          {
            key: "NanoRacks",
            count: "10"
          },
          {
            key: "Nike",
            count: "10"
          },
          {
            key: "Nootie",
            count: "10"
          },
          {
            key: "ONEHOPE",
            count: "10"
          },
          {
            key: "OrderMyGear",
            count: "10"
          },
          {
            key: "Overdrive Brands",
            count: "10"
          },
          {
            key: "PCI Group",
            count: "10"
          },
          {
            key: "PPT Solutions (Oklahoma)",
            count: "10"
          },
          {
            key: "PR Management",
            count: "10"
          },
          {
            key: "Parkmobile",
            count: "10"
          },
          {
            key: "Peachtree Tents \u0026 Events",
            count: "10"
          },
          {
            key: "Peridot Solutions",
            count: "10"
          },
          {
            key: "Pete and Gerry\u0027s Organics",
            count: "10"
          },
          {
            key: "Pharmapacks",
            count: "10"
          },
          {
            key: "Phoenics Electronics",
            count: "10"
          },
          {
            key: "PreScouter",
            count: "10"
          },
          {
            key: "Private Jets",
            count: "10"
          },
          {
            key: "Proozy",
            count: "10"
          },
          {
            key: "Pure Romance",
            count: "10"
          },
          {
            key: "Pure Solutions",
            count: "10"
          },
          {
            key: "RK Parisi Enterprises",
            count: "10"
          },
          {
            key: "Raney\u0027s",
            count: "10"
          },
          {
            key: "Readerlink",
            count: "10"
          },
          {
            key: "Rising Sun Farms",
            count: "10"
          },
          {
            key: "Royal Vending",
            count: "10"
          },
          {
            key: "Russell Cellular",
            count: "10"
          },
          {
            key: "SOUTHERN DESIGNS",
            count: "10"
          },
          {
            key: "SRS Distribution",
            count: "10"
          },
          {
            key: "Savewize Wholesale Distribution",
            count: "10"
          },
          {
            key: "Semihandmade",
            count: "10"
          },
          {
            key: "Sender One Climbing",
            count: "10"
          },
          {
            key: "Sofaclub",
            count: "10"
          },
          {
            key: "Soflo Mattress",
            count: "10"
          },
          {
            key: "Squaremouth",
            count: "10"
          },
          {
            key: "Staples",
            count: "10"
          },
          {
            key: "Sterling Talent Solutions",
            count: "10"
          },
          {
            key: "Studio Movie Grill",
            count: "10"
          },
          {
            key: "Sustainable Supply",
            count: "10"
          },
          {
            key: "Sweet Peach Wax \u0026 Sugaring Studio",
            count: "10"
          },
          {
            key: "Talk Mobile",
            count: "10"
          },
          {
            key: "Tallwave",
            count: "10"
          },
          {
            key: "Tender Loving Empire",
            count: "10"
          },
          {
            key: "Tenon Tours",
            count: "10"
          },
          {
            key: "The Cabinet Joint",
            count: "10"
          },
          {
            key: "The Reagor Dykes Auto Group",
            count: "10"
          },
          {
            key: "The Revenue Optimization Companies",
            count: "10"
          },
          {
            key: "The/Studio",
            count: "10"
          },
          {
            key: "Titan",
            count: "10"
          },
          {
            key: "Villa Dolce Gelato",
            count: "10"
          },
          {
            key: "WMK",
            count: "10"
          },
          {
            key: "Watterson",
            count: "10"
          },
          {
            key: "Weisser Distributing",
            count: "10"
          },
          {
            key: "Winc (formerly Club W)",
            count: "10"
          },
          {
            key: "Xero Shoes",
            count: "10"
          },
          {
            key: "Xtenfer Consulting",
            count: "10"
          },
          {
            key: "Young Automotive Group",
            count: "10"
          },
          {
            key: "complexnetwork",
            count: "10"
          },
          {
            key: "dressbarn",
            count: "10"
          },
          {
            key: "knix",
            count: "10"
          },
          {
            key: "onezerOne",
            count: "10"
          },
          {
            key: "sbug",
            count: "10"
          },
          {
            key: "simba",
            count: "10"
          }
        ]
      }
    },
    Renewal_Date: {
      topn: {
        buckets: [
          {
            key: "Q4 2021",
            count: "620"
          },
          {
            key: "Q1 2022",
            count: "430"
          },
          {
            key: "Q2",
            count: "380"
          },
          {
            key: "Q3",
            count: "300"
          },
          {
            key: "Q4",
            count: "250"
          }
        ]
      }
    },
    SignupDate: {
      histogram: {
        buckets: [
          {
            key: "1970-01-01T00:00:00.000Z",
            count: "2000"
          }
        ]
      }
    },
    Subscription_Type: {
      topn: {
        buckets: [
          {
            key: "Enterprise",
            count: "2000"
          }
        ]
      }
    },
    Upsellopp: {
      topn: {
        buckets: [
          {
            key: "Yes",
            count: "1320"
          },
          {
            key: "No",
            count: "680"
          }
        ]
      }
    }
  }
};
