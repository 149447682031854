import { EntityPropertyValue } from "../../../core";
import { EventTypeResultList, EntityTypeResultList } from "../auto-discovery/types";
import { EntityReference } from "../configuration/types/entity";
import { EntityType } from "../entity-mapping";
import { BizField, UserServiceField } from "../explore";
import { DimensionV2 } from "../onboarding";
import { VerticalIdentifier } from "../use-case-catalog";

export type SchemaTypes = "configType" | "entityType" | "relationshipType";

export type BaseTypeReference = {
  id: string;
  typeName: string;
  desc: string;
  category?: string; // category: biz
};

export type TrafficTypeReference = {
  id: string;
  kindId: string;
  typeName: string;
  desc: string;
};

export type RelTypeReference = BaseTypeReference & {
  kindId: string;
};

export type Properties = Record<string, string>;
export type IdPropertySet = { prop: string[] };
export type IdProperties = IdPropertySet[];

export type ConfigType = {
  configType: {
    typeReference: BaseTypeReference;
    properties: Properties;
  };
};

export type EntityTypeWrapper = {
  entityType: EntityType;
};

export type TrafficType = {
  trafficType: {
    typeReference: TrafficTypeReference;
    contextEntityTypeId: string;
    sourceEntityTypeId: string;
    targetEntityTypeId: string;
  };
};

export type RelationshipType = {
  relationshipType: {
    typeReference: RelTypeReference;
    rightEntityTypeId: string;
    leftEntityTypeId: string;
    rightToLeftNavName: string;
    leftToRightNavName: string;
    leftCardinality: string;
    rightCardinality: string;
  };
};

export type SchemaType = ConfigType | EntityTypeWrapper | RelationshipType | TrafficType;

export type SchemaConfig = {
  types: [SchemaType];
};

export type EntityAggregationRequest = Record<string, any> & {
  name: string;
  field: string;
};

export type FieldCardinality = {
  value: string;
  ratio: number;
};

export type EntityAggregationMeta = {
  cardinality?: FieldCardinality;
  stats?: {
    count: string;
    min: number;
    max: number;
    avg: number;
    sum: number;
  };
};

export type EntityAggregationSuggestion = {
  kind: string;
  field: string;
  description?: string;
  aggregationMeta: EntityAggregationMeta;
  suggestedAggregationOperator: Record<string, any>;
};

export type EntityAggregationValue = {
  key: string;
  label?: string;
  count: string;
  from?: number;
  to?: number;
};

export type EntityAggregationBuckets = {
  buckets: EntityAggregationValue[];
};

export type EntityAggregationEntry = Record<string, EntityAggregationBuckets>;

export type EntityAggregationResponse = {
  entityType: string;
  aggregations: Record<string, Partial<EntityAggregationEntry>>;
};

export type EntityAggregationSuggestionResponse = {
  entityType: string;
  suggestedAggregations: Record<string, EntityAggregationSuggestion>;
};

export type EntityCountRequest = {
  entityType: string;
  reqId: string;
  entityTypeSelector?: Record<string, any>;
  cohortSelector?: {
    cohortId: string;
  };
};

export type FieldValuesCompletionResponse = {
  entityType: string;
  suggestedAggregations: Record<string, EntityAggregationSuggestion>;
  aggregations: EntityAggregationResponse["aggregations"];
};

export type FieldAggregation = {
  field: UserServiceField;
  aggregation: EntityAggregationMeta;
};

export type FieldAggregationResponse = {
  fieldAggregation: FieldAggregation[];
};

export type EntityCountResponse = {
  entityType: string;
  reqId: string;
  count: number;
};

export interface EntityMetricResult {
  metricId: string;
  externalName: string;
  baseMetricName: string;
  sliceMetricNames?: Record<string, string>;
}

export type EntitySearchResult = {
  data: {
    results: EntitySearchResultEntry[];
  };
};

export type EntitySearchResultEntry = {
  entity: {
    idReferences: EntityReference[];
    typeId: string;
    name: string;
    discoveryTs: string;
    lastSeenTs: string;
    entityId: string;
    displayName: string;
    props?: Record<string, EntityPropertyValue>;
  };
};

export type PropValue = {
  stringVal?: string;
  longVal?: number;
  doubleVal?: number;
  booleanVal?: boolean;
  setValue?: boolean;
  dateVal?: string;
};

export enum CustomEventTypes {
  globalError = "globalError"
}

export type ExportEntityCSVRequest = {
  typeId: string;
  cohortId: string;
  bizField: BizField[];
  fileName: string;
};

export interface UseCaseDiscoveryResponse {
  verticalIdentifier: VerticalIdentifier;
  eventTypes: EventTypeResultList;
  entityTypes: EntityTypeResultList;
  dimensions?: DimensionResultList;
  numberSampleValues?: number;
}

export interface DimensionResultList {
  dimensionResult: DimensionResult[];
}

export interface DimensionResult {
  dimension: DimensionV2;
}

export interface GetDimensionsRequesPayload {
  companyName: string;
  dimensions: string;
  uniqueValueCount: number;
  sampleValueCount: number;
}

export interface SaveOnboardingResponse {
  success?: boolean;
  messege?: string;
  streamId?: string;
  error?: string;
}
