import { useRef, useMemo } from "react";
import { useIsVisible } from "./useIsVisible";

export const useRenderOnVisibility = () => {
  const ref = useRef<HTMLDivElement>(null);
  const wasVisibleRef = useRef(false);

  const isVisible = useIsVisible(ref);

  useMemo(() => {
    if (isVisible) {
      wasVisibleRef.current = true;
    }
  }, [isVisible]);

  return {
    isVisible,
    wasVisibleOnce: wasVisibleRef.current,
    ref
  };
};
