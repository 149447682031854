import { getBizEntityUrl } from "../utils";

export const Triage = {
  attributes: {
    useCaseFeedContainer: "use-case-feed-container",
    insightFeedCard: "insight-feed-card",
    drilldownTriageButton: "drilldown-traige-button",
    causeGraphWrapper: "cause-graph-wrapper",
    causeSummaryWrapper: "cause-summary-wrapper",
    causeSummary: "cause-summary",
    triageSummary: "triage-summary",
    summaryContainer: "summary-container",
    timelineViz: "timeline-viz",
    impactSummary: "impact-summary",
    drilldownTable: "drilldown-table"
  },
  requestUrls: {
    fetchOpSchema: getBizEntityUrl("op10ze/*/schema?*"),
    getMembership: getBizEntityUrl("triage/getMembership/**"),
    alert: getBizEntityUrl("alert/**"),
    op10ZeSearch: getBizEntityUrl("op10ze/search?*"),
    drilldownSearch: getBizEntityUrl("triage-path/drilldown/search"),
    impactedWidgetsSearch: getBizEntityUrl("triage-path/impacted_widgets/search"),
    diagnosticFieldsSearch: getBizEntityUrl("triage-path/diagnostic_fields/search"),
    triagePageSearch: getBizEntityUrl("triage-path/triage_page/search"),
    getTriageData: getBizEntityUrl("triage/auto/**"),
    getTriageDataStatus: getBizEntityUrl("triage/auto/status/*"),
    getCorrelatedEvents: getBizEntityUrl("triage/getCorrelatedEvents/**"),
    getIncidentInfo: getBizEntityUrl("triage/getIncidentInfo?*"),
    getAlertWidgetData: getBizEntityUrl("triage/impactWidget/data?*"),
    getAlertDetails: getBizEntityUrl("alert/*"),
    fetchSchema: getBizEntityUrl("op10ze/*/fetch/schema?*"),
    getAlertsWidgetSummary: "/alerts/widget/summary/*"
  }
};
