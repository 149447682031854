import { CancelToken } from "axios";
import { isEmpty, omit } from "lodash";
import { DatasourceApi } from "../../api/DatasourceService";
import { exploreApiService, WidgetCompareDataRequest, WidgetDataDTO, ExploreEntityFilter } from "../../api/explore";
import { DatasourceSettings, BaseApi, DataQueryResponse, DataQueryRequest } from "../../api/types";
import kbn from "../core/kbn";

import { ExploreQuery, ExploreQueryType } from "./types";

const DATA_FETCH_ERROR = "Error fetching widget data.";

export interface ExploreSettings extends DatasourceSettings {}

export class ExploreDatasource extends DatasourceApi<ExploreQuery, ExploreSettings> {
  constructor(name: string, settings: ExploreSettings, baseService: BaseApi) {
    super(name, "explore", settings, baseService);
  }

  async query(request: DataQueryRequest<ExploreQuery>): Promise<DataQueryResponse<WidgetDataDTO[]>> {
    const { startTime, endTime = new Date().valueOf(), targets, interval, cancelToken } = request;

    const allPromises = targets.map(target => {
      const { type } = target;

      if (type === ExploreQueryType.adhoc) {
        return this.getAdhocWidgetData(target, startTime, endTime, interval, cancelToken);
      }
      return this.getSavedWidgetData(target, startTime, endTime, interval, cancelToken);
    });

    const results = await Promise.all(allPromises);

    return this.processResults(results);
  }

  getQueryPayload(request: DataQueryRequest<ExploreQuery>) {
    const { targets, interval } = request;

    const queries = targets.map(target => {
      const { type } = target;

      if (type === ExploreQueryType.adhoc) {
        return this.getAdhocWidgetDataQuery(target, interval);
      }
      return this.getSavedWidgetDataQuery(target, interval);
    });

    return queries;
  }

  getAdhocWidgetDataQuery(target: ExploreQuery, interval: string) {
    const { payload } = target;

    const {
      version,
      widgetConfig,
      compareInfo,
      limit,
      sliceSpec,
      entityFilters,
      metricUserServiceFilters,
      excludeWidgetData = false
    } = payload;

    const intervalSecs = kbn.interval_to_seconds(interval);

    const compareFetchConfig: WidgetCompareDataRequest = compareInfo?.enabled ? omit(compareInfo, "enabled") : null;

    const partPayload = exploreApiService.getWidgetDataQueryByConfig(
      widgetConfig,
      version,
      sliceSpec,
      compareFetchConfig,
      entityFilters,
      excludeWidgetData,
      limit,
      metricUserServiceFilters
    );

    return {
      ...partPayload,
      intervalSecs
    };
  }

  async getAdhocWidgetData(
    target: ExploreQuery,
    startTime: number,
    endTime: number,
    interval: string,
    cancelToken: CancelToken
  ): Promise<DataQueryResponse<WidgetDataDTO>> {
    const { payload, refId } = target;

    const {
      entityType = null,
      entityId = null,
      version,
      widgetConfig,
      compareInfo,
      limit,
      sliceSpec,
      entityFilters,
      metricUserServiceFilters,
      excludeWidgetData = false,
      includeQueryConfig = false,
      demoParams
    } = payload;

    const intervalSecs = kbn.interval_to_seconds(interval);

    const compareFetchConfig: WidgetCompareDataRequest = compareInfo?.enabled ? omit(compareInfo, "enabled") : null;

    if (!demoParams && isEmpty(widgetConfig)) {
      return Promise.resolve({
        data: null,
        error: {
          message: "Invalid widget config for adhoc explore query",
          data: {
            error: "Got null/undefined/ empty widget config",
            message: "Invalid widget config for adhoc explore query"
          },
          refId
        }
      });
    } else {
      const { data, error, message, cancelled } = await exploreApiService.getWidgetDataByConfig(
        entityType,
        entityId,
        widgetConfig,
        version,
        startTime,
        endTime,
        intervalSecs,
        sliceSpec,
        compareFetchConfig,
        cancelToken,
        entityFilters,
        excludeWidgetData,
        limit,
        includeQueryConfig,
        metricUserServiceFilters,
        demoParams
      );
      return {
        data,
        error: error
          ? {
              message,
              data: {
                error: DATA_FETCH_ERROR,
                message
              },
              cancelled
            }
          : {
              cancelled
            }
      };
    }
  }

  getSavedWidgetDataQuery(target: ExploreQuery, interval: string) {
    const { payload } = target;

    const {
      widgetId,
      compareInfo,
      limit,
      sliceSpec,
      entityFilters,
      tagFilters,
      excludeWidgetData = false,
      metricUserServiceFilters
    } = payload;

    const intervalSecs = kbn.interval_to_seconds(interval);
    const compareFetchConfig: WidgetCompareDataRequest = compareInfo?.enabled ? omit(compareInfo, "enabled") : null;

    const requestPayload = exploreApiService.getWidgetDataQuery(
      sliceSpec,
      compareFetchConfig,
      entityFilters,
      tagFilters,
      excludeWidgetData,
      limit,
      metricUserServiceFilters
    );

    return {
      ...requestPayload,
      widgetId,
      intervalSecs
    };
  }

  async getSavedWidgetData(
    target: ExploreQuery,
    startTime: number,
    endTime: number,
    interval: string,
    cancelToken: CancelToken
  ): Promise<DataQueryResponse<WidgetDataDTO>> {
    const { payload, refId } = target;

    const {
      entityId = null,
      entityType = null,
      widgetId,
      compareInfo,
      limit,
      sliceSpec,
      entityFilters,
      tagFilters,
      excludeWidgetData = false,
      includeQueryConfig = false,
      useV2ApiForCompare = false,
      metricUserServiceFilters,
      demoParams
    } = payload;

    const intervalSecs = kbn.interval_to_seconds(interval);
    const compareFetchConfig: WidgetCompareDataRequest = compareInfo?.enabled ? omit(compareInfo, "enabled") : null;

    if (!demoParams && isEmpty(widgetId)) {
      return Promise.resolve({
        data: null,
        error: {
          message: "Invalid widget id for explore query",
          data: {
            error: "Got null / undefined / empty widget id",
            message: "Invalid widget id explore query"
          },
          refId
        }
      });
    } else {
      const { data, error, message, cancelled } = await exploreApiService.getWidgetData(
        entityId,
        entityType,
        widgetId,
        startTime,
        endTime,
        intervalSecs,
        sliceSpec,
        compareFetchConfig,
        cancelToken,
        entityFilters,
        tagFilters,
        excludeWidgetData,
        limit,
        includeQueryConfig,
        useV2ApiForCompare,
        metricUserServiceFilters,
        demoParams
      );
      return {
        data,
        error: error
          ? {
              message,
              data: {
                error: DATA_FETCH_ERROR,
                message
              },
              cancelled
            }
          : {
              cancelled
            }
      };
    }
  }

  private processResults(results: Array<DataQueryResponse<WidgetDataDTO>>): DataQueryResponse<WidgetDataDTO[]> {
    const dataResponse: DataQueryResponse<WidgetDataDTO[]> = {
      data: []
    };

    const errors: string[] = [];

    results.forEach(result => {
      const { data, error } = result;

      if (!error?.cancelled) {
        if (error && (error.data || error.message)) {
          errors.push(error.data?.message || error.message || DATA_FETCH_ERROR);
        } else {
          dataResponse.data.push(data);
        }
      }
    });

    if (errors.length) {
      const errMsg = errors.join(", ");
      dataResponse.error = {
        data: {
          error: DATA_FETCH_ERROR,
          message: errMsg
        },
        message: errMsg
      };
    }

    return dataResponse;
  }

  private getValidEntityFilters(entityFilters: ExploreEntityFilter[]): ExploreEntityFilter[] {
    return entityFilters.filter(entityFilter => entityFilter.filters.length > 0);
  }
}
