import React, { useCallback, useMemo } from "react";
import Select, { GroupTypeBase, NamedProps } from "react-select";
import Creatable from "react-select/creatable";
import { css, cx } from "emotion";
import { getInceptionTheme } from "../../themes/ThemeProvider";
import { IncFaIcon, IncErrorIcon, IncInfoIcon } from "../../icons";
import { handleIsValidNewOption } from "../../utils/components/selectUtils";
import { getStringPossibleWidth } from "../../utils";
import { IncToolTip } from "../antd-components";
import { CreateLabelFormatter } from "./common";
import { IncSelectOption2 } from "./types";

export type IncSelectOption<P = any> = IncSelectOption2<P>;

export interface IncGroupSelectOption<P = any> {
  groupLabel: string;
  options: Array<IncSelectOption<P>>;
}

export interface CommonSelectProps {
  /**
   * Label to be shown for the select. Empty string will hide the label.
   */
  label?: string;
  labelClassName?: string;

  helpTextId?: string;
  helpText?: string;

  hasError?: boolean;
  errorText?: string; // pass error text to be shown on tooltip

  allowCreate?: boolean;
  wrapperClass?: string;

  disablePopper?: boolean;
  required?: boolean;
  autoSort?: boolean;
  alignment?: "row" | "column";

  width?: number | string;
  autoAdjustWidth?: boolean;
  autoAdjustWidthFontSize?: number;
  autoAdjustWidthBuffer?: number;
  transparent?: boolean;

  formatCreateLabel?: (input: string) => string | JSX.Element;
  createLabelPrefix?: string;
}

export interface IncSelectProps<OptionType extends IncSelectOption, IsMulti extends boolean>
  extends NamedProps<OptionType, IsMulti>,
    CommonSelectProps {
  readOnly?: boolean;
  asTagsEditor?: boolean;
}

interface SelectLabelProps {
  label: string;
  className?: string;
  helpTextId?: string;
  helpText?: string;
  required?: boolean;
}

type SortArg<OptionType> = OptionType | GroupTypeBase<OptionType>;

export const SelectDropdownIndicator = () => (
  <IncFaIcon
    className="inception-react-select__dropdown-indicator"
    iconName="caret-down"
  />
);

export const IncSelectLabel = (props: SelectLabelProps) => {
  const { label, className, helpText, helpTextId, required } = props;
  const appliedClassName = `inc-flex-row inc-text-subtext-medium inception-select-label-container ${className || ""}`;

  return (
    <div className={appliedClassName}>
      <div>{label}</div>
      {required && <span className="inc-span-required-star">*</span>}
      {(helpTextId || helpText) && (
        <IncToolTip
          placement="top-start"
          showArrow
          titleId={helpTextId}
          titleText={helpText}
        >
          <div>
            <IncInfoIcon />
          </div>
        </IncToolTip>
      )}
    </div>
  );
};

//when there is a type change in props, take care of sorting logic inside compareAndSortOptions function
const IncSelect = <OptionType extends IncSelectOption, IsMulti extends boolean = false>(
  props: IncSelectProps<OptionType, IsMulti>
) => {
  const {
    className: pClassName = "",
    classNamePrefix: pClassNamePrefix = "",
    components: pComponents = {},
    wrapperClass,
    labelClassName,
    disablePopper = false,
    menuPortalTarget: pMenuPortalTarget,
    menuPlacement = "auto",
    errorText,
    hasError,
    required,
    options,
    autoSort = true,
    alignment = "column",
    width,
    formatCreateLabel,
    createLabelPrefix,
    autoAdjustWidth = false,
    autoAdjustWidthBuffer = 0,
    autoAdjustWidthFontSize = 12,
    placeholder,
    value,
    readOnly = false,
    isDisabled = false,
    allowCreate = false,
    label = "",
    helpText = "",
    helpTextId,
    transparent = false,
    styles: defStyles,
    asTagsEditor,
    ...restProps
  } = props;

  const theme = getInceptionTheme();

  let valueLabel = Array.isArray(value) ? value.map(v => v.label).join(", ") : (value as OptionType)?.label || "";
  valueLabel = valueLabel || placeholder?.toString() || "";
  const computedWidth = getStringPossibleWidth(valueLabel, autoAdjustWidthFontSize).width + autoAdjustWidthBuffer;

  const className = useMemo(() => {
    const widthStr = typeof width === "number" ? `${width}px` : width;
    const widthClassName =
      autoAdjustWidth && computedWidth
        ? css`
            width: ${computedWidth + (readOnly ? 10 : 36)}px;
          `
        : widthStr
          ? css`
              width: ${widthStr};
            `
          : "";

    return cx("inception-react-select-container", {
      [pClassName]: pClassName !== "",
      [widthClassName]: widthClassName !== ""
    });
  }, [autoAdjustWidth, computedWidth, pClassName, readOnly, width]);

  const wrapperClassName = useMemo(
    () =>
      cx("inception-select", {
        [wrapperClass || ""]: wrapperClass !== "",
        "inc-flex-row": alignment === "row",
        "inc-flex-column": alignment === "column",
        "inception-select--transparent": transparent,
        "tags-editor": asTagsEditor
      }),
    [alignment, asTagsEditor, transparent, wrapperClass]
  );

  const classNamePrefix = pClassNamePrefix ? `${pClassNamePrefix} inception-react-select` : "inception-react-select";
  const components = {
    ...pComponents,
    DropdownIndicator: pComponents.DropdownIndicator || SelectDropdownIndicator
  };

  const formatCreateLabelValue = useCallback(
    (input: string) =>
      formatCreateLabel ? (
        formatCreateLabel(input)
      ) : (
        <CreateLabelFormatter
          createPrefix={createLabelPrefix}
          input={input}
        />
      ),
    [createLabelPrefix, formatCreateLabel]
  );
  const menuPortalTarget = useMemo(
    () => (pMenuPortalTarget ? pMenuPortalTarget : disablePopper ? null : document.body),
    [disablePopper, pMenuPortalTarget]
  );

  const compareAndSortOptions = useCallback((a: SortArg<OptionType>, b: SortArg<OptionType>) => {
    const labelA = a.label || (a as GroupTypeBase<OptionType>).groupLabel || "";
    const labelB = b.label || (b as GroupTypeBase<OptionType>).groupLabel || "";

    return labelA.localeCompare(labelB);
  }, []);

  const formatGroupLabel = useCallback((group: GroupTypeBase<OptionType>) => {
    const { groupLabel } = group;
    return <div className="inception-select--group-label">{groupLabel}</div>;
  }, []);

  let pOptions = options?.slice();
  pOptions = autoSort ? pOptions?.sort(compareAndSortOptions) : pOptions;

  const errComponent =
    errorText && hasError ? (
      <IncToolTip
        placement="top-start"
        showArrow
        titleText={errorText}
        variant="error"
      >
        <IncErrorIcon
          style={{
            color: theme.inceptionColors.palette.R400,
            marginLeft: !props.label ? 8 : 0
          }}
        />
      </IncToolTip>
    ) : (
      <></>
    );

  const styles = useMemo<IncSelectProps<OptionType, IsMulti>["styles"]>(
    () => ({
      ...(defStyles || {}),
      control: (base: any) => ({
        ...base,
        minHeight: 32
      })
    }),
    [defStyles]
  );

  const dataAttributes = useMemo(
    () =>
      Object.keys(restProps).reduce(
        (acc, key) => {
          if (key.startsWith("data-")) {
            acc[key] = (restProps as Record<string, string>)[key] as string;
          }

          return acc;
        },
        {} as Record<string, string>
      ),
    [restProps]
  );

  return (
    <>
      <div
        className={wrapperClassName}
        data-readonly={readOnly}
        {...dataAttributes}
      >
        {Boolean(label) && (
          <div className="inc-flex-row inc-flex-center-vertical flex-gap-8">
            <IncSelectLabel
              className={labelClassName}
              helpText={helpText}
              helpTextId={helpTextId}
              label={label}
              required={required}
            />
            {errComponent}
          </div>
        )}

        {!allowCreate && (
          <Select<OptionType, IsMulti>
            className={className}
            classNamePrefix={classNamePrefix}
            components={components}
            options={pOptions}
            {...restProps}
            formatGroupLabel={formatGroupLabel}
            isDisabled={readOnly || isDisabled}
            menuPlacement={menuPlacement}
            menuPortalTarget={menuPortalTarget}
            menuShouldScrollIntoView={false}
            placeholder={placeholder}
            styles={styles}
            value={value}
          />
        )}

        {allowCreate && (
          <Creatable<OptionType, IsMulti>
            className={className}
            classNamePrefix={classNamePrefix}
            components={components}
            options={pOptions}
            placeholder={placeholder}
            value={value}
            {...restProps}
            allowCreateWhileLoading={true}
            formatCreateLabel={formatCreateLabelValue}
            formatGroupLabel={formatGroupLabel}
            isDisabled={readOnly || isDisabled}
            isValidNewOption={handleIsValidNewOption}
            menuPlacement={menuPlacement}
            menuPortalTarget={menuPortalTarget}
            menuShouldScrollIntoView={false}
            styles={styles}
          />
        )}

        {!label && errComponent}
      </div>
    </>
  );
};

export { IncSelect };
