import { cloneDeep, isEmpty } from "lodash";
import { dateTime } from "../../../core/moment_wrapper";
import { getTimeRangeEpoch } from "../../../utils/TimeRange";
import { Payload, PayloadConstructor, QueryMode } from "./types";
import { KNOWN_KEYS } from "./constants";

export const constructPayload = ({
  type,
  from: fromTime,
  to: toTime,
  payload: dataPayload = {},
  query = "",
  mode,
  sort = "",
  groupBy = [],
  isComparison,
  params = {},
  periodicitySecs = 30,
  traceRowLimit = 50
}: PayloadConstructor) => {
  const { from, to } = getTimeRangeEpoch(fromTime, toTime);
  const fromMs = dateTime(from).valueOf(); //.subtract(2, 'm');
  const toMs = dateTime(to).valueOf(); //.subtract(2, 'm');
  let payload: Payload = {
    timeRange: {
      from: fromMs,
      to: toMs
    },
    filters: {},
    aggregations: {},
    mode
  };

  if (mode === "events") {
    payload.datasourceType = "events";
  }

  if (query) {
    if (mode === "traces") {
      query = constructQuery(query);
    }
    payload = {
      ...payload,
      filters: {
        ...payload.filters,
        type: "string",
        value: query
      }
    };
  }

  switch (type) {
    case "traces":
      return {
        ...payload,
        aggregations: {
          ...payload.aggregations,
          results: [
            {
              type: "SPAN_FIELDS",
              name: "traceFields",
              params: {
                limit: traceRowLimit,
                order: sort
              }
            }
          ]
        }
      };

    case "histogram": {
      let results = [
        {
          type: "HISTOGRAM",
          name: "tracesDurationHistogram",
          field: "duration",
          params: {
            ...dataPayload.params,
            min: 1,
            max: 60000000
          }
        },
        {
          type: "METRICS",
          name: "event_count",
          field: "fields.Amount",
          metricType: "count",
          params: {
            ...dataPayload.params,
            periodicitySecs: periodicitySecs
          }
        },
        {
          type: "METRICS",
          field: "duration",
          metricType: "max",
          name: "traceLatencyMax",
          params: {}
        }
      ];
      const quantiles = getQuantileFractionsPayload();
      const attributes = attributesPayload(mode as QueryMode);
      if (isComparison) {
        const quantileObj = {
          type: "METRICS",
          name: "traceLatencyQuantile",
          metricType: "quantile",
          field: "duration",
          params: {
            ...dataPayload.params
          }
        };
        results.push(quantileObj);
      }
      if (!isComparison) {
        results = [...results, ...quantiles];
      }
      if (!isComparison) {
        return (payload = {
          ...payload,
          aggregations: {
            ...payload.aggregations,
            ...attributes,
            results: [...results]
          }
        });
      }
      return {
        ...payload,
        aggregations: {
          ...payload.aggregations,
          results: [...results]
        }
      };
    }

    case "quantile":
      return {
        ...payload,
        aggregations: {
          ...payload.aggregations,
          results: [
            {
              type: "METRICS",
              name: "traceLatencyQuantile",
              metricType: "quantile",
              field: "duration",
              params: {
                ...dataPayload.params
              }
            }
          ]
        }
      };
    case "structure":
      return {
        ...payload,
        filters: {
          ...payload.filters
        },
        aggregations: {
          ...payload.aggregations,
          results: [
            {
              type: "STRUCTURE",
              name: "structure",
              params
            }
          ]
        }
      };

    case "order":
      return {
        ...payload,
        aggregations: {
          ...payload.aggregations,
          results: [
            {
              type: "SPAN_FIELDS",
              name: "traceFields",
              params: {
                limit: 50,
                order: sort
              }
            }
          ]
        }
      };

    case "group":
      return {
        ...payload,
        aggregations: {
          ...aggregationsGroup(groupBy, mode as QueryMode, traceRowLimit)
        }
      };

    case "query-group": {
      const group = groupBy[0];
      return {
        ...payload,
        aggregations: {
          [group]: {
            fromField: group,
            type: "term",
            results: [
              {
                type: "METRICS",
                name: `${group}Count`,
                metricType: "count",
                params: {}
              }
            ]
          }
        }
      };
    }

    case "attributes":
      return {
        ...payload,
        aggregations: {
          spanError: {
            fromField: mode === "events" ? KNOWN_KEYS.HAS_ERROR : KNOWN_KEYS.SPAN_ERROR,
            type: "term",
            results: [
              {
                type: "METRICS",
                name: "spanErrorCount",
                metricType: "count",
                params: {}
              }
            ]
          },
          results: [
            {
              type: "METRICS",
              field: "duration",
              metricType: "max",
              name: "traceLatencyMax",
              params: {}
            },
            {
              type: "METRICS",
              field: "duration",
              metricType: "min",
              name: "traceLatencyMin",
              params: {}
            },
            {
              type: "METRICS",
              field: "duration",
              metricType: "avg",
              name: "traceLatencyAverage",
              params: {}
            }
          ]
        }
      };
    case "all":
      return {
        ...payload,
        aggregations: {
          ...payload.aggregations,
          results: [
            {
              type: "METRICS",
              name: "traceLatencyQuantile",
              metricType: "quantile",
              field: "duration",
              params: {
                ...dataPayload.params
              }
            },
            {
              type: "HISTOGRAM",
              name: "tracesDurationHistogram",
              field: "duration",
              params: {
                ...dataPayload.params
              }
            },
            {
              type: "SPAN_FIELDS",
              name: "traceFields",
              params: {
                limit: 50
              }
            }
          ]
        }
      };

    default:
      break;
  }
};

const getQuantileFractionsPayload = () => {
  let results = [];
  const quantileObj = {
    type: "METRICS",
    name: "traceLatencyQuantile",
    metricType: "quantile",
    field: "duration"
  };
  const fractions = ["0.95", "0.90", "0.99", "0.50"];
  results = fractions.map((fraction: string) => ({
    ...quantileObj,
    name: `traceLatencyQuantile${fraction.split(".")[1]}`,
    params: {
      fractions: fraction
    }
  }));
  return results;
};

const attributesPayload = (mode: QueryMode) => {
  let metrics = {
    type: "METRICS",
    name: "responseCodeCount",
    metricType: "count",
    params: {}
  };
  const aggregations: Record<string, any> = {};
  const attributes = [
    {
      key: mode === "events" ? KNOWN_KEYS.HAS_ERROR : KNOWN_KEYS.SPAN_ERROR,
      name: "spanErrorCount"
    },
    {
      key: "userService",
      name: "userService"
    }
  ];
  for (let i = 0; i < attributes.length; i += 1) {
    const attribute = attributes[i];
    metrics = {
      ...metrics,
      name: attribute.name
    };
    aggregations[attribute.key] = {
      fromField: attribute.key,
      type: "term",
      results: [metrics]
    };
  }
  return aggregations;
};

export const aggregationsGroup = (groupBy: string[], mode: QueryMode, traceRowLimit = 100) => {
  const finalAggregations: any = {};
  const results = [
    {
      type: "METRICS",
      name: "traceLatencyAverage",
      metricType: "avg",
      field: "duration",
      params: {
        limit: traceRowLimit,
        includeSchema: true
      }
    },
    {
      type: "METRICS",
      name: "traceCount",
      metricType: "count",
      params: {
        limit: traceRowLimit,
        includeSchema: true
      }
    }
  ];

  const aggreGationOn = {
    aggregations: {
      spanError: {
        fromField: mode === "events" ? KNOWN_KEYS.HAS_ERROR : KNOWN_KEYS.SPAN_ERROR,
        type: "term",
        results: [
          {
            type: "METRICS",
            name: "spanErrorCount",
            metricType: "count",
            params: {
              limit: traceRowLimit,
              includeSchema: true
            }
          }
        ]
      }
    }
  };

  const finalObj = { aggregations: {} };
  groupBy.reduce((obj: any, val, index, arr) => {
    const aggregation = {
      fromField: val,
      type: "term",
      results: [] as any,
      aggregations: {}
    };
    if (index === arr.length - 1) {
      aggregation.aggregations = aggreGationOn.aggregations;
      aggregation.results = results;
    }
    return (obj.aggregations[val] = aggregation);
  }, finalObj);

  return (finalAggregations["aggregations"] = finalObj.aggregations);
};

//const cache: Record<string, any> = Object.create(null);

const extractTokens = (query: string): [string[], string[]] => {
  const a = query.split("");
  let currChar = null,
    flush = false,
    idx = 0;

  const tokens = [],
    operators = [],
    isBraceStart = [];
  let buffer = [];

  while (idx < a.length) {
    currChar = a[idx];

    const isAndOperand =
      a[idx - 1] === " " && currChar === "A" && a[idx + 1] === "N" && a[idx + 2] === "D" && a[idx + 3] === " ";
    const isOrOperand = a[idx - 1] === " " && currChar === "O" && a[idx + 1] === "R" && a[idx + 2] === " ";
    const isOperand = isAndOperand || isOrOperand;
    flush = false;

    if (currChar === "(") {
      isBraceStart.push(true);
    }

    if (currChar === ")") {
      isBraceStart.pop();
      if (isBraceStart.length === 0) {
        flush = true;
      }
    }

    if ((isBraceStart.length === 0 && isOperand) || idx === a.length - 1) {
      flush = true;
    }

    if (!isOperand) {
      buffer.push(currChar);
    } else if (isOperand && isBraceStart.length > 0) {
      buffer.push(isAndOperand ? "AND" : isOrOperand ? "OR" : "");
    }

    if (flush) {
      //console.log("buffer", buffer);
      tokens.push(buffer.join(""));
      buffer = [];
    }

    if (isAndOperand) {
      idx = idx + 2;
      if (isBraceStart.length === 0) {
        operators.push("AND");
      }
    } else if (isOrOperand) {
      idx = idx + 1;
      if (isBraceStart.length === 0) {
        operators.push("OR");
      }
    }
    idx++;
  }
  return [tokens.map(t => t.trim()).filter(t => !isEmpty(t)), operators];
};

export const constructQuery = (query: string) => {
  const [tokens, operators] = extractTokens(query);
  const wrapTokensInSpanSelect = tokens.map(t => `spanSelect(${t})`);

  let wrappedQuery = "";
  wrapTokensInSpanSelect.forEach((wrapped, idx) => {
    if (idx > 0 && operators[idx - 1]) {
      wrappedQuery = `${wrappedQuery} ${operators[idx - 1]} `;
    }
    wrappedQuery = `${wrappedQuery}${wrapped}`;
  });
  return wrappedQuery;

  // const regEx = new RegExp(/AND|OR/g);
  // const operatorMap: Record<number, string> = {},
  //   indices: number[] = [];
  // let result = regEx.exec(query);

  // if (cache[query]) {
  //   return cache[query];
  // }
  // if (!result) {
  //   cache[query] = `spanSelect(${query})`;
  //   return cache[query];
  // }
  // if (result) {
  //   let fString = '',
  //     lastUpdated = 0;

  //   while (result) {
  //     indices.push(result.index);
  //     operatorMap[result.index] = result[0];
  //     result = regEx.exec(query);
  //     console.log(result);
  //   }
  //   indices.forEach((val: number) => {
  //     const operator = operatorMap[val];

  //     fString += `spanSelect(${query.substring(lastUpdated, val).trim()}) ${operator} `;
  //     lastUpdated = val + operator.length;
  //   });
  //   const lastIndex = indices[indices.length - 1];

  //   fString += `spanSelect(${query.substring(lastIndex + operatorMap[lastIndex].length).trim()})`;
  //   cache[query] = fString;
  //   return cache[query];
  // }
  // return query;
};

export const trimDollarInstancesFromTraceResponse = (traceResponse: any) => {
  let traceData = cloneDeep(traceResponse);
  const trimDollar = (label: string) => label.substring(0, label.indexOf("$"));
  const createNewAndDeleteOldKey = (data: any, key: string) => {
    const newKey = trimDollar(key);
    data[newKey] = data[key];
    delete data[key];
    return data;
  };

  if (traceData?.structure) {
    (traceData?.structure?.fields || []).forEach((x: any) => {
      if (x.tagKey?.includes("$")) {
        x.tagKey = trimDollar(x.tagKey);
      }
    });
    (traceData?.structure?.globalFields || []).forEach((x: any) => {
      if (x.tagKey.includes("$")) {
        x.tagKey = trimDollar(x.tagKey);
      }
    });
  }

  if (traceData?.traceFields) {
    (traceData.traceFields || []).forEach((x: any) => {
      const keyList = Object.keys(x);
      keyList.forEach(key => {
        if (key.includes("$")) {
          x = createNewAndDeleteOldKey(x, key);
        }
      });
      (x.fields || []).forEach((x: any) => {
        if (x.key.includes("$")) {
          x.key = trimDollar(x.key);
        }
      });
    });
  }

  const traceKeys = Object.keys(traceData);

  traceKeys.forEach((x: any) => {
    if (x.includes("$")) {
      traceData = createNewAndDeleteOldKey(traceData, x);
    }
  });

  const newKeySet = Object.keys(traceData);

  newKeySet.forEach((x: any) => {
    if (!isEmpty(traceData[x].data?.result)) {
      traceData[x].data?.result.forEach((k: any) => {
        if (k.metric) {
          const keys = Object.keys(k.metric);
          keys.forEach((i: any) => {
            if (i.includes("$")) {
              k.metric = createNewAndDeleteOldKey(k.metric, i);
            }
          });
          const updatesKeys = Object.keys(k.metric);
          updatesKeys.forEach((i: any) => {
            if (k.metric[i].includes("$")) {
              k.metric[i] = trimDollar(k.metric[i]);
            }
          });
        }
      });
    }
  });

  return traceData;
};
