import { inRange } from "lodash";
import { BicycleOOTBMetrics } from "./types";

export class DataTypeManagerUtils {
  static getCardinalityCategory(cardinality: number) {
    if (inRange(cardinality, 0, 10)) {
      return "very-low";
    } else if (inRange(cardinality, 10, 5000)) {
      return "low";
    } else if (inRange(cardinality, 5000, 10000)) {
      return "high";
    }
    return "very-high";
  }

  static getOOTBMetricNameForFieldName(fieldName: string): BicycleOOTBMetrics {
    switch (fieldName) {
      case "duration":
        return BicycleOOTBMetrics.requestsLatency;
      case "hasError":
        return BicycleOOTBMetrics.errors;
      case "eventID":
        return BicycleOOTBMetrics.requestsCount;
      default:
        return null;
    }
  }
}
