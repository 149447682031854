export type OpStateList = {
  opStatus: OpState[];
};

export type OpState = {
  id: string;
  name: string;
  lastRunEpochMillis: string;
  status: OpStateStatus;
  numAlertEvents: number;
};

export enum OpStateStatus {
  na = "na",
  success = "success",
  failed = "failed",
  processing = "processing"
}
