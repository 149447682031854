import React, { useCallback, useState } from "react";
import { components, ControlProps, MenuProps } from "react-select";
import { getSelectValueText } from "../../../utils/components/selectUtils";
import IncClickAway from "../../ClickAway/ClickAway";
import { IncFaIcon } from "../../../icons";
import IncSmartText from "../../SmartText/SmartText";
import IncTextfield from "../../Textfield/TextField";
import { IncSelect, IncSelectOption, IncSelectProps } from "../InceptionSelect";

type SelectProps<OptionType extends IncSelectOption> = Omit<
  IncSelectProps<OptionType, false>,
  "components" | "disablePopper" | "menuIsOpen"
>;

interface IncSlimSelectProps<OptionType extends IncSelectOption> extends SelectProps<OptionType> {
  /**
   * max width in pixels for the component.
   */
  maxWidth?: number;
}

export const IncSlimSelect = <OptionType extends IncSelectOption>(props: IncSlimSelectProps<OptionType>) => {
  const { isSearchable, options, wrapperClass } = props;

  const { label, onChange, maxWidth } = props;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const numOptions = (options || []).length;

  const ValueContainer = useCallback(
    (props: ControlProps<OptionType, false>) => {
      const selectedOp = props.getValue();
      const displayText = getSelectValueText(
        selectedOp as OptionType[],
        numOptions,
        label,
        undefined,
        false,
        1,
        maxWidth
      );

      const onSearchTextChange = (e: any) => setSearchText(e.target.value);
      const onClickAway = () => {
        setMenuIsOpen(false);
        setSearchText("");
      };

      const jsx =
        menuIsOpen && isSearchable ? (
          <IncTextfield
            autoFocus
            containerClassName="slim-text-field"
            onChange={onSearchTextChange}
            value={searchText}
          />
        ) : selectedOp.length > 1 ? (
          displayText
        ) : (
          <div style={{ width: maxWidth ? maxWidth : "inherit" }}>
            <span className="group-title text-ellipsis">
              <IncSmartText text={`${displayText}`} />
            </span>
          </div>
        );

      return (
        <IncClickAway onClickAway={onClickAway}>
          {ref => (
            <div
              className={`inception-select--slim-container`}
              onClick={() => setMenuIsOpen(!menuIsOpen)}
              ref={ref}
            >
              {jsx}
              <IncFaIcon
                className="marginLt4"
                iconName="caret-down"
              />
            </div>
          )}
        </IncClickAway>
      );
    },
    [isSearchable, label, maxWidth, menuIsOpen, numOptions, searchText]
  );

  const MenuList = useCallback(
    (props: MenuProps<OptionType, false>) => (
      <div className="inception-select--slim-menu-list">
        <components.Menu {...props} />
      </div>
    ),
    []
  );

  const onSelectionChange = useCallback(
    (options: OptionType | null, action: any) => {
      onChange && onChange(options, action);
      setMenuIsOpen(false);
    },
    [onChange]
  );

  return (
    <IncSelect<OptionType, false>
      {...props}
      components={{
        Control: ValueContainer,
        Menu: MenuList
      }}
      disablePopper
      inputValue={searchText}
      menuIsOpen={menuIsOpen}
      onChange={onSelectionChange}
      wrapperClass={`inception-select--slim ${wrapperClass ?? ""}`}
    />
  );
};
