import { Visualisations } from "../data/types";

export enum QueryType {
  timeseries = "timeseries",
  histogram = "histogram",
  aggregatedTimeseries = "aggregated-timeseries",
  aggregatedHistogram = "aggregated-histogram",
  aggregatedTimeseriesOverTag = "aggregated-timeseries-over-tag",
  aggregatedHistogramOverTag = "aggregated-histogram-over-tag",
  aggregatedTimeseriesOverTime = "aggregated-timeseries-over-time",
  aggregatedHistogramOverTime = "aggregated-histogram-over-time"
}

export type VizQueryOptions = Record<string, any> & {
  aggregateBySliceTag?: boolean;
  aggregateByEntityTag?: boolean;
};

export type VizToQueryConfig = {
  visualisation: Visualisations;
  queryType: QueryType;
  options?: VizQueryOptions; // Used for custom configurations
};

export type VisualisationConfig = {
  basic: VizToQueryConfig[];
  timeseries: VizToQueryConfig[];
};

export enum BicycleOOTBMetrics {
  errors = "errors",
  requestsLatency = "request-latency",
  requestsCount = "request-count"
}
