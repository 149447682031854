import { isJsonString } from "../utils/Utils";

const asyncGetItem = (key: string) => {
  const value = localStorage.getItem(key);
  return Promise.resolve({
    data: isJsonString(value) ? JSON.parse(value) : null
  });
};

export { asyncGetItem };
