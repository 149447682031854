import { IncFaIconName, ISaxIconName, ISaxIconProps } from "@inception/ui";
import { BizDataQuery, GroupedAlert } from "../../explore";
import { AlertFilterResponse } from "../../operationalise";
import { ConversationFragment, LabelFragment, PlanActionContext } from "../../chat";

export enum FeedType {
  UNKNOWN = "UNKNOWN",
  INSIGHT = "INSIGHT",
  TASK = "TASK",
  NEW_OBJECT = "NEW_OBJECT",
  EXPLORE = "EXPLORE"
}

export enum FeedActionType {
  UNKNOWN = "UNKNOWN",
  NAVIGATE = "NAVIGATE",
  OPEN = "OPEN",
  NOOP = "NOOP",
  CREATE = "CREATE"
}

export enum FeedActionButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  LINK = "LINK"
}

export enum FeedDestinationType {
  DESTINATION_UNKNOWN = "DESTINATION_UNKNOWN",
  DESTINATION_URL = "DESTINATION_URL",
  DESTINATION_USE_CASE = "DESTINATION_USE_CASE",
  DESTINATION_WIDGET = "DESTINATION_WIDGET",
  DESTINATION_OP = "DESTINATION_OP",
  DESTINATION_DASHBOARD = "DESTINATION_DASHBOARD",
  DESTINATION_CHAT = "DESTINATION_CHAT",
  DESTINATION_REPORT = "DESTINATION_REPORT",
  DESTINATION_TRIAGE = "DESTINATION_TRIAGE",
  DESTINATION_INSIGHTS = "DESTINATION_INSIGHTS",
  DESTINATION_COMMAND = "DESTINATION_COMMAND",
  DESTINATION_DATA_ONBOARDING = "DESTINATION_DATA_ONBOARDING",
  DESTINATION_RESPOND_TO_REQUEST_COMMAND = "DESTINATION_RESPOND_TO_REQUEST_COMMAND",
  DESTINATION_ONBOARD_USERS = "DESTINATION_ONBOARD_USERS",
  DESTINATION_SUBSCRIBE = "DESTINATION_SUBSCRIBE",
  DESTINATION_PLAN = "DESTINATION_PLAN"
}

export enum FeedObjectToCreate {
  NA = "NA",
  OP = "OP",
  DASHBOARD = "DASHBOARD",
  USE_CASE = "USE_CASE",
  CHAT = "CHAT"
}

export enum FeedObjectToOpen {
  NA_OBJECT_TO_OPEN = "NA_OBJECT_TO_OPEN",
  MARKDOWN = "MARKDOWN"
}

export interface FeedThumbnail {
  imageUrl?: string;
  videoUrl?: string;
  insightThumbnail?: FeedInsightThumbnail;
  altText: string;
  icon: IncFaIconName | ISaxIconProps["iconName"];
}

export interface FeedInsightThumbnail {
  opId: string;
  alertFilterResponse: AlertFilterResponse;
  bizDataQuery: BizDataQuery;
  groupedAlert: GroupedAlert;
}

export interface Feed {
  id: string;
  title: string;
  description: string;
  summary: string;
  thumbnail: FeedThumbnail;
  link: string;
  feedType: FeedType;
  actions: FeedAction[];

  vizFragment: ConversationFragment;
  timestampMillis: string;
}

export interface FeedAction {
  actionType: FeedActionType;
  actionButtonIcon: IncFaIconName | ISaxIconName;
  actionName: string;
  destination: FeedDestination;
  objectToCreate: FeedObjectToCreate;
  objectToOpen: FeedObjectToOpen;
  buttonType: FeedActionButtonType;
  planContext?: PlanActionContext;
}

export interface FeedDestination {
  destinationType: FeedDestinationType;

  url?: string;
  useCaseId?: string;
  widgetId?: string;
  opId?: string;
  dashboardId?: string;
  chatId?: string;
  reportId?: string;
}

export interface FeedCommand {
  id: string;
  name: string;
  description: string;
  labels: LabelFragment[];
  icon: ISaxIconName | IncFaIconName;
  action: FeedAction;
}

export interface FeedCommandList {
  feedCommandList: FeedCommand[];
}
