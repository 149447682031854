import { useMemo, useCallback, useRef } from "react";
import {
  operationaliseV2ApiService,
  ActionCategoryType,
  ActionSourceTypes,
  OpCreationConfig,
  UIIntegrationActionConfigList
} from "../../../services/api/operationalise";
import { InitialState, FetchFn, FetchCallBackResult, useDataFetch } from "../useDataFetch";

export const useFetchUIIntegrationConfigs = (
  actionCategoryType: ActionCategoryType,
  sourceTypeId: ActionSourceTypes,
  opCreationConfig: OpCreationConfig
) => {
  const opCreationConfigRef = useRef(opCreationConfig);
  useMemo(() => {
    opCreationConfigRef.current = opCreationConfig;
  }, [opCreationConfig]);

  const initialState: InitialState<UIIntegrationActionConfigList, string> = useMemo(
    () => ({
      data: {
        actionRunModeLabels: {
          bulk: "",
          loop: ""
        },
        uiIntegrationActionConfigs: []
      },
      isFetching: true,
      error: null
    }),
    []
  );

  const fetchFn = useCallback<FetchFn<UIIntegrationActionConfigList, string>>(async () => {
    const result: FetchCallBackResult<UIIntegrationActionConfigList, string> = {
      data: {
        actionRunModeLabels: {
          bulk: "",
          loop: ""
        },
        uiIntegrationActionConfigs: []
      },
      error: null,
      isError: false,
      isSuccess: false
    };

    const opCreationConfig = opCreationConfigRef.current;

    if (actionCategoryType && sourceTypeId) {
      try {
        const { data, error, message } = await operationaliseV2ApiService.getIntegrationActionConfigs(
          actionCategoryType,
          sourceTypeId,
          opCreationConfig
        );

        result.data = data || result.data;
        result.isError = error;
        result.isSuccess = !error;
        result.error = error ? message : "";
      } catch (err) {
        result.isError = true;
        result.isSuccess = false;
        result.error = err.message;
      }
    } else {
      result.isError = true;
      result.error = "Invalid action category - source type combination";
    }

    return result;
  }, [actionCategoryType, sourceTypeId]);

  return useDataFetch(fetchFn, initialState, true);
};
