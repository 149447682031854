import React from "react";
import { Slider } from "antd";
import { IncLabel } from "../../..";
import { IncRangeSliderProps } from "./types";

export const IncRangeSlider: React.FC<IncRangeSliderProps> = React.forwardRef((props, ref) => {
  const { label: labelStr, disabled = false, className = "", labelProps = {}, ...restSliderProps } = props;

  const labelPlacement = labelProps.placement || "start";

  const slider = (
    <Slider
      ref={ref}
      {...restSliderProps}
    />
  );

  const label = labelStr ? (
    <IncLabel
      {...labelProps}
      disabled={disabled}
    >
      {labelStr}
    </IncLabel>
  ) : null;

  return (
    <div className={`slider-container ${className}`}>
      {labelPlacement === "start" ? label : slider}
      {labelPlacement === "end" ? label : slider}
    </div>
  );
});
