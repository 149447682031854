import React, { useEffect, useMemo, useRef, useCallback, useState } from "react";
import {
  TableState,
  PluginHook,
  useResizeColumns,
  useRowSelect,
  useTable,
  usePagination,
  useFlexLayout,
  useSortBy,
  useGlobalFilter,
  SortingRule,
  useExpanded,
  useFilters,
  FilterType,
  Row
} from "react-table";
import { isEmpty, flattenDeep, uniq } from "lodash";
import { ColumnHeaders, GlobalFilter, PaginationController, Rows } from "./components";
import { TableDataItem, ExtColumn } from "./types";
import { useConstructColumns, useStateReducerCallback, useLoadingState, addToSelectedAndExpanded } from "./util-hooks";
import { IncRTableProps } from "./props";

const DEFAULT_PAGE_SIZE = 10;

type Props<T extends TableDataItem> = IncRTableProps<T> & {
  tableWidth: number;
};

export const IncTableContainer = <T extends TableDataItem>(props: Props<T>) => {
  const {
    nonDataColumns: pNonDataColumns,
    columns: pColumns,
    data: pData,
    addRowSelectionColumn: enableSelection = false,
    resizableColumns: resizable = false,
    globalFilter,
    pagination,
    variant = "default",
    density = "normal",
    onGlobalFilterChange,
    onPageChange,
    onRowClick,
    onRowDblClick,
    getRowStyle,
    onSelectionChange,
    onExpansionChange,
    isLoading = false,
    hasError = false,
    loadingMessage,
    noDataMessage,
    errorDataMessage,
    classNames,
    sort,
    persistSortState = false,
    persistPageState = false,
    alwaysExpanded = false,
    children: filterChildren,
    subRowRenderer,
    onSortChange,
    enableAllRowSelection = false,
    expandIconVariant = "normal",
    showDisplayStats = false,
    autoResetGlobalFilter = true,
    tableWidth,
    expansionColumnWidth,
    skipForceShowSubRows
  } = props;

  const [bodyEl, setBodyEl] = useState<HTMLDivElement>();
  const [contentScrollable, setContentScrollable] = useState(false);

  const bodyRef = useCallback((el: HTMLDivElement) => setBodyEl(el), []);

  const [tableContainerEl, setTableContainerEl] = useState<HTMLDivElement>();
  const tableContainerRef = useCallback((el: HTMLDivElement) => setTableContainerEl(el), []);

  const [tableHeaderEl, setTableHeaderEl] = useState<HTMLDivElement>();
  const tableHeaderRef = useCallback((el: HTMLDivElement) => setTableHeaderEl(el), []);

  useEffect(() => {
    setContentScrollable((bodyEl?.clientHeight || 0) < (bodyEl?.scrollHeight || 0));
  }, [bodyEl, isLoading]);

  const data = useMemo(() => pData || [], [pData]);
  const columns = useMemo(() => pColumns || [], [pColumns]);
  const nonDataColumns = useMemo(() => pNonDataColumns || [], [pNonDataColumns]);

  const subRowsExist = data.reduce((prev, row) => prev || (row.subRows?.length || 0) > 0, false);

  const { accessor: defaultSortAccessor, order: defaultSortOrder } = sort || {};

  const {
    enabled: presetPaginationEnabled,
    defaultPage: presetDefaultPage,
    pageSize: presetPageSize,
    viewMode = "detailed",
    externalControl: externalPageControl = false,
    autoPaginateProps
  } = pagination || {};

  const viewMinimalPagination = viewMode === "minimal";

  const { uPageSize, defaultPage, paginationEnabled } = useMemo(() => {
    let uPageSize = presetPageSize || DEFAULT_PAGE_SIZE;
    const defaultPage = presetDefaultPage || 1;

    let paginationEnabled = presetPaginationEnabled || false;

    if (paginationEnabled) {
      // Enable pagination only if the data size is greater than page size
      const allData = data.reduce((acc, datum) => [...acc, datum, ...(datum.subRows || [])], [] as TableDataItem[]);
      const flatData = flattenDeep(allData);

      const dataSize = flatData.length;

      if (autoPaginateProps && tableContainerEl && tableHeaderEl) {
        const headerHeight = getElementHeight(tableHeaderEl);
        const approxRowHeight = autoPaginateProps?.approxRowHeight ? autoPaginateProps.approxRowHeight : 45;

        const parentElement = tableContainerEl.parentElement as HTMLDivElement | null;
        if (parentElement) {
          const incTableContainerHeight = getElementHeight(parentElement);

          if (incTableContainerHeight > headerHeight) {
            const bodyHeight = parseFloat((incTableContainerHeight - headerHeight).toFixed(2));
            uPageSize = Math.floor(bodyHeight / approxRowHeight);
          }
        }
      }

      paginationEnabled = dataSize > uPageSize;
    }

    return {
      uPageSize,
      defaultPage,
      paginationEnabled
    };
  }, [
    autoPaginateProps,
    data,
    presetDefaultPage,
    presetPageSize,
    presetPaginationEnabled,
    tableContainerEl,
    tableHeaderEl
  ]);

  const { nonDataColumnIds, selectionColumnId, tableColumns, expansionColumnId, columnVsGlobalFilterFn } =
    useConstructColumns({
      nonDataColumns,
      columns,
      enableSelection,
      paginationEnabled,
      subRowsExist,
      alwaysExpanded,
      tableWidth,
      enableAllRowSelection,
      expandIconVariant,
      expansionColumnWidth,
      skipForceShowSubRows
    });

  const filterTypes = useMemo<Record<string, FilterType<T>>>(() => {
    const filterTypes: Record<string, FilterType<T>> = {};

    tableColumns.forEach(col => {
      const extCol = col as ExtColumn<T>;
      const { filterFn: pFilterFn, id, accessor, disableFilters } = extCol;

      const addFilterType = disableFilters === false;
      if (addFilterType) {
        const filterFn: FilterType<T> = (rows, colIds, filterValue) => {
          const shouldTrigger = colIds.includes(id as string);

          if (shouldTrigger) {
            if (pFilterFn) {
              return pFilterFn(rows, filterValue);
            } else {
              if (typeof filterValue === "string" || typeof filterValue === "number") {
                return rows.filter(r => {
                  const d = (r.original as any)?.[accessor]?.toString() || "";
                  return d.includes(filterValue.toString());
                });
              }

              return rows;
            }
          } else {
            return rows;
          }
        };

        filterTypes[id as string] = filterFn;
      }
    });

    return filterTypes;
  }, [tableColumns]);

  const globalFilterFn = useCallback(
    (rows: Array<Row<T>>, columnIds: string[], filterValue: string) => {
      const filteredRows = rows.filter(row => {
        filterValue = filterValue || "";

        const canInclude = columnIds.some(colId => {
          const filterFn = columnVsGlobalFilterFn[colId];
          const originalData = row.original;
          if (filterFn) {
            return filterFn(originalData, filterValue);
          }

          const cellValue = row.values?.[colId] || "";
          return String(cellValue).toLowerCase().includes(filterValue.toLowerCase());
        });

        return canInclude;
      });

      return filteredRows;
    },
    [columnVsGlobalFilterFn]
  );

  const getDefaultPage = useCallback(() => (defaultPage as number) - 1, [defaultPage]);
  const getDefaultSort = useCallback(() => {
    if (defaultSortAccessor) {
      // Get the 1st column id whose accessor matches the sort accessor
      const col = tableColumns.filter(col => col.accessor === defaultSortAccessor)[0];
      if (col) {
        return {
          id: col.id || (defaultSortAccessor as string),
          desc: defaultSortOrder === "desc"
        };
      }
    }
  }, [defaultSortAccessor, defaultSortOrder, tableColumns]);

  const sortState = useRef<SortingRule<T> | undefined>(getDefaultSort());
  const pageIdx = useRef<number>(getDefaultPage());

  useEffect(() => {
    pageIdx.current = getDefaultPage();
  }, [defaultPage, getDefaultPage]);

  useEffect(() => {
    sortState.current = getDefaultSort();
  }, [getDefaultSort]);

  // Maintain the state as a ref to define callback on events like selection, page change etc
  const stateReducer = useStateReducerCallback({
    data,
    onGlobalFilterChange,
    onPageChange,
    onSelectionChange,
    onExpansionChange,
    onSortChange
  });

  const { selectedRowIds: pSelectedRowIds, expanded } = useMemo(() => {
    const selectedRowIds: Record<string, boolean> = {};
    const expanded: Record<string, boolean> = {};
    addToSelectedAndExpanded(data, "", selectedRowIds, expanded, alwaysExpanded);
    return {
      selectedRowIds,
      expanded
    };
  }, [alwaysExpanded, data]);

  const initialState = useMemo<Partial<TableState<T>>>(() => {
    const tableState: Partial<TableState<T>> = {
      pageIndex: pageIdx.current,
      pageSize: uPageSize,
      globalFilter: globalFilter?.filter || "",
      selectedRowIds: pSelectedRowIds,
      expanded
    };

    if (sortState.current) {
      tableState.sortBy = [{ ...sortState.current }];
    }

    return tableState;
  }, [uPageSize, globalFilter, pSelectedRowIds, expanded]);

  const loadingData = useLoadingState(tableColumns);

  const finTableData = isLoading && !loadingMessage ? loadingData : data;

  const plugins: Array<PluginHook<T>> = [
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns
  ];

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    globalFilteredRows,
    setGlobalFilter,
    page,
    flatRows,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    pageCount,
    setSortBy,
    state: {
      pageIndex,
      pageSize,
      globalFilter: globalFilterStr = "",
      sortBy,
      columnResizing: { isResizingColumn, columnWidths: resizeColumnWidths },
      selectedRowIds
    }
  } = useTable(
    {
      columns: tableColumns,
      data: finTableData,
      initialState,
      stateReducer,
      filterTypes,
      autoResetGlobalFilter,
      globalFilter: globalFilterFn
    },
    ...plugins
  );

  // Persist sort to ensure we persist sort order for dynamic columns
  useEffect(() => {
    if (sortBy && persistSortState) {
      sortState.current = sortBy[0] ? { ...sortBy[0] } : undefined;
    }
  }, [persistSortState, sortBy]);

  // Persist page index to ensure we persist page index for dynamic columns
  useEffect(() => {
    if (persistPageState) {
      pageIdx.current = pageIndex;
    }
  }, [pageIndex, persistPageState]);

  useEffect(() => {
    if (uPageSize && pageSize !== uPageSize && externalPageControl) {
      setPageSize(uPageSize);
    }
  }, [externalPageControl, pageSize, setPageSize, uPageSize]);

  useEffect(() => {
    if (sort && sortState.current) {
      const sortBy = [sortState.current];
      setSortBy(sortBy);
    }
  }, [setSortBy, sort]);

  const isResizing = !isEmpty(isResizingColumn);
  const paginationClassName = paginationEnabled ? "inc-table--paginated" : "";
  const tableClassName = `inc-table inc-table--var-${variant} inc-table--autofit-height ${paginationClassName} inc-table--den-${density} ${classNames?.table || ""}`;
  const bodyClassName = `inc-table--body ${classNames?.body || ""}`;
  const headerRowClassName = `inc-table--header-row ${classNames?.header || ""}`;
  const rowClassName = `inc-table--row ${classNames?.row || ""}`;
  const cellClassName = `inc-table--row-cell ${classNames?.cell || ""}`;

  const leafRows = useMemo(() => flatRows.filter(r => !r.canExpand), [flatRows]);
  const rowCount = !subRowsExist ? flatRows.length : leafRows.length;

  const { visibleRows, leafVisibleRows } = useMemo(() => {
    const tempVisibleRows = paginationEnabled ? [...page] : [...rows];
    let visibleRows: typeof tempVisibleRows = [];

    tempVisibleRows.forEach(row => {
      visibleRows.push(row);
      /** Forcefully push subRows into visible rows since they might get skipped if usual pagination is being done */
      if (row.canExpand && row.isExpanded && skipForceShowSubRows) {
        visibleRows.push(...(row.subRows || []));
      }
    });

    visibleRows = uniq(visibleRows);

    const leafVisibleRows = visibleRows.reduce(
      (acc, row) => {
        if (row.canExpand) {
          return [...acc, ...(row.subRows || [])];
        } else {
          return [...acc, row];
        }
      },
      [] as Array<Row<T>>
    );

    return {
      visibleRows,
      leafVisibleRows
    };
  }, [page, paginationEnabled, rows, skipForceShowSubRows]);

  const visibleRowCount = visibleRows.reduce(
    (rowCount, row) => (row.canExpand ? rowCount + (row.subRows?.length || 0) : !row.depth ? rowCount + 1 : rowCount),
    0
  );

  const { rowStartIdx } = useMemo(() => {
    const rowStartIdx = leafRows.findIndex(row => row.id === leafVisibleRows[0]?.id);

    return {
      rowStartIdx: rowStartIdx < 0 ? 0 : rowStartIdx
    };
  }, [leafRows, leafVisibleRows]);

  return (
    <>
      {columns.length > 0 && (
        <>
          <GlobalFilter
            config={globalFilter}
            filteredRows={globalFilteredRows}
            globalFilter={globalFilterStr}
            isLoading={isLoading}
            setGlobalFilter={setGlobalFilter}
          >
            {filterChildren}
          </GlobalFilter>
          <div
            {...getTableProps({ style: { minWidth: "unset" } })}
            className={tableClassName}
            ref={tableContainerRef}
          >
            {!props.hideHeaders && (
              <div
                className="inc-table--head"
                ref={tableHeaderRef}
              >
                <ColumnHeaders
                  className={headerRowClassName}
                  contentScrollable={contentScrollable}
                  expansionColumnId={expansionColumnId}
                  headerGroups={headerGroups}
                  nonDataColumnIds={nonDataColumnIds}
                  resizeEnabled={resizable}
                  selectionColumnId={selectionColumnId}
                />
              </div>
            )}
            <div
              {...getTableBodyProps()}
              className={bodyClassName}
              ref={bodyRef}
            >
              <Rows
                cellClassName={cellClassName}
                className={rowClassName}
                errorDataMessage={errorDataMessage}
                expansionColumnId={expansionColumnId}
                getStyle={getRowStyle}
                hasError={hasError}
                isLoading={isLoading}
                isResizing={isResizing}
                loadingMessage={loadingMessage}
                noDataMessage={noDataMessage}
                nonDataColumnIds={nonDataColumnIds}
                onClick={onRowClick}
                onDblClick={onRowDblClick}
                prepareRow={prepareRow}
                resizeColumnWidths={resizeColumnWidths}
                rows={visibleRows}
                selectRowOnClick={enableSelection}
                selectedRowIds={selectedRowIds}
                selectionColumnId={selectionColumnId}
                subRowRenderer={subRowRenderer}
              />
            </div>
          </div>
          {paginationEnabled && !isLoading && (
            <PaginationController
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              gotoPage={gotoPage}
              minimal={viewMinimalPagination}
              nextPage={nextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              previousPage={previousPage}
              rowCount={rowCount}
              rowStartIdx={rowStartIdx}
              setPageSize={setPageSize}
              showDisplayStats={showDisplayStats}
              visibleRowCount={visibleRowCount}
            />
          )}
          {!paginationEnabled && Boolean(rowCount) && showDisplayStats && !isLoading && (
            <div className="inc-table-pagination inc-text-subtext-medium">
              <div className="marginRtAuto">
                Showing {visibleRowCount} of {rowCount} results
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const getElementHeight = (element: HTMLDivElement | undefined) => {
  if (element) {
    const dimensions = element.getBoundingClientRect();
    if (dimensions) {
      return dimensions.height;
    }
  }
  return 0;
};
