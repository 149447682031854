import React from "react";
import { InfoOutlined } from "./MaterialIcons";

export const IncInfoIcon = React.forwardRef<any, any>((props, ref) => (
  <InfoOutlined
    {...props}
    className="marginLt6"
    ref={ref}
    style={{ height: 14 }}
  />
));
