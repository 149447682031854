import { cloneDeep } from "lodash";
import { TableDataItem } from "../types";

export function addToSelectedAndExpanded(
  datum: TableDataItem[],
  parentIdxStr: string,
  selection: Record<string, boolean>,
  expanded: Record<string, boolean>,
  alwaysExpanded: boolean
) {
  if (datum?.length) {
    datum.forEach((d, idx) => {
      const idxStr = parentIdxStr ? `${parentIdxStr}.${idx}` : idx.toString();
      if (d.rowSelected) {
        selection[idxStr] = true;
      }

      if (d.rowExpanded || alwaysExpanded) {
        expanded[idxStr] = true;
      }

      addToSelectedAndExpanded(d.subRows || [], idxStr, selection, expanded, alwaysExpanded);
    });
  }
}

export function setSelectedOrExpanded(
  datum: TableDataItem[],
  parentIdxStr: string,
  stateMap: Record<string, boolean>,
  resultRows: TableDataItem[]
) {
  if (datum?.length) {
    datum.forEach((d, idx) => {
      const idxStr = parentIdxStr ? `${parentIdxStr}.${idx}` : idx.toString();
      if (stateMap[idxStr]) {
        d.rowExpanded = true;
        resultRows.push(cloneDeep(d));
      } else {
        d.rowExpanded = false;
      }

      setSelectedOrExpanded(d.subRows || [], idxStr, stateMap, resultRows);
    });
  }
}

export const getCircularJSONString = (object: Record<string, any>): string =>
  JSON.stringify(object, getCircularReplacer());

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen?.has(value)) {
        return;
      }
      seen?.add(value);
    }
    return value;
  };
};
