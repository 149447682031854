import { Action } from "./features";

//place all actions under configuration list here
export enum CONFIGURATION_ACTIONS {
  createConfig = "createConfig",
  updateConfig = "updateConfig",
  showConfigs = "showConfigs"
}

export const ConfigurationListFeatureActions: Record<keyof typeof Action, Array<keyof typeof CONFIGURATION_ACTIONS>> = {
  //All CREATE actions goes here
  //Enabled only for Admins,Editors
  CREATE: ["createConfig"],

  //All UPDATE actions goes here
  //Enabled only for Admins,Editors
  UPDATE: ["updateConfig"],

  //All READ actions goes here
  //Enabled for All
  READ: ["showConfigs"],

  //All DELETE actions goes here
  //Enabled only for Admins,Editors
  DELETE: []
};
