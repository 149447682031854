import React, { FC, createContext, useContext, useReducer } from "react";
import { OpAnalysisCreationConfig } from "../../../services/api/operationalise";
import { OpAnalysisState, OpAnalysisStateAction } from "./types";
import opAnalysisReducer from "./reducer";
import AnalysisOperationalizeImpl from "./AnalysisOperationalizeImpl";
import { getDefaultAnalysisConfig } from "./utils";

interface OpAnalysisContextProps {
  state: OpAnalysisState;
  dispatch: React.Dispatch<OpAnalysisStateAction>;
}

const OpAnalysisContext = createContext<OpAnalysisContextProps>(null);

interface Props {
  opAnalysisConfig: OpAnalysisCreationConfig;
  children?: React.ReactNode;
}

export const OpAnalysisProvider: FC<Props> = props => {
  const { opAnalysisConfig, children } = props;

  const [state, dispatch] = useReducer(opAnalysisReducer, initState(opAnalysisConfig));

  return (
    <OpAnalysisContext.Provider
      value={{
        dispatch,
        state
      }}
    >
      {children}
    </OpAnalysisContext.Provider>
  );
};

export const useOpAnalysisStore = () => useContext(OpAnalysisContext);

const initState = (opAnalysisConfig: OpAnalysisCreationConfig): OpAnalysisState => ({
  opAnalysisImpl: new AnalysisOperationalizeImpl(opAnalysisConfig || getDefaultAnalysisConfig()),
  dryRun: false
});
