export const Common = {
  attributes: {
    rollingFrequencySelector: "rolling-frequency-selector"
  },
  mockRequests: {
    "/api-proxy/api/bizEntity/AppType?**": {
      body: {
        name: "AppType",
        entityTypeId: "AppType",
        count: "0",
        metadata: {}
      },
      statusCode: 200
    },
    "/api-proxy/api/userService/i_userService:00000000-0000-0000-0000-0001db848028:0?**": {
      name: "Booking",
      entityId: "i_userService:00000000-0000-0000-0000-0001db848028:0",
      metadata: {
        icon: "ticket-airline"
      }
    }
  }
};
