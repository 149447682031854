export type OpConfigErrors = Record<ErrorKeys, string>;

type TriggerKeysType =
  | "name"
  | "opCreationConfigDef"
  | "opAnalysisConfig"
  | "opCreationConfig.rollingFunction"
  | "opCreationConfig.rollingFreq"
  | "bizDataQuery"
  | "bizDataQuery.sliceSpec"
  | "bizDataQuery.queryConfig"
  | "bizDataQuery.buildingBlocksConfig.cohortAggregator"
  | "bizDataQuery.buildingBlocksConfig.aggregator"
  | "bizDataQuery.buildingBlocksConfig.fieldConfig"
  | "schedule"
  | "schedule.startOffset"
  | "schedule.endOffset"
  | "schedule.startTimeEpochSecs"
  | "schedule.endTimeEpochSecs"
  | "schedule.specificScheduleConfig"
  | "threshold"
  | "threshold.thresholdDef"
  | "threshold.thresholdDef.peerThreshold.peerAgg"
  | "threshold.thresholdDef.peerThreshold"
  | "threshold.thresholdDef.offsetThreshold"
  | "threshold.thresholdDef.thresholdOp"
  | "threshold.thresholdDef.scalar"
  | "triggerCondition"
  | "triggerCondition.howManyTimesToViolate"
  | "triggerCondition.outOfTime"
  | "triggerCondition.outOfPoints"
  | "outlierConfig";

type ActionsKeysType = string;

type ErrorKeys = TriggerKeysType | ActionsKeysType;

export const OP_TRIGGER_KEYS: TriggerKeysType[] = [
  "name",
  "opCreationConfigDef",
  "opCreationConfig.rollingFunction",
  "opCreationConfig.rollingFreq",
  "bizDataQuery",
  "bizDataQuery.sliceSpec",
  "bizDataQuery.queryConfig",
  "bizDataQuery.buildingBlocksConfig.cohortAggregator",
  "bizDataQuery.buildingBlocksConfig.aggregator",
  "bizDataQuery.buildingBlocksConfig.fieldConfig",
  "schedule",
  "schedule.startOffset",
  "schedule.endOffset",
  "schedule.startTimeEpochSecs",
  "schedule.endTimeEpochSecs",
  "schedule.specificScheduleConfig",
  "threshold",
  "threshold.thresholdDef",
  "threshold.thresholdDef.peerThreshold.peerAgg",
  "threshold.thresholdDef.peerThreshold",
  "threshold.thresholdDef.offsetThreshold",
  "threshold.thresholdDef.thresholdOp",
  "threshold.thresholdDef.scalar",
  "triggerCondition",
  "triggerCondition.howManyTimesToViolate",
  "outlierConfig",
  "opAnalysisConfig"
];

export const OP_ACTION_ERROR_PREFIX = "bizAction";
