import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { combineReducers } from "redux";

export const history = createBrowserHistory();

export const createReducer = (injectedReducers = {}) => {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...injectedReducers
  });

  return rootReducer;
};
