import Axios, { CancelTokenSource } from "axios";
import { useCallback, useMemo, useRef } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../core";
import { FeedCommand } from "../types";
import { useCaseApiService } from "../UseCaseApiService";

type Result = FeedCommand[];

export const useFetchUseCaseCommands = (useCaseId: string, lazy = false) => {
  const initialState = useMemo<InitialState<Result, string>>(
    () => ({
      data: [],
      error: "",
      isError: false,
      isFetching: !lazy,
      isSuccess: false
    }),
    [lazy]
  );

  const cancelTokenRef = useRef<CancelTokenSource>();

  const fetchFn = useCallback<FetchFn<Result, string>>(async () => {
    cancelTokenRef.current?.cancel();
    cancelTokenRef.current = Axios.CancelToken.source();

    const result: FetchCallBackResult<Result, string> = {
      data: [],
      error: "",
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message, cancelled } = await useCaseApiService.getFeedCommandsForUseCase(
        useCaseId,
        cancelTokenRef.current.token
      );

      if (!cancelled) {
        if (error) {
          result.error = message;
          result.isError = true;
        } else {
          const { feedCommandList = [] } = data || {};

          result.data = feedCommandList;
          result.isSuccess = false;
          result.error = "";
        }
      } else {
        result.isCancelled = true;
      }
    } catch (err) {
      result.isError = true;
      result.error = err.message || String(err);
    }

    return result;
  }, [useCaseId]);

  return useDataFetch(fetchFn, initialState, lazy);
};
