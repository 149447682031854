export const copyRefs = (parentRef: any, childRef: any) => {
  const { current: domElement } = childRef;
  if (domElement && parentRef) {
    if (typeof parentRef === "function") {
      parentRef(domElement);
    } else {
      parentRef.current = domElement;
    }
  }
};
