import { IncSelectOption, RelativeDurationType } from "@inception/ui";
import {
  ActionChannelConfig,
  BizFieldPredicate,
  CompareConfig,
  MetricDefinition,
  MetricSourceType,
  NotifyActionConfig,
  TagFilter,
  TraceUIAggregation,
  UserServiceFieldSliceSet,
  WidgetConfigDTO
} from "./exploreTypes";
import { SliceSet, WidgetQuerySchema } from "./common";
import { FieldPickerOptionData } from "./fieldpickerTypes";

export const FILL_VAL_DEFAULT = "auto";

export type MetricAndSliceRecord = {
  metricId: string;
  metricSourceType: MetricSourceType;
  metricDefinition: MetricDefinition;
  querySchema: WidgetQuerySchema;
  sliceSet?: SliceSet; // Refactor: this field is not optional, should be mandatory.
  usfSlices?: UserServiceFieldSliceSet;
  isOperationalized: boolean;
  compareConfigs: CompareConfig[];
};

export enum SCHEDULE_TYPE {
  INTERVALBASED = "Interval Based",
  CONTINUOUS = "Continuous"
}

export interface OperationaliseState {
  // Context
  widgetConfigData: WidgetConfigDTO;
  widgetQuerySchema: WidgetQuerySchema[];
  metricAndSliceRecords: MetricAndSliceRecord[];

  scheduleType?: SCHEDULE_TYPE;
  metrics?: Record<string, MetricDefinition>;
  selectedInsightsTab?: InsightsConfigTabs;
  manualInsightConfig?: ManualInsightConfig;
  anomalyDetectionConfig?: AnomalyDetectionConfig;
  peerGroupConfig?: PeerGroupTabConfig;
  cohortMembersCount?: number;
  // selectedMetricObject?: MetricDefinition;
  metricAndSliceValue?: MetricAndSliceRecord;
  compareConfig: CompareConfig;
  tagFilters?: TagFilter[][];
  compareName: string;
  editedCompareId?: string;
  editedCompareName?: string;

  linkedDashboardIds?: string[];
}

export interface OperationaliseContext {
  widgetConfigDto: WidgetConfigDTO;
  widgetQuerySchema: WidgetQuerySchema[];
  compareId?: string;
  metricId?: string;
  sliceList?: string[];
}

export interface ManualInsightConfig {
  boundValue: number;
  boundType: string;
  dsFreqSecs: number;
  violationCount: number;
  totalTimeOrPoints: number;
  rollingAggFunc: TraceUIAggregation;
  rollingFreq: number;
  rollingDurationType: RelativeDurationType;
  violationType?: "points" | "minutes";
  thresholdType?: "number" | "percentage";
  shiftSecs?: number;
  dsFunc?: TraceUIAggregation;
  fillValue?: string;
}

export interface AnomalyDetectionConfig {
  boundType: "above" | "below" | "aboveOrBelow";
  violationCount: number;
  pointsValue: number;
  rollingAggFunc: TraceUIAggregation;
  rollingFreq: number;
  rollingDurationType: RelativeDurationType;
  violationType?: "points" | "minutes";
  seasonSecs?: string;
  ashFreq?: string;
  fillValue?: string;
}

export interface PeerGroupTabConfig {
  boundType: IncSelectOption;
  boundValue: number;
  dsFreqSecs: number;
  violationCount: number;
  totalTimeOrPoints: number;
  rollingAggFunc: TraceUIAggregation;
  rollingFreq: number;
  rollingDurationType: RelativeDurationType;
  violationType?: "points" | "minutes";
  fields?: FieldPickerOptionData[];
  aggrValue?: TraceUIAggregation;
  dsFunc?: TraceUIAggregation;
}

export interface UpdateActionRuleConfig {
  actionId: string;
  expressions: BizFieldPredicate[];
}

export interface UpdateActionChannelConfig {
  actionId: string;
  template: ActionChannelConfig;
}

export interface ActionConfigTestPayload {
  actionConfig: NotifyActionConfig;
  compareConfig: CompareConfig;
  bizEntityType?: string;
  userServiceEntityId?: string;
  widgetId: string;
  metricId: string;
}

export type InsightsConfigTabs = "manual" | "anomalyDetection" | "peerGroup";
