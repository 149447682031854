import React, { FC } from "react";
import { Calendar, CalendarProps } from "antd";
import { Moment } from "moment-timezone";

export interface IncCalendarProps extends CalendarProps<Moment> {}

export const IncCalendar: FC<IncCalendarProps> = props => {
  const { className, ...restProps } = props;

  return (
    <div className="inc-calendar">
      <Calendar
        {...restProps}
        className={className}
      />
    </div>
  );
};
