import { debounce, isEmpty, isEqual, map } from "lodash";
import { duration } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IncSelect, IncSelectOption } from "../Select/InceptionSelect";
import IncTextfield from "../Textfield/TextField";
import { IncDuration, RelativeDurationType } from "./types";

interface DurationSelectProps {
  duration?: IncDuration;
  includeSeconds?: boolean;
  onChange: (duration: IncDuration) => void;
  className?: string;
  label?: string;

  hasError?: boolean;
  errorText?: string; // pass error text to be shown on tooltip
  textfieldClass?: string;
  selectClass?: string;
}

const IncDurationSelect: React.FC<DurationSelectProps> = (props: DurationSelectProps) => {
  const {
    onChange,
    duration,
    includeSeconds,
    label,
    className: pClassName = "",
    hasError: pHasError,
    errorText: pErrorText,
    textfieldClass,
    selectClass
  } = props;
  const [durationNum, setDurationNum] = useState<string>("");
  const [durationType, setDurationtype] = useState<IncSelectOption>(null as any);

  useEffect(() => {
    if (!duration) {
      return;
    }
    setDurationNum(duration?.duration?.toString());
    setDurationtype(getDurationTypeOption(duration?.durationType));
  }, [duration]);

  const callOnChange = useCallback(
    (newDuration: IncDuration) => {
      if (isEqual(duration, newDuration)) {
        return;
      }

      onChange(newDuration);
    },
    [duration, onChange]
  );

  const debounedCallOnChange = useMemo(() => debounce(callOnChange, 700), [callOnChange]);

  const durationOptions = useMemo<IncSelectOption[]>(
    () =>
      map(durationMap, (v, k) => ({
        label: v,
        value: k.toString()
      })).filter(op => (includeSeconds ? true : op.value !== durationMap[RelativeDurationType.SECONDS])),
    [includeSeconds]
  );

  const durationError = isEmpty(durationNum)
    ? "Duration is a required parameter"
    : durationNum <= "0"
      ? "Duration should be a positive number"
      : "";

  const onDurationNumChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const numVal = parseInt(value, 10);
      const val = !isNaN(numVal) ? value : "1";
      setDurationNum(val);

      debounedCallOnChange({
        duration: parseInt(val, 10),
        durationType: parseInt(durationType.value, 10)
      });
    },
    [durationType, debounedCallOnChange]
  );

  const onDurationTypeChange = useCallback(
    (op: IncSelectOption) => {
      const val = parseInt(op.value, 10);
      setDurationtype(op);

      callOnChange({
        duration: parseInt(durationNum, 10),
        durationType: val
      });
    },
    [durationNum, callOnChange]
  );

  const hasError = !isEmpty(durationError) || pHasError;
  const durationErrorMsg = pErrorText || durationError;

  const className = `inc-duration-select ${hasError ? "inc-duration-select--error" : ""} ${pClassName}`;

  return (
    <div className={className}>
      {label && <div className="marginBt6 inc-label-common">label</div>}
      <div className="inc-flex-row inc-flex-center-vertical">
        <IncTextfield
          className={textfieldClass}
          errorText={durationErrorMsg}
          hasError={hasError}
          label=""
          min="1"
          onChange={onDurationNumChange}
          type="number"
          value={durationNum}
        />
        <IncSelect
          autoSort={false}
          className={selectClass}
          isMulti={false}
          label=""
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onChange={onDurationTypeChange as any}
          options={durationOptions}
          value={durationType}
        />
      </div>
    </div>
  );
};

export default IncDurationSelect;

const durationMap = {
  [RelativeDurationType.SECONDS]: "second(s)",
  [RelativeDurationType.MINUTES]: "minute(s)",
  [RelativeDurationType.HOURS]: "hour(s)",
  [RelativeDurationType.DAYS]: "day(s)",
  [RelativeDurationType.WEEKS]: "week(s)",
  [RelativeDurationType.MONTHS]: "month(s)"
};

function getDurationTypeOption(durationType: RelativeDurationType = RelativeDurationType.HOURS) {
  const op: IncSelectOption = {
    label: durationMap[durationType],
    value: durationType.toString()
  };
  return op;
}

export function durationToDurationString(incDuration: IncDuration): string {
  const { duration: durationNum, durationType } = incDuration;

  let durationTypeStr = "";

  switch (durationType) {
    case RelativeDurationType.SECONDS: {
      durationTypeStr = "s";
      break;
    }
    case RelativeDurationType.MINUTES: {
      durationTypeStr = "m";
      break;
    }
    case RelativeDurationType.HOURS: {
      durationTypeStr = "h";
      break;
    }
    case RelativeDurationType.DAYS: {
      durationTypeStr = "d";
      break;
    }
    case RelativeDurationType.WEEKS: {
      durationTypeStr = "w";
      break;
    }
    case RelativeDurationType.MONTHS: {
      durationTypeStr = "M";
      break;
    }

    default:
      durationTypeStr = "h";
  }

  return durationNum + durationTypeStr;
}

export function durationToMillis(incDuration: IncDuration): number {
  const { duration: durationNum, durationType } = incDuration;

  let durationTypeStr = "";

  switch (durationType) {
    case RelativeDurationType.SECONDS: {
      durationTypeStr = "s";
      break;
    }
    case RelativeDurationType.MINUTES: {
      durationTypeStr = "m";
      break;
    }
    case RelativeDurationType.HOURS: {
      durationTypeStr = "h";
      break;
    }
    case RelativeDurationType.DAYS: {
      durationTypeStr = "d";
      break;
    }
    case RelativeDurationType.WEEKS: {
      durationTypeStr = "w";
      break;
    }
    case RelativeDurationType.MONTHS: {
      durationTypeStr = "M";
      break;
    }

    default:
      durationTypeStr = "h";
  }

  const durationMillis = duration(durationNum, durationTypeStr as any).asMilliseconds();
  return durationMillis;
}
