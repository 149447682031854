import { PREFIX_FIELDS } from "../../../core/data/constants";

export const queryOptions = {
  enabled: false,
  refetchOnWindowFocus: false,
  retry: false,
  refetchOnMount: false
};

export enum KNOWN_KEYS {
  HTTP_STATUS_CODE = "http.status_code",
  SPAN_ERROR = "spanError",
  HAS_ERROR = "hasError",
  SERVICE_NAME = "service",
  OPERATION_NAME = "api",
  USER_SERVICE = "userService"
}

export const PREFIX_TAGS = "tags.";
export const PREFIX_GLOBAL_FIELDS = "globalFields.";
export const PREFIX_SUMMARY = "summary.";
export const INCEPTION_PREFIX = "__inception";

export { PREFIX_FIELDS };
