import React, { useMemo, useCallback } from "react";
import { cx } from "emotion";
import IncButton, { IncButtonSize } from "../Button/Button";
import { generateId } from "../../utils";

export type ButtonGroupOption<T = any> = {
  id: string;
  label: string;
  icon?: JSX.Element;
  data?: T;
  isDisabled?: boolean;
};

export interface ButtonSelectionGroupProps<T> {
  options: Array<ButtonGroupOption<T>>;
  selectedOptions: ButtonGroupOption<T> | Array<ButtonGroupOption<T>>;
  onChange: (nSelection: ButtonGroupOption<T> | Array<ButtonGroupOption<T>>) => void;

  label?: string;
  isMulti?: boolean;
  size?: IncButtonSize;
  unstyled?: boolean;
  layout?: "horizontal" | "vertical";
  classNames?: {
    container?: string;
    button?: string;
    selected?: string;
    unSelected?: string;
  };
}

export const ButtonSelectionGroup = <T,>(props: ButtonSelectionGroupProps<T>) => {
  const {
    options,
    selectedOptions,
    isMulti,
    onChange,
    layout = "horizontal",
    size = "regular",
    unstyled = false,
    classNames,
    label = ""
  } = props;

  const {
    button: buttonClassName = "",
    container: containerClassName = "",
    selected: selectedClassName = "",
    unSelected: unSelectedClassName = ""
  } = classNames || {};

  const buttonGroupId = useMemo(() => generateId(), []);

  const selectedOptIds = useMemo(
    () => (Array.isArray(selectedOptions) ? selectedOptions.map(({ id }) => id) : selectedOptions.id),
    [selectedOptions]
  );

  const onButtonClick = useCallback(
    (buttonId: string, isCurrentlySelected: boolean) => {
      const selectedOpt = options.find(({ id }) => buttonId === id) as ButtonGroupOption<T>;
      if (selectedOpt) {
        if (isMulti) {
          const prevSelection = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
          if (isCurrentlySelected) {
            const nSelection = prevSelection.filter(({ id }) => id !== buttonId);
            onChange(nSelection);
          } else {
            const nSelection = [...prevSelection, selectedOpt];
            onChange(nSelection);
          }
        } else {
          if (!isCurrentlySelected) {
            onChange(selectedOpt);
          }
        }
      }
    },
    [isMulti, onChange, options, selectedOptions]
  );

  const className = useMemo(
    () =>
      cx(containerClassName, {
        "inc-button-selection-group--vertical": layout === "vertical",
        "inc-button-selection-group--horizontal": layout === "horizontal",
        "inc-button-selection-group--unstyled": unstyled
      }),
    [containerClassName, layout, unstyled]
  );

  const buttons = useMemo(
    () =>
      options.map(option => {
        const { label, id, isDisabled, icon } = option;
        const isSelected = selectedOptIds.includes(id);

        const key = `button-group-${buttonGroupId}-${option}-${id}`;
        const className = cx(buttonClassName, {
          [selectedClassName]: isSelected,
          [unSelectedClassName]: !isSelected
        });

        const onClick = () => onButtonClick(id, isSelected);
        const color = isDisabled ? "secondary" : isSelected ? "primary" : "secondary";

        return (
          <IncButton
            className={className}
            color={color}
            key={key}
            onClick={onClick}
            size={size}
          >
            <div className="inc-flex-row inc-flex-center-vertical">
              {icon}
              {label}
            </div>
          </IncButton>
        );
      }),
    [
      buttonClassName,
      buttonGroupId,
      onButtonClick,
      options,
      selectedClassName,
      selectedOptIds,
      size,
      unSelectedClassName
    ]
  );

  const labelExists = Boolean(label);

  return (
    <div className="inc-button-selection-group">
      {labelExists && <div className="inc-label-common marginBt8">{label}</div>}
      <div className={className}>{options.length > 0 ? buttons : "No data found"}</div>
    </div>
  );
};
