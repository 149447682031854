import { clone, isEmpty } from "lodash";
import { TimeSeries } from "../../core";
import { EntityEnricher } from "./types";
import { isEntity } from "./utils";

export const TimeSeriesEntityEnricher: EntityEnricher<TimeSeries> = {
  type: "timeseries",
  parser: function (data: TimeSeries[], isEntity: (s: string) => boolean): string[] {
    const entityIdToTagNameMap: Map<string, string> = new Map();
    const entityIds: Set<string> = new Set();
    data.forEach(s => {
      for (const [key, value] of Object.entries(s.tags)) {
        const val = value as string;
        if (isEntity(val)) {
          entityIds.add(val);
          entityIdToTagNameMap.set(val, key);
        }
      }
    });

    // if no entityIds's simply proceed;
    if (entityIds.size === 0) {
      return [] as string[];
    }
    return Array.from(entityIds) as string[];
  },

  enrich: function (data: TimeSeries[], entityMap: Map<string, string>): TimeSeries[] {
    if (isEmpty(entityMap)) {
      return data;
    }

    const entityIds: string[] = Array.from(entityMap.keys());

    //const tagNameSuffix = 'Name';
    data.forEach(s => {
      s.eTags = clone(s.tags);
      for (const [key, value] of Object.entries(s.tags)) {
        const val = value as string;
        //s.eTags[key + tagNameSuffix] = val; // default initialization
        if (entityIds.includes(val) && isEntity(val)) {
          const e = entityMap.get(val);
          s.eTags[key] = e;
        }
      }
    });
    return data;
  }
};
