import _ from "lodash";
import { IncidentSummary, IncidentTimeSeries, Tag, Range, IncludedIncident } from "../core";
import { IncidentModel } from "./IncidentSummaryModel";

export const EXCLUDE_TAGS: string[] = ["_apptuit_named_query", "__apptuit__metric", "__apptuit__mq", "__apptuit_ts"];
// Hack remove metric and mq after server response deletes it.

type Include = IncludedIncident;

type TS = IncidentTimeSeries;

type ColumnHeader = {
  key: string;
  count: number;
  value: string[];
  show: boolean;
  isCommon?: boolean;
};

type GanttRow = {
  index: number;
  key: string;
  parentKey: string;
  isChild: boolean;
  hasChildren: boolean;
  show: boolean;
  isOpen: boolean;
  isLatest: boolean;
  selected: boolean;
  highlight: boolean;
  parentIndex: number;
};

export class Row {
  intervals: any;
  name: string;
  children: any[];
  key: any;
  metric: any;
  tags: Tag;
  data: any;
  isChild: boolean;
  parentKey: any;
  isLatest: boolean;
  tagCount: number;

  tsId: number;
  mtsId: string;
  mqId: string;
  mqName: string;
  incidentId: string;

  onGoing: boolean; // True indicate the mtsId is currently having an incident.
  ranges: Range[];
  idTags: string[];
  idTagsObj: any;

  syncEndTime: number;

  constructor(
    includeIncident: Include,
    tsList: TS[],
    mqId?: string,
    mqName?: string,
    incidentId?: string,
    idTags?: string[],
    isChild?: boolean,
    parentKey?: string
  ) {
    if (!includeIncident || !tsList) {
      return;
    }
    this.tsId = includeIncident.tsi;
    this.mtsId = includeIncident.mtsId;
    this.mqId = mqId || "";
    this.mqName = mqName || "";
    this.incidentId = incidentId || "";
    this.metric = tsList[includeIncident.tsi].m;
    this.tags = tsList[includeIncident.tsi].tags || {};
    this.excludeTags();
    this.idTags = idTags || [];
    this.idTagsObj = this.getIdTags();
    this.tagCount = _.keys(this.tags).length;
    this.key = this.getKey(this.tags, this.tsId);
    this.isChild = isChild ? isChild : false;
    this.parentKey = parentKey;
    this.children = [];
    this.intervals = [];
    this.ranges = includeIncident.ranges;
    this.processRanges();
    this.processChildren(includeIncident.includes, tsList);
  }

  getIdTags() {
    if (_.isEmpty(this.idTags)) {
      return this.tags;
    } else {
      const idTagsObj: Record<string, string> = {};
      this.idTags.map(idTag => (idTagsObj[idTag] = this.tags[idTag] || "-NA-"));
      return idTagsObj;
    }
  }

  excludeTags() {
    EXCLUDE_TAGS.map(key => delete this.tags[key]);
  }

  processRanges() {
    _.each(this.ranges, (range: Range) => {
      this.onGoing = !this.onGoing && range.end === -1 ? true : false;
      const end = this.onGoing ? (this.syncEndTime ? this.syncEndTime : new Date().getTime() / 1000) : range.end;
      this.intervals.push([range.start, end]);
    });
  }

  processChildren(includes: Include[], tsList: TS[]) {
    if (!_.isEmpty(includes)) {
      _.each(includes, (includes: Include) => {
        this.children.push(
          new Row(includes, tsList, this.mqId, this.mqName, this.incidentId, this.idTags, true, this.parentKey)
        );
      });
    }
  }

  getKey(tags: Tag, tsId: number) {
    let key = `${tsId}-`;
    _.each(tags, (tK, tV) => {
      key += `${tK}-${tV}--`;
    });
    return key.length === 0 ? ROOT_KEY : key;
  }

  getTagValue(key: string) {
    return this.tags[key];
  }

  getChild(childKey: string) {
    return _.find(this.children, o => o.key === childKey);
  }

  getStart() {
    const first: number = _.first(_.sortBy(_.flatten(this.intervals)));
    return first ? first * 1000 : 0;
  }

  getEnd() {
    const last: number = _.last(_.sortBy(_.flatten(this.intervals)));
    return last ? last * 1000 : 0;
  }

  getWindowStart(startTime: number) {
    const range = _.find(this.ranges, { start: startTime });
    if (range) {
      return range.windowStartTS > 0 ? range.windowStartTS * 1000 : -1;
    }
    return -1;
  }

  getMetricStr() {
    const tagStr = `{ ${this.getTagsStr()} }`;
    return `${this.metric} ${tagStr}`;
  }

  getTagsStr() {
    const tags = _.isEmpty(this.idTagsObj) ? this.tags : this.idTagsObj;
    const tagStrArr = _.map(tags, (v, k) => `${k}: ${v}`);
    return `${_.join(tagStrArr, ", ")}`;
  }
}

const ROOT_KEY = "root";
const oneHour = 1 * 60 * 60 * 1000;

export class AnalysisDataModel {
  readonly COLOURS = 11;
  readonly TAG_CARDINALITY_THRESHOLD = 4;
  analyses: IncidentSummary;
  rootIncident: IncidentModel;
  hoverInfo: any;

  status: any;
  rowOrder: GanttRow[];
  tagHeaders: ColumnHeader[];
  nRows: Record<string, Row>;

  rowHeight: any;
  isActive: boolean;
  commonTags: any;
  headerTags: any;
  canRender: boolean;
  colorModel: any;

  start: number;
  end: number;

  idTags: string[];
  readonly rootKey = "root";

  // initialMtsId and initialGTRow are used to select a row when gantt is initialized.
  // Further row selection are done using events emission.
  initialMtsId: string;
  initialGTRow: GanttRow;
  invalidInitialMtsId = false;

  constructor(
    rootIncident: IncidentModel,
    analyses: IncidentSummary,
    isActive?: boolean,
    idTags?: string[],
    startTime?: number,
    endTime?: number,
    initialMtsId?: string
  ) {
    this.analyses = analyses;
    this.rootIncident = rootIncident;
    this.idTags = idTags || [];
    this.canRender = this.checkSanity(this.analyses);
    if (!this.canRender) {
      return;
    }
    this.start = startTime ? startTime : this.analyses.ranges[0].start * 1000 - oneHour;
    this.end = endTime
      ? endTime
      : this.analyses.ranges[0].end === -1
        ? new Date().getTime()
        : this.analyses.ranges[0].end * 1000 - oneHour;
    this.initialMtsId = initialMtsId;
    this.tagHeaders = [];
    this.rowOrder = [];
    this.nRows = {};
    this.rowHeight = "30px";
    this.isActive = isActive ? isActive : false;
    this.commonTags = [];
    this.headerTags = [];
    this.colorModel = {};
    this.processIncident();
    this.createRowDatabase();
    this.canRender = this.canRender && !_.isEmpty(this.rowOrder);
    if (this.canRender) {
      this.processTags(this.analyses.tsList);
    }
  }

  checkSanity(analyses: IncidentSummary) {
    if (!analyses || analyses.ranges.length === 0) {
      return false;
    }
    return true;
  }

  getTagHeaders(tagList: TS[], rows?: Row[]): ColumnHeader[] {
    if (!_.isEmpty(this.idTags)) {
      const groupedRows = _.filter(rows, r => r.children.length > 0);
      const groupedKeys: string[] = [];
      groupedRows.map(r => {
        _.each(r.tags, (v, k) => groupedKeys.push(k));
        return true;
      });
      const headersKeys = _.union(this.idTags, _.uniq(groupedKeys));
      return headersKeys.map(i => ({
        key: i,
        count: 1,
        value: [],
        show: true
      })) as ColumnHeader[];
    } else {
      const tagHeaders: ColumnHeader[] = [];
      _.each(tagList, (tgs: TS) => {
        _.each(tgs.tags, (v, k) => {
          let header = _.find(tagHeaders, th => th.key === k);
          if (!header) {
            header = {
              key: k,
              count: 1,
              value: [v],
              show: true
            };
            tagHeaders.push(header);
          } else {
            header.value.push(v);
            header.value = _.uniq(header.value);
            header.count += 1;
          }
        });
      });
      return _.orderBy(tagHeaders, ["count"], ["asc"]);
    }
  }

  processTags(tagList: TS[]) {
    this.tagHeaders = this.getTagHeaders(tagList, _.values(this.nRows));
    this.computeCommonTags();
    this.computeTitleTags();
  }

  getAnalysisCount() {
    return _.filter(this.rowOrder, rOrd => !rOrd.isChild).length;
  }

  computeTitleTags() {
    const isInvalid = (gtRow: GanttRow) => _.isEmpty(gtRow.key) || gtRow.key === this.rootKey;
    const totalRows = this.rowOrder.length;
    let gtRow: GanttRow;
    if (this.isActive) {
      const latest = _.filter(this.rowOrder, rwOrd => rwOrd.isLatest);
      _.each(latest, lt => {
        if (!isInvalid(lt) && !gtRow) {
          gtRow = lt;
        }
      });
    } else {
      const lastRow = _.last(this.rowOrder);
      gtRow = !isInvalid(lastRow) ? lastRow : totalRows >= 2 ? this.rowOrder[totalRows - 3] : null;
    }

    if (gtRow) {
      const { tags } = this.getRowFromGTRow(gtRow);
      if (!_.isEmpty(this.idTags)) {
        this.tagHeaders.map(th => {
          this.headerTags.push({
            tagk: th.key,
            tagv: tags[th.key] || "-NA-"
          });
          return true;
        });
      } else {
        const commonHeaders = _.map(this.commonTags, cT => cT.tagk);
        _.each(tags, (v, k) => {
          const key = k;
          if (_.indexOf(commonHeaders, key) === -1) {
            this.headerTags.push({
              tagk: k,
              tagv: v
            });
          }
        });
      }

      if (_.isEmpty(this.headerTags)) {
        const metricName = this.rootIncident.query.query.metric;
        this.headerTags.push({
          tagk: "metric",
          tagv: metricName
        });
      }
    }
  }

  computeCommonTags() {
    const cTags = [];
    const totalRows = this.getRowCount();
    if (this.tagHeaders.length > 2) {
      const common = _.filter(this.tagHeaders, h => {
        const isCommon = h.value.length === 1 && h.count >= totalRows;
        h.isCommon = isCommon;
        return isCommon;
      });
      _.each(common, t => {
        cTags.push({
          tagk: t.key,
          tagv: t.value[0]
        });
      });
    }
    const hideCommon = this.tagHeaders.length - cTags.length >= 1 && totalRows > 1;
    //this.commonTags = hideCommon ? cTags : [];
    this.commonTags = [];
    _.each(this.tagHeaders, th => {
      th.show = th.isCommon && hideCommon ? false : true;
    });
  }

  getKey(tags: Tag) {
    let key = "";
    _.each(tags, (tK, tV) => {
      key += `${tK}-${tV}--`;
    });
    return key.length === 0 ? this.rootKey : key;
  }

  getRowAlias(row: GanttRow) {
    const { tags } = this.getRow(row.key, row.parentKey);
    const keys = _.union(this.idTags, _.keys(tags));
    const alias = keys.map(k => ` ${k}: <span style="font-weight:500;">${tags[k] || "-NA-"}</span>`).join("</br>");

    return `<p style='text-align:left;'>${alias}</p>`;
  }

  getTagValues(row: Row, header: ColumnHeader) {
    return this.getTag(row.key, row.parentKey, header.key);
  }

  markLatest() {
    let activeIncidents = this.isActive
      ? _.filter(this.nRows, (row: Row) => row.onGoing === true)
      : _.values(this.nRows);
    activeIncidents = _.sortBy(activeIncidents, [a => a.getStart()]);
    const activeIncident = _.first(activeIncidents);
    if (activeIncident) {
      activeIncident.isLatest = true;
    }
  }

  processIncident() {
    _.each(this.analyses.includes, (analysis: Include) => {
      const row = new Row(analysis, this.analyses.tsList);
      this.nRows[row.key] = row;
    });
    this.markLatest();
  }

  getGTRowFromIndex(index: string): GanttRow {
    return _.find(this.rowOrder, rw => rw.index === parseInt(index, 10));
  }

  getRowFromGTRow(gtRow: GanttRow): Row {
    return this.getRow(gtRow.key, gtRow.parentKey);
  }

  getRow(key: string, parentKey: string): Row {
    return parentKey ? this.nRows[parentKey].getChild(key) : this.nRows[key];
  }

  getInitialGanttRow(): GanttRow {
    return this.initialGTRow;
  }

  getTag(key: string, parentKey: string, tagk: string) {
    const r = this.getRow(key, parentKey);
    const tagV = r.tags[tagk];
    return tagV ? tagV : _.keys(r.tags).length === 0 ? this.rootKey : "";
  }

  getRowCount() {
    return this.rowOrder.length;
  }

  getRowHeight() {
    return 35;
  }

  getRowOrderSequence(isActiveIncident: boolean): Array<{ key: string; start: number }> {
    let rowVsStart: Array<{ key: string; start: number }> = [];
    const latest: Array<{ key: string; start: number }> = [];
    _.each(this.nRows, (nRow, key) => {
      if (nRow.isLatest && isActiveIncident) {
        latest.push({
          key: key,
          start: nRow.getStart()
        });
      } else {
        rowVsStart.push({
          key: key,
          start: nRow.getStart()
        });
      }
    });
    rowVsStart = _.orderBy(rowVsStart, ["start"], ["asc"]);
    rowVsStart = _.concat(latest, rowVsStart);
    return rowVsStart;
  }

  getFirstAnalysis(): GanttRow {
    return _.first(this.rowOrder);
  }

  getLatestAnalysis(): GanttRow[] {
    const a = _.filter(this.rowOrder, rwOrd => rwOrd.isLatest);
    return a;
  }

  getGtRowWithChildren(): GanttRow[] {
    return _.filter(this.rowOrder, rOrd => rOrd.hasChildren);
  }

  markSelected(rowKey: string) {
    const gantRow = this.rowOrder.find(rw => rw.key === rowKey);
    if (gantRow) {
      this.rowOrder.forEach(rw => (rw.selected = false));
      gantRow.selected = true;
    }
  }

  getSelectedRow(): Row {
    const selectedGanttRow = this.rowOrder.find(rw => rw.selected);
    if (selectedGanttRow) {
      return this.getRowFromGTRow(selectedGanttRow);
    } else {
      return null;
    }
  }

  createRowDatabase() {
    let rowCount = 0;

    const constructRowOrder = (
      key: string,
      parentKey: string,
      isChild: boolean,
      hasChildren: boolean,
      isLatest: boolean,
      parentIndex: number
    ): GanttRow => {
      const r: GanttRow = {
        index: rowCount,
        key: key,
        parentKey: parentKey,
        isChild: isChild,
        hasChildren: hasChildren,
        show: !isChild,
        isOpen: false,
        isLatest: isLatest,
        selected: false,
        highlight: false,
        parentIndex: parentIndex
      };
      ++rowCount;
      return r;
    };

    const rows: GanttRow[] = [];
    const _rowVsStart = this.getRowOrderSequence(this.isActive);
    _.each(_rowVsStart, rVsSt => {
      const r: Row = this.nRows[rVsSt.key];
      const gtRow = constructRowOrder(r.key, null, false, r.children.length > 0, r.isLatest, null);
      rows.push(gtRow);

      if (this.initialMtsId && r.mtsId === this.initialMtsId) {
        this.initialGTRow = gtRow;
      }
      const children = _.sortBy(r.children, c => c.getStart());
      _.each(children, c => {
        rows.push(constructRowOrder(c.key, r.key, true, c.children.length > 0, r.isLatest, gtRow.index));
      });
    });

    if (this.initialMtsId && !this.initialGTRow) {
      this.invalidInitialMtsId = true;
    }
    this.rowOrder = rows;
  }

  getGtRowChildren(rIndex: number) {
    const parentGTRow = this.rowOrder[rIndex];
    if (!parentGTRow.hasChildren) {
      return [];
    }
    return _.filter(this.rowOrder, rowOrder => rowOrder.parentKey === parentGTRow.key);
  }

  getGtRowParent(rIndex: number) {
    const child = this.rowOrder[rIndex];
    return _.find(this.rowOrder, rowOrder => rowOrder.key === child.parentKey && rowOrder.hasChildren);
  }

  getGTRowFromTags(tags: Record<string, string>): GanttRow {
    const rowKey = _.findKey(this.nRows, rw => checkIfTagEntryMatches(rw.tags, tags));
    const ganttRow = this.rowOrder.find(rw => rw.key === rowKey);
    return ganttRow;
  }

  updateGtRowProperty(propName: string, propValue: string, index: number) {
    const a: Record<string, string> = {};
    a[propName] = propValue;
    if (index > -1) {
      _.extend(this.rowOrder[index], a);
    } else {
      _.each(this.rowOrder, rwOrd => {
        _.extend(rwOrd, a);
      });
    }
  }

  gtRowToggleView(rKey: string, rIndex: number) {
    this.rowOrder[rIndex].isOpen = !this.rowOrder[rIndex].isOpen;
    _.map(this.rowOrder, rw => {
      if (rw.parentKey && rw.parentKey === rKey) {
        rw.show = !rw.show;
      }
    });
  }

  assignColour() {
    return 0;
  }

  getSeries() {
    const data: any = [];

    const rwToShow = _.filter(this.rowOrder, rw => rw.show);

    let _rowCount = rwToShow.length - 0.5;
    _.each(rwToShow, (rwOrd, i) => {
      const rw = this.getRow(rwOrd.key, rwOrd.parentKey);
      _rowCount = i !== 0 ? _rowCount - 1 : _rowCount;
      _.each(rw.intervals, obj => {
        const start = obj[0] * 1000;
        const end = obj[1] * 1000;
        data.push([start, _rowCount, end, [start, end, rwOrd]]);
      });
    });
    const config = {
      data: data,
      start: this.start,
      end: this.end,
      yAxisTicks: rwToShow.length,
      rowHeight: this.getRowHeight(),
      canvasHeight: rwToShow.length * this.getRowHeight()
    };

    return config;
  }
}

const checkIfTagEntryMatches = (tagEntry: Record<string, string>, matchTags: Record<string, string>) => {
  const tagKeys = Object.keys(tagEntry);
  return tagKeys.every(tag => tagEntry[tag] === matchTags[tag]);
};
