import React from "react";

const MILLISEC_IN_SEC = 1000;

interface TimelineMarkerProps {
  durationInMs: number;
  numMarkers: number;
}

const TimelineMarker: React.FC<TimelineMarkerProps> = (props: TimelineMarkerProps) => {
  const getTickInterval = (): number => props.durationInMs / props.numMarkers;

  const getDisplayValue = (timeInMicros: number): string => {
    const timeInMillis = timeInMicros / 1000;

    // if total duration is greater than 3 seconds, show everything in sec
    if (timeInMillis > 1000) {
      return `${(timeInMillis / MILLISEC_IN_SEC).toFixed(2)} s`;
    } else {
      return `${timeInMillis.toFixed(2)} ms`;
    }
  };

  return (
    <div className={"ts-timeline-row"}>
      {/* Empty space to align with the actual timeline */}

      <div className={"ts-timeline-events-container timeline-marker"}>
        {
          /* tslint:disable */
          [...Array(props.numMarkers)].map((d: number, idx: number) => {
            if (idx === props.numMarkers - 1) {
              return (
                <div
                  className={"timeline-marker-col"}
                  key={`${Math.random()}`}
                >
                  <span>{getDisplayValue(getTickInterval() * idx)} </span>
                  <span style={{ float: "right" }}>{getDisplayValue(props.durationInMs)} </span>
                </div>
              );
            } else {
              return (
                <div
                  className={"timeline-marker-col"}
                  key={`${Math.random()}`}
                >
                  {getDisplayValue(getTickInterval() * idx)}
                </div>
              );
            }
          })
        }
      </div>
    </div>
  );
};

export default TimelineMarker;
