import React from "react";
import { cx } from "emotion";
import { formatPercent, formatNumber } from "../../utils/formatters/NumberFormatter";
import { IncFaIcon } from "../../icons";

type Direction = "up" | "down" | "none";
type Size = "small" | "regular" | "large";
type ValueType = "absolute" | "percent";

export interface IncChangeRendererProps {
  value: number;
  total?: number;
  direction?: Direction;
  size?: Size;
  /**
   * Down is green, up is red. Works only if direction is provided
   */
  invertColors?: boolean;
  decimalPrecision?: number;
  changeType?: ValueType;
  dataType?: string;
  formatter?: (value: number, valueType: ValueType, dataType?: string) => string;
}

export const IncChangeRenderer: React.FC<IncChangeRendererProps> = props => {
  const {
    value,
    total,
    direction = "none",
    size: pSize = "regular",
    invertColors,
    decimalPrecision = 0,
    changeType = "absolute",
    formatter,
    dataType
  } = props;

  const iconName = direction === "up" ? "caret-up" : direction === "down" ? "caret-down" : "";

  let divColor: "green" | "red" | null = direction === "up" ? "green" : direction === "down" ? "red" : null;
  if (invertColors) {
    divColor = divColor === "green" ? "red" : "green";
  }

  const changeRendererClassNames = cx(
    `inc-change-renderer`,
    `inc-change-renderer--${divColor}`,
    `inc-change-renderer-${pSize}`
  );

  if (total === 0 || value === Number.MAX_VALUE || value === Number.MIN_VALUE) {
    return <></>;
  }

  const changeValue = formatter
    ? formatter(value, changeType, dataType)
    : changeType === "absolute"
      ? formatNumber(value)
      : formatPercent(value, total, decimalPrecision);

  return (
    <div className={changeRendererClassNames}>
      {iconName && (
        <div className="inc-change-renderer--icon-container">
          <IncFaIcon iconName={iconName} />
        </div>
      )}
      <span>{changeValue}</span>
    </div>
  );
};
