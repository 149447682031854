import { map, omit } from "lodash";

export const APPTUIT_NAMED_QUERY = "_apptuit_named_query";
export const APPTUIT_SERIES_TYPE = "__apptuit__type";
export const PROM_METRIC_NAME_TAG = "__name__";
export const ENTITY_TAG = "i_entity";
export const USERSERVICE_TAG = "i_userService";
export const MISSING_VALUE = "__missing__";

const getCompareMetricNameFromTags = (tags: Record<string, string>) => tags[APPTUIT_SERIES_TYPE] || "";

const getMetricNameFromTags = (tags: Record<string, string>, metricName = "", removeNameTagIfExists = true): string => {
  // Always delete the anmed_query tag, since it isn't used anywhere
  delete tags[APPTUIT_NAMED_QUERY];

  metricName = metricName || tags[PROM_METRIC_NAME_TAG] || "";

  if (removeNameTagIfExists) {
    delete tags.__name__;
  }

  const entityName = tags[ENTITY_TAG] || "";
  const tagsWithoutEntity = omit(tags, [ENTITY_TAG]);

  const labelPart = map(tagsWithoutEntity, (tagv, tagk) => `${tagk}="${tagv}"`).join(", ");
  const preLabelPart = [metricName, entityName].filter(Boolean).join(" - ");

  const fMetricName =
    preLabelPart && labelPart ? `${preLabelPart} {${labelPart}}` : preLabelPart ? preLabelPart : `{${labelPart}}`;

  return fMetricName;
};

export { getMetricNameFromTags, getCompareMetricNameFromTags };
