import { BizIdProps } from "../CommonTypesV2";
import { SliceSet } from "../../../explore";
import { EntityPropertyValue } from "../../../../../core";
import { AlertStatus } from "./alerts";

export interface IncidentPayload {
  incident?: Incident;
  /** Move to AlertEventTypeDef */
  idProps?: BizIdProps;
  /** Dynamic Context Payload */
  context: Record<string, EntityPropertyValue>;
  /** Move to AlertEventTypeDef */
  sliceSet?: SliceSet;
  incidentType: IncidentType;
}

export enum IncidentType {
  unset = "unset",
  "real_time" = "real_time",
  accelerated = "accelerated",
  UNRECOGNIZED = "UNRECOGNIZED"
}

export interface RawAlertOperationlize {
  count: AlertOperationlize[];
}

export interface AlertOperationlize {
  count: string;
  groupBy: AlertGroupByOperationalze;
  url: string;
}

export interface AlertGroupByOperationalze {
  opConfigId: string;
  alertStatus: AlertStatus;
}

type TimeSeriesState = "stopped" | "stoppedNow" | "active";

export interface MTSIncidentSummaryLine {
  lastSeenSec: number;
  ts: TimeSeries;
  ranges: SummarySpan[];
  mtsId: string;
  state?: TimeSeriesState;

  eventCount?: number;
  discoveryTimeSec?: number;
}

export interface Incident {
  id: string;
  tenantId: string;
  opConfigId: string;
  alertStatus: AlertStatus;
  startTimeMillis: number;
  endTimeMillis: number;
  eventFieldSummary?: EventFieldsSummary;
  timeSeriesSummary?: TimeSeriesSummary;
  seriesChangeSummary?: SeriesChangeSummary;
  timeOutlierSummary?: TimeOutlierSummary;
  seriesOutlierSummary?: SeriesOutlierSummary;
  trendSummary?: TrendSummary;
  multiDimSummary?: MultiDimSummary;
}

export interface SeriesChangeSummary {
  seriesChangeSummarycomponents: MTSIncidentSummaryLine[];
}

export interface TimeOutlierSummary {
  outliers: TimeOutlier[];
}

export interface TimeOutlierComponent {
  outlierTime: any;
  value: number;
  lowerBound: number;
  upperBound: number;
}

interface TimeOutlier extends Partial<MTSIncidentSummaryLine> {
  outliers: TimeOutlierComponent[];
  fingerprint: string;
}

export interface SeriesOutlierSummary {
  outliers: SeriesOutlier[];
}

export interface SeriesOutlier {
  ts: TimeSeries;
  peers: SeriesOutlierComponent[];
  fingerprint: string;
  mtsId: string;
  ranges: SummarySpan[];
  state: TimeSeriesState;
}

export interface SeriesOutlierComponent {
  component: SeriesOutlierComponentComponent | boolean;
  condition: MatchingCondition;
}

export type SeriesOutlierComponentComponent = {
  series?: TimeSeriesList;
  all?: boolean;
};

interface TimeSeriesList {
  ts: Record<string, TimeSeries>;
}

export interface EventFieldsSummary {
  events: EventSummary[];
}

interface MatchingCondition {
  seriesCondition: SeriesMatchingCondition;
  eventMatchingCondition: EventMatchingCondition;
}

interface SeriesMatchingCondition {
  tags: {
    tag: string[];
  };
  allTagsMatch: boolean;
  matchAllExcept: {
    tag: string[];
  };
}

interface EventMatchingCondition {
  eventFieldToTagName: Record<string, string>;
}

interface TrendSummary {
  kpiId: string;
  trendingUp: TrendSeries[];
  TrendingDown: TrendSeries[];
}

interface TrendSeries {
  series: TimeSeries;
  slope: number;
  volume: number;
  mtsId: string;
}

interface MultiDimSummary {
  kpiId: string;
  increasing: MultiDimSeries[];
  decreasing: MultiDimSeries[];
}

interface MultiDimSeries {
  series: TimeSeries;
  total: number;
  previousTotal: number;
  percentChange: number;
  score: number;
  mtsId: string;
}

export interface EventSummary {
  eventId: string;
  /** @deprecated */
  fields: EventField[];
  eventFields: Record<string, EventField>;
  field?: EventField;
  lowerBound: number;
  upperBound: number;
  predicateValue: number;
  eventTimeInMillis: number;
  startTimeMicros: number;
  userService: string;
  duration: number;
  groupingId: string;
  lowerBoundActualValue: number;
  upperBoundActualValue: number;
  peerGroup: EventField[];
}

export interface EventSummaryEventFieldsEntry {
  key: string;
  value?: EventField;
}

export interface EventField {
  name: string;
  value: string;
  type: string;
  entityType: string;
  subType: string;
  complexObjectType: string;
  values: string[];
}

export interface TimeSeriesSummary {
  ranges: SummarySpan[];
  linesByTimeSeries: MTSIncidentSummary[];
  lastSeenSec: number;
}

export interface TimeSeries {
  metricName: string;
  tags: Record<string, string>;
  aggTks: string[];
}

export interface TimeSeriesTagsEntry {
  key: string;
  value: string;
}

export interface MTSIncidentSummary {
  /** main */
  summaryLine: MTSIncidentSummaryLine;
  /** child lines TODO: applicable only for compare incident */
  line?: MTSIncidentSummaryLine[];
}

export interface SummarySpan {
  windowStartSec: number;
  anomScoreSum: number;
  negativeAnomScoresCount: number;
  positiveAnomScoresCount: number;
  maxDeviationFromPredicted: number;
  maxPositiveDeviationFromPredicted: number;
  maxNegativeDeviationFromPredicted: number;
  maxDeviationFromBounds: number;
  maxPositiveDeviationFromBounds: number;
  maxNegativeDeviationFromBounds: number;
  startSec: number;
  endSec: number;
}
