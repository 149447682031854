import React, { useMemo } from "react";
import { Radio, RadioChangeEvent, RadioProps } from "antd";
import { omit } from "lodash";
import { cx } from "emotion";
import { IncInfoIcon } from "../../../icons";
import { generateId } from "../../../utils";
import { IncToolTip } from "../Tooltip";
import IncRadiobuttonProps from "./types";

const omitProps = ["label", "className", "name", "helpText", "helpTextId", "labelRenderer", "onChange"];

export const IncRadioButton: React.FC<IncRadiobuttonProps> = React.forwardRef<HTMLElement, IncRadiobuttonProps>(
  (props, ref) => {
    const { label: labelStr, disabled = false, className = "", name: pName, helpText, helpTextId, onChange } = props;

    const disabledClass = cx("radio-button--disabled");
    const name = useMemo(() => pName || generateId(), [pName]);

    const radioBtnclassNames = cx("radio-button", disabled ? disabledClass : "");

    const labelClassName = cx("inc-label-common", disabled ? "disabled" : "");

    const onChangeInternal = (evt: RadioChangeEvent) => {
      if (onChange) {
        onChange(evt as unknown as React.ChangeEvent<HTMLInputElement>, evt.target.checked);
      }
    };

    const radioBtnProps: Omit<RadioProps, "onChange"> = omit(props, omitProps);
    const radioButton = (
      <Radio
        {...radioBtnProps}
        className={radioBtnclassNames}
        id={name}
        name={name}
        onChange={onChangeInternal}
        ref={ref}
      />
    );

    let label = null;

    if (labelStr) {
      label = (
        <div className={labelClassName}>
          <label
            className="inc-flex-row inc-cursor-pointer"
            htmlFor={name}
          >
            {labelStr}
          </label>

          {(helpTextId || helpText) && (
            <IncToolTip
              placement="top-start"
              showArrow
              titleId={helpTextId}
              titleText={helpText}
            >
              <IncInfoIcon />
            </IncToolTip>
          )}
        </div>
      );
    }

    const classNames = cx("radio-button-container", className);

    return (
      <div
        className={classNames}
        onClick={disabled ? () => {} : (props.onClick as any)}
      >
        {radioButton}
        {label}
      </div>
    );
  }
);
