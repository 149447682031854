import { gql } from "@apollo/client";

import { Entity } from "../../../core";
import entityApiService from "../EntityApiService";
import { DateTime } from "../../../core/moment_wrapper";
import { I_BIZOUTCOME, I_API, I_COMPONENT, I_KPOD } from "./constants";
import { parseBusinessGraphEntityResponse } from "./utils";
import { ErrorType, RelationQueryPayload } from "./types";

class BusinessGraphApiService {
  private fetchData(entityType: string, startTime: DateTime, endTime: DateTime) {
    return entityApiService.fetchEntitiesByTypeId(entityType, startTime, endTime);
  }

  getRelationshipQuery({ entityIds, startTime, endTime }: RelationQueryPayload) {
    const query = gql`
      query entityLookup($entityIds: [String], $startTime: DateTime!, $endTime: DateTime!) {
        entityLookup(ids: $entityIds, timeRange: { st: $startTime, et: $endTime }) {
          name
          id
          properties(names: ["*"]) {
            name
            value
          }
          bo2api: relationship(type: "i_me2api") {
            name
            id
            properties(names: ["*"]) {
              name
              value
            }
            component2api: relationship(type: "i_component2api") {
              name
              id
              properties(names: ["*"]) {
                name
                value
              }
              component2ServiceInstance: relationship(type: "i_component2serviceInstance") {
                serviceInstance2Container: relationship(type: "i_kcontainer2serviceInstance") {
                  container2Pod: relationship(type: "i_kpod2kcontainer") {
                    name
                    id
                    properties(names: ["*"]) {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const variables = {
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      entityIds
    };

    return {
      query,
      variables,
      errorPolicy: "all" as ErrorType
    };
  }

  async getUserServices(startTime: DateTime, endTime: DateTime): Promise<Entity[]> {
    const response = await this.fetchData(`${I_API}`, startTime, endTime);

    return response?.data?.entities;
  }

  async getServices(startTime: DateTime, endTime: DateTime): Promise<Entity[]> {
    const response = await this.fetchData(`${I_COMPONENT}`, startTime, endTime);

    return response?.data?.entities;
  }

  async getBizOutcomes(startTime: DateTime, endTime: DateTime): Promise<Entity[]> {
    const response = await this.fetchData(`${I_BIZOUTCOME}`, startTime, endTime);

    return response?.data?.entities;
  }

  async getPods(startTime: DateTime, endTime: DateTime): Promise<Entity[]> {
    const response = await this.fetchData(`${I_KPOD}`, startTime, endTime);

    return response?.data?.entities;
  }

  async getEntitiesByType(entityType: string, startTime: DateTime, endTime: DateTime): Promise<Entity[]> {
    const response = await this.fetchData(entityType, startTime, endTime);

    return response?.data?.entities;
  }

  async getRelationForEntityIds(entityIds: string[], startTime: DateTime, endTime: DateTime): Promise<Entity[][]> {
    const query = this.getRelationshipQuery({
      entityIds,
      startTime: startTime.valueOf(),
      endTime: endTime.valueOf()
    });
    const response = await entityApiService.fetchData(query);
    const entities = parseBusinessGraphEntityResponse(response);

    return entities;
  }

  async getAllEntities(startTime: DateTime, endTime: DateTime): Promise<Entity[][]> {
    const entityPromiseArray = [I_BIZOUTCOME, I_API, I_COMPONENT, I_KPOD].map((entityType: string) =>
      this.getEntitiesByType(`${entityType}`, startTime, endTime)
    );
    const results: Entity[][] = await Promise.all(entityPromiseArray);

    return results;
  }
}

const businessGraphApiService = new BusinessGraphApiService();

export default businessGraphApiService;
