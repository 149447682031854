import { CurrencyType } from "@inception/ui";
import { LeftNavItemName } from "../../../../app/leftNav/options/constants";
import { TCCustomTimeRange } from "../time-range/types";

export interface TenantConfig {
  currency: CurrencyType;
  demoTenant: boolean;
  pocTenant: boolean;
  enableBizFlow: boolean;
  defaultTimezone?: string;

  useOnboardingHome: boolean;
  useBizProcessHome: boolean;
  defaultDrilldownVersion: TenantDrilldownVersion;
  showDemoInsights: boolean;
  suggestionsLimit: number;

  showSubVerticalSwitcher: boolean;

  leftNavPreset?: LeftNavItemName[];
  disableNewDashboardFilterPanel?: boolean;
  enableCalendarInLeftNavPanel?: boolean;
  enableDiscoverySandbox?: boolean;
  customTimeRanges?: TCCustomTimeRange[];
  showSampleDataCardBanner?: boolean;
  enableNewTriageSummary?: boolean;
  enableKpiBasedOnboarding?: boolean;
  enableRecipeInOp10zePreview?: boolean;
  enableDashboardShare?: boolean;
  enableAddEntityFilter?: boolean;
  enablePartnerFeatures?: boolean;
  enableChat?: boolean;

  blobStoreFileUploadTimeoutMins?: number;
  useOldNavigation?: boolean;

  // Temporary tenantFlag being constructed from tenant and feature flag
  useNewWidgetInBusinessCatalog?: boolean;
}

export enum TenantDrilldownVersion {
  v3 = "v3",
  v4 = "v4",
  v5 = "v5"
}

export interface UITenantConfig extends Omit<TenantConfig, "useOldNavigation"> {
  useNewNavigation: boolean;
}

export interface TenantConfigState {
  tenantConfig: UITenantConfig;
  isFetching: boolean;
}
