import { Role } from "../core";
import { FeatureActionsMap } from "./accessConfig";
import { Action, Feature } from "./features";

export default class AccessRegister {
  private accessBox: Map<string, any>;
  private CREATE_ACTION_ROLES = [Role.Admin, Role.Editor];
  private UPDATE_ACTION_ROLES = [Role.Admin, Role.Editor];
  private READ_ACTION_ROLES = [Role.Admin, Role.Editor, Role.Viewer];
  private DELETE_ACTION_ROLES = [Role.Admin, Role.Editor];

  constructor(features: Record<keyof typeof Feature, Record<keyof typeof Action, string[]>>) {
    this.accessBox = new Map();
    for (const [feature, actions] of Object.entries(features)) {
      if (actions) {
        this.registerFeature(feature as keyof typeof Feature, actions);
      }
    }
  }

  private getRoleActions(action: keyof typeof Action, subActions: string[]) {
    let actionRoles: string[] = [];

    if (action === "CREATE") {
      actionRoles = this.CREATE_ACTION_ROLES;
    } else if (action === "READ") {
      actionRoles = this.READ_ACTION_ROLES;
    } else if (action === "UPDATE") {
      actionRoles = this.UPDATE_ACTION_ROLES;
    } else if (action === "DELETE") {
      actionRoles = this.DELETE_ACTION_ROLES;
    }

    const roleActions = new Map();
    if (subActions?.length) {
      subActions.forEach(action => roleActions.set(action, actionRoles));
    }
    return roleActions;
  }

  private getFeatureRoleActions(featureActions: Record<keyof typeof Action, string[]>) {
    let actions = new Map();
    if (featureActions) {
      for (const [action, subActions] of Object.entries(featureActions)) {
        const roleActions = this.getRoleActions(action as keyof typeof Action, subActions);
        actions = new Map([...actions, ...roleActions]);
      }
    }
    return actions;
  }

  getAccesses() {
    return this.accessBox;
  }

  private registerFeature(feature: keyof typeof Feature, featureActions: Record<keyof typeof Action, string[]>) {
    const featureRoleActions = this.getFeatureRoleActions(featureActions);
    this.accessBox.set(feature, featureRoleActions);
  }
}

export const accessRegister = Object.freeze(new AccessRegister(FeatureActionsMap));
