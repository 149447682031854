import React, { useMemo } from "react";
import { cx } from "emotion";
import { generateId } from "../../utils";

export interface Tab {
  id: string;
  label?: string;
  subText?: string;
  icon?: JSX.Element;
  disable?: boolean;
}

interface IncTabsProps {
  tabs: Tab[];
  selectedTabId: string;
  onChange: (tabId: string) => void;

  className?: string;
  labelSize?: "regular" | "small";
  asPills?: boolean;
}

export const IncTabButtons: React.FC<IncTabsProps> = props => {
  const { tabs, selectedTabId, onChange, labelSize = "small", asPills = false, className: eClassName = "" } = props;

  const uid = useMemo(() => generateId(), []);

  const fontSizeLabel = labelSize === "small" ? "subtext" : "body";

  const className = cx("tab-button-container", eClassName, {
    "as-pills": asPills
  });

  const tabContainers = tabs.map(tab => {
    const { id, disable, icon, label, subText } = tab;

    const isTabSelected = selectedTabId === id;

    const key = `${uid}-${id}`;
    const className = cx("tab-button", {
      "tab-button--active": isTabSelected,
      "tab-button--disable": disable
    });
    const onClick = () => {
      if (!disable) {
        onChange(id);
      }
    };

    const iconClassName = cx("inc-flex-row", "inc-flex-center", {
      "width-100": !label && !subText
    });
    const labelClassName = cx("tab-label", {
      [`inc-text-${fontSizeLabel}-medium`]: isTabSelected,
      [`inc-text-${fontSizeLabel}`]: !isTabSelected
    });
    const subTextClassName = cx("tab-sub-label", {
      "inc-text-element": labelSize === "small",
      "inc-text-subtext": labelSize !== "small"
    });

    return (
      <div
        className={className}
        key={key}
        onClick={onClick}
      >
        {icon && <div className={iconClassName}>{icon}</div>}
        {Boolean(label || subText) && (
          <div className="label-wrapper">
            {Boolean(label) && (
              <div
                className={labelClassName}
                title={label}
              >
                {label}
              </div>
            )}
            {Boolean(subText) && (
              <div
                className={subTextClassName}
                title={subText}
              >
                {subText}
              </div>
            )}
          </div>
        )}
      </div>
    );
  });

  return (
    <div
      className={className}
      data-cy="toggle-bar"
    >
      {tabContainers}
    </div>
  );
};
