import { DataType } from "../types";

export const numericDataTypes: DataType[] = [
  "CURRENCY",
  "DURATION",
  "DURATION_MILLIS",
  "DURATION_SECS",
  "EPOCH",
  "EPOCH_MILLIS",
  "EPOCH_SECS",
  "LONG",
  "LIST_LONG",
  "DOUBLE",
  "LIST_DOUBLE"
];
