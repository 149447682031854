import { MetricAggregationsRecord, DataType, AggregationUIOptions } from "../data";
import { DataTypeManagerUtils } from "./ManagerUtils";
import { BicycleOOTBMetrics } from "./types";

type AggregationResponse = {
  aggOpts: AggregationUIOptions[];
  defaultAgg: AggregationUIOptions;
};

export class DataTypeAggregationManager extends DataTypeManagerUtils {
  private static readonly allAggs = [
    MetricAggregationsRecord.avg,
    MetricAggregationsRecord.sum,
    MetricAggregationsRecord.min,
    MetricAggregationsRecord.max,
    MetricAggregationsRecord.P25,
    MetricAggregationsRecord.P50,
    MetricAggregationsRecord.P90,
    MetricAggregationsRecord.P95,
    MetricAggregationsRecord.P99,
    MetricAggregationsRecord.count,
    MetricAggregationsRecord.contribution,
    MetricAggregationsRecord.distinctCount,
    MetricAggregationsRecord.last
  ];

  static getAggregationsByUIDataType(uiDataType: DataType, cardinality?: number): AggregationResponse {
    switch (uiDataType) {
      // Boolean data type
      case "BOOLEAN": {
        return {
          aggOpts: [
            MetricAggregationsRecord.count,
            MetricAggregationsRecord.distinctCount,
            MetricAggregationsRecord.contribution
          ],
          defaultAgg: MetricAggregationsRecord.count
        };
      }

      // Entity based data types
      case "ENTITY":
      case "LIST_ENTITY": {
        return {
          aggOpts: [
            MetricAggregationsRecord.count,
            MetricAggregationsRecord.distinctCount,
            MetricAggregationsRecord.contribution
          ],
          defaultAgg: MetricAggregationsRecord.count
        };
      }

      // Numeric based data types
      case "LONG":
      case "LIST_LONG":
      case "DOUBLE":
      case "LIST_DOUBLE":
      case "NUMBER": {
        return {
          aggOpts: [...this.allAggs],
          defaultAgg: MetricAggregationsRecord.avg
        };
      }

      case "PERCENT_1":
      case "PERCENT_100": {
        const cardinalityType = this.getCardinalityCategory(cardinality);
        switch (cardinalityType) {
          case "very-low":
          case "low":
          case "high":
            return {
              aggOpts: [
                MetricAggregationsRecord.count,
                MetricAggregationsRecord.distinctCount,
                MetricAggregationsRecord.contribution
              ],
              defaultAgg: MetricAggregationsRecord.count
            };
          case "very-high":
            return {
              aggOpts: [...this.allAggs],
              defaultAgg: MetricAggregationsRecord.avg
            };

          default: {
            return {
              aggOpts: [...this.allAggs],
              defaultAgg: MetricAggregationsRecord.avg
            };
          }
        }
      }

      case "CURRENCY":
      case "DURATION":
      case "DURATION_MILLIS":
      case "DURATION_SECS":
      case "EPOCH":
      case "EPOCH_MILLIS":
      case "EPOCH_SECS": {
        return {
          aggOpts: [...this.allAggs],
          defaultAgg: MetricAggregationsRecord.avg
        };
      }

      // Entity and string based data types
      case "GEOLOCATION":
      case "ZIP_CODE": {
        return {
          aggOpts: [
            MetricAggregationsRecord.count,
            MetricAggregationsRecord.distinctCount,
            MetricAggregationsRecord.contribution
          ],
          defaultAgg: MetricAggregationsRecord.count
        };
      }

      // String based data types
      case "STRING":
      case "LIST_STRING":
      case "DATE":
      case "DATETIME":
      case "URL":
      case "IP_ADDRESS":
      case "ADDRESS":
      case "TELEPHONE":
      case "EMAIL": {
        return {
          aggOpts: [...this.allAggs],
          defaultAgg: MetricAggregationsRecord.count
        };
      }

      default: {
        return {
          aggOpts: [...this.allAggs],
          defaultAgg: MetricAggregationsRecord.avg
        };
      }
    }
  }

  static getAggregationsForOOTBMetrics(ootbMetric: BicycleOOTBMetrics): AggregationResponse {
    if (ootbMetric === BicycleOOTBMetrics.errors || ootbMetric === BicycleOOTBMetrics.requestsCount) {
      return {
        aggOpts: this.allAggs.filter(a => a.type !== "distinctCount"),
        defaultAgg: MetricAggregationsRecord.count
      };
    } else {
      return {
        aggOpts: [...this.allAggs],
        defaultAgg: MetricAggregationsRecord.avg
      };
    }
  }
}
