import { BizIdProps } from "../../operationalise";
import { VizOption } from "../../chat";
import {
  UserServiceField,
  SliceSpec,
  BizField,
  PostAggProjection,
  OverTimeAgg,
  OverTagAgg,
  SliceSet,
  TimeObjUnit
} from "./common";
import {
  UserServiceFilterExpression,
  UserServiceFieldSliceSet,
  BinaryArithmeticOperator,
  ExpressionDef,
  MetricDefinition,
  WidgetConfig,
  AuditInfo,
  BizFieldPredicate,
  EventFieldQueryConfig,
  ExploreEntityFilter,
  MetricUserServiceFilters
} from "./exploreTypes";
import { UsageJourneyConfig } from "./usage-journey";
import { BizJourneyConfig } from "./biz-journey";

export interface BizDataQueryList {
  bizDataQuery: BizDataQuery[];
}

export interface BizDataQueryLabels extends Record<string, string> {
  name: string;
  entityTypeId?: string;
  eventTypeId?: string;
  chartType?: VizOption;
  timeShiftCompareUnit?: TimeObjUnit;
}

export type FunnelInfo = {
  journeyId: string;
  funnelId?: string;
  funnelConfig?: any;
};

export enum FunnelProjections {
  na = "na",
  dropOff = "dropOff",
  conversion = "conversion",
  funnel = "funnel"
}

export type FunnelSpec = {
  nodeIds: string[];
  funnelProjections: FunnelProjections;
  projections: PostAggProjection[];
  isSingleStatQuery: boolean;
  useEventCount: boolean;

  overTimeAgg?: OverTimeAgg;
  overTagAgg?: OverTagAgg;
  timeShiftCompare?: any;
  sliceSet?: SliceSet;
};

export interface BizDataQuery {
  id?: string; // widgetId
  cohortId?: string;
  dataQuery?: DataQueryConfig;
  buildingBlockConfig?: BuildingBlockConfig;
  widgetConfig?: WidgetConfig;
  funnelInfo?: FunnelInfo;
  funnelSpec?: FunnelSpec;

  sliceSpec: SliceSpec;

  labels?: BizDataQueryLabels;
  entityFilters?: ExploreEntityFilter[];
  metricUserServiceFilters?: MetricUserServiceFilters;
  isSpikePositive?: boolean;
  idProps?: BizIdProps; // added for Copilot catalog
  kpiId?: string;
}

export type FunnelFilters = {
  funnelInfo: FunnelInfo;
  funnelSpec: FunnelSpec;
};

export interface DataQueryConfig {
  name: string;
  description: string;
  sourceType: DataQuerySourceType;

  eventQueryConfig?: EventFieldQueryConfig;
  buildingBlocksConfig?: BuildingBlockConfig;
  metricConfig?: MetricDefinition;
  entityDataQueryConfig?: EntityDataQueryConfig;
  widgetConfig?: WidgetConfig;
  usageJourneyConfig?: UsageJourneyConfig;
  bizJourneyConfig?: BizJourneyConfig;

  opConfigIds: string[];
  createdBy: AuditInfo;
}

export enum DataQuerySourceType {
  na = "na",
  event = "event",
  buildingBlocks = "buildingBlocks",
  metric = "metric",
  widget = "widget",
  entity = "entity",
  usageJourney = "usage_journey",
  bizJourney = "biz_journey"
}

export interface BuildingBlockConfig {
  id: string;
  name: string;
  aggregator?: string; // PostAgg. Ex: Total Amount aggregated by SUM across all merchants

  cohortId?: string;
  buildingBlockDef?: BuildingBlockDef;
  buildingBlockExpDef?: BuildingBlockExprDef;

  bizIdProps?: BizIdProps;
}

export interface BuildingBlockFilterDef {
  filterExpressions?: UserServiceFilterExpression[];
  bizFieldPredicates?: BizFieldPredicate[];
}

export interface BuildingBlocksSliceDef {
  sliceSets?: UserServiceFieldSliceSet[];
  bizFields?: BizField[];
}

export interface BuildingBlockDef {
  aggregator: string;
  fieldConfig: FieldConfig;
  filters: BuildingBlockFilterDef;
  sliceDef: BuildingBlocksSliceDef;

  internalId?: string;
  name?: string;
}

export interface BuildingBlockExprDef {
  leftBuildingBlockDef: BuildingBlockConfig;
  op: BinaryArithmeticOperator;
  rightBuildingBlockDef: BuildingBlockConfig;
}

export interface FieldConfig {
  userServiceField?: UserServiceField;
  bizField?: BizField;
  fieldExprDef?: ExpressionDef;
}

export interface EntityDataQueryConfig {}
