import React from "react";
import { cx } from "emotion";

export interface CircleProgressBarProps {
  progress?: number;
  size?: number;
  indicatorWidth?: number;
  trackWidth?: number;
  indicatorColor?: string;
  trackColor?: string;
  indicatorCap?: "round" | "inherit" | "butt" | "square";
  label?: string;
  labelColor?: string;
  spinnerMode?: boolean;
  spinnerSpeed?: number;
  className?: string;
  hideProgressLabel?: boolean;
  smallProgress?: boolean;
}

const CircleProgressBar = (props: CircleProgressBarProps) => {
  const {
    size = 150,
    progress: pProgress = 0,
    trackWidth = 3,
    trackColor = "#3B444D",
    indicatorWidth = 3,
    indicatorColor = "#FF523B",
    indicatorCap = `round`,
    label = `Loading...`,
    labelColor = `#333`,
    spinnerMode = false,
    spinnerSpeed = 1,
    className = "",
    hideProgressLabel = true,
    smallProgress = false
  } = props;

  const progress = isNaN(pProgress) ? 0 : pProgress;

  const center = size / 2,
    radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  const hideLabel = size < 20 || !label.length || spinnerMode || hideProgressLabel ? true : false;
  const commonStyle = {
    height: size,
    width: size
  };
  const classes = cx("svg-pi-wrapper", className);

  return (
    <>
      <div
        className={classes}
        style={commonStyle}
      >
        <svg
          className="svg-pi"
          style={commonStyle}
        >
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className={`svg-pi-indicator ${spinnerMode ? "svg-pi-indicator--spinner" : ""}`}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
            strokeWidth={indicatorWidth}
            style={{ animationDuration: `${spinnerSpeed}s` }}
          />
        </svg>

        {!hideLabel && (
          <div
            className="svg-pi-label"
            style={{ color: labelColor }}
          >
            {/* <span className="svg-pi-label__loading">{label}</span> */}

            {!spinnerMode && (
              <span className={smallProgress ? "svg-pi-label__progress_small" : "svg-pi-label__progress"}>
                {`${progress > 100 ? 100 : progress}%`}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CircleProgressBar;
