import React, { FC } from "react";

interface Props {
  input: string;
  createPrefix?: string;
}

export const CreateLabelFormatter: FC<Props> = props => {
  const { input, createPrefix } = props;

  return (
    <div className="inception-select--create-option">
      {createPrefix} {input}
    </div>
  );
};
