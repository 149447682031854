import React, { FC } from "react";
import { Badge, BadgeProps } from "antd";

export interface IncBadgeProps extends BadgeProps {}

export const IncBadge: FC<IncBadgeProps> = props => {
  const { className, ...restProps } = props;
  const effClassName = `inc-badge ${className}`;

  return (
    <Badge
      {...restProps}
      className={effClassName}
    />
  );
};
