import { getInceptionTheme } from "@inception/ui";

export const traceLatencyHistogramColors: string[] = ["#264653", "#2A9D8F", "#E9C46A"];

export default function getChartColor(index: number, comparison?: boolean) {
  const themeSpecificChartColors = getInceptionTheme().chartColors;
  const { length } = themeSpecificChartColors;

  let i = comparison ? length - index - 1 : index;
  i = Math.abs(i) % length;
  return themeSpecificChartColors[i];
}

export function getNumChartColors() {
  const themeSpecificChartColors = getInceptionTheme().chartColors;
  const { length } = themeSpecificChartColors;
  return length;
}

export function getRandomColor() {
  const themeSpecificChartColors = getInceptionTheme().chartColors;
  const i = Math.floor(Math.random() * themeSpecificChartColors.length);
  return themeSpecificChartColors[i];
}

// const integrationTagColors: string[] = [
//   "#E0F9D7",
//   "#FCEACA",
//   "#CFFAFF",
//   "#F9E2D2",
//   "#F9BA8F",
//   "#FCE2DE",
//   "#BADFF4",
//   "#ffbf71",
//   "#F9D9F9",
//   "#DEDAF7",
//   "#B7DBAB",
//   "#F4D598",
//   "#F29191",
// ];

export function getIntegrationTagColor(index: number) {
  const themeSpecificChartColors = getInceptionTheme().chartColors;
  const i = Math.abs(index) % themeSpecificChartColors.length;
  return themeSpecificChartColors[i];
}
