export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Entity = {
  __typename?: "Entity";
  entityId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  discoveryTime?: Maybe<Scalars["String"]>;
  lastSeenTime?: Maybe<Scalars["String"]>;
  identifiers?: Property[];
  properties?: Maybe<Array<Maybe<Property>>>;
  systemProperties?: Maybe<Array<Maybe<Property>>>;
  relationship?: Maybe<Array<Maybe<Entity>>>;
};

export type EntityFilter = {
  type: "prop" | "name";
  key: string;
  value: string;
  op: "eq" | "regex" | "gt" | "lt";
};

export type EntityPropertiesArgs = {
  names: Array<Maybe<Scalars["String"]>>;
};

export type EntityRelationshipArgs = {
  type: Scalars["String"];
};

export type ObjectId = {
  type: Scalars["String"];
  id: Scalars["String"];
};

export enum Operator {
  Eq = "eq",
  Contains = "contains",
  Startswith = "startswith",
  Gt = "gt",
  Lt = "lt"
}

export type MapValueFormat = { key: string; value: string };

export type Property = {
  __typename?: "Property";
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
  mapValue?: MapValueFormat[];
  setValue?: any[];
};

export type PropertyFilter = {
  key: Scalars["String"];
  type: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
  op: Operator;
};

export type Query = {
  __typename?: "Query";
  entities?: Maybe<Array<Maybe<Entity>>>;
  entityLookup?: Maybe<Array<Maybe<Entity>>>;
  traffic?: Maybe<Array<Maybe<Traffic>>>;
  types?: Maybe<Array<Maybe<Type>>>;
};

export type QueryEntitiesArgs = {
  type: Scalars["String"];
  filter?: Maybe<Array<Maybe<PropertyFilter>>>;
  timeRange: EQTimeRange;
};

export type QueryEntityLookupArgs = {
  ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
  timeRange: EQTimeRange;
};

export type QueryTrafficArgs = {
  origin?: Maybe<ObjectId>;
  dest?: Maybe<ObjectId>;
  context?: Maybe<ObjectId>;
  trafficTypes: Array<Maybe<Scalars["String"]>>;
  timeRange: EQTimeRange;
};

export type QueryTypesArgs = {
  kind: Scalars["String"];
};

export type EQTimeRange = {
  st?: Maybe<Scalars["DateTime"]>;
  et?: Maybe<Scalars["DateTime"]>;
};

export type Traffic = {
  __typename?: "Traffic";
  id: Scalars["String"];
  type: Scalars["String"];
  src?: Maybe<Entity>;
  target?: Maybe<Entity>;
  context?: Maybe<Entity>;
};

export type Type = {
  __typename?: "Type";
  typeId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  typeName?: Maybe<Scalars["String"]>;
  desc?: Maybe<Scalars["String"]>;
};

export type EntityLookUpResponse = {
  data: {
    entityLookup: Entity[];
  };
};

export enum EQPredicateOperation {
  eq = "eq",
  lt = "lt",
  gt = "gt",
  contains = "contains",
  startsWith = "startsWith",
  exists = "exists",
  doesNotExist = "doesNotExist",
  or = "or",
  and = "and",
  regex = "regex",
  neq = "neq",
  ge = "ge",
  le = "le",
  notContains = "notContains",
  range = "range",
  notRegex = "notRegex"
}

export type EntityPropertySet = {
  values: string[];
};

export type EntityPropertyMap = {
  entries: Record<string, string>;
};

export type ObjectPropertyMap = {
  entries: Record<string, EntityPropertyValue>;
};

export type EntityPropertyValue = {
  longVal?: number;
  doubleVal?: number;
  stringVal?: string;
  booleanVal?: boolean;
  dateVal?: string;
  dateTimeVal?: string;
  mapValue?: EntityPropertyMap;
  setValue?: EntityPropertySet;
  objectMapVal?: ObjectPropertyMap;
};

export enum LogicalOperator {
  And = "logicalAnd",
  Or = "logicalOr"
}

interface QueryPredicateCommon {
  type: EntityFieldType;
  key: string;
  op: EQPredicateOperation;
}

interface SingleValueQueryPredicate extends QueryPredicateCommon {
  value: EntityPropertyValue;
}

interface RangeValueQueryPredicate extends QueryPredicateCommon {
  rangeValues: EntityPropertyValue[];
}

export type EntityQueryPredicate = SingleValueQueryPredicate | RangeValueQueryPredicate;

export type QueryPredicate = {
  type: "prop" | "displayName" | "systemProp";
  key: string;
  mapKey?: string;
  op: EQPredicateOperation;
  value?: EntityPropertyValue;
  rangeValues?: EntityPropertyValue[];
};

type LPredicate = {
  logicalPredicates: LogicalPredicate[];
};

type SPredicate = {
  simplePredicates: EntityQueryPredicate[];
};

export type LogicalPredicate = {
  operator: LogicalOperator;
} & (LPredicate | SPredicate);

export type EntityFieldType = "any" | "idProp" | "prop" | "systemProp" | "displayName";

export interface EntityProjection {
  fieldType: EntityFieldType;
  name: string;
}
