import React from "react";
import { useTheme } from "emotion-theming";
import { FormattedMessage } from "react-intl";

import { IncToolTip, InceptionTheme } from "../../";
import { IncInfoIcon } from "../../icons";
import { getErrorStyles } from "./styles";

interface Props {
  titleId: string;
  titleValues?: Record<string, string>;
  imageSrc?: string;
  additionalText?: string;
}

const Error: React.FC<Props> = (props: Props) => {
  const { titleId, imageSrc, additionalText, titleValues } = props;
  const theme: InceptionTheme = useTheme();
  const styles = getErrorStyles(theme);

  return (
    <div className={styles.errorContent}>
      {imageSrc && (
        <img
          alt="Error"
          className={styles.errorImage}
          src={imageSrc}
        />
      )}
      <div className={styles.errorText}>
        <FormattedMessage
          id={titleId}
          values={titleValues}
        />
        {additionalText && (
          <IncToolTip
            placement="top"
            titleText={additionalText}
            variant="error"
          >
            <IncInfoIcon />
          </IncToolTip>
        )}
      </div>
    </div>
  );
};

export default Error;
