import { cloneDeep, escape } from "lodash";
import { MQuery, MTSQuery, Range, STag, IncidentTimeSeries } from "../core";
import { EventField } from "../services/api/explore";
import {
  Query,
  AnalysisMetaData,
  AnalysisValue,
  CorrelatedIncident,
  BusinessFieldAnalysis
} from "../services/api/Triage";
import MQueryModel from "./MQueryModel";
import MTSQueryModel from "./MTSQueryModel";
import { EXCLUDE_TAGS } from "./AnalysisDataModel";

export const DEVIATION_TAG = "Deviation";

export class CorrelatedIncidentModel implements CorrelatedIncident {
  category: string;
  entityId: string;
  entityType: string;
  mqName: string;
  maxDeviationFromPredicted: number;
  tsid: string;
  mqId: string;
  incidentId: string;
  aggregatedAnomScore: number;
  ts: IncidentTimeSeries;
  tags: STag;
  ranges: Range[];

  query: Query;

  constructor(query: Query, incident: CorrelatedIncident) {
    incident = incident || ({} as CorrelatedIncident);
    const {
      category = "",
      entityId = "",
      entityType = "",
      mqName = "",
      maxDeviationFromPredicted,
      tsid = "",
      mqId = "",
      incidentId = "",
      aggregatedAnomScore,
      ts,
      ranges = []
    } = incident;

    this.entityId = entityId;
    this.entityType = entityType;
    this.category = category;
    this.mqName = mqName;
    this.maxDeviationFromPredicted = maxDeviationFromPredicted;
    this.tsid = tsid;
    this.mqId = mqId;
    this.incidentId = incidentId;
    this.aggregatedAnomScore = aggregatedAnomScore;
    this.ts = ts;
    this.tags = ts?.tags;
    this.ranges = ranges;

    this.query = query;
  }

  getIdTags(): string[] {
    return [];
  }

  getMTSQueryModel(): MTSQueryModel {
    const mts1 = Object.assign(cloneDeep(this.query.query), {
      tsId: this.tsid,
      mqId: this.mqId,
      idTags: this.getIdTags(),
      incidentId: this.incidentId,
      ranges: this.ranges as Range[]
    }) as MTSQuery;
    return new MTSQueryModel(mts1);
  }

  getMQueryModel(): MQueryModel {
    const { tags } = this;

    EXCLUDE_TAGS.map(key => delete tags[key]);
    const mQueryModel = new MQueryModel(this.query.query as MQuery);
    for (const [key, value] of Object.entries(tags)) {
      mQueryModel.addFilter(key, value, "=");
    }
    return mQueryModel;
  }
}

export class BusinessFieldAnalysisModel implements BusinessFieldAnalysis {
  fieldName: string;
  eventFields: {
    eventFields: EventField[];
  };
  analysis: {
    metadata: AnalysisMetaData;
    countvalues: AnalysisValue[];
    aggvalues: AnalysisValue[];
  };

  constructor(businessField: BusinessFieldAnalysis) {
    const { analysis, fieldName } = businessField || {};

    this.fieldName = fieldName;
    this.analysis = analysis;
  }

  getValues(): AnalysisValue[] {
    return this.analysis.countvalues;
  }

  getMetadata(): AnalysisMetaData {
    return this.analysis.metadata;
  }

  getName(): string {
    return this.fieldName.replace(/(fields|tags)\./g, "");
  }

  /**@deprecated */
  getSeries(ignoreMissingEntities = false) {
    const values: AnalysisValue[] = this.getValues();
    const dataPositive: number[] = [],
      categories: string[] = [],
      dataNegative: number[] = [];

    (values || []).forEach((val: AnalysisValue) => {
      const { positiveDeviationScore, negativeDeviationScore, value, entityName } = val;

      const category = escape(entityName || value);
      const addToSeries = ignoreMissingEntities ? category !== "__missing__" : true;

      if (addToSeries) {
        dataPositive.push(positiveDeviationScore * 100);
        dataNegative.push(-1 * negativeDeviationScore * 100);
        categories.push(category);
      }
    });

    return {
      dataPositive,
      dataNegative,
      categories,
      name: this.getName()
    };
  }

  getScoreAndDeviationSeries(ignoreMissingEntities = false) {
    const values: AnalysisValue[] = this.getValues();
    const scores: number[] = [],
      categories: string[] = [],
      deviations: number[] = [];

    (values || []).forEach((val: AnalysisValue) => {
      const { positiveDeviationScore, negativeDeviationScore, value, entityName, score } = val;

      const category = escape(entityName || value);
      const addToSeries = ignoreMissingEntities ? category !== "__missing__" : true;

      if (addToSeries) {
        const isPositiveDeviation = positiveDeviationScore > 0;
        const scoreValue = isPositiveDeviation ? score : -1 * score;
        const deviation = isPositiveDeviation ? positiveDeviationScore : -1 * negativeDeviationScore;

        scores.push(scoreValue);
        deviations.push(deviation * 100);
        categories.push(category);
      }
    });

    return {
      scores,
      deviations,
      categories,
      name: this.getName()
    };
  }
}
