import React, { FC } from "react";
import { IncChangeRenderer, IncChangeRendererProps } from "./IncChangeRenderer";

export type IncPercentRendererProps = Omit<IncChangeRendererProps, "changeType">;

export const IncPercentRenderer: FC<IncPercentRendererProps> = props => (
  <IncChangeRenderer
    {...props}
    changeType="percent"
  />
);
