import React, { FC, memo, useCallback } from "react";
import { IncListProps } from "@inception/ui";
import entityStoreApiService from "../../services/api/EntityStoreApiService";
import { EQPredicateOperation, EntityQueryPredicate } from "../../core";
import { EntityList, EntityListData } from "./EntityList";

interface Props {
  entityTypeName: string;
  entityTypeId: string;

  startTime: number;
  endTime: number;

  cohortId?: string;
  maxEntries?: number;
  title?: string;
  entityIdFilters?: string[];

  matchType?: "startsWith" | "contains";
  size?: IncListProps["size"];
  sortData?: IncListProps["sortData"];
}

export const AsyncEntityList: FC<Props> = memo(props => {
  const {
    entityTypeId,
    entityTypeName,
    startTime,
    endTime,
    title,
    cohortId,
    maxEntries = 50,
    matchType = "startsWith",
    size,
    sortData,
    entityIdFilters
  } = props;

  const onSearch = useCallback(
    async (searchStr: string) => {
      const predicates = getPredicates(searchStr, matchType);
      const searchResult = await entityStoreApiService.searchEntities(
        entityTypeId,
        startTime,
        endTime,
        cohortId,
        predicates,
        undefined,
        undefined,
        maxEntries,
        entityIdFilters
      );
      const entityIds: EntityListData["entityIds"] = [];
      const entityLookupData: EntityListData["entityLookupData"] = {};

      (searchResult || []).forEach(sr => {
        const { entityId, displayName } = sr.entity;
        entityIds.push(entityId);
        entityLookupData[entityId] = displayName;
      });

      return {
        entityIds,
        entityLookupData
      };
    },
    [cohortId, endTime, entityIdFilters, entityTypeId, matchType, maxEntries, startTime]
  );

  return (
    <EntityList
      entityTypeName={entityTypeName}
      onSearch={onSearch}
      searchable
      size={size}
      sortData={sortData}
      title={title}
    />
  );
});

const getPredicates = (searchStr: string, matchType: Props["matchType"]): EntityQueryPredicate[] => {
  if (!searchStr) {
    return [];
  }

  const op = matchType === "startsWith" ? EQPredicateOperation.startsWith : EQPredicateOperation.contains;

  return [
    {
      key: "displayName",
      op,
      value: {
        stringVal: searchStr
      },
      type: "displayName"
    }
  ];
};
