import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import { UserServiceFilterExpression, CorrelatedEventsRequest, CorrelatedEventsResponse } from "../types";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "../../../../core";
import alertApiService from "../AlertApiService";

export const useFetchCorrelatedEvents = (
  incidentId: string,
  op10zeId: string,
  startTimeMillis: number,
  endTimeMillis: number,
  userServiceFilterExpressions?: UserServiceFilterExpression[],
  bins?: number,
  eventFieldSearchList?: CorrelatedEventsRequest["eventFieldSearchList"],
  prefetchedData?: CorrelatedEventsResponse
) => {
  const initialState = useMemo<InitialState<CorrelatedEventsResponse, string>>(
    () => ({
      data: prefetchedData,
      error: null,
      isError: false,
      isFetching: false
    }),
    [prefetchedData]
  );

  const fetchFn = useCallback<FetchFn<CorrelatedEventsResponse, string>>(async () => {
    const result: FetchCallBackResult<CorrelatedEventsResponse, string> = {
      data: null,
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await alertApiService.getCorrelatedEvents(
        incidentId,
        op10zeId,
        startTimeMillis,
        endTimeMillis,
        userServiceFilterExpressions,
        bins,
        eventFieldSearchList
      );

      if (error) {
        result.isError = true;
        result.error = message;
      } else {
        result.isSuccess = true;
        result.data = data;
      }
    } catch (err) {
      result.isError = true;
      const axiosError = err as AxiosError;
      if (axiosError.isAxiosError) {
        result.error = axiosError.message;
      } else {
        result.error = err.message?.toString() || err.toString();
      }
    }

    return result;
  }, [bins, endTimeMillis, eventFieldSearchList, incidentId, op10zeId, startTimeMillis, userServiceFilterExpressions]);

  return useDataFetch(fetchFn, initialState, true);
};
