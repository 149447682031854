import React, { memo } from "react";
import IncButton from "../Button/Button";

type Props = {
  message: string | JSX.Element;
  onConfirm: () => void;
  onCancel: () => void;

  noOperation?: boolean;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;

  children?: React.ReactNode;

  size?: "md" | "sm";
};

export const IncInModalConfirmation = memo<Props>(props => {
  const {
    message,
    onCancel,
    onConfirm,
    cancelBtnLabel = "Cancel",
    confirmBtnLabel = "Confirm",
    noOperation = false,
    children,
    size = "md"
  } = props;

  const buttonSize = size === "md" ? "regular" : "small";
  const opClassName = `inc-flex-row inc-flex-center-horizontal${size === "md" ? " marginTp24" : " marginTp10"}`;
  const textClassName = size === "md" ? "inc-text-body-medium" : "inc-text-subtext-medium";

  return (
    <div
      className="inc-modal--confirmation"
      data-size={size}
    >
      <div className="inc-modal--confirmation-content">
        {Boolean(message) && <div className={textClassName}>{message}</div>}

        {children}

        {!noOperation && (
          <div className={opClassName}>
            <IncButton
              className="marginRt12"
              color="danger"
              onClick={onConfirm}
              size={buttonSize}
            >
              {confirmBtnLabel}
            </IncButton>

            <IncButton
              color="primary"
              onClick={onCancel}
              size={buttonSize}
            >
              {cancelBtnLabel}
            </IncButton>
          </div>
        )}
      </div>
    </div>
  );
});
