export interface TagFilter {
  tagK: string;
  tagV: string;
  operator: string;
}

export interface Filter {
  tagk: string;
  type: string;
  filter: string;
  matchCase: boolean;
  groupBy?: boolean;
  enable?: boolean;
  errors?: any;
}

export interface STag {
  [key: string]: string;
}

export interface TSQuery {
  metric: any;
  output: boolean;
  alias: any;
  id: any;
  agg: any;
  rate: string;
  dsInterval: any;
  //dsDisable: boolean;
  dsFn: any;
  filters: TagFilter[];
  //errors: any;

  query?: any;
}

export interface MQuery {
  delaySec: number;
  downsampleFn: string;
  dsIntervalSec: number;
  metric: string;
  rate: string;
  tagFilters: TagFilter[];

  target?: any; // Delete this after usage check;
  addFilter?: (key: string, value: string, operator: string) => void;
  processTarget?: (target: any, downsample: string, isEdit?: boolean) => void;
  getTimeseriesTarget?: () => any;
  getQueryString?: () => any;
  getLegendFormat?: () => string;
}

export interface Range {
  start: number;
  end: number;
  windowStartTS: number;
}

interface TitleTag {
  tagk: string;
  tagv: string;
}

export interface MTSQuery extends MQuery {
  tsId: string;
  ranges: Range[];
  idTags: string[];
  titleTags: TitleTag[]; // used to customize panel title.
  incidentId: string; // used to link an mts to a incident.
  mqId: string;
  anomalyType: string; // show in panel title.

  addRange: (winStart: number, start: number, end: number) => void;
  addRanges: (r: Range[]) => void;
  getMTSQueryString: () => string;
}

export const IDTAGS = "id.tags",
  SQBASED = "is.sq.based";

export interface MonitoredQuery {
  createdAt: number;
  createdBy: string;
  createdFrom: string;
  dataFreqSecs: number;
  hasActiveIncident: boolean;
  id: string;
  imConfig: Record<string, any>;
  incidents: Incident[];
  labels: string[];
  lastModifiedAt: number;
  lastModifiedBy: string;
  lastModifiedFrom: string;
  mqConfig: { dashLinks: any[] };
  mtsCount: number;
  name: string;
  orgId: number;
  properties: Record<string, any>;
  query: MQuery;
  queryText: string;
  status: string;
}

export interface Incident {
  end: number;
  id: string;
  incidentFeedback: Record<string, string | number | any>;
  missed: boolean;
  name: string;
  queryId: string;
  severity: string;
  severityFeedback: Record<string, string | number | any>;
  start: number;
  windowStartSecs: number;
  status: string;
  summary: string;
  tags: any[];
  mqConfig: MonitoredQuery;
  incidentCloseWindowStartSecs: number;
}

export interface IncidentRange extends Range {
  anomScoreSum: number;
  negativeAnomScoresCount: number;
  positiveAnomScoresCount: number;
  maxDeviationFromPredicted: number;
}

export interface IncludedIncident {
  mtsId: string;
  tsi: number;
  lastTS: number;
  ranges: IncidentRange[];
  includes: IncludedIncident[];
  series?: IncidentTimeSeries;
}

export type Tag = Record<string, string>;
export type AggTag = Tag[];

export interface IncidentTimeSeries {
  m: string;
  tags: Tag;
  aggTags: AggTag;
}

export interface IncidentSummary {
  tsi: number;
  lastTS: number;
  ranges: IncidentRange[];
  includes: IncludedIncident[];
  tsList: IncidentTimeSeries[];
}

export interface IncidentRange extends Range {
  windowStartTs: number;
  // this is a very bad move.
  // Ideally server should not be sending 2 different Range objects one with windowStartTS and other with windowStartTs
}

export interface IncidentGroupMember {
  anomalyType: string;
  groupId: string;
  groupType: "member";
  lastSeen: number;
  ranges: IncidentRange[];
  series: {
    aggregatedAnomScore: number;
    globalTags: string[];
    incidentId: string;
    maxDeviationFromPredicted: number;
    mqId: string;
    mqName: string;
    resourceTags: TagFilter[];
    tags: TagFilter[];
    ts: string;
    tsid: string;
  };
}
export interface IncidentGroup {
  groupId: string;
  groupKey: string[];
  groupType: "time";
  members: IncidentGroupMember[];
  ranges: IncidentRange[];
}

export interface IncidentGrouping {
  status: string;
  grouping: {
    requestId: string;
    tenantId: string;
    incidentGroups: IncidentGroup[];
  };
  incident: Incident;
}
