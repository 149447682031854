import { generateId } from "@inception/ui";
import { isEqual, unionBy } from "lodash";
import {
  BizDataQuery,
  BuildingBlockConfig,
  TimeObjUnit,
  UserServiceField,
  UserServiceFieldMetricConfigDefinition,
  UserServiceFieldSlice
} from "../../../../services/api/explore";
import {
  DiagnosticKPIs,
  GetDefaultDiagnosticKpiRequest,
  OpAnalysisCreationConfig,
  OpAnalysisType,
  OpBizDataQuery,
  operationaliseV2ApiService
} from "../../../../services/api/operationalise";
import { SCHEDULE_TYPES, UI_SCHEDULE_KEY } from "../../../constants";
import { logger } from "../../../../core";
import { EventIdFieldName, FieldPickerUtils } from "../../../../utils";

export const getDefaultAnalysisConfig = (): OpAnalysisCreationConfig => {
  const config: OpAnalysisCreationConfig = {
    compareToOffset: {
      unit: TimeObjUnit.weeks,
      value: 1
    },
    dimensionSetsToAnalyze: [],
    dimensionsToAnalyze: [],
    diagnosticFields: [],
    kpis: {},
    diagnosticKpis: {},
    rollingFreq: {
      unit: TimeObjUnit.weeks,
      value: 1
    },
    schedule: {
      schedule: {
        endTimeEpochSecs: null,
        startTimeEpochSecs: null,
        specificScheduleConfig: {
          hour: null,
          dayOfMonth: null,
          dayOfWeek: null,
          minute: {
            step: 1
          },
          month: null
        }
      },
      endOffset: null,
      startOffset: null,
      labels: {
        [UI_SCHEDULE_KEY]: SCHEDULE_TYPES.everyMinute
      }
    },
    suppression: [],
    templateFormat: "",
    templateDefStr: "",
    storyTemplates: [],
    analysisType: [OpAnalysisType.MULTI_DIM]
  };
  return config;
};

export const getDefaultBizDataQuery = (eventTypeId: string): BizDataQuery => {
  const buildingBlockConfigId = generateId();
  const buildingBlockConfig: BuildingBlockConfig = {
    id: buildingBlockConfigId,
    name: "Metric Name",
    aggregator: null,
    buildingBlockDef: {
      aggregator: "sum",
      fieldConfig: {
        userServiceField: {
          userServices: eventTypeId
            ? [
                {
                  userServiceEntityId: eventTypeId
                }
              ]
            : []
        } as UserServiceField
      },
      filters: {
        filterExpressions: []
      },
      sliceDef: {
        sliceSets: [
          {
            slices: []
          }
        ]
      }
    },
    bizIdProps: {
      primary: {
        bizEntityTypeId: "",
        eventTypes: {
          userServiceInfo: [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        }
      },
      secondary: {}
    }
  };

  const bizDataQuery: BizDataQuery = {
    sliceSpec: {
      selectorSpec: {
        filters: []
      },
      sliceSet: {
        slices: []
      },
      buildingBlockConfigId
    },
    buildingBlockConfig,
    idProps: {
      primary: {
        bizEntityTypeId: "",
        eventTypes: {
          userServiceInfo: [
            {
              userServiceEntityId: eventTypeId
            }
          ]
        }
      },
      secondary: {}
    }
  };

  return bizDataQuery;
};

export const overrideBizDataQuery = async (
  query: OpBizDataQuery,
  generateDemo?: boolean
): Promise<DiagnosticKPIs | string> => {
  try {
    if (generateDemo) {
      const bizDataQueryList = await Promise.resolve(getBizDataQueriesBasedOnExpressionMetric(query.bizDataQuery));
      const kpi = bizDataQueryList.map(bQuery => ({
        ...query,
        bizDataQuery: bQuery
      }));
      return {
        kpi
      };
    }
    const kpiRequest: GetDefaultDiagnosticKpiRequest = {
      kpi: query
    };
    const { data, error, message } = await operationaliseV2ApiService.overrideAnalysisKpi(kpiRequest);
    if (error) {
      logger.error("AnalysisConfig", "Error overriding kpi", message);
      return "Error overriding kpi";
    }
    return (
      data?.relatedKpis || {
        kpi: []
      }
    );
  } catch (error) {
    logger.error("AnalysisConfig", "Error overriding kpi", error);
    return "Error overriding kpi";
  }
};

export const getSetsOptionsFromKpis = (kpis: Record<string, OpBizDataQuery>): UserServiceFieldSlice[] => {
  const values: UserServiceFieldSlice[] = [];
  Object.values(kpis).forEach(kpi => {
    const { bizDataQuery } = kpi;
    const { widgetConfig } = bizDataQuery || {};
    if (widgetConfig) {
      const { dataDefinition, userServiceEntityId, bizEntityType } = widgetConfig;
      const { metrics } = dataDefinition || {};

      const metricDefinitions = Object.values(metrics || {}).filter(metric => metric.sourceType === "userServiceField");
      metricDefinitions.forEach(metricDefinition => {
        const definition = metricDefinition as UserServiceFieldMetricConfigDefinition;
        const { userServiceField } = definition.userServiceFieldMetricConfig;
        const isExist = values.find(val => isEqual(val.userServiceField, userServiceField));
        if (!isExist) {
          const tagName = FieldPickerUtils.getPromSanitizedUSFName(userServiceField);
          if (tagName) {
            values.push({
              tagName: tagName === EventIdFieldName ? bizEntityType || userServiceEntityId : tagName,
              userServiceField
            });
          }
        }
      });
    }
  });
  return unionBy(values, a => a.tagName);
};

export const getBizDataQueriesBasedOnExpressionMetric = (query: BizDataQuery): BizDataQuery[] => {
  const bizQueries: BizDataQuery[] = [];

  const { widgetConfig } = query;
  const { dataDefinition } = widgetConfig || {};
  const { metrics } = dataDefinition || {};
  const isExpressionExists = Object.values(metrics || {}).find(e => e.sourceType === "expression");

  if (isExpressionExists) {
    Object.keys(metrics || {}).forEach(metricKey => {
      const metric = metrics?.[metricKey];
      if (metric?.sourceType === "userServiceField") {
        bizQueries.push({
          ...query,
          sliceSpec: {
            ...query.sliceSpec,
            metricId: metricKey,
            buildingBlockConfigId: null
          },
          buildingBlockConfig: null,
          widgetConfig: {
            ...widgetConfig,
            dataDefinition: {
              fields: {},
              metrics: {
                [metricKey]: metric
              }
            },
            labels: {
              expressions: "{}"
            }
          }
        });
      }
    });
  }
  return bizQueries;
};

export const getUuidForAnalysisConfig = () => generateId();
