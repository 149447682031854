import { useMemo, useCallback } from "react";
import { FetchFn, useDataFetch, InitialState } from "../../../../core";
import { ResultCohort } from "../types";
import exploreApiService from "../ExploreApiService";

export const useFetchCohortList = (entityType: string, isPrefetched?: boolean) => {
  const initialState = useMemo<InitialState<ResultCohort[], string>>(
    () => ({
      data: null,
      error: "",
      isError: false,
      isSuccess: false,
      isFetching: false
    }),
    []
  );

  const fetchCohorts = useCallback<FetchFn<ResultCohort[], string>>(async () => {
    const result = {
      data: null as ResultCohort[],
      error: null as string,
      isError: false,
      isSuccess: false,
      isFetching: false
    };

    if (entityType && !isPrefetched) {
      try {
        result.isFetching = true;
        const cohortListResponse = await exploreApiService.listCohort(entityType);
        result.data = cohortListResponse.cohorts || [];
        result.isSuccess = true;
        result.isFetching = false;
      } catch (error) {
        result.error = error.message;
        result.isError = true;
        result.isFetching = false;
      }
    }

    return result;
  }, [entityType, isPrefetched]);

  return useDataFetch(fetchCohorts, initialState);
};
