export const getStringPossibleWidth = (str: string, fontSize: number, fontFamily?: string, fontWeight?: number) => {
  let canvas = document.getElementById("internal_size_measure_canvas") as HTMLCanvasElement;
  if (!canvas) {
    canvas = document.createElement("canvas");
    canvas.setAttribute("id", "internal_size_measure_canvas");
    document.body.appendChild(canvas);
  }

  const canvasContext = canvas?.getContext("2d");
  if (canvasContext) {
    canvasContext.font = `${fontWeight || 500} ${fontSize}px ${fontFamily || "sans-serif"}`;
    const metrics = canvasContext.measureText(str);
    return {
      width: Math.ceil(metrics.width),
      height: Math.ceil(metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent)
    };
  } else {
    return {
      width: 0,
      height: 0
    };
  }
};
