import { css, cx } from "emotion";

const cardCssClassName = css`
  background: #212b36 !important;
`;
export const analysisCardClassName = cx("inc-card-layout display-block width-100 marginBt16", cardCssClassName);

export const diagnosticKpiWrapperClass = css`
  background: #27313c;
  padding: 20px;
  border-radius: 8px;
  margin: 12px;
`;
export const diagnosticKpiChildClass = css`
  padding: 12px;
  border-radius: 4px;
  background: #313b45;
`;
