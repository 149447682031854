import { map } from "lodash";
import { DataFrame, DataFrameFieldType, Field, TimeSeries } from "../types";

const timeseriesToDataFrame = (
  data: TimeSeries,
  dataType: DataFrame["dataType"],
  subType: DataFrame["subType"]
): DataFrame => {
  let dataFrame: DataFrame;
  if (isTimeseries(data)) {
    const tsData = data as TimeSeries;
    const { datapoints, refId, target, tags = {}, eTags = tags, metricName } = tsData;

    const title = getTitle(target, tags);
    const timestampField: Field<number> = {
      id: `${refId}-timestamps`,
      name: "Timestamp",
      data: [],
      title: "Timestamp",
      type: DataFrameFieldType.time
    };

    const dataField: Field<number> = {
      id: `${refId}-values`,
      name: title,
      title: title,
      data: [],
      type: DataFrameFieldType.numeric
    };

    datapoints.forEach(dp => {
      const [value, timestamp] = dp;
      timestampField.data.push(timestamp);
      dataField.data.push(value);
    });

    dataFrame = {
      fields: [timestampField, dataField],
      id: refId,
      name: target,
      labels: tags,
      eLabels: eTags,
      metricName,
      dataType,
      subType
    };
  }
  return dataFrame;
};

export default timeseriesToDataFrame;

const isTimeseries = (data: any) => {
  if (typeof data === "object") {
    const keys = Object.keys(data);
    return keys.indexOf("target") !== -1 && keys.indexOf("datapoints") !== -1;
  }
  return false;
};

const getTitle = (target: string, tags: Record<string, string>): string => {
  const tagsStrArr = map(tags, (v, k) => `${k}: ${v}`);
  const tagsStr = tagsStrArr.join(", ");
  const tagsExist = tagsStrArr.length > 0;
  const prefix = tagsExist ? " { " : "";
  const suffix = tagsExist ? " }" : "";

  return target + prefix + tagsStr + suffix;
};
