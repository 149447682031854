import { useMemo, useCallback } from "react";
import { InitialState, useDataFetch, FetchFn, FetchCallBackResult } from "../../../../core";
import { AlertFilterResponses } from "../types";
import { operationaliseV2ApiService } from "../OperationaliseV2Api";

type Result = AlertFilterResponses["alertFilterResponses"];

export const useFetchIncidentsDataForMultipleOps = (
  opIds: string[],
  startTimeMillis: number,
  endTimeMillis: number,
  lazyFetch = false,
  includeSnapshots = false,
  includeLookupData = false,
  includeAdditionalData = false,
  includeActions = false,
  generateDemoData = false
) => {
  const initState = useMemo<InitialState<Result, string>>(
    () => ({
      data: {},
      error: null,
      isError: false,
      isFetching: !lazyFetch,
      isSuccess: false
    }),
    [lazyFetch]
  );

  const fetchFn = useCallback<FetchFn<Result, string>>(async () => {
    const resData: FetchCallBackResult<Result, string> = {
      data: {},
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await operationaliseV2ApiService.getIncidentsDataForMultipleOp10ze(
        opIds,
        startTimeMillis,
        endTimeMillis,
        includeSnapshots,
        includeLookupData,
        includeAdditionalData,
        includeActions,
        generateDemoData
      );

      if (error) {
        resData.error = typeof message !== "string" ? "Error fetching incidents" : message;
        resData.isError = true;
      } else {
        resData.isSuccess = true;
        resData.data = data?.alertFilterResponses || {};
      }
    } catch (err) {
      resData.error = err.toString();
      resData.isError = true;
    }

    return resData;
  }, [
    endTimeMillis,
    generateDemoData,
    includeActions,
    includeAdditionalData,
    includeLookupData,
    includeSnapshots,
    opIds,
    startTimeMillis
  ]);

  return useDataFetch(fetchFn, initState, lazyFetch);
};
