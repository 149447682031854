import { SelectorSpec } from "../../explore";

export type BizImpactConfig = {
  id: string;
  name: string;
  description: string;
  startTimeInSec: number;
  endTimeInSec: number;
  impact: ImpactDefinition;
};

export type ImpactDefinition = {
  type: ImpactDefImpactType[];
  baseLineChangeConfig: BaseLineChangeConfig;
};

export enum ImpactDefImpactType {
  NO_IMPACT = "NO_IMPACT",
  BASELINE_CHANGE = "BASELINE_CHANGE",
  BASELINE_EXCLUDE = "BASELINE_EXCLUDE",
  SUPPRESSION = "SUPPRESSION"
}

export type BaseLineChangeConfig = {
  type: BaselineChangeType;
  upperBoundChange: number;
  lowerBoundChange: number;
};

export enum BaselineChangeType {
  NA = "NA",
  DELTA = "DELTA",
  PERCENTAGE = "PERCENTAGE"
}

export type OpImpactConfig = {
  id: string;
  opId: string;
  bizImpactId: string;
  selectorSpec: SelectorSpec;
  description: string;
};
