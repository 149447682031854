import { IncidentGrouping } from "../../../core";
import datasoureApiManager from "../DatasourceApiService";
import { InceptionResponse } from "../types";
import { Outcome } from "./types";

const OUTCOME_ENTITY_TYPE = "i_bizoutcome";
interface OutcomeListResponse {
  data: Outcome[];
  status: string;
}

class OutcomeApiService {
  readonly url = "/api/ade/monitoredEntityConfig";
  async getList(): Promise<Outcome[]> {
    const response = await datasoureApiManager.getDefault().get(this.url, { entityType: OUTCOME_ENTITY_TYPE });
    const data = response.data as OutcomeListResponse;
    return data.data;
  }

  async get(entityId: string): Promise<Outcome> {
    const url = `${this.url}/${entityId}`;
    const response = await datasoureApiManager.getDefault().get(url, {});
    return response.data as Outcome;
  }

  async delete(entityId: string): Promise<InceptionResponse<any>> {
    const url = `${this.url}/${entityId}`;
    const response = await datasoureApiManager.getDefault().delete(url, {});
    return response.data as InceptionResponse<any>;
  }

  async post(data: Outcome): Promise<any> {
    const response = await datasoureApiManager.getDefault().post(this.url, data);
    return response.data;
  }

  async getCorrelatedIncidents(
    entityId: string,
    mqId: string,
    incidentId: string,
    mtsId: string,
    tags: Record<string, string>
  ): Promise<IncidentGrouping> {
    const groupingUrl = `${this.url}/${entityId}/${mqId}/incidents/${incidentId}/${mtsId}`;
    const response = await datasoureApiManager.getDefault().get<IncidentGrouping, any, any>(groupingUrl, {
      mtsTags: encodeURIComponent(JSON.stringify(tags))
    });
    return response.data;
  }

  async put(data: Outcome): Promise<any> {
    const url = `${this.url}/${data.entityId}`;
    const response = await datasoureApiManager.getDefault().put(url, data);
    return response.data;
  }
}

const outcomeApiService = new OutcomeApiService();

export default outcomeApiService;
