import { UserServiceField, UserServiceFieldWithMeta } from "../services/api/explore";
import { DataType } from "../core/data";
import { ENTITY_DATA_TYPE } from "./constants";

export const getUserFieldDatatype = (field: UserServiceField) => {
  if (field?.dataType === ENTITY_DATA_TYPE && field?.entityField?.propType && field.entityField.propType !== "NA") {
    return field.entityField.propType;
  }
  return field?.dataType;
};

const priorityList: Record<DataType, number> = {
  ENTITY: 1,
  LONG: 2,
  STRING: 3,
  BOOLEAN: 4,
  DATETIME: 5,
  DOUBLE: 6,
  MAP: 7,
  SET: 8,
  DATE: 9,
  LIST_STRING: 10,
  LIST_LONG: 11,
  LIST_ENTITY: 12,
  LIST_DOUBLE: 13,
  EMAIL: 14,
  GEOLOCATION: 15,
  URL: 16,
  ZIP_CODE: 17,
  DATE_STRING: 18,
  TELEPHONE: 19,
  ADDRESS: 20,
  IP_ADDRESS: 21,
  CURRENCY: 22,
  NUMBER: 23,
  EPOCH_MICROS: 24,
  EPOCH_MILLIS: 25,
  EPOCH_SECS: 26,
  DURATION_MILLIS: 27,
  DURATION_SECS: 28,
  PERCENT_1: 29,
  PERCENT_100: 30,
  EPOCH: 31,
  DURATION: 32,
  OBJECT_MAP: 33,
  ERROR: 34
};

export const compare = (x: UserServiceFieldWithMeta, y: UserServiceFieldWithMeta) => {
  const xDataType = x.userServiceField?.dataType as DataType;
  const yDataType = y.userServiceField?.dataType as DataType;

  if (priorityList[xDataType] < priorityList[yDataType]) {
    return -1;
  } else if (priorityList[xDataType] > priorityList[yDataType]) {
    return 1;
  }
  return 0;
};

export const isEntityField = (dataType: DataType) => {
  const entityDataTypes: DataType[] = ["LIST_ENTITY", "ENTITY"];
  return entityDataTypes.includes(dataType);
};
