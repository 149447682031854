import { Slice, BizField, UserServiceField, SelectorSpec } from "../../explore";
import { EntityPropertyValue } from "../../../../core";
import { FieldTransformationConfig } from "../../../../transformation/transform-config/TransformConfig";
import { ActionRunMode, BizIdProps, OpSchedule } from "./CommonTypesV2";

export enum ActionCategoryType {
  NOTIFY = "Notification",
  AUTOMATE = "Automate",
  REVERSEETL = "ReverseETL"
}

export enum ActionTriggerMode {
  on_incident = "on_incident",
  on_schedule = "on_schedule",
  on_event = "on_event"
}

export interface ParamValueTemplate {
  label?: string;

  // oneOf
  value?: EntityPropertyValue;
  templateId?: string;
  templateSection?: TemplateCanvasSection;
  templateCanvas?: TemplateCanvas;

  templateParamTokens?: Record<string, string>;

  fieldTransformationConfig?: FieldTransformationConfig<any>;
  actionTemplate?: ActionTemplate;
}

export type ElementPropertyValues = {
  propValue: Record<string, EntityPropertyValue>;
};

export interface TemplateCanvasSection {
  sectionId: string;
  sectionElementId: "rich-text-section" | "script-section" | "event-groupby-section" | "toolbar-section";
  isEmbeddedLoop?: boolean;
  type: SectionElementType;

  hasTwoColumn?: boolean;

  title?: string;
  content?: string;
  prop?: ElementPropertyValues;
  childElementProp?: Record<string, ElementPropertyValues>;

  title2?: string;
  content2?: string;
  prop2?: ElementPropertyValues;
  childElementProp2?: Record<string, ElementPropertyValues>;
}

export interface TemplateCanvas {
  header: TemplateCanvasSection[];
  section: TemplateCanvasSection[];
  footer: TemplateCanvasSection[];

  prop?: ElementPropertyValues;
  uiProps?: Record<string, string>;
}

export type SectionKey = "header" | "footer" | "section";

export interface ActionTemplate {
  notificationTemplate: NotificationActionTemplate;
}

export interface NotificationActionTemplate {
  subject: string;
  templateSections: TemplateSection[];
}

export interface TemplateSection {
  topDivider: boolean;
  sectionHeader: string;
  textDef?: string;
  tableDef?: string;
  bottomDivider: boolean;
}

export interface BizActionConfig {
  alertActionId?: string;
  alertActionConfig?: AlertActionConfig;
  actionVariablesLookUp: Record<string, BizActionVariable>;
  isDisabled?: boolean;
}

export interface BizActionVariableList {
  variables: BizActionVariable[];
}

export interface BizActionVariable {
  // oneOf
  userServiceField?: UserServiceField;
  slice?: Slice;
  bizField?: BizField;

  templateKey: string;
  sampleValues: string[];
  category: string;
  description: string;
}

export interface AlertActionConfig {
  actionId: string;
  name: string;
  alertActionConfigDef: AlertActionConfigDef;
  opConfigId?: string[];

  tags?: Record<string, string>;
}

export interface AlertActionConfigDef {
  // oneOf
  alertActionDef?: AlertActionDef;
  uberAlertActionDef?: UberAlertActionDef;

  idProps: BizIdProps;
}

export interface UberAlertActionDef {
  filter: string; // if merchant.country = India then do the bottom actions
  ifTrueDef: AlertActionConfigDef[];
  elseDef: AlertActionConfigDef[];
}

export enum AlertScheduleType {
  na = "na",
  instant = "instant",
  scheduled = "scheduled"
}

export interface AlertSchedule {
  type: AlertScheduleType;
  schedule: OpSchedule;
}

export interface MuteTimeRange {
  startTimeMillis?: number;
  endTimeMillis?: number;
}

export type ActionMuteSpec = {
  mute?: boolean;
  muteTimeRange: MuteTimeRange;
};

export type ActionEvalSpec = {
  excludeSeriesFilterList?: SeriesFilterList;
};

export type SeriesFilterList = {
  seriesFilters: SeriesFilter[];
};

export type SeriesFilter = {
  mtsId?: string;
  selectorSpec?: SelectorSpec;
  timeRange: MuteTimeRange;
};

export interface AlertActionDef {
  actionCategoryType: ActionCategoryType;
  sourceTypeId: string;
  integrationActionConfigId: string;
  connectorId: string;
  actionRunMode?: ActionRunMode;
  params: Record<string, ParamValueTemplate>;
  staticContext: Record<string, EntityPropertyValue>;
  actionMuteSpec?: ActionMuteSpec;
  actionTriggerMode?: ActionTriggerMode;
}

export interface TableDef {
  columns: TableColumnDef[];
  rowLimit: number;
}

export interface TableColumnDef {
  headerName: string;
  staticPropValue?: EntityPropertyValue;
  fieldTransformationConfig?: FieldTransformationConfig<any>;
}

export enum SectionElementType {
  UNSET = "section_element_type_unset",
  RICH_TEXT = "rich_text",
  TOOLBAR = "toolbar",
  EVENT_GROUP_BY = "event_group_by",
  TABLE = "table"
}
