import React, { ComponentPropsWithRef } from "react";
import { cx } from "emotion";
import { useTheme } from "emotion-theming";
import { IncErrorIcon, IncFaIconName, IncGenericIcon, IncInfoIcon, ISaxIconProps } from "../../icons";
import { InceptionTheme } from "../../themes/types/theme";
import { IncToolTip } from "../antd-components";

interface IncTextAreaProps extends React.HTMLAttributes<HTMLTextAreaElement>, ComponentPropsWithRef<any> {
  label?: string;
  resize?: boolean;

  hasError?: boolean;
  errorText?: string; // pass error text to be shown on tooltip

  helpText?: string; // pass content to show on tooltip
  helpTextId?: string; // pass i18n id to show on tooltip

  containerClassName?: string;

  startIcon?: IncFaIconName | ISaxIconProps["iconName"];
  endIcon?: IncFaIconName | ISaxIconProps["iconName"];
  onEndIconClick?: () => void;
  endIconClassName?: string;
}

const IncTextArea = React.forwardRef<HTMLTextAreaElement, IncTextAreaProps>((props, ref) => {
  const {
    label,
    disabled = false,
    className = "",
    value,
    hasError,
    errorText,
    helpTextId,
    helpText,
    resize = true,
    containerClassName = "",
    startIcon,
    endIcon,
    onEndIconClick,
    endIconClassName = "",
    ...otherProps
  } = props;

  const theme: InceptionTheme = useTheme();

  const appliedTextInputClassName = cx(className, "input-text-area", {
    "input-text-area--has-start-icon": Boolean(startIcon),
    "input-text-area--has-end-icon": Boolean(endIcon),
    "inc-text-area-no-resize": resize,
    "inc-text-error": hasError
  });

  const textarea = (
    <textarea
      className={appliedTextInputClassName}
      disabled={disabled}
      ref={ref}
      value={value}
      {...otherProps}
    />
  );

  const appliedContainerClassName = cx("textarea-container", containerClassName);

  return (
    <div className={appliedContainerClassName}>
      {label && (
        <div className="input-label">
          {label}
          {(helpTextId || helpText) && (
            <IncToolTip
              placement="top-start"
              showArrow
              titleId={helpTextId}
              titleText={helpText}
            >
              <IncInfoIcon />
            </IncToolTip>
          )}
          {errorText && hasError && (
            <IncToolTip
              placement="top-start"
              showArrow
              titleText={errorText}
              variant="error"
            >
              <IncErrorIcon style={{ color: theme.inceptionColors.palette.R400 /*R400*/ }} />
            </IncToolTip>
          )}
        </div>
      )}

      <div className="inc-flex-row inc-flex-vertical-center">
        {startIcon && (
          <IncGenericIcon
            className="start-icon"
            iconName={startIcon}
          />
        )}
        {textarea}
        {endIcon && (
          <IncGenericIcon
            className={cx("end-icon", endIconClassName)}
            iconName={endIcon}
            onClick={onEndIconClick ? onEndIconClick : () => {}}
          />
        )}
      </div>
    </div>
  );
});

export default IncTextArea;
