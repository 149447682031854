import { debounce, isEmpty } from "lodash";
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UseGlobalFiltersInstanceProps } from "react-table";
import IncTextfield from "../../Textfield/TextField";
import { IncRTableProps } from "../props";
import { TableDataItem } from "../types";

interface Props<T extends TableDataItem> {
  config: IncRTableProps<T>["globalFilter"];
  globalFilter: string;
  filteredRows: UseGlobalFiltersInstanceProps<T>["preGlobalFilteredRows"];
  setGlobalFilter: UseGlobalFiltersInstanceProps<T>["setGlobalFilter"];
  children?: ReactElement<any>;
  isLoading: boolean;
}

const GlobalFilter = <T extends TableDataItem>(props: Props<T>) => {
  const {
    filteredRows,
    globalFilter: pGlobalFilter,
    setGlobalFilter: pSetGlobalFilter,
    config,
    children: actions,
    isLoading
  } = props;

  const inputTextRef = useRef<HTMLInputElement>(null);

  const {
    enabled = false,
    align = "right",
    hideLabel = false,
    hideStatus = false,
    placeholder = "",
    label = "Search",
    recordsStrFn = (filCount: number) => `${filCount} record(s) found`
  } = config || {};

  const [globalFilter, setGlobalFilter] = useState(pGlobalFilter);
  const className = `inc-table-global-filter ${align}`;

  useEffect(() => {
    setGlobalFilter(pGlobalFilter);
  }, [pGlobalFilter]);

  const debounceFilterUpdate = useMemo(
    () => debounce((newFilter: string) => pSetGlobalFilter(newFilter), 700),
    [pSetGlobalFilter]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || "";
      setGlobalFilter(value);
      debounceFilterUpdate(value);
    },
    [debounceFilterUpdate]
  );

  const onTextFilterReset = useCallback(() => {
    setGlobalFilter("");
    debounceFilterUpdate("");
  }, [debounceFilterUpdate]);

  const recordsStr = recordsStrFn(filteredRows.length);
  const showStatus = !isLoading && !hideStatus && !isEmpty(recordsStr);

  useEffect(() => {
    if (config?.focusTextInputOnLoad === true) {
      inputTextRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return enabled ? (
    <div className={className}>
      <div className="inc-table-global-filter--filter">
        {showStatus && <span className="filter-stats">{recordsStr}</span>}
        <IncTextfield
          label={hideLabel ? "" : label}
          onChange={onChange}
          placeholder={placeholder || ""}
          ref={inputTextRef}
          value={globalFilter}
          {...(globalFilter
            ? {
                endIcon: "xmark",
                onEndIconClick: onTextFilterReset
              }
            : {
                endIcon: "search"
              })}
        />
      </div>
      {actions}
    </div>
  ) : (
    <></>
  );
};

export default GlobalFilter;
