/**
 * This a wrapper on Intl's DateTimeFormat.
 * Refer to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat for options
 */

import { FormatDateOptions } from "react-intl";

export type IncDateFormatOptions = Pick<FormatDateOptions, "dateStyle" | "day" | "month" | "weekday" | "year">;

export type IncTimeFormatOptions = Pick<
  FormatDateOptions,
  "hour" | "hourCycle" | "minute" | "second" | "timeStyle" | "hour12"
>;

export type IncDateTimeFormatOptions = IncDateFormatOptions & IncTimeFormatOptions;

export enum IncDateTimeFormat {
  /**
   * @description Displays short month and day. Eg: Tue, Apr 25, 2021
   */
  full = "full",

  /**
   * @description Displays short month only. Eg:  Apr 25, 2021
   */
  minimal = "minimal",

  /**
   * @description Displays date numerically. Eg: 25-01-2021
   */
  numeric = "numeric",
  /**
   * @description Displays date numerically. Eg: 25-01-2021
   */
  cohortNumericDate = "cohortNumericDate",
  /**
   * @description Displays date numerically. Eg: 25-01-2021
   */
  cohortNumericDateTime = "cohortNumericDateTime"
}

export enum IncHighchartsDateTimeFormat {
  /**
   * @description Displays time only. Eg: 11:30 or 11:30:20
   */
  timeOnly = "timeOnly",

  /**
   * @description Displays month, date, time and skips year. Eg: 25-05 11:30 or 25-05 11:30:20
   */
  monthDayTime = "monthDayTime",

  /**
   * @description Displays month, date,and skips year and time. Eg: 25-05
   */
  monthDay = "monthDay",

  /**
   * @description Displays month, date, short year and skips time. Eg: 25-05-21
   */
  monthDayYearTime = "monthDayYearTime",

  /**
   * @description Displays day in short format only. Eg: Mon, Tue
   */
  shortDayOnly = "shortDayOnly",

  /**
   * @description Displays month as month name, date,and skips year and time. Eg: May 01
   */
  monthDayDescriptive = "monthDayDescriptive"
}

export interface DateTimeOptions {
  /**
   * @description Whether to show seconds in the time. Defaults to false.
   */
  withSeconds?: boolean;

  /**
   * @description Whether to show milli seconds in the time. Defaults to false.
   */
  withMilliSeconds?: boolean;

  /**
   * @description The separator to be used for separating date and time.
   */
  dateTimeSeparator?: string;

  /**
   * @description The separator to be used for separating parts of date
   */
  dateSeparator?: string;

  /**
   *  Locale to be used for formatting the datetime.
   */
  locale?: string;
  /**
   *  Locale to be used for formatting the datetime.
   */
  i18nDisabled?: boolean;

  /**
   * @description Whether to skip the time. Defaults to false.
   */
  skipTime?: boolean;

  /**
   * @description Show relative date. Defaults to false.
   */
  relative?: boolean;
}
