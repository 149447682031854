import { Ace } from "ace-builds";
import { cx } from "emotion";
import { useTheme } from "emotion-theming";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InceptionTheme } from "../../themes/types/theme";
import IncEditorProps from "../Editor/types";
import IncEditor from "../Editor/Editor";
import { IncInfoIcon } from "../../icons";
import { IncToolTip } from "../antd-components";
import { getQueryBuilderStyles } from "./QueryBuilderStyles";
import Tokenize from "./Tokenize";
import { AceSnippet, QueryModel, TokenProp } from "./types";

interface QueryBuilderProps {
  label?: string;
  helpText?: string;
  containerClassName?: string;
  editorProps?: Partial<IncEditorProps>;
  query: string;
  onChange: (args: string) => void;
  onLoad?: (editor: Ace.Editor) => void;
  onBlur?: (arags: FocusEvent) => void;
  autoCompleter: QueryModel;
  mode?: string;
  placeholder?: string;
  onSubmitQuery: (args: string) => void;
  padding?: number;
  contentMargin?: number;
  contentMarginTop?: number;
  contentMarginBottom?: number;
  contentMarginLeft?: number;
  contentMarginRight?: number;
  autoCompletionDelay?: number;
  isDisabled?: boolean;
  editorClassName?: string;
  shouldForceUpdate?: boolean;
}

type NoopCb = (error: Error | null, results: AceSnippet[]) => void;
type Position = {
  column: number;
  row: number;
};

const defaultEditorProps = {
  minLines: 2,
  fontSize: 14,
  fontFamily: '"Inter", "Helvetica Neue", Arial, sans-serif',
  height: "40px",
  maxLines: Infinity,
  showGutter: false,
  enableSnippets: true,
  enableLiveAutocompletion: true,
  showLineNumbers: false,
  highlightActiveLine: false,
  setAutoScrollEditorIntoView: true,
  enableBasicAutocompletion: true
};

const QueryBuilder: React.FC<QueryBuilderProps> = (props: QueryBuilderProps) => {
  const {
    label,
    helpText,
    containerClassName,
    editorProps,
    query,
    onChange,
    onLoad,
    onBlur,
    autoCompleter,
    onSubmitQuery,
    mode,
    placeholder,
    autoCompletionDelay = 0,
    editorClassName,
    shouldForceUpdate
  } = props;

  const theme: InceptionTheme = useTheme();
  const styles = getQueryBuilderStyles(theme);
  const editorRef = useRef<Ace.Editor>(null);
  const [queryEntered, setQuery] = useState(query);
  const [update, forceUpdate] = useState(0);

  useEffect(() => {
    setQuery(props.query);
  }, [props.query]);

  useEffect(() => {
    if (shouldForceUpdate) {
      forceUpdate(state => state + 1);
    }
  }, [autoCompleter, forceUpdate, shouldForceUpdate]);

  const onInputChange = useCallback(
    (data: string) => {
      setQuery(data);
      onChange(data);
    },
    [setQuery, onChange]
  );

  const handleOnBlur = (event: FocusEvent) => {
    if (onBlur && typeof onBlur === "function") {
      onBlur(event);
    }
  };

  const onSuggestKeys = useMemo(
    () =>
      debounce(async (callback: NoopCb, prefix: string) => {
        const data = await autoCompleter.suggestKeys(prefix);

        callback(null, data);
      }, autoCompletionDelay),
    [autoCompleter, autoCompletionDelay]
  );

  const onSuggestValues = useMemo(
    () =>
      debounce(async (callback: NoopCb, currentToken?: TokenProp) => {
        const data = await autoCompleter.suggestValues(currentToken);

        callback(null, data);
      }, autoCompletionDelay),
    [autoCompleter, autoCompletionDelay]
  );

  const onSuggestOperators = useCallback(
    async (callback: NoopCb) => {
      const data = await autoCompleter.suggestOperators();

      callback(null, data);
    },
    [autoCompleter]
  );

  const onSuggestConditions = useCallback(
    async (callback: NoopCb) => {
      const data = await autoCompleter.suggestConditions(mode);

      callback(null, data);
    },
    [autoCompleter, mode]
  );

  const onQuerySubmit = (editor: Ace.Editor) => {
    if (editor) {
      onSubmitQuery(editor.getSession().getValue().trim());
    }
  };

  const checkToken = useCallback(
    (prevToken: TokenProp, prefix: string, search, callback: NoopCb, prevToPrevToken?: TokenProp) => {
      const tokenType = prevToken?.tokenType;

      if (tokenType === "CONDITION") {
        onSuggestKeys((error: Error | null, completions: AceSnippet[]) => {
          search(completions)(prefix, callback);
        }, prefix);
      } else if (tokenType === "OPERATOR") {
        onSuggestValues((error: Error | null, completions: AceSnippet[]) => {
          search(completions)(prefix, callback);
        }, prevToPrevToken);
      } else if (tokenType === "VALUE") {
        onSuggestConditions((error: Error | null, completions: AceSnippet[]) => {
          search(completions)(prefix, callback);
        });
      } else if (tokenType === "KEY") {
        onSuggestOperators((error: Error | null, completions: AceSnippet[]) => {
          search(completions)(prefix, callback);
        });
      } else {
        onSuggestKeys((error: Error | null, completions: AceSnippet[]) => {
          search(completions)(prefix, callback);
        }, prefix);
      }
    },
    [onSuggestKeys, onSuggestValues, onSuggestOperators, onSuggestConditions]
  );

  const onEditorLoad = useCallback(
    (editor: Ace.Editor) => {
      const padding = props.padding ?? 0;
      const marginTop = (props.contentMarginTop || props.contentMargin) ?? 0;
      const marginBottom = (props.contentMarginBottom || props.contentMargin) ?? 0;
      const marginLeft = (props.contentMarginLeft || props.contentMargin) ?? 0;
      const marginRight = (props.contentMarginRight || props.contentMargin) ?? 0;

      editor?.renderer.setPadding(padding);
      editor?.renderer.setScrollMargin(marginTop, marginBottom, marginLeft, marginRight);

      const completers = {
        identifierRegexps: [/[a-zA-Z_0-9.:$\-\u00A2-\uFFFF]/],
        getCompletions: debounce(
          (editor: Ace.Editor, session: Ace.EditSession, pos: Position, prefix: string, callback: NoopCb) => {
            const document = session.getDocument();
            let lines = document.getAllLines();
            lines = lines.filter(line => line);
            let line = lines.join(" "); // [pos.row];

            const posInline =
              lines.length > 1
                ? lines.reduce((acc: number, curr: string, index: number) => {
                    if (index < pos.row) {
                      return acc + curr.length + pos.column;
                    }
                    return acc;
                  }, 0)
                : pos.column;

            line = line.substring(0, posInline);
            const tokenize = new Tokenize(line);
            // current token -> token created at current position of cursor
            const currentToken = tokenize.getTokenAtCursorPosition(posInline);
            // previous token -> token at cursor position - current_text length;
            const previousToken = tokenize.getTokenAtCursorPosition(posInline - prefix.length);
            // token previous to previous token
            const previousToPreviousToken = tokenize.getPreviousTokenAtCursorPosition(posInline);
            // console.log('Line, Prefix, posInline, Current Token, Previous Token, Previous Previous Token: ',
            //   line, prefix, posInline, currentToken, previousToken, previousToPreviousToken);

            const search = (completions: AceSnippet[]) => (token: string, callback: NoopCb) => {
              const filteredSnippets = (completions || []).filter((completion: AceSnippet) => completion.caption);
              const resultSnippets = filteredSnippets.map((snip: AceSnippet) => snip);

              callback(null, resultSnippets);
              if (autoCompleter.getMaxWidthOfSuggestedSnippet) {
                const width = autoCompleter.getMaxWidthOfSuggestedSnippet(completions);

                if ((editor as any)?.completer) {
                  const popup = (editor as any)?.completer?.popup;

                  if (popup?.container) {
                    popup.container.style.width = `${width}px`;
                    popup.resize();
                  }
                }
              }
            };

            if (prefix) {
              if (!previousToken) {
                onSuggestKeys((error: Error | null, completions: AceSnippet[]) => {
                  search(completions)(prefix, callback);
                }, prefix);
              } else {
                checkToken(previousToken, prefix, search, callback, previousToPreviousToken);
              }
            } else {
              checkToken(currentToken, prefix, search, callback, previousToPreviousToken);
            }
          },
          autoCompletionDelay
        )
      };

      editor.completers = [{ ...completers }];
      if (onLoad && typeof onLoad === "function") {
        onLoad(editor);
      }
    },
    [
      autoCompleter,
      autoCompletionDelay,
      checkToken,
      onLoad,
      onSuggestKeys,
      props.contentMargin,
      props.contentMarginBottom,
      props.contentMarginLeft,
      props.contentMarginRight,
      props.contentMarginTop,
      props.padding
    ]
  );

  const containerClass = cx(editorClassName ? "" : styles.queryContainer, containerClassName);
  const mergedEditorProps = {
    ...defaultEditorProps,
    ...editorProps
  };

  return (
    <>
      <div
        className="inc-flex-column inc-flex-grow textfield-container"
        key={update}
      >
        {label && (
          <div className="input-label inc-flex-row">
            {label}
            {helpText && (
              <IncToolTip
                placement="top-start"
                showArrow
                titleText={helpText}
              >
                <div>
                  <IncInfoIcon />
                </div>
              </IncToolTip>
            )}
          </div>
        )}

        <div className={containerClass}>
          <IncEditor
            className={cx(styles.editor, "query-editor", editorClassName)}
            commands={[
              {
                name: "submitQuery",
                bindKey: {
                  win: "Ctrl-Enter",
                  mac: "Command-Enter"
                },
                exec: onQuerySubmit
              }
            ]}
            mode="text"
            onBlur={handleOnBlur}
            onChange={onInputChange}
            onLoad={onEditorLoad}
            placeholder={placeholder}
            value={queryEntered}
            {...mergedEditorProps}
            ref={editorRef}
            wrapEnabled
          />
        </div>
      </div>
    </>
  );
};

export default QueryBuilder;
