export const FieldPicker = {
  attributes: {
    fieldPickerPopup: (parentCy: string) => `${parentCy}-field-picker-popup`,
    bizEntityFieldsTable: "biz-entity-fields-table",
    bizEntityMetricsTable: "biz-entity-metrics-table",
    userServiceMetricsTable: "user-service-metrics-table",
    businessFieldsTable: "business-fields-table",
    performanceFieldsTable: "performance-fields-table",
    businessEntitiesTable: "business-entities-table",
    fieldPickerFieldsTable: "field-picker-fields-table"
  }
};
