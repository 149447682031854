import { LabelFragment, LowLevelFragment } from "../../chat";

export type OpCreationFragmentV2 = {
  name: string;
  description: string;
  summary: string;

  labelFragment: LabelFragment;
  lowLevelFragments: LowLevelFragment[];
  type: OpCreationFragmentType;
};

export type OpCreationFragmentV2List = {
  opFragments: OpCreationFragmentV2[];
};

export enum OpCreationFragmentType {
  UNSET = "UNSET",
  INSIGHT_GENERATION = "INSIGHT_GENERATION",
  INSIGHT_EXPLANATION = "INSIGHT_EXPLANATION",
  INSIGHT_ACTIONS = "INSIGHT_ACTIONS"
}
