import axios, { AxiosError, AxiosInstance } from "axios";
import { cloneDeep, isEmpty } from "lodash";
import qs from "query-string";
import { generateUUID } from "@inception/ui";
import appConfig from "../../../../appConfig";
import { logger } from "../../../core";
import { BaseApi, InceptionRequestConfig, InceptionResponse, UrlParams, CustomEventTypes } from "../types";
import { camelizeKeys } from "../utils";
import { mockResponseHandler } from "./MockResponseHandler";

export const apiConfig: Record<string, any> = {
  returnRejectedPromiseOnError: true,
  withCredentials: true,
  timeout: 2 * 60 * 1000, // 2 min
  common: {
    headers: {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  }
};

class BaseApiService implements BaseApi {
  protected api: AxiosInstance;
  private source: Record<string, any>;

  constructor(config?: InceptionRequestConfig) {
    this.api = axios.create(config);
    this.api.interceptors.request.use(this.requestInterceptors.bind(this));
    this.api.interceptors.response.use(
      this.responseSuccessInterceptors.bind(this),
      this.responseErrorInterceptors.bind(this)
    );
  }

  getCommonHeaders(): Record<string, string> {
    return this.api.defaults.headers || {};
  }

  setCommonHeader(header: string, value: string) {
    if (!isEmpty(header) && !isEmpty(value)) {
      this.api.defaults.headers = this.api.defaults.headers || {};
      this.api.defaults.headers[header] = value;
      logger.debug("BaseApiRequest", `Setting common header Header: ${header} Value ${value}`);
    } else {
      logger.error("BaseApiRequest", `Header/Value cannot be empty. Header: ${header} Value ${value}`);
    }
  }

  request<T, R = InceptionResponse<T>>(config: InceptionRequestConfig): Promise<R> {
    return this.api.request(config);
  }

  requestInterceptors(config: InceptionRequestConfig): Promise<InceptionRequestConfig> {
    // Setting unique request header
    config.headers = config.headers || {};
    config.headers[appConfig.incRequestIdHeader] = (generateUUID() as any).replaceAll("-", ""); // sending uuidv4 without hipen to conform to otel spec
    config.headers[appConfig.incReferrerHeader] = config.headers.referrer || window.location.href;

    if (appConfig.visitorId) {
      config.headers[appConfig.incVisitorIdHeader] = appConfig.visitorId;
    }

    if (isNaN(config.retryCount)) {
      config.retryCount = appConfig.maxRetries;
    }

    const logObject = {
      headers: config.headers,
      url: config.url,
      method: config.method,
      params: config.params,
      data: config.data,
      requestId: config.requestId
    };

    this.logger(logObject);
    return Promise.resolve(config);
  }

  responseSuccessInterceptors<T>(response: InceptionResponse<T>) {
    return this.success(response);
  }

  responseErrorInterceptors(error: AxiosError<Error>) {
    return this.error(error);
  }

  async get<T, B = UrlParams, R = InceptionResponse<T>>(
    url: string,
    payload?: B,
    config?: InceptionRequestConfig,
    encode = false
  ): Promise<R> {
    const { response, handled } = await mockResponseHandler.get<T, R>(url, config);
    if (handled) {
      return response;
    }

    url += !isEmpty(payload) ? `?${qs.stringify(payload, { encode: encode })}` : "";
    return this.api.get(url, config);
  }

  async delete<T, R = InceptionResponse<T>>(url: string, config?: InceptionRequestConfig): Promise<R> {
    const { response, handled } = await mockResponseHandler.delete<T, R>(url, config);
    if (handled) {
      return response;
    }

    return this.api.delete(url, config);
  }

  async post<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const { response, handled } = await mockResponseHandler.post<T, R>(url, config);
    if (handled) {
      return response;
    }

    return this.api.post(url, data, config);
  }

  async put<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const { response, handled } = await mockResponseHandler.put<T, R>(url, config);
    if (handled) {
      return response;
    }

    return this.api.put(url, data, config);
  }

  async patch<T, B, R = InceptionResponse<T>>(url: string, data?: B, config?: InceptionRequestConfig): Promise<R> {
    const { response, handled } = await mockResponseHandler.patch<T, R>(url, config);
    if (handled) {
      return response;
    }

    return this.api.patch(url, data, config);
  }

  cancelRequest(requestId: string | number) {
    if (this.source[requestId]) {
      this.source[requestId].cancel("Canceled");
    }
  }

  success<T>(response: InceptionResponse<T>): InceptionResponse<T> {
    if (response?.config?.camelizeResponse) {
      const cData = camelizeKeys(response.data as any);
      response.data = cData;
    }
    return response;
  }

  error(error: AxiosError<Error>) {
    const config = error.config as InceptionRequestConfig;
    if (appConfig.retryHttpErrorCodes.includes(error?.response?.status) && config.retryCount > 0) {
      const nConfig = {
        ...config,
        retryCount: config.retryCount - 1
      };
      return this.api.request(nConfig);
    }
    if (error?.response?.status === 401) {
      const isLoginRequired = false; //isEmpty(error.request.headers?.cookie);
      const unAuthorizedEvent = new CustomEvent(CustomEventTypes.globalError, {
        detail: {
          message: "Access denied. Please contact administrator",
          isLoginRequired
        }
      });
      window.dispatchEvent(unAuthorizedEvent);
    }
    throw error;
  }

  logger(payload: Record<string, any>) {
    logger.network("BaseApiRequest", JSON.stringify(payload));
  }
}

const baseApiService = new BaseApiService(cloneDeep(apiConfig));

export { baseApiService as request };
