import { AxiosError, AxiosResponse } from "axios";
import appConfig from "../../../../appConfig";
import { User } from "../../../../features/admin/users/types";
import { Org, Role } from "../../../core";
import { request } from "../base-api";
import { InceptionResponse } from "../types";
import datasourceApiManager from "../DatasourceApiService";
import {
  BootConfigPayload,
  AuthCheckResult,
  AllowedOrgsApiResponse,
  SupportLoginApiResponse,
  SupportLoginApiRequest
} from "./types";
import mockUiApiService from "./MockUIApiService";

export interface SsoAction {
  action: "error" | "redirect" | "none";
  message: string;
  // statusCode: number;
  redirectUrl?: string;
  orgNames?: string[];
}

export interface CsvDataPayload {
  headers: string[];
  rows: Array<{ [key: string]: string }>;
}

interface MigrationResult {
  data: number;
  error: string;
  errorJson: Record<string, string>;
}

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

export class UIApiService {
  readonly UI_SERVICE_URL: string = `${appConfig.baseUrl}/ui/api`;

  async fetchBootConfig(): Promise<BootConfigPayload> {
    try {
      const url = this.getUrl("/boot_config");
      const response = await request.get<BootConfigPayload>(url);
      return response.data;
    } catch (error) {
      if (appConfig.anomShareId) {
        const bootConfigPayload = mockUiApiService.fetchBootConfig();
        return bootConfigPayload;
      }
      throw error;
    }
  }

  async validateSSOLogin(username: string, redirectUrl: string, orgName?: string): Promise<AuthCheckResult> {
    const url = this.getUrl("/login/sso/validate");
    const params: Record<string, string> = {
      email: username,
      redirect_to: redirectUrl
    };

    if (orgName) {
      params["org"] = orgName;
    }

    let response: AuthCheckResult = {
      action: "error",
      message: "Invalid user. Contact Support.",
      orgs: null,
      preferredOrg: null,
      redirectUrl: null
    };
    try {
      const { data } = await request.get<AuthCheckResult, any>(url, params, {}, true);
      return data;
    } catch (err) {
      const axiosResponse = err.response as AxiosResponse<AuthCheckResult>;
      const isAxiosError = err.isAxiosError || false;
      if (!isAxiosError) {
        response.message = err.message || String(err);
      } else {
        const { status, data } = axiosResponse;
        response = data;
        const isRedirect = status === 307;
        const noSAML = status === 400;
        const invalidUser = status === 413;
        if (isRedirect) {
          response.action = "redirect";
          response.redirectUrl = data.redirectUrl;
        } else if (noSAML) {
          response.action = "error";
          response.message = data.message;
        } else if (invalidUser) {
          response.action = "error";
          response.message = data.message;
        } else {
          response.message = data?.message || axiosResponse.statusText;
        }
      }
    }

    return response;
  }

  switchUserOrg(targetOrg: string): Promise<InceptionResponse<SsoAction>> {
    const url = this.getUrl(`/user/switch/${targetOrg}`);
    return request.post<SsoAction, any>(url).catch((e: AxiosError<SsoAction>) => {
      throw e;
    });
  }

  async getAllowedOrgs(): Promise<Org[]> {
    const url = this.getUrl("/list_switch_orgs");
    const { data } = await request.get<AllowedOrgsApiResponse>(url);

    return data?.allowedOrgs || [];
  }

  async performSupportLogin(orgId: number, role: Role, durationSecs: number): Promise<SupportLoginApiResponse> {
    const url = this.getUrl("/support/login");
    const bodyPayload: SupportLoginApiRequest = {
      orgId,
      role,
      durationSecs
    };
    const { data } = await request.post<SupportLoginApiResponse, SupportLoginApiRequest>(url, bodyPayload);

    return data;
  }

  async migrateDashboards(): Promise<{
    data: number;
    error: any;
    errorJson: any;
  }> {
    const url = this.getUrl("/dashboards/migrate");
    try {
      const { data } = await request.get<MigrationResult>(url);
      return data;
    } catch (e) {
      return {
        data: 0,
        error: e.isAxiosError ? e.response.statusText : e.message,
        errorJson: e.isAxiosError ? e.toJSON() : e
      };
    }
  }

  performSSOLogin(ssoUrl: string): Promise<void> {
    window.location.href = ssoUrl;
    return Promise.resolve();
  }

  async getEventsAsCsvBlob(payload: CsvDataPayload): Promise<Blob> {
    const url = this.getUrl("/events_export");
    const response = await request.post(url, payload, { responseType: "blob" });
    return response.data as Blob;
  }

  async getUsersList(): Promise<Result<{ users: User[] }>> {
    const dataSourceManagerRequest = datasourceApiManager.getDefault();

    const url = "/api/tenants/users";
    const result: Result<{ users: User[] }> = {
      data: {
        users: []
      },
      error: false,
      message: ""
    };

    try {
      const response = await dataSourceManagerRequest.get<{ users: User[] }, any>(url);
      result.data = response.data;
      result.message = response.statusText;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async downloadTableWidgetCsv(columns: any[]): Promise<Result<string>> {
    const url = this.getUrl("/table_widget/export");
    const result: Result<string> = {
      data: "",
      error: false,
      message: ""
    };

    try {
      const response = await request.post<any, any>(url, { columns });
      const { data: csvData, errors } = response.data || {};

      result.data = csvData || "";
      result.error = Boolean(errors?.length);
      result.message = result.error ? errors.join("\n") : response.statusText;
    } catch (err) {
      result.error = true;
      result.message = err.response.data.message;
    }

    return result;
  }

  async logUIErrorStackTrace(payload: Record<string, any>) {
    const url = this.getUrl("/log-ui-web");
    const result: Result<string> = {
      data: "",
      error: false,
      message: ""
    };

    try {
      await request.post<any, any>(url, payload, {
        params: { logLevel: "error" }
      });
    } catch (err) {
      result.error = true;
      result.message = err.message;
    }

    return result;
  }

  private getUrl(path: string): string {
    return this.UI_SERVICE_URL + path;
  }
}

const uiApiService = new UIApiService();

export default uiApiService;
