import { isArray, isDate, isRegExp, keys, isObject } from "lodash";
import camelcase from "camelcase";

type MyObj = string | number | { [key: string]: string };

// recursively constructing the nested keys of an object to camelcase
const walk = (obj: MyObj) => {
  if (!isObject(obj)) {
    return obj;
  }
  if (isDate(obj) || isRegExp(obj)) {
    return obj;
  }
  if (isArray(obj)) {
    return map(obj, walk);
  }
  return reduce(
    keys(obj),
    (acc, key) => {
      const camel = camelcase(key);

      acc[camel] = walk(obj[key]);
      return acc;
    },
    {}
  );
};

const map = (xs: any[], f: (args: any, arg?: number) => any) => {
  if (xs.map) {
    return xs.map(f);
  }
  const res = [];

  for (let i = 0; i < xs.length; i++) {
    res.push(f(xs[i], i));
  }
  return res;
};

const reduce = (xs: any[], f: (args: any, ar: string, arg?: number) => any, acc: any) => {
  if (xs.reduce) {
    return xs.reduce(f, acc);
  }
  for (let i = 0; i < xs.length; i++) {
    acc = f(acc, xs[i], i);
  }
  return acc;
};

export const camelizeKeys = (obj: MyObj) => {
  if (typeof obj === "string") {
    return camelcase(obj);
  }
  return walk(obj);
};

export const isErrorResponse = (statusCode: number) => statusCode >= 400;
