import React, { FC, useMemo, memo, useCallback } from "react";
import { SubMenu, MenuItem, Menu, MenuButton, MenuGroup, ClickEvent, MenuChangeEvent } from "@szhsin/react-menu";
import { generateId } from "../../utils";
import { IncCheckbox } from "../antd-components";
import { IncMenuItem } from "./types";

interface Props {
  label: string | JSX.Element;
  menuClassName?: string;
  menuLabelClassName?: string;
  menuItemClassName?: string;
  subMenuClassName?: string;

  menuItems: IncMenuItem[];
  onMenuItemClick: (menuItem: IncMenuItem) => void;
  noItemsText?: string;
  skipCloseOnMenuSelect?: boolean;

  menuItemRenderer?: (menuItem: IncMenuItem) => JSX.Element;

  onMenuChange?: (isOpen: boolean) => void;
}

export const IncMenuV2: FC<Props> = props => {
  const {
    menuItems,
    onMenuItemClick,
    menuItemRenderer,
    menuClassName = "",
    menuLabelClassName = "",
    label,
    onMenuChange: pOnMenuChange,
    noItemsText = "No options"
  } = props;

  const uniqId = useMemo(() => generateId(), []);

  const menuItemsJSX = useMemo(() => {
    if (!menuItems?.length) {
      return (
        <div
          className="inc-menu-v2--item readonly inc-text-subtext-medium inc-text-inactive"
          style={{
            whiteSpace: "nowrap",
            padding: "8px 16px"
          }}
        >
          {noItemsText}
        </div>
      );
    }

    return menuItems.map((item, idx) => {
      const key = `${uniqId}-${idx}`;
      return (
        <MenuItemRenderer
          {...props}
          id={key}
          key={key}
          menuItem={item}
          onClick={onMenuItemClick}
          renderer={menuItemRenderer}
        />
      );
    });
  }, [menuItemRenderer, menuItems, noItemsText, onMenuItemClick, props, uniqId]);

  const menuButton = useMemo(() => {
    const labelClassName = ["inc-menu-v2--label", menuLabelClassName].join(" ");
    return <MenuButton className={labelClassName}>{label}</MenuButton>;
  }, [label, menuLabelClassName]);

  const onMenuChange = useCallback(
    (e: MenuChangeEvent) => {
      if (pOnMenuChange) {
        pOnMenuChange(e.open);
      }
    },
    [pOnMenuChange]
  );

  const className = `inc-menu-v2 ${menuClassName}`;
  return (
    <Menu
      className={className}
      menuButton={menuButton}
      onMenuChange={onMenuChange}
      overflow="auto"
      setDownOverflow
      theming="dark"
    >
      <MenuGroup takeOverflow>{menuItemsJSX}</MenuGroup>
    </Menu>
  );
};

type IProps = Pick<Props, "menuItemClassName" | "subMenuClassName" | "skipCloseOnMenuSelect"> & {
  menuItem: IncMenuItem;
  id: string;
  onClick: Props["onMenuItemClick"];
  renderer: Props["menuItemRenderer"];
};

const MenuItemRenderer: FC<IProps> = memo(props => {
  const {
    id,
    menuItem,
    onClick,
    renderer,
    menuItemClassName = "",
    subMenuClassName = "",
    skipCloseOnMenuSelect
  } = props;

  const { label, subMenuItems, asCheckBox, checked, indeterminate } = menuItem;

  const labelToRender = useMemo(() => (renderer ? renderer(menuItem) : label), [renderer, menuItem, label]);

  const handleClick = useCallback(
    (e: ClickEvent) => {
      if (asCheckBox || skipCloseOnMenuSelect) {
        e.stopPropagation = true;
        e.keepOpen = true;
      }
      onClick(menuItem);
    },
    [asCheckBox, menuItem, onClick, skipCloseOnMenuSelect]
  );

  const subMenu = useMemo(() => {
    if (subMenuItems?.length) {
      const subMenuItemsJSX = (subMenuItems || []).map((subMenuItem, idx) => {
        const key = `${id}-${idx}`;
        return (
          <MenuItemRenderer
            {...props}
            id={key}
            key={key}
            menuItem={subMenuItem}
          />
        );
      });

      const labelClassName = ["inc-menu-v2--item", menuItemClassName].join(" ");
      const className = ["inc-menu-v2--sub-menu", subMenuClassName].join(" ");

      const labelJsx = <div className={labelClassName}>{labelToRender}</div>;

      return (
        <SubMenu
          className={className}
          key={id}
          label={labelJsx}
          overflow="auto"
          setDownOverflow
        >
          <MenuGroup takeOverflow>{subMenuItemsJSX}</MenuGroup>
        </SubMenu>
      );
    }

    return null;
  }, [id, labelToRender, menuItemClassName, props, subMenuClassName, subMenuItems]);

  if (subMenu) {
    return subMenu;
  }

  const className = ["inc-menu-v2--item", menuItemClassName].join(" ");
  return (
    <MenuItem
      className={className}
      key={id}
      onClick={handleClick}
    >
      {asCheckBox && (
        <IncCheckbox
          checked={checked}
          className="readonly"
          indeterminate={indeterminate}
          noImplicitHeight
        />
      )}
      {labelToRender}
    </MenuItem>
  );
});
