import { uniq } from "lodash";
import { constructPayload } from "../services/api/traces/transformers";
import { traceApi } from "../services/api/traces/TraceApi";
import { DateTime } from "../core/moment_wrapper";
import { PREFIX_SUMMARY } from "../services/api/traces/constants";
import { IncStructure, StructureTag, IncStructureTag } from "../services/api/traces/types";
import { ENTITY, LIST_ENTITY } from "../utils/EntityUtils";
import { ValueType } from "../core";
import { eventFieldUtils } from "../utils/EventFieldUtils";

type TagValue = {
  key: string;
  name?: string;
  value?: any;
  valueType?: any;
};
type Tag = {
  key: string;
  value: any;
  type: any;
};

const timeInMicrosKeys = ["spentTime", "duration", "cpuTime"];
const timeToDate = ["startTime", "endTime"];

export interface IncTraceStructureModel extends TraceStructureModel {}

class TraceStructureModel {
  private currTraceData: any;
  private readonly entityTypes = [ENTITY, LIST_ENTITY];
  tagsMap: Map<string, TagValue> = new Map();
  structureKeysMap: Map<string, IncStructureTag> = new Map();

  setTraceData(data: any) {
    this.currTraceData = data;
  }

  getTraceData(): any {
    return this.currTraceData;
  }

  private findElementBykey(key: string, object: Array<Record<string, any>>) {
    return object.find((elm: Record<string, any>) => elm.key === key);
  }

  private getTagsInAllTraces(traces: any) {
    if (traces) {
      traces.forEach((trace: any) => {
        const { fields = [] } = trace;

        fields.forEach((tag: Tag) => {
          const { key, value, type } = tag;
          this.tagsMap.set(key, {
            name: key,
            key,
            value,
            valueType: type
          });
        });
      });
    }
    return this.tagsMap;
  }

  getAllTagsMap() {
    return this.tagsMap;
  }

  setAllTagsMap(traces: any) {
    this.getTagsInAllTraces(traces);
  }

  groupTagsByKey(data: any, key: string) {
    if (!data) {
      return;
    }
    const statusMap: Map<string, any> = new Map();

    data.forEach((trace: any) => {
      const { tags = [] } = trace;
      const tag = this.findElementBykey(key, tags);

      if (tag) {
        if (statusMap.get(tag.value)) {
          const tagsArray = statusMap.get(tag.value);

          tagsArray.push(tag);
          statusMap.set(tag.value, tagsArray);
        } else {
          const tagsArray = [];

          tagsArray.push(tag);
          statusMap.set(tag.value, tagsArray);
        }
      }
    });
    console.log("tags group: ", statusMap);
    return statusMap;
  }

  /**
   * @deprecated: planning to remove this in the next iteration
   */
  async getStructureData(query?: string, from?: DateTime, to?: DateTime, mode?: string) {
    const payload = constructPayload({
      type: "structure",
      query,
      from,
      to,
      mode
    });
    const structure = await traceApi.getTraceFields(payload);

    return structure;
  }
  /**
   * @deprecated: planning to remove this in the next iteration
   */
  async setStructureKeysMap(query?: string, from?: DateTime, to?: DateTime, mode?: string) {
    const data = await this.getStructureData(query, from, to, mode);
    this.initializeStructureKeyMaps(data);
  }

  getStructureKeysMap() {
    return this.structureKeysMap;
  }

  getStringTypeFields(structureMap: Map<string, StructureTag>): string[] {
    const stringFields: string[] = [];

    if (structureMap.size) {
      for (const [key, value] of structureMap.entries()) {
        const { type } = value;
        const typeLowerCase = type.toLowerCase();

        if (typeLowerCase === "string") {
          stringFields.push(key);
        }
      }
    }
    return stringFields;
  }

  initializeStructureKeyMaps(structureData: IncStructure): void {
    const { structure: { fields = [], globalFields = [], summary = [] } = {} } = structureData;
    this.structureKeysMap = new Map();
    for (const field of fields) {
      const { tagKey } = field;

      this.structureKeysMap.set(tagKey, {
        ...field,
        fieldType: "field",
        tagWithPrefix: eventFieldUtils.addFieldsPrefix(tagKey)
      });
    }
    for (const tag of summary) {
      const { tagKey } = tag;

      this.structureKeysMap.set(tagKey, {
        ...tag,
        fieldType: "summary",
        tagWithPrefix: PREFIX_SUMMARY + tagKey
      });
    }
    for (const tag of globalFields) {
      const { tagKey } = tag;

      this.structureKeysMap.set(tagKey, {
        ...tag,
        fieldType: "global",
        tagWithPrefix: tagKey
      });
    }
  }

  getEntityIds(keys: string[], item: { [key: string]: any }) {
    const entityIds: string[] = [];

    keys.forEach((key: string) => {
      if (Array.isArray(item[key])) {
        const tags = item[key];

        tags.forEach((tag: Tag) => {
          if (tag.key && this.checkIfTagIsTypeEntity(tag)) {
            entityIds.push(tag.value);
          }
        });
      } else if (item[key] && this.checkIfKeyIsTypeEntity(key)) {
        entityIds.push(item[key]);
      }
    });
    return entityIds;
  }

  getEntityIdsFromList(list: Array<{ [key: string]: any }>) {
    let entityIds: string[] = [];

    for (const item of list) {
      const keys = Object.keys(item) as string[];
      const ids = this.getEntityIds(keys, item);

      entityIds = [...entityIds, ...ids];
    }
    entityIds = entityIds.flat();
    return uniq(entityIds);
  }

  checkIfKeyIsTypeEntity(key: string) {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(key);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    if (!this.structureKeysMap.get(updatedKey)) {
      return false;
    }
    const keyVal: IncStructureTag = this.structureKeysMap.get(updatedKey);
    return this.entityTypes.includes(keyVal.type);
  }

  checkIfKeyIsTypeList(key: string) {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(key);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    if (!this.structureKeysMap.get(updatedKey)) {
      return false;
    }
    const keyVal: IncStructureTag = this.structureKeysMap.get(updatedKey);
    return keyVal.type === "LIST_ENTITY" || keyVal.type === "LIST_STRING";
  }

  checkIfTagIsTypeEntity(tag: Tag) {
    if ("type" in tag && tag.type !== undefined) {
      return this.entityTypes.includes(tag.type);
    }
    return this.checkIfKeyIsTypeEntity(tag.key);
  }

  // gets the type of entity from the tagKey
  // if key consists of entity type appended with a $ then this fn looks for the exact match in the structureKeyMap and returns the entityType
  getEntityTypeByKey(traceFieldName: string): string {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(traceFieldName);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    if (!this.structureKeysMap.get(updatedKey)) {
      return "";
    }
    const resultList: IncStructureTag[] = [];
    this.structureKeysMap.forEach((val, key, map) => {
      const type = map.get(key)?.type;
      if (key === updatedKey && this.entityTypes.includes(type)) {
        resultList.push(val);
      }
    });
    if (resultList.length > 1 && keyWithoutPrefix.includes("$")) {
      const entityTypeOfKey = keyWithoutPrefix.split("$")[1];
      const keyVal = resultList.find(x => x.tagKey === updatedKey && x.entityType === entityTypeOfKey);
      return keyVal?.entityType ?? resultList[0].entityType;
    } else {
      return resultList[0]?.entityType;
    }
  }

  getKeyType = (key: string): ValueType => {
    if (this.checkIfKeyIsTypeEntity(key)) {
      return ValueType.entityId;
    }
    if (timeInMicrosKeys.includes(key)) {
      return ValueType.timeInMicros;
    }
    if (timeToDate.includes(key)) {
      return ValueType.date;
    }
    return ValueType.string;
  };

  getDataTypeByKey(key: string) {
    return this.structureKeysMap.get(key)?.type;
  }

  getTagByKey(key: string) {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(key);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    return this.structureKeysMap.get(updatedKey);
  }

  getQueryKey(fieldName: string) {
    const field = this.structureKeysMap.get(fieldName);
    let key = field.tagKey;
    if (field.fieldType === "field" || field.fieldType === "summary") {
      key = eventFieldUtils.addFieldsPrefix(key);
    }
    if (field.entityType) {
      key += `$${field.entityType}`;
    }
    return key;
  }

  getTagsByEntityId(entityTypeId: string) {
    const tags: IncStructureTag[] = [];
    for (const tag of this.structureKeysMap.values()) {
      if (tag.entityType === entityTypeId) {
        tags.push(tag);
      }
    }
    return tags;
  }

  getTagsByDataTypes(dataTypes: string[]) {
    const tags: IncStructureTag[] = [];
    for (const tag of this.structureKeysMap.values()) {
      if (dataTypes.includes(tag.type)) {
        tags.push(tag);
      }
    }
    return tags;
  }

  isEntityField(tagKeyOrTag: string | StructureTag) {
    let tag: IncStructureTag;
    if (typeof tagKeyOrTag === "string") {
      tag = this.structureKeysMap.get(tagKeyOrTag);
    }
    return this.entityTypes.includes(tag?.type);
  }
}

export default TraceStructureModel;
