import { routerMiddleware, RouterState } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { TracesState } from "../features/traces/Analytics/types";
import { TitleState, TimeRangeState } from "../platform/core";
import { SchemaStore } from "../platform/core/hooks/schema-store/reducer";
import { AuthState, BootConfigState } from "../platform/login/state/types";
import { VerticalConfigState } from "../platform/core/hooks/vertical-config";
import { TenantConfigState } from "../platform/core/hooks/tenant-config";
import { createReducer, history } from "./rootReducer";

export interface ApplicationState {
  auth: AuthState;
  router: RouterState;
  traces: TracesState;
  title: TitleState;
  timeRange: TimeRangeState;
  schema: SchemaStore;
  tenantConfig: TenantConfigState;
  bootConfig: BootConfigState;
  verticalConfig: VerticalConfigState;
}

export default function configureStore(initialState: ApplicationState) {
  const composeEnhancers = composeWithDevTools({});
  const store: any = createStore(
    createReducer(),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history)))
  );

  store.injectedReducers = {};

  return store;
}
