import React, { ComponentPropsWithRef, FC, forwardRef } from "react";
import { cx } from "emotion";
import IncLoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { IncFaIcon, IncFaIconName } from "../../icons";

export type IncButtonSize = "regular" | "small";
export type IncButtonColor =
  | "primary"
  | "secondary"
  | "link"
  | "danger"
  | "secondary-red"
  | "secondary-green"
  | "secondary-blue"
  | "warning"
  | "brand-ai";
type IncButtonType = "button" | "reset" | "submit";
type IncButtonIcon = "icon" | "iconText";

/**
 * for regular size 'icon' button, icon must be of 24 x 24 dimensions
 * for regular size 'iconText' button, icon must be of 16 x 16 dimensions
 */

/**
 * for small size 'icon' button, icon must be of 16x16 dimensions
 * for small size 'iconText' button, icon must be of 14x14 dimensions
 */

interface IncButtonProps extends React.HTMLAttributes<HTMLElement>, ComponentPropsWithRef<any> {
  /**
   * The display label value
   */
  label?: string;
  /**
   * This has one of these values: "primary" | "secondary" | "link" | "danger" | "delete"
   */
  color: IncButtonColor;
  /**
   * Optional.
   * Value should be one of 'button' | 'reset' | 'submit' | string
   */
  type?: IncButtonType;

  /**
   * Optional.
   */
  disabled?: boolean;

  /**
   * Optional.
   * Value should be 'icon' if the button has only icon in it
   * Value should be 'iconText' if the button has icon and text in it
   */
  iconType?: IncButtonIcon;

  /**
   * Optional.
   * Value should be 'icon name' if the button has icon in it
   */
  iconName?: IncFaIconName;

  /**
   * Optional.
   * Value should be one of 'regular' | 'small'
   */
  size?: IncButtonSize;

  /**
   * Optional.
   * Value should be a boolean
   */
  loading?: boolean;

  /**
   * Optional.
   * Value is of type string
   * Default value is Loading...
   */
  loadingText?: string;
}

const IncButton: FC<IncButtonProps> = forwardRef<HTMLButtonElement, IncButtonProps>((props, ref) => {
  const {
    className,
    color,
    disabled,
    iconName,
    iconType = "",
    label,
    loading,
    loadingText,
    size = "regular",
    type,
    ...otherProps
  } = props;
  const iconTypeClassName = iconType ? `inc-button-${iconType}-${size}` : "";

  const appliedClassName = cx(
    className,
    `inc-button`,
    `inc-button-${color === "secondary" ? "secondary-blue" : color}`,
    `inc-button-${size}`,
    iconTypeClassName
  );

  return (
    <button
      className={appliedClassName}
      disabled={disabled || loading}
      ref={ref}
      type={type}
      {...otherProps}
    >
      {loading ? (
        <IncLoadingSpinner label={loadingText} />
      ) : (
        <>
          {iconName && (
            <IncFaIcon
              className="marginRt6"
              iconName={iconName}
            />
          )}
          {label}
          {props.children}
        </>
      )}
    </button>
  );
});

export default IncButton;
