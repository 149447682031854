import { isEmpty } from "lodash";
import { MTSQuery, Range } from "../core";
import MQueryModel from "./MQueryModel";

export default class MTSQueryModel extends MQueryModel implements MTSQuery {
  tsId: string;
  ranges: Range[];
  idTags: string[];
  titleTags: any; // used to customize panel title.
  incidentId: string; // used to link an mts to a incident.
  mqId: string;
  anomalyType: string; // show in panel title.

  constructor(model?: MTSQuery) {
    super(model);
    this.tsId = model ? model.tsId : null;
    this.ranges = model && model.ranges ? model.ranges : [];
    this.mqId = model ? model.mqId : null;
  }

  addRange(winStart: number, start: number, end: number) {
    this.ranges.push({
      windowStartTS: winStart,
      start: start,
      end: end
    });
  }

  addRanges(r: Range[]) {
    this.ranges.push(...r);
  }

  getMTSQueryString(): string {
    const tsid = this.tsId;
    //const operator = bootConfig.env === 'production' ? '==' : '=';
    const operator = "=";
    const interval = "[[__interval]]";
    const mtsQuery =
      `a=fetch('anomalies.${tsid}', align='${this.dsIntervalSec}s').tag_filter('__apptuit__model${operator}MonitoringBaseADModel')` +
      `.tag_filter('__apptuit__ts${operator}${tsid}').tag_filter('__apptuit__type${operator}lower|upper|anom-score|predicted'` +
      `).downsample('${interval}','avg').sum(by='__apptuit__type');\nb=1;\na=b.mult(a);\n` +
      `anomaly=fetch('anomalies.${tsid}', align='${this.dsIntervalSec}s').tag_filter('__apptuit__type${operator}anomaly'` +
      `).downsample('${interval}','avg').sum();\n` +
      `scored=fetch('anomalies.${tsid}', align='${this.dsIntervalSec}s').tag_filter('__apptuit__type${operator}original'` +
      `).downsample('${interval}','avg').sum();\n` +
      `anomaly=scored.mult(anomaly.div(anomaly), ignore="__apptuit__type");\n`;

    const outputQuery = `output(a, scored, anomaly);`;
    return `${mtsQuery}${outputQuery}`;
  }

  getIncidentQueryString(): string {
    if (isEmpty(this.tagFilters)) {
      return "";
    }
    const operator = "==";
    let incidentsQuery = `mtsincidents=fetch('incident').tag_filter('_apptuit_ts${operator}${this.tsId}')`;
    incidentsQuery += `.tag_filter("_apptuit_metricname${operator}${this.metric}")`;
    this.tagFilters.forEach(tagFilter => {
      incidentsQuery += `.tag_filter('${tagFilter.tagK}${operator}${tagFilter.tagV}')`;
    });
    incidentsQuery += `.downsample('[[__interval]]','avg');`;

    const outputQuery = `\noutput(mtsincidents);`;
    return `${incidentsQuery}${outputQuery}`;
  }

  getLegendFormat(incomingIdTags?: string[]): string {
    const tagKeys = incomingIdTags ? incomingIdTags : this.idTags;
    return super.getLegendFormat(tagKeys);
  }
}
