import React, { CSSProperties, FC, memo, SVGAttributes } from "react";
import { IncFaIcon, IncFaIconName, IncFaIconVariant } from "./font-awesome";
import { checkIfISaxIconExists, ISaxIcon, ISaxIconName, ISaxIconVariant } from "./iconsax";

interface Props extends Omit<SVGAttributes<SVGSVGElement>, "children" | "mask" | "transform"> {
  iconName: ISaxIconName | IncFaIconName;
  size?: number;
  variant?: ISaxIconVariant | IncFaIconVariant;
}

export const IncGenericIcon: FC<Props> = memo(props => {
  const { iconName, onClick, size = 16, className: pClassName = "", variant, style, ...restProps } = props;

  const appliedStyle: CSSProperties = {
    ...(style || {}),
    fontSize: size
  };

  const caseCheck = iconName[0] === iconName[0].toUpperCase();
  const isISaxIcon = caseCheck && checkIfISaxIconExists(iconName as ISaxIconName);
  const className = `inc-flex-row inc-flex-center-vertical inc-generic-icon ${pClassName}`;

  return (
    <div
      className={className}
      onClick={onClick as any}
    >
      {isISaxIcon && (
        <ISaxIcon
          iconName={iconName as ISaxIconName}
          variant={variant as ISaxIconVariant}
          {...restProps}
          size={size}
          style={appliedStyle}
        />
      )}
      {!isISaxIcon && (
        <IncFaIcon
          iconName={iconName as IncFaIconName}
          variant={variant as IncFaIconVariant}
          {...restProps}
          style={appliedStyle}
        />
      )}
    </div>
  );
});
