import { cloneDeep, isEmpty } from "lodash";
import { BizService, BizEntityApiResult } from "../explore/BizServiceCommon";
import {
  TriageConfigDTO,
  TriageConfigPayload,
  TRIAGE_CONFIG_LABEL,
  TriageConfigRequestPayload,
  TriagePathConfigListResponse,
  TriagePathConfigResponse
} from "../explore/types/triagePathTypes";
import { BizIdProps } from "../operationalise";

class Triage3ApiService extends BizService {
  async getTriagePathConfig(configId: string) {
    await this.init();
    const url = this.getBizEntityUrl(`triage/triage-path/config/${configId}`);
    const result: BizEntityApiResult<TriageConfigDTO> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };
    try {
      const response = await this.datasource.get<TriageConfigPayload, any>(url);
      if (response) {
        const d = response.data;
        result.data = {
          ...d,
          type: d.idProps.secondary.labels[TRIAGE_CONFIG_LABEL],
          configDTO: JSON.parse(d.jsonPayload || "{}")
        } as TriageConfigDTO;
      }
    } catch (err) {
      result.error = true;
      result.message = err as string;
      this.handleError(err, result);
    }
    return result;
  }

  async getTriagePathConfigs(triageConfigReq: TriageConfigRequestPayload) {
    await this.init();
    const { configType } = triageConfigReq;

    const bizProps = this.convertTriageReqPayloadToBizProps(triageConfigReq);
    const url = this.getBizEntityUrl(`triage-path/${configType}/search`);

    const result: BizEntityApiResult<TriageConfigDTO[]> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await this.datasource.post<TriagePathConfigListResponse, BizIdProps>(url, bizProps);
      if (response) {
        const { triagePath: triagePathConfig } = response.data;
        const data = triagePathConfig || [];
        result.data = (data || []).map(
          (d): TriageConfigDTO =>
            ({
              ...d,
              type: d.idProps?.secondary?.labels?.[TRIAGE_CONFIG_LABEL] || "",
              configDTO: JSON.parse(d.jsonPayload || "{}")
            }) as TriageConfigDTO
        );
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async upsertTriagePathConfig(triagePathConfigReq: TriageConfigDTO) {
    await this.init();
    const url = this.getBizEntityUrl(`triage-path/config`);
    const result: BizEntityApiResult<TriagePathConfigResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    const reqBodyPayload = cloneDeep(triagePathConfigReq);
    delete reqBodyPayload.configDTO;
    delete reqBodyPayload.type;

    try {
      const response = await this.datasource.post<any, TriageConfigPayload>(url, reqBodyPayload);
      const { errors } = response.data;
      if (!errors?.length) {
        result.message = response.statusText;
      } else {
        result.error = true;
        result.message = errors.join("\n");
      }
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  async deleteTriagePathConfig(configId: string) {
    await this.init();
    const url = this.getBizEntityUrl(`triage-path/config/${configId}`);

    const result: BizEntityApiResult<TriagePathConfigResponse> = {
      data: null,
      error: false,
      message: "",
      cancelled: false
    };

    try {
      const response = await this.datasource.delete(url);
      result.message = response.statusText;
    } catch (err) {
      this.handleError(err, result);
    }
    return result;
  }

  convertTriageReqPayloadToBizProps(reqPayload: TriageConfigRequestPayload): BizIdProps {
    const props: BizIdProps = {
      primary: {},
      secondary: {
        labels: {}
      }
    };

    if (!isEmpty(reqPayload.entityTypeId)) {
      props.primary.bizEntityTypeId = reqPayload.entityTypeId;
    }

    if (!isEmpty(reqPayload.eventTypes)) {
      props.primary.eventTypes = {
        userServiceInfo: reqPayload.eventTypes.map(usId => ({
          userServiceEntityId: usId
        }))
      };
    }

    if (!isEmpty(reqPayload.labels)) {
      props.secondary.labels = { ...reqPayload.labels };
    }

    if (!isEmpty(reqPayload.cohortId)) {
      props.secondary.cohortId = reqPayload.cohortId;
    }

    if (!isEmpty(reqPayload.widgetId)) {
      props.secondary.widgetId = reqPayload.widgetId;
    }

    if (!isEmpty(reqPayload.metricsId)) {
      props.secondary.labels["metricsId"] = reqPayload.metricsId;
    }

    if (!isEmpty(reqPayload.compareId)) {
      props.secondary.labels["compareId"] = reqPayload.compareId;
    }

    return props;
  }
}

export const triage3ApiService = new Triage3ApiService();
