import {
  WidgetCompareDataRequest,
  WidgetConfigDTO,
  SliceSpec,
  TagFilter,
  CohortEntityFilter,
  MetricUserServiceFilters,
  DemoDataParams,
  DataSortSpec
} from "../../api/explore";
import { DataQuery } from "../../api/types";

export enum ExploreQueryType {
  adhoc = "adhoc",
  saved = "saved"
}

export interface ExploreQueryPayload {
  /**
   * Only one of entityType or entityId is allowed.
   * entityType not null bizEntity, entityId not null implies userService
   */
  entityType: string;
  entityId: string;
  widgetId?: string;
  widgetConfig?: WidgetConfigDTO;
  version?: number;
  compareInfo: { enabled: boolean } & WidgetCompareDataRequest;
  sliceSpec: SliceSpec[];
  limit?: number;
  entityFilters: CohortEntityFilter[];
  tagFilters: TagFilter[];
  metricUserServiceFilters?: MetricUserServiceFilters;
  excludeWidgetData?: boolean;
  includeQueryConfig?: boolean;
  useV2ApiForCompare?: boolean;
  demoParams?: DemoDataParams;
  dataSortSpec?: DataSortSpec;
}
export interface ExploreQuery extends DataQuery {
  type: ExploreQueryType;
  payload: ExploreQueryPayload;
}
