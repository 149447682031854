import { last } from "lodash";
import { generateId, getAggregationFromAggrValue } from "../core";
import { MetricAggregatorExpanded } from "../core/data/types/MetricTypes";
import {
  OverTimeAggregators,
  OverTagAggregators,
  WidgetConfigDTO,
  UserServiceFieldMetricConfigDefinition,
  UserServiceField,
  UserServiceFieldSliceSet,
  UserServiceFieldSlice,
  MetricDefSliceProperties,
  ConditionWithPickerType,
  UserServiceFilterExpression,
  UserServiceFilterExpressionTree,
  UserServiceFilterNode
} from "../services/api/explore/types";
import { WidgetConfigUtils } from "../services/api/explore/widget/widgetConfigUtils";
import { ConditionWithPickerTypeExpressionTree, ConditionWithPickerTypeNode } from "../components/explore/types";
import { FieldPickerUtils } from "./FieldPickerUtils";

export class ExploreQueryUtils {
  static getOverTimeAggregatorForAggregator(aggregator: string): OverTimeAggregators {
    switch (aggregator) {
      case "avg":
      case "sum":
      case "min":
      case "max":
      case "P25":
      case "P50":
      case "P90":
      case "P95":
      case "P99":
        return aggregator;

      case "count":
        return "sum";

      case "distinctCount":
        return "avg";

      default:
        return "avg";
    }
  }

  static getOverTagAggregatorForAggregator(aggregator: string): OverTagAggregators {
    switch (aggregator) {
      case "avg":
      case "sum":
      case "min":
      case "max":
        return aggregator;

      case "count":
        return "sum";

      default:
        return "avg";
    }
  }

  static getUSFieldWidgetConfig(
    bizEntityType: string,
    usField: UserServiceField,
    aggregator: string,
    addUSFieldSlice: boolean,
    addImplicitSlice: boolean,
    metricId?: string,
    metricName?: string,
    additionalSlices?: UserServiceFieldSlice[]
  ): WidgetConfigDTO {
    const matchAggr =
      getAggregationFromAggrValue(aggregator as MetricAggregatorExpanded)?.longDisplayName || aggregator;

    metricId = metricId || generateId();
    metricName = metricName || FieldPickerUtils.generateMetricNamefromUSF(usField, matchAggr);

    const sliceSets = this.getSliceSets(bizEntityType, usField, addUSFieldSlice, addImplicitSlice, additionalSlices);

    const sliceProperties = sliceSets.map((ss): MetricDefSliceProperties => {
      const { slices } = ss;
      const tagName = last(slices)?.tagName;

      return {
        slices,
        legendsFormat: tagName ? `{{${tagName}}}` : metricName
      };
    });

    const metricDefinition: UserServiceFieldMetricConfigDefinition = {
      id: metricId,
      name: metricName,
      sourceType: "userServiceField",
      userServiceFieldMetricConfig: {
        aggregator,
        eventFilters: {
          userServiceFilters: []
        },
        sliceSets,
        userServiceField: usField,
        metricDefProperties: {
          sliceProperties
        }
      }
    };

    return {
      dataDefinition: {
        fields: {},
        metrics: {
          [metricId]: metricDefinition
        }
      },
      isStatic: false,
      name: metricName,
      visualizations: [
        {
          dataDefs: [
            {
              enabled: true,
              id: metricId,
              type: "userServiceField"
            }
          ],
          id: metricId,
          type: "timeseries"
        }
      ],
      bizEntityType,
      entityTypeName: bizEntityType
    };
  }

  static getSliceSets(
    entityTypeId: string,
    usField: UserServiceField,
    addUSFieldSlice: boolean,
    addImplicitSlice: boolean,
    addtionalSlices: UserServiceFieldSlice[] = []
  ): UserServiceFieldSliceSet[] {
    const implicitSlice = WidgetConfigUtils.getImplicitSlice(usField);

    implicitSlice.userServiceField.entityField = {
      propName: "",
      propType: "NA",
      entityType: entityTypeId
    };

    implicitSlice.userServiceField.fieldName = implicitSlice.userServiceField.bizEntityFieldName || "userService";

    const sliceSets: UserServiceFieldSliceSet[] = [];

    // Add implicit slice
    if (addImplicitSlice) {
      sliceSets.push({
        slices: [implicitSlice]
      });
    }

    if (addUSFieldSlice) {
      const usFieldSlice: UserServiceFieldSlice = {
        tagName: FieldPickerUtils.getPromSanitizedUSFName(usField),
        userServiceField: usField
      };
      sliceSets.push({
        slices: addImplicitSlice
          ? [implicitSlice, usFieldSlice, ...addtionalSlices]
          : [usFieldSlice, ...addtionalSlices]
      });
    } else if (addtionalSlices?.length) {
      sliceSets.push({
        slices: addImplicitSlice ? [implicitSlice, ...addtionalSlices] : [...addtionalSlices]
      });
    }

    if (sliceSets.length === 0) {
      sliceSets.push({
        slices: []
      });
    }

    return sliceSets;
  }

  static getDefaultWidgetConfig(): WidgetConfigDTO {
    return {
      dataDefinition: {
        fields: {},
        metrics: {}
      },
      isStatic: false,
      name: "",
      visualizations: [],
      bizEntityType: "",
      cohortDefinition: null,
      commonMetricProperties: null,
      userServiceEntityId: null
    };
  }

  static validateFilter(filter: ConditionWithPickerType) {
    return filter && (filter?.value === null || filter?.value || filter?.values?.length);
  }

  static getUserServiceFilterExpression(filter: ConditionWithPickerType) {
    return getUserServiceFilterExpression(filter);
  }

  static getUserServiceFilterExpressionTree(filterTree: ConditionWithPickerTypeExpressionTree, skipValidation = false) {
    return getUserServiceFilterExpressionTree(filterTree, skipValidation);
  }

  static getConditionFilters(usFilterExprs: UserServiceFilterExpression[]): ConditionWithPickerType[] {
    const filters = usFilterExprs.map(usFilterExpr => {
      if (!usFilterExpr) {
        return null;
      }

      return getConditionFilter(usFilterExpr);
    });

    return filters;
  }

  static getAdvancedConditionFilters(
    usFiltersTree: UserServiceFilterExpressionTree
  ): ConditionWithPickerTypeExpressionTree {
    return getConditionExpressionTree(usFiltersTree);
  }
}

const getConditionExpressionTree = (
  usFiltersTree: UserServiceFilterExpressionTree
): ConditionWithPickerTypeExpressionTree => {
  const { filterNodes, logicalOperator } = usFiltersTree;

  return {
    logicalOperator,
    filterNodes: getConditionNodes(filterNodes)
  };
};

const getConditionNodes = (filterNodes: UserServiceFilterNode[]) =>
  (filterNodes || []).map((filterNode): ConditionWithPickerTypeNode => {
    const { expression, expressionTree } = filterNode;

    if (expression) {
      return {
        expression: getConditionFilter(expression)
      };
    } else {
      return {
        expressionTree: getConditionExpressionTree(expressionTree)
      };
    }
  });

const getConditionFilter = (expression: UserServiceFilterExpression): ConditionWithPickerType => ({
  field: {
    payload: expression.field,
    type: "userServiceField"
  },
  operator: expression.operator,
  value: expression.value,
  values: expression.values
});

const getUserServiceFilterExpressionTree = (
  conditionsTree: ConditionWithPickerTypeExpressionTree,
  skipValidation = false
): UserServiceFilterExpressionTree => {
  const { filterNodes, logicalOperator } = conditionsTree;

  const usfFilterNodes = getFilterExpressionNodes(filterNodes, skipValidation).filter(Boolean);
  if (usfFilterNodes.length) {
    return {
      filterNodes: usfFilterNodes,
      logicalOperator
    };
  }

  return;
};

const getFilterExpressionNodes = (filterNodes: ConditionWithPickerTypeNode[], skipValidation = false) =>
  (filterNodes || []).map((filterNode): UserServiceFilterNode => {
    const { expression, expressionTree } = filterNode;

    if (expression) {
      const usfExpression = getUserServiceFilterExpression(expression, skipValidation);
      if (usfExpression) {
        return {
          expression: usfExpression
        };
      }
    } else {
      const usfExpressionTree = getUserServiceFilterExpressionTree(expressionTree, skipValidation);
      if (usfExpressionTree) {
        return {
          expressionTree: usfExpressionTree
        };
      }
    }

    return;
  });

const getUserServiceFilterExpression = (filter: ConditionWithPickerType, skipValidation = false) => {
  const isFilterValid = skipValidation ? true : ExploreQueryUtils.validateFilter(filter);

  if (!isFilterValid) {
    return;
  }

  const eFilter: UserServiceFilterExpression = {
    field: filter.field?.payload as UserServiceField,
    operator: filter.operator,
    value: filter.value,
    values: filter.values
  };
  if (filter.value === "Does not exist") {
    filter.value = null;
  }
  return eFilter;
};
