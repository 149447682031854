export const getImageUrlFromBlob = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function () {
      const imageDataURL = reader.result as string;
      const url = imageDataURL;

      resolve(url);
    };

    reader.onerror = error => reject(error);
    reader.readAsDataURL(blob);
  });
