import { debounce } from "lodash";
import { useCallback, useState, ChangeEvent, useMemo, useRef } from "react";

export const useInputState = (initialValue?: string, debouncedMillis = 0) => {
  const [value, setValue] = useState(initialValue || "");
  const [debouncedValue, setDebouncedValue] = useState(initialValue || "");
  const isDirtyRef = useRef(false);

  const onDebouncedInputChange = useMemo(
    () => debounce((value: string) => setDebouncedValue(value), debouncedMillis),
    [debouncedMillis]
  );

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      isDirtyRef.current = true;
      setValue(e.target.value);

      if (debouncedMillis) {
        onDebouncedInputChange(e.target.value);
      }
    },
    [debouncedMillis, onDebouncedInputChange]
  );

  return {
    inputValue: value,
    debounceInputValue: debouncedValue,
    onInputChange,
    setInputValue: setValue,
    isDirty: isDirtyRef.current
  };
};
