import invariant from "invariant";
import { isEmpty, isFunction, isString } from "lodash";
import { useContext, useEffect } from "react";
import { ReactReduxContext } from "react-redux";
import { Reducer } from "redux";
import { createReducer } from "./rootReducer";

const injectReducerFactory = (store: any) =>
  function registerReducer(key: string, reducer: Reducer) {
    invariant(
      isString(key) && !isEmpty(key) && isFunction(reducer),
      "Register Reducer: Expected `reducer` to be a reducer function"
    );
    if (Reflect.has(store.injectedReducers, key) && store.injectedReducers[key] === reducer) {
      return;
    }
    store.injectedReducers[key] = reducer;
    store.replaceReducer(createReducer(store.injectedReducers));
    return store;
  };

const getReducerInjector = (store: any) => ({
  registerReducer: injectReducerFactory(store)
});

const useRegisterReducer = (key: string, reducer: Reducer) => {
  const context = useContext(ReactReduxContext);

  useEffect(() => {
    getReducerInjector(context.store).registerReducer(key, reducer);
  });
};

export { useRegisterReducer };
