import { createAction } from "@reduxjs/toolkit";
import appConfig from "../../../../appConfig";
import { TimeRange } from "../types";
import timeRangeUtils from "../../../utils/TimeRangeUtils";
import { TenantConfigState, TenantDrilldownVersion, UITenantConfig } from "./types";

const defaultTenantConfig: UITenantConfig = {
  currency: "USD",
  demoTenant: false,
  pocTenant: false,
  enableBizFlow: false,
  defaultDrilldownVersion: TenantDrilldownVersion.v5,
  useOnboardingHome: false,
  useBizProcessHome: false,
  showDemoInsights: false,
  suggestionsLimit: appConfig.defaultSuggestionsLimit,
  showSubVerticalSwitcher: false,
  useNewNavigation: true
};

const defaultTenantConfigState: TenantConfigState = {
  tenantConfig: defaultTenantConfig,
  isFetching: true
};

const setTenantConfig = createAction<TenantConfigState>("set-tenant-config");

const updateAppConfig = (state: UITenantConfig) => {
  appConfig.tenantConfig = state;
};

const tenantConfigReducer = (state = defaultTenantConfigState, { type, payload }: any) => {
  let nState = state;
  switch (type) {
    case "set-tenant-config":
      nState = {
        ...state,
        ...payload
      };
      break;
    default:
      break;
  }
  updateAppConfig(nState?.tenantConfig);
  return nState;
};

export { setTenantConfig, tenantConfigReducer };

export const getTenantCustomTimeRanges = () => appConfig?.tenantConfig?.customTimeRanges || [];
export const getTenantDefaultTimeRange = (): TimeRange => {
  const customTimeRanges = getTenantCustomTimeRanges();
  const defaultTimeRange = customTimeRanges?.find(range => range.isDefault);

  return defaultTimeRange ? timeRangeUtils.getTimeRangeFromRaw(defaultTimeRange) : null;
};
