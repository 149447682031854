import appConfig from "../../../../appConfig";
import { Role } from "../../../core";
import { BizEntityApiResult, BizService } from "../explore/BizServiceCommon";
import {
  SuggestUsecasesResponse,
  WidgetDataFlowResponse,
  UseCaseCatalogList,
  UseCaseCatalogListRequest,
  CompanyInfoList,
  UseCaseCatalogListWithSchema,
  UseCaseCatalogWithSchema,
  UseCaseSummaryWithSchema,
  UseCaseSchema,
  SubVerticalList,
  CompanyInfo,
  UseCaseOp10zeQueryParams
} from "./types";
import { useCaseApiService } from "./UseCaseApiService";

class UseCaseCatalogApiService extends BizService {
  getCompanyInfos(role?: Role): Promise<BizEntityApiResult<CompanyInfoList>> {
    if (role === Role.Guest || appConfig.anomShareId) {
      return Promise.resolve({
        data: { companyInfo: [] },
        error: false,
        message: ""
      });
    }

    this.init();
    const subUrl = `fetch-company-infos`;
    const url = this.getUrl(subUrl);
    const request = () => this.datasource.get<CompanyInfoList, null>(url);
    return this.getResult(request);
  }

  getCompanyInfo(role: Role, companyName: string): Promise<BizEntityApiResult<CompanyInfo>> {
    if (role === Role.Guest || appConfig.anomShareId) {
      return Promise.resolve({
        data: {
          name: companyName,
          verticalToSubVerticals: {}
        },
        error: false,
        message: ""
      });
    }

    this.init();
    const subUrl = `get-company-info?companyName=${companyName}`;
    const url = this.getUrl(subUrl);
    const request = () => this.datasource.get<CompanyInfo, null>(url);
    return this.getResult(request);
  }

  suggestSubVerticalsForCompany(companyName: string): Promise<BizEntityApiResult<SubVerticalList>> {
    this.init();
    const subUrl = `${companyName}/suggest-subverticals`;
    const url = this.getUrl(subUrl);
    const request = () => this.datasource.get<SubVerticalList, null>(url);
    return this.getResult(request);
  }

  async getUseCaseCatalogConfig(
    verticalId: string,
    subVerticalId: string,
    companyName: string,
    queryParams?: UseCaseOp10zeQueryParams
  ) {
    await this.init();

    const subUrl = `list`;
    const url = this.getUrl(subUrl);

    const payload: UseCaseCatalogListRequest = {
      companyName,
      subVerticalId,
      verticalId
    };

    Object.keys(payload).forEach(key => {
      const cKey = key as keyof UseCaseCatalogListRequest;
      if (!payload[cKey]) {
        delete payload[cKey];
      }
    });

    const request = () =>
      this.datasource.post<UseCaseCatalogList, UseCaseCatalogListRequest>(url, payload, {
        params: queryParams
      });
    return this.getResult(request);
  }

  async getUseCaseCatalogConfigWithSchema(
    verticalId: string,
    subVerticalId: string,
    companyName: string,
    sanitizeConfigs = true,
    filterOutDraftOpInsights = true,
    useCaseCatalogConfigParams?: UseCaseOp10zeQueryParams
  ) {
    const result: BizEntityApiResult<UseCaseCatalogListWithSchema> = {
      data: null,
      error: false,
      message: ""
    };

    try {
      const { data, error, message } = await this.getUseCaseCatalogConfig(
        verticalId,
        subVerticalId,
        companyName,
        useCaseCatalogConfigParams
      );

      if (error) {
        result.message = message;
        result.error = true;
      } else {
        const schemaCatalogs: UseCaseCatalogWithSchema[] = [];
        const schemaFetchParams: Array<[number, number, string]> = [];

        (data.catalogs || []).forEach((catalog, catalogIdx) => {
          const { useCases, ...restCatalog } = catalog;

          const summariesWithSchema = useCases
            .map(
              (summary): UseCaseSummaryWithSchema => ({
                ...summary,
                dataQueryConfigs: []
              })
            )
            .filter(useCase => Boolean(useCase.id) && Boolean(useCase.name));

          summariesWithSchema.forEach((summary, useCaseIdx) =>
            schemaFetchParams.push([catalogIdx, useCaseIdx, summary.id])
          );

          schemaCatalogs.push({
            ...restCatalog,
            useCases: summariesWithSchema
          });
        });

        const errors: string[] = [];
        const schemaPromises = schemaFetchParams.map(params => {
          const [catalogIdx, useCaseIdx, useCaseId] = params;
          return useCaseApiService
            .getUseCaseSchema(useCaseId)
            .then(res => ({
              ...res,
              catalogIdx,
              useCaseIdx
            }))
            .catch(err => ({
              data: null as UseCaseSchema,
              error: true,
              message: (err.message || String(err)) as string,
              catalogIdx,
              useCaseIdx,
              cancelled: false
            }));
        });

        const schemas = await Promise.allSettled(schemaPromises);

        schemas.forEach(schema => {
          if (schema.status === "fulfilled") {
            const { data, error, message, catalogIdx, useCaseIdx } = schema.value;

            if (error) {
              errors.push(message);
            } else {
              const catalogObj = schemaCatalogs[catalogIdx];
              if (catalogObj) {
                const useCasesArr = catalogObj.useCases || [];
                const useCaseObj = useCasesArr[useCaseIdx];

                if (useCaseObj) {
                  useCaseObj.dataQueryConfigs = (data?.useCaseConfig?.dataQueryConfigs || []).filter(dqc => {
                    let canInclude = true;
                    if (sanitizeConfigs) {
                      canInclude = Boolean(dqc.id) && Boolean(dqc.name);
                    }

                    if (filterOutDraftOpInsights) {
                      canInclude = canInclude && Boolean(dqc.relatedOpConfigs?.[0]?.bizDataOperationalize?.opConfigId);
                    }

                    return canInclude;
                  });
                }
              }
            }
          } else {
            errors.push(schema.reason.message);
          }
        });

        schemaCatalogs.forEach(catalog => {
          catalog.useCases = catalog.useCases.sort((a, b) => a.name.localeCompare(b.name));
        });

        result.data = {
          catalogs: schemaCatalogs
        };
        result.error = errors.length > 0;
        result.message = errors.join(", ");
      }
    } catch (err) {
      result.error = true;
      result.message = err.message || String(err);
    }

    return result;
  }

  async suggestUseCasesByVertical(vertical: string, subVertical: string) {
    await this.init();

    const subUrl = `${vertical}/${subVertical}/suggest`;
    const url = this.getUrl(subUrl);

    const request = () => this.datasource.get<SuggestUsecasesResponse, null>(url);
    return this.getResult(request);
  }

  async getWidgetDataFlow(
    entityType: string,
    eventTypeId: string,
    widgetId: string,
    startTime: number,
    endTime: number
  ) {
    await this.init();

    const timeParams = this.getTimeParams(startTime, endTime);
    const url = this.getWidgetUrl(eventTypeId, entityType, `/${widgetId}/dataFlow${timeParams}`);
    const request = () => this.datasource.post<WidgetDataFlowResponse, unknown>(url, {});
    return this.getResult(request);
  }

  async getUseCaseDataFlow(useCaseId: string, startTime: number, endTime: number) {
    await this.init();

    const requestPayload = {
      useCaseId
    };

    const timeParams = this.getTimeParams(startTime, endTime);
    const url = this.getBizEntityUrl(`/usecase/v2/dataFlow${timeParams}`);
    const request = () => this.datasource.post<WidgetDataFlowResponse, typeof requestPayload>(url, requestPayload);
    return this.getResult(request);
  }

  private getUrl(subPath: string) {
    return this.getBizEntityUrl(`usecaseCatalog/${subPath}`);
  }
}

export const useCaseCatalogApiService = new UseCaseCatalogApiService();
